import { AlertDialog, Button, HStack, Text, VStack, View } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../styles/GlobalStyle";
import { BoldTxt, SmallBoldTxt, Txt } from "../TextsComponents";
import DimensionsHook from "../../hooks/DimensionsHook";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Spinner from "../Spinner";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useSelector } from "react-redux";
import axios from "axios";
import CommunityConditionsModal from "./communityConditionsModal";
import { Platform } from "react-native";

const ChatEnterModal = ({
  isOpen,
  setIsOpen,
  navigation,
  times,
  setShowGoFlashCoachingModal,
  setShowChatModal,
}) => {
  const { t } = useTranslation();

  const { isMobile, isDesktop } = DimensionsHook();

  const cancelRef = React.useRef(null);
  const [showChartModal, setShowChartModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null)
  const [loader, setLoader] = useState(false);
  //times.filter((el) => el.open === true);
  const { subscription_info, company } = useSelector((state) => state.userData);
  const enterChat = () => {
    setIsOpen(false);
    setLoader(false);
    if (Platform.OS == "web" && isDesktop) {
      setShowChatModal(true);
    } else {
      navigation.navigate("Chat");
    }
  };

  const submitHandler = async () => {
    if (isDisabled) {
      setErrorMessage(t('chat.error'))
    } else {
      setErrorMessage(null)
      setLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      axios
        .get(process.env.USER_CHAT_COUNT, config)
        .then((res) => {
          if (res.data < 3) {
            axios
              .post(process.env.CHAT_ENTRIES, {
                access_token: TOKEN,
                subscription_info,
                company,
              })
              .then(() => {
                enterChat();
              })
              .catch((err) => {
                setLoader(false);
              });
          } else {
            if (setShowGoFlashCoachingModal) {
              setShowGoFlashCoachingModal(true);
            }
            setIsOpen(false);
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    }

  };

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      const today = new Date();

      const todayDay = today.getDay();
      const todayTime = times.find((el) => el._id == todayDay);
      if (todayTime) {
        // case today is open check time
        let i = 0;

        while (i < todayTime.documents.length) {
          // Perform the desired operation on arr[i]
          const startSplitted = todayTime.documents[i].start.split(":");
          const endSplitted = todayTime.documents[i].end.split(":");

          const startTime = new Date();
          startTime.setHours(startSplitted[0]);
          startTime.setMinutes(startSplitted[1]);
          startTime.setSeconds(0);
          const endTime = new Date();
          endTime.setHours(endSplitted[0]);
          endTime.setMinutes(endSplitted[1]);
          endTime.setSeconds(0);

          const startTimeStamp = startTime.getTime();
          const endTimeStamp = endTime.getTime();
          const todayStamp = today.getTime();

          // console.log(i, startTimeStamp, endTimeStamp, todayStamp);
          // Check if the condition is met
          if (todayStamp > startTimeStamp && todayStamp < endTimeStamp) {
            // case today ok &&  NOW is  between start time and end time
            setIsDisabled(false);

            break; // Exit the loop and function
          } else {
            setIsDisabled(true);
          }
          i++; // Increment the index
        }
      } else {
        setIsDisabled(true);
        //console.log("no enter !");
      }
    }
  }, [isOpen]);

  return (
    <AlertDialog
      style={{ backgroundColor: "#00000080" }}
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialog.Content
        style={{
          width: "95%",
          maxWidth: 700,
        }}
      >
        <AlertDialog.Header>
          <AlertDialog.CloseButton focusable={false} />

          <HStack alignItems="center">
            <BoldTxt style={{ fontSize: 16 }}>{t("chat.title")}</BoldTxt>
          </HStack>
        </AlertDialog.Header>

        <AlertDialog.Body style={{ paddingHorizontal: isMobile ? 5 : 30 }}>
          <View my="2">
            <Txt style={{ fontSize: 17 }}>{t("chat.title1")}</Txt>
            <Txt style={{ marginTop: 10, fontSize: isMobile ? 14 : 16 }}>
              {t("chat.text3")}
            </Txt>
            <Txt style={{ marginTop: 10, fontSize: isMobile ? 14 : 16 }}>
              {t("chat.text4")}
            </Txt>
          </View>
          {times.map((day) => {
            return <RenderDay key={day._id} day={day} />;
          })}
          <VStack mt="4">
            <Txt style={{ fontSize: 14 }}>
              {t("chat.text1")}{" "}
              <BoldTxt
                style={{ fontSize: 14, textDecoration: "underline" }}
                onPress={() => setShowChartModal(true)}
              >
                {t("chat.text2")}
              </BoldTxt>
            </Txt>
          </VStack>
          {errorMessage && <BoldTxt style={{ fontSize: 14, color: colors.red1, textAlign: 'center', marginHorizontal: 20, marginTop: 15 }}>
            {errorMessage}
          </BoldTxt>}
          <Button.Group mt="5" mb="2" alignSelf="center" space={2}>
            <Button
              focusable={false}
              style={{
                backgroundColor: "transparent",
                borderWidth: 1,
                borderColor: colors.blue3,
                width: 140,
                height: 40,
              }}
              onPress={onClose}
              ref={cancelRef}
            >
              <Txt style={{ fontSize: 14, color: colors.blue3 }}>
                {t("delete_account_modal.cancel")}
              </Txt>
            </Button>
            <Button

              focusable={false}
              style={{
                backgroundColor: colors.green2,
                color: colors.white,
                height: 40,
                width: 140,

              }}
              onPress={submitHandler}
            >
              {loader ? (
                <Spinner color={colors.white} />
              ) : (
                <Txt
                  style={{
                    fontSize: 14,
                    color: colors.white,
                  }}
                >
                  {t("chat.button")}
                </Txt>
              )}
            </Button>
          </Button.Group>
        </AlertDialog.Body>
        {/** charte modal to accept if first visit  */}
        <CommunityConditionsModal
          navigation={navigation}
          community_visit={true}
          isOpen={showChartModal}
          setIsOpen={setShowChartModal}
          getCurrentTrails={() => { }}
        />
      </AlertDialog.Content>
    </AlertDialog>
  );
};

const RenderDay = ({ day }) => {
  const { t } = useTranslation();
  const d = t(`days.${day._id}`);
  const times = day.documents;

  times.sort((a, b) => {
    const startTimeA = a.start;
    const startTimeB = b.start;

    if (startTimeA < startTimeB) {
      return -1;
    } else if (startTimeA > startTimeB) {
      return 1;
    }
    return 0;
  });

  //console.log(d, times);
  let merged = [];

  for (let i = 0; i < times.length; i++) {
    if (merged.length == 0 || times[i].start > merged[merged.length - 1].end) {
      merged.push(times[i]);
    } else {
      merged[merged.length - 1].end =
        merged[merged.length - 1].end > times[i].end
          ? merged[merged.length - 1].end
          : times[i].end;
    }
  }
  //console.log(d, merged);

  return (
    <VStack
      mt="4"
      justifyContent="space-between"
      style={{
        borderBottomWidth: 1,
        paddingBottom: 5,
        borderColor: colors.grayBorder,
      }}
    >
      <HStack alignItems="flex-end">
        <MaterialCommunityIcons
          name="account-clock"
          size={20}
          color={colors.blue3}
        />
        <Txt style={{ marginLeft: 4, fontSize: 15 }}>{" • " + d}</Txt>
      </HStack>
      {merged.map((time) => {
        return (
          <HStack
            style={{ marginLeft: 42, marginTop: 6 }}
            key={time._id}
            alignItems="center"
          >
            <SmallBoldTxt
              style={{ fontSize: 13.5 }}
            >{`${time.start} →`}</SmallBoldTxt>
            <SmallBoldTxt
              style={{ fontSize: 13.5 }}
            >{` ${time.end}`}</SmallBoldTxt>
          </HStack>
        );
      })}
    </VStack>
  );
};

export default ChatEnterModal;
