import {
    StyleSheet,
    ScrollView,
    ImageBackground,
    Image,
    Platform,
} from "react-native";
import React, { useState } from "react";
import BackHeader from "../../components/BackHeader";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { useTranslation } from "react-i18next";
import { DomPiece } from "../../assets/dominos/DominosIcon";
import { HStack, VStack, View, } from "native-base";
import { H2, H6, Txt } from "../../components/TextsComponents";
import * as Animatable from "react-native-animatable";
import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import GreenModal from "../../components/modals/GreenModal";
import RedModal from "../../components/modals/RedModal";
import Spinner from "../../components/Spinner";

const Game = ({ navigation, route }) => {

    const level = route.params;
    const { isBigScreen } = DimensionsHook();
    const { t, i18n } = useTranslation();
    const [selectedPiece, setSelectedPiece] = useState(null);
    const [choices, setChoices] = useState(level.choisePieces);
    const [isCorrectAnswer, setIsCorrectAnswer] = useState(null);
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);
    const [isFailOpen, setIsFailOpen] = useState(false);
    const [gameLoader, setGameLoader] = useState(false)
    // number of tentatives
    const [number, setNumber] = useState(0);

    const RenderStars = ({ value, withAnimation }) => {
        if (withAnimation) {
            return (
                <HStack
                    space="2"
                    alignSelf="center"
                    alignItems="center"
                    justifyContent="center"
                    mb="2"
                >
                    <Animatable.View animation="tada" easing="linar" delay={0} useNativeDriver={Platform.OS != "web"} duration={1000} iterationCount="infinite">


                        <FontAwesome
                            color={value == 0 ? colors.blue3 : colors.yellow}
                            style={{ fontSize: 35 }}
                            name={value == 0 ? "star-o" : "star"}
                        />
                    </Animatable.View>

                    <Animatable.View animation="tada" easing="linar" delay={200} useNativeDriver={Platform.OS != "web"} duration={1000} iterationCount="infinite">
                        <FontAwesome
                            color={value > 1 ? colors.yellow : colors.blue3}
                            style={{ fontSize: 35 }}
                            name={value > 1 ? "star" : "star-o"}
                        />
                    </Animatable.View>
                    <Animatable.View animation="tada" easing="linar" delay={400} useNativeDriver={Platform.OS != "web"} duration={1000} iterationCount="infinite">

                        <FontAwesome
                            color={value != 3 ? colors.blue3 : colors.yellow}
                            style={{ fontSize: 35 }}
                            name={value != 3 ? "star-o" : "star"}
                        />
                    </Animatable.View>
                </HStack>
            );
        }
        return (
            <HStack
                space="0.5"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
            >
                <FontAwesome
                    color={value == 0 ? "#484848" : colors.yellow}
                    style={{ fontSize: 22 }}
                    name={value == 0 ? "star-o" : "star"}
                />
                <FontAwesome
                    color={value > 1 ? colors.yellow : "#484848"}
                    style={{ fontSize: 22 }}
                    name={value > 1 ? "star" : "star-o"}
                />
                <FontAwesome
                    color={value != 3 ? "#484848" : colors.yellow}
                    style={{ fontSize: 22 }}
                    name={value != 3 ? "star-o" : "star"}
                />
            </HStack>
        );
    };

    const choosePieceHandler = async (p, i) => {
        if (isCorrectAnswer != true) {
            setSelectedPiece(p);
            setTimeout(async () => {
                setNumber(number + 1);
                if (
                    p.down == level.correctPiece.down &&
                    p.up == level.correctPiece.up
                ) {
                    setIsCorrectAnswer(true);
                    setIsSuccessOpen(true)
                    const TOKEN = await AsyncStorage.getItem("access_token");
                    axios
                        .post(`${process.env.GAMES}/save-dominos-answer`, {
                            access_token: TOKEN,

                            star: number == 0 ? 3 : number == 1 ? 2 : 1,
                            level: level._id,
                        })
                        .then(() => { });
                } else {
                    setIsFailOpen(true)
                    setIsCorrectAnswer(false);

                    let arr = choices;
                    const newArr = arr.filter(
                        (item) => item.up != p.up || item.down != p.down
                    );

                    setChoices(newArr);
                }
            }, 150);
        }
    };

    //const isLastLevel = dominos[dominos.length - 1]._id == level._id;

    const buttonHandler = async () => {
        const number = Math.random()

        if (number < 0.5) {
            if (!gameLoader) {
                setGameLoader(true)
                const TOKEN = await AsyncStorage.getItem("access_token");
                const config = {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                    },
                };
                axios
                    .get(
                        `${process.env.GAMES}/random-game?game=${level._id}`,
                        config
                    )
                    .then((res) => {

                        setGameLoader(false);
                        navigation.replace('DominoGame', res.data)
                    })
                    .catch((err) => {
                        setGameLoader(false);

                    });
            }
        } else {
            navigation.replace('CardFlip')
        }

    };

    const RenderPieces = ({ correct }) => {

        return (
            <HStack
                mb="6"
                mt="8"
                alignItems="center"
                justifyContent="center"

                space="3"
            >
                {level.gamePieces.map((piece, i) => (
                    <Animatable.View
                        useNativeDriver={Platform.OS == "web" ? false : true}
                        iterationCount={1}
                        animation="flipInY"
                        duration={600}

                        delay={300}
                        style={{ width: 50, height: 100 }}
                        key={`${piece.up}_${piece.down}`}

                    >
                        <DomPiece up={piece.up} down={piece.down} />
                    </Animatable.View>
                ))}
                {/** selected Piece */}
                <Animatable.View
                    useNativeDriver={Platform.OS == "web" ? false : true}
                    iterationCount={1}
                    animation="flipInY"
                    duration={1200}

                    delay={900}
                    style={{ width: 50, height: 100 }}

                >
                    {selectedPiece ? (
                        <DomPiece
                            choice={true}
                            isCorrectAnswer={correct}
                            up={selectedPiece.up}
                            down={selectedPiece.down}
                        />
                    ) : (
                        <VStack
                            style={{
                                width: 50,
                                height: 94,
                                marginTop: 3,
                                borderWidth: 3,
                                borderColor: colors.blue3,
                                borderRadius: 10,
                            }}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <FontAwesome5
                                name="question"
                                size={24}
                                color={colors.blue3}
                            />
                        </VStack>
                    )}
                </Animatable.View>
            </HStack>
        )



    }



    return (
        <View style={styles.container}>
            <ImageBackground
                source={require("../../assets/background.png")}
                style={{ flex: 1 }}
            >
                <ScrollView>
                    <BackHeader
                        isOpen={false}
                        setIsOpen={() => { }}
                        navigation={navigation}
                    />

                    <VStack position="absolute" style={{ right: 20, top: 26 }}>
                        {level.answer_data[0] ? (
                            <RenderStars value={level.answer_data[0].star} />
                        ) : (
                            <Txt style={{ fontSize: 14, color: "#484848" }}>
                                {number} {t("dominos.try")}
                            </Txt>
                        )}
                    </VStack>
                    <VStack
                        px="3"
                        style={{
                            width: "95%",
                            alignSelf: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            maxWidth: 900,

                        }}
                    >
                        <H2
                            style={{ fontSize: 26, textAlign: "center", marginVertical: 20 }}
                            color="#484848"
                        >
                            Dominos
                        </H2>

                        <Txt
                            style={{ fontSize: 18, textAlign: "center" }}
                            color={colors.blue3}
                        >
                            {i18n.language == "fr" ? level.text1_fr : level.text1_en}
                        </Txt>
                        {/** 4 pieces + show the choice when selected */}
                        <HStack
                            mb="6"
                            mt="8"
                            alignItems="center"
                            justifyContent="center"
                            space="3"
                        >
                            {level.gamePieces.map((piece, i) => (
                                <Animatable.View
                                    useNativeDriver={Platform.OS == "web" ? false : true}
                                    iterationCount={1}
                                    animation="flipInX"
                                    duration={800}

                                    delay={800}
                                    style={{ width: 50, height: 100 }}
                                    key={`${piece.up}_${piece.down}`}
                                >
                                    <DomPiece up={piece.up} down={piece.down} />
                                </Animatable.View>
                            ))}
                            {/** selected Piece */}
                            <Animatable.View
                                useNativeDriver={Platform.OS == "web" ? false : true}
                                iterationCount={1}
                                animation="flipInX"
                                duration={800}

                                delay={800}
                                style={{ width: 50, height: 100 }}
                            >
                                {selectedPiece ? (
                                    <DomPiece
                                        choice={true}
                                        isCorrectAnswer={isCorrectAnswer}
                                        up={selectedPiece.up}
                                        down={selectedPiece.down}
                                    />
                                ) : (
                                    <VStack
                                        style={{
                                            width: 50,
                                            height: 94,
                                            marginTop: 3,
                                            borderWidth: 3,
                                            borderColor: colors.blue3,
                                            borderRadius: 10,
                                        }}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <FontAwesome5
                                            name="question"
                                            size={24}
                                            color={colors.blue3}
                                        />
                                    </VStack>
                                )}
                            </Animatable.View>
                        </HStack>
                        <Animatable.View
                            animation="fadeIn"
                            delay={1600}
                            iterationCount={1}
                            duration={800}

                        >
                            {/**  choises  */}

                            {isCorrectAnswer != true && (
                                <VStack>
                                    <VStack>
                                        <Txt
                                            style={{ fontSize: 18, textAlign: "center" }}
                                            color={colors.blue3}
                                        >
                                            {i18n.language == "fr" ? level.text2_fr : level.text2_en}
                                        </Txt>
                                    </VStack>
                                    <HStack
                                        my="6"
                                        alignItems="center"
                                        justifyContent="center"
                                        space="4"
                                    >
                                        {choices.map((piece, i) => (
                                            <Animatable.View
                                                useNativeDriver={Platform.OS == "web" ? false : true}
                                                iterationCount={1}
                                                animation="flipInY"
                                                duration={1200}

                                                delay={2500}
                                                style={{ width: 50, height: 100 }}
                                                key={`${piece.up}_${piece.down}`}
                                            >
                                                <TouchableOpacity
                                                    disabled={piece.disabled ? true : false}
                                                    onPress={() => choosePieceHandler(piece, i)}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        opacity: piece.disabled ? 0.7 : 1,
                                                    }}
                                                >
                                                    <DomPiece up={piece.up} down={piece.down} />
                                                </TouchableOpacity>
                                            </Animatable.View>
                                        ))}
                                    </HStack>
                                </VStack>
                            )}

                        </Animatable.View>
                    </VStack>
                </ScrollView>
                {isBigScreen && (
                    <Image
                        source={{
                            uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
                        }}
                        style={styles.image}
                    />
                )}
            </ImageBackground>
            {/** fail modal */}
            <RedModal
                isOpen={isFailOpen}
                setIsOpen={setIsFailOpen}
                title={t('dominos.title2')}
                text={t('dominos.text2')}
                renderStars={() => (
                    <VStack>


                        <VStack >
                            <RenderPieces correct={false} />
                            <HStack space="1" my="5" alignItems='center' justifyContent="center">


                                <PrimaryButton

                                    onPress={() => {
                                        setIsFailOpen(false)
                                    }}
                                >
                                    {/*isLastLevel ? t("dominos.exit") : t("dominos.next")*/}
                                    Réssayer
                                </PrimaryButton>
                            </HStack>

                        </VStack>
                    </VStack>
                )}
            />
            {/** success modal */}
            <GreenModal
                navigation={navigation}
                isOpen={isSuccessOpen}
                setIsOpen={setIsSuccessOpen}
                title={t('dominos.title1')}
                text={level.answer_data[0] ? "" : number > 2 ? "" : `${t('dominos.win')} ${number == 1 ? 2 : 1} ${number == 1 ? t('score_screen.points') : t('score_screen.point')}`}
                renderStars={() => (
                    <VStack>

                        <RenderStars
                            withAnimation={true}
                            value={number == 1 ? 3 : number == 2 ? 2 : 1}
                        />
                        <VStack >
                            <RenderPieces correct={true} />
                            <HStack space="1" my="5" alignItems='center' justifyContent="center">

                                <SecondaryButton
                                    style={{ backgroundColor: colors.grayLabel }}
                                    isWhite={true}
                                    onPress={() => navigation.goBack()}
                                >
                                    {/*isLastLevel ? t("dominos.exit") : t("dominos.next")*/}
                                    Sortir
                                </SecondaryButton>
                                <PrimaryButton

                                    onPress={buttonHandler}
                                >
                                    {/*isLastLevel ? t("dominos.exit") : t("dominos.next")*/}
                                    {gameLoader ? <Spinner color="white" /> : t('card_game.play')}
                                </PrimaryButton>
                            </HStack>

                        </VStack>
                    </VStack>
                )}
            />
        </View>
    );
};

export default Game;

const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.beige,
        flex: 1,
    },

    image: {
        position: "absolute",
        bottom: 0,
        right: 20,
        height: 200,
        width: 120,
    },
});
