import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Platform,
  ImageBackground,
} from "react-native";
import React, { useEffect, useState } from "react";
import { colors } from "../../styles/GlobalStyle";
import HeaderComponent from "../../components/HeaderComponent";
import { H6, BoldTxt, Txt } from "../../components/TextsComponents";
import DimensionsHook from "../../hooks/DimensionsHook";
import Swiper from "../../components/swiper/Swiper";
import { DropDown } from "../../components/Inputs";
import Footer from "../../components/Footer";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import axios from "axios";
import CurrentTrailsSwiper from "../../components/swiper/CurrentTrailsSwiper";
import { CustomTooltip } from "../../components/Tooltips";
import TutoVideo from "../../components/videos/TutoVideo";
import {
  setAnsweredQuestionnaires,
  setMonthlyMessage,
  setQuestionnaires,
} from "../../redux/Actions";
import MonthlyMessageModal from "../../components/modals/MonthlyMessageModal";
import ForBeginSection from "../../components/swiper/ForBeginSection";
import { MaterialIcons, Octicons } from "@expo/vector-icons";
import TrailerVideo from "../../components/videos/TrailerVideo";
import NavigateToWelcomeQuestionnaire from "../../components/modals/QuestionnairesModal/NavigateToWelcomeQuestionnaire";
import HomeSearch from "./HomeSearch";

const HomeMain = ({ navigation }) => {
  const userActivity = useSelector((state) => state.userActivity);
  const questionnairesData = useSelector((state) => state.questionnairesData);
  const answeredQuestionnaires = useSelector(
    (state) => state.answeredQuestionnaires
  );
  const { isMobile, isDesktop, height } = DimensionsHook();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [currentTrails, setCurrentTrails] = useState([]);
  const [episodesNumber, setEpisodesNumber] = useState(0);
  const [questionnairesLength, setQuestionnairesLength] = useState(0);
  const [workshopsNumber, setWorkshopsNumber] = useState(0);
  const [isOpenSuggestion, setIsOpenSuggestion] = useState(false);
  const Categories = [
    {
      label: t("workshop_types.label"),
      value: "",
    },
    {
      label: t("workshop_types.conscience"),
      value: "conscience",
    },
    {
      label: t("workshop_types.corps"),
      value: "body",
    },
    {
      label: t("workshop_types.plaisir"),
      value: "fun",
    },
    {
      label: t("workshop_types.rituels"),
      value: "rituals",
    },
    {
      label: t("workshop_types.science"),
      value: "science",
    },
    {
      label: t("workshop_types.temoignages"),
      value: "testimony",
    },
    {
      label: t("workshop_types.outils"),
      value: "tools",
    },
  ];

  const swiperContainerStye = {
    backgroundColor: colors.white,
    width: "95%",
    alignSelf: "center",
    paddingTop: 15,
    paddingBottom: 5,
    borderRadius: 20,
    marginTop: 20,
    paddingLeft: isMobile ? 10 : 20,
    paddingRight: isMobile ? 0 : 20,
  };
  const loaderContainer = {
    height: height - 140,
    alignItems: "center",
    justifyContent: "center",
  };

  const [showTuto, setShowTuto] = useState(false);
  const [showTrailer, setShowTrailer] = useState(false);
  const [showMonthlyMessage, setShowMonthlyMessage] = useState(false);
  const [showWelcomeQuestionnaire, setShowWelcomeQuestionnaire] =
    useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [selectedCategorie, setSelectedCategorie] = useState(Categories[0]);
  const [keyword, setKeyword] = useState("");

  const searchHandler = async (searchText) => { };

  /*const OpeningPopUpHandler = async () => {
    await dispatch(setAnsweredQuestionnaires());
    if (
      questionnairesData.length > 0 &&
      questionnairesData.length !== questionnairesLength
    ) {
      setQuestionnairesLength(questionnairesData.length);
      const welcomeQuestionnaire = questionnairesData.find(
        (el) => el.welcome == true
      );

      if (welcomeQuestionnaire) {
        const isWelcomeAnswered = answeredQuestionnaires.includes(
          welcomeQuestionnaire._id
        );

        if (!isWelcomeAnswered) {
          const isSetToNotRemind = await AsyncStorage.getItem("amont_welcome");

          if (!isSetToNotRemind) {
            setTimeout(() => {
              setShowWelcomeQuestionnaire(true);
            }, 500);
          }
        }
      }
    }
  };*/

  const getCurrentTrails = async () => {
    const TOKEN = await AsyncStorage.getItem("access_token");
    await AsyncStorage.setItem('logged', "true")
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const url = `${process.env.QUESTIONNAIRES_DATA}?language=${i18n.language}`;
    await dispatch(setQuestionnaires(url));
    await dispatch(setMonthlyMessage());
    await dispatch(setAnsweredQuestionnaires());
    // get number of episodes and workshops + get current activity ressources
    axios
      .get(`${process.env.RESSOURCES_NUMBER}?language=${i18n.language}`, config)
      .then(async (response) => {
        setEpisodesNumber(response.data.episodesNumber);
        setWorkshopsNumber(response.data.workshopsNumber);
        axios
          .get(
            `${process.env.CURRENT_TRAILS}?language=${i18n.language}`,
            config
          )
          .then((res) => {
            // remove finished trails from current trails
            const data = res.data.filter(
              (trail) =>
                !userActivity.finished_trails.includes(trail.trail_code)
            );
            setCurrentTrails(data);

            setLoader(false);
          });
      });
  };

  /*useEffect(() => {
    OpeningPopUpHandler();
  }, [questionnairesData]);*/

  useEffect(() => {
    getCurrentTrails();
  }, [i18n.language, userActivity]);

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView showsVerticalScrollIndicator={Platform.OS === "web"}>
          <View>
            <HeaderComponent
              name={t("tab_navigation.home")}
              navigation={navigation}
            />
            {loader ? (
              <View style={loaderContainer}>
                <Spinner size="large" />
              </View>
            ) : (
              <View>
                <View style={{ minHeight: height - 160 }}>
                  {/** website + trailer */}

                  <View
                    style={[
                      styles.greenContainer,
                      {
                        paddingHorizontal: isMobile ? 10 : 20,
                      },
                    ]}
                  >
                    {/** open www.lislup.com */}
                    <View style={styles.siteContainer}>
                      <Octicons name="info" size={20} color={colors.blue3} />
                      <Txt
                        style={{ fontSize: 14, marginLeft: 5 }}
                        color={colors.blue3}
                      >
                        {t("home_main.info1")}{" "}
                        <BoldTxt
                          onPress={() => {
                            if (Platform.OS == "web") {
                              setLoader(false);
                              window.open("https://lislup.com", "_blank");
                            } else {
                              Linking.openURL("https://lislup.com");
                              setLoader(false);
                            }
                          }}
                          style={{
                            textDecorationLine: "underline",
                            fontSize: 14,
                          }}
                          color={colors.blue3}
                        >
                          {t("home_main.info2")}.
                        </BoldTxt>
                      </Txt>
                    </View>

                    <TouchableOpacity
                      onPress={() => setShowTrailer(true)}
                      style={styles.tutoButton}
                    >
                      <MaterialIcons
                        name="slideshow"
                        size={18}
                        color={colors.white}
                        style={{ marginRight: 3 }}
                      />
                      <Txt style={{ fontSize: 13 }} color={colors.white}>
                        Trailer
                      </Txt>
                    </TouchableOpacity>
                  </View>

                  {/** search bar */}
                  <View
                    style={{
                      marginHorizontal: "2.5%",
                      marginTop: 10,
                    }}
                  >
                    <View
                      style={{
                        width: isMobile ? "100%" : 300,
                        alignSelf: "flex-end",
                      }}
                    >
                      <HomeSearch
                        keyword={keyword}
                        setKeyword={setKeyword}
                        searchHandler={searchHandler}
                        isOpenSuggestion={isOpenSuggestion}
                        setIsOpenSuggestion={setIsOpenSuggestion}
                      />
                    </View>
                  </View>
                  {/** for begin */}

                  <View style={[swiperContainerStye, { zIndex: -1 }]}>
                    <View style={styles.row}>
                      <CustomTooltip
                        placement="left top"
                        label={t("begin.title")}
                        bg={colors.blue3}
                      >
                        <View>
                          <H6>{t("begin.title")}</H6>
                        </View>
                      </CustomTooltip>
                    </View>

                    <ForBeginSection
                      openTutoHandler={() => setShowTuto(true)}
                      openMonthlyMessageHandler={() =>
                        setShowMonthlyMessage(true)
                      }
                      navigation={navigation}
                    />
                  </View>

                  {/** Current Trails Swiper */}
                  {currentTrails.length > 0 && (
                    <CurrentTrailsSwiper
                      data={currentTrails}
                      navigation={navigation}
                    />
                  )}
                  {/**  Trails Swiper */}
                  <View style={[swiperContainerStye, { zIndex: -1 }]}>
                    <View style={styles.row}>
                      <CustomTooltip
                        placement="left top"
                        label={t("tooltips.trails_home")}
                        bg={colors.blue3}
                      >
                        <View>
                          <H6>{t("home_main.trails")}</H6>
                        </View>
                      </CustomTooltip>

                      <TouchableOpacity
                        onPress={() => navigation.navigate("SeeAllTrails")}
                      >
                        <BoldTxt style={{ paddingRight: isMobile ? 10 : 20 }}>
                          {t("home_main.see_all")}
                        </BoldTxt>
                      </TouchableOpacity>
                    </View>
                    <Txt
                      style={{
                        fontSize: 15,
                        marginRight: 10,
                        marginLeft: 5,
                        marginTop: 5,
                      }}
                    >
                      {t("home_main.trail_description")}
                    </Txt>

                    <Txt
                      style={{ marginLeft: 5, fontSize: 14, marginTop: 5 }}
                      color={colors.grayLabel}
                    >
                      {episodesNumber} {t("home_main.text1")}
                    </Txt>

                    <Swiper
                      navigation={navigation}
                      type="Trail"
                      endpoint={`${process.env.TRAILS_ENDPOINT}?language=${i18n.language}&keyword=${keyword}`}
                    />
                  </View>
                  {/**  Workshops */}
                  <View style={[swiperContainerStye, { marginBottom: 30 }]}>
                    <View
                      style={[styles.row, { paddingRight: 10, zIndex: 2000 }]}
                    >
                      <CustomTooltip
                        placement="left top"
                        label={t("tooltips.workshops_home")}
                        bg={colors.blue3}
                      >
                        <View>
                          <H6>{t("home_main.workshop_title")} </H6>
                        </View>
                      </CustomTooltip>

                      <View style={{ width: isDesktop ? 280 : 210 }}>
                        <DropDown
                          height={40}
                          placeholder=""
                          show={showCategories}
                          setShow={() => setShowCategories(!showCategories)}
                          value={selectedCategorie}
                          setValue={setSelectedCategorie}
                          options={Categories}
                        />
                      </View>
                    </View>
                    <Txt
                      style={{
                        fontSize: 15,
                        marginRight: 10,
                        marginLeft: 5,
                        marginTop: 5,
                      }}
                    >
                      {t("home_main.workshop_description")}
                    </Txt>

                    <Txt
                      style={{ marginLeft: 5, fontSize: 14, marginTop: 5 }}
                      color={colors.grayLabel}
                    >
                      {workshopsNumber} {t("home_main.text2")}
                    </Txt>

                    <Swiper
                      navigation={navigation}
                      type="Atelier"
                      endpoint={`${process.env.WORKSHOPS_ENDPOINT}?language=${i18n.language}&type=${selectedCategorie.value}&keyword=${keyword}`}
                    />
                  </View>
                </View>
              </View>
            )}
            {/** modal tuto */}
            <TutoVideo isOpen={showTuto} setIsOpen={setShowTuto} />
            {/** modal trailer */}
            <TrailerVideo isOpen={showTrailer} setIsOpen={setShowTrailer} />
            {isDesktop && <Footer />}
            {/** monthly message  */}
            <MonthlyMessageModal
              isOpen={showMonthlyMessage}
              setIsOpen={setShowMonthlyMessage}
              fromHome
              setShowTuto={setShowTuto}
            />
            {/** welcome questionnaire modal */}
            {/*<NavigateToWelcomeQuestionnaire
              isOpen={showWelcomeQuestionnaire}
              setIsOpen={setShowWelcomeQuestionnaire}
              navigation={navigation}
                    />*/}
          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

export default HomeMain;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.beige,
  },
  siteContainer: {
    alignSelf: "center",
    backgroundColor: colors.green0,
    paddingVertical: 15,
    borderRadius: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  item: {
    height: 240,
    backgroundColor: colors.blue3,
    borderRadius: 20,
    marginRight: 10,
    marginBottom: 20,
  },

  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: -1,
  },
  searchBarContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },

  greenContainer: {
    width: "95%",
    alignSelf: "center",
    backgroundColor: colors.green0,
    paddingVertical: 15,
    marginTop: 20,
    justifyContent: "space-between",
    flexWrap: "wrap",
    borderRadius: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  webSiteContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 5,
    zIndex: -1,
  },
  tutoButton: {
    backgroundColor: colors.blue3,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 35,
    borderRadius: 10,
    paddingHorizontal: 15,
    marginVertical: 5,
  },
});
