import { StyleSheet, ImageBackground, Pressable, Image } from "react-native";
import React, { useState } from "react";
import { Spinner, View } from "native-base";
import { Octicons } from "@expo/vector-icons";
import DimensionsHook from "../hooks/DimensionsHook";
import { PrimaryButton } from "../components/Buttons";
import { colors } from "../styles/GlobalStyle";
import { H5, H6, Txt } from "../components/TextsComponents";
import { useTranslation } from "react-i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useSelector } from "react-redux";
import * as Animatable from "react-native-animatable";
import axios from "axios";

const GiftScreen = ({ navigation, route }) => {
  const { gift } = route.params;
  const { t } = useTranslation();
  const [giftLoader, setGiftLoader] = useState(false);
  const { isDesktop } = DimensionsHook();
  const { email } = useSelector((state) => state.userData);

  const getGiftHandler = async () => {
    if (!giftLoader) {
      setGiftLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      setTimeout(() => {
        const url = process.env.BEGIN_TRIAL;
        axios
          .post(url, {
            access_token: TOKEN,
            email,
          })
          .then(async () => {
            setGiftLoader(false);
            navigation.reset({
              index: 0,
              routes: [{ name: "Loader" }],
            });
          })
          .catch((err) => {
            setGiftLoader(false);
            console.log(err);
          });
      }, 1200);
    }
  };

  const pickupGiftLaterHandler = () => {
    navigation.replace("TabNavigation", true);
  };

  return (
    <View style={{ flex: 1 }}>
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <View flex={1} alignItems="center" justifyContent="center">
          <View style={styles.greenContainer}>
            {/** open www.lislup.com */}

            <View style={styles.iconContainer}>
              <Animatable.View
                animation="tada"
                iterationCount="infinite"
                easing="linear"
                duration={1500}
              >
                <Octicons
                  style={{ alignSelf: "center" }}
                  name="gift"
                  size={80}
                  color={colors.blue3}
                />
              </Animatable.View>
            </View>
            <H5>{t("plans.gift_title")}</H5>
            <Txt
              style={{
                textAlign: "center",
                marginTop: 15,
                marginBottom: 30,
                marginHorizontal: isDesktop ? 30 : 20,
              }}
            >
              {gift.plan == "sub_one_month"
                ? t("plans.gift_text_month")
                : gift.plan == "sub_lifetime"
                ? t("plans.gift_text_lifetime")
                : gift.plan == "sub_annual"
                ? t("plans.gift_text_annual")
                : t("plans.gift_text_trim")}
            </Txt>
            <PrimaryButton
              style={{
                width: 180,
              }}
              onPress={getGiftHandler}
            >
              {giftLoader ? (
                <Spinner color={colors.white} />
              ) : (
                t("plans.gift_button")
              )}
            </PrimaryButton>
            <Pressable
              onPress={pickupGiftLaterHandler}
              style={styles.laterButton}
            >
              <Txt>Je récupére mon cadeau ultérierement</Txt>
            </Pressable>
          </View>
        </View>
        {isDesktop && (
          <Image
            source={{
              uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
            }}
            style={styles.mascotte}
          />
        )}
      </ImageBackground>
    </View>
  );
};

export default GiftScreen;

const styles = StyleSheet.create({
  greenContainer: {
    backgroundColor: colors.green0,
    paddingVertical: 40,
    maxWidth: 500,
    width: "95%",
    alignSelf: "center",
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  iconContainer: {
    width: 100,
    height: 100,
    alignSelf: "center",
  },
  mascotte: {
    position: "absolute",
    bottom: 0,
    right: 30,
    height: 210,
    width: 130,
  },
  laterButton: {
    borderBottomWidth: 1,
    height: 45,
    alignItems: "center",
    justifyContent: "flex-end",
    paddingBottom: 2,
    borderColor: colors.blue3,
  },
});
