import {
  StyleSheet,
  ImageBackground,
  View,
  ScrollView,
  Platform,
} from "react-native";
import React, { useState, useEffect } from "react";
import { colors } from "../styles/GlobalStyle";
import HeaderComponent from "../components/HeaderComponent";
import { H2, H3 } from "../components/TextsComponents";
import DimensionsHook from "../hooks/DimensionsHook";
import Spinner from "../components/Spinner";
import ResponsiveItem from "../components/seeall/ResponsiveItem";
import axios from "axios";
import Footer from "../components/Footer";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import { Entypo } from "@expo/vector-icons";
import HomeSearch from "./Home/HomeSearch";

const Trails = ({ navigation }) => {
  const { t, i18n } = useTranslation();
  const [keyword, setKeyword] = useState("");
  const { isMobile, isDesktop, isTablet, isBigScreen, width, height } =
    DimensionsHook();

  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isOpenSuggestion, setIsOpenSuggestion] = useState(false);

  const searchHandler = async (searchText) => {

  };

  const getData = async () => {
    setLoader(true);
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const Response = await axios.get(
      `${process.env.TRAILS_ENDPOINT}/search?language=${i18n.language}`,
      config
    );

    setData(Response.data);
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const itemContainer = {
    backgroundColor: colors.grayBackground,
    marginTop: 15,
    height: 240,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    overflow: "hidden",
    width: isBigScreen
      ? (width * 0.95 - 15) * 0.25 - 20
      : isDesktop
        ? (width * 0.95 - 15) * 0.33 - 20
        : isTablet
          ? (width * 0.95 - 15) * 0.5 - 20
          : width * 0.95,
    marginLeft: isMobile ? 0 : 15,
    alignSelf: isMobile ? "center" : "flex-start",
  };

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView showsVerticalScrollIndicator={Platform.OS === "web"}>
          <HeaderComponent
            name={t("tab_navigation.trails")}
            navigation={navigation}
          />
          <View style={{ marginHorizontal: "2.5%" }}>
            <View
              style={{
                flexDirection: isMobile ? "column" : "row-reverse",
                alignItems: isMobile ? "flex-start" : "center",
                justifyContent: isMobile ? "flex-start" : "space-between",
                marginTop: 10,
                marginBottom: 20
              }}
            >
              {/** search bar */}
              <View
                style={{
                  width: isMobile ? "100%" : 280,

                }}
              >
                <HomeSearch
                  type="Trail"
                  keyword={keyword}
                  setKeyword={setKeyword}
                  searchHandler={searchHandler}
                  isOpenSuggestion={isOpenSuggestion}
                  setIsOpenSuggestion={setIsOpenSuggestion}
                />
              </View>
              <H2 style={{ marginTop: isMobile ? 20 : 0 }}>Trails</H2>
            </View>
            {/** list of trails in rows  */}
            <View style={{ minHeight: height - 220, zIndex: -1, }}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",

                  paddingBottom: 15,

                  flexWrap: "wrap",
                }}
              >
                {loader ? (
                  Array.from(Array(2)).map((_, i) => {
                    return (
                      <View key={i} style={itemContainer}>
                        <Spinner />
                      </View>
                    );
                  })
                ) : Data.length === 0 ? (
                  <View
                    style={{
                      width: "100%",
                      marginTop: 30,
                      alignItems: "center",
                    }}
                  >
                    <Entypo
                      name="notifications-off"
                      size={40}
                      color={colors.grayLabel}
                    />
                    <H3
                      style={{ textAlign: "center" }}
                      color={colors.grayLabel}
                    >
                      {t("trails.no_content")}
                    </H3>
                  </View>
                ) : (
                  Data.map((item) => {
                    return (
                      <ResponsiveItem
                        oneOnSmall={true}
                        key={item.trail_code}
                        item={item}
                        type={"Trail"}
                        navigation={navigation}
                      />
                    );
                  })
                )}
              </View>
            </View>
          </View>
          {isDesktop && <Footer />}
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

export default Trails;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },
  searchBarContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
});
