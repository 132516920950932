import { Svg, G, Path, Polygon, Ellipse } from "react-native-svg";

export const GiftScreenImage = () => {
  return (
    <Svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
    >
      <G>
        <G>
          <G>
            <Path
              fill="#A8C49250"
              d="M75.9,124.3c15.2-4.1,31.4-2.4,45.5,4.6c24.5,12,78.1,31.8,83.1-22.6c6-65.5,142.4-84.6,193.3-34.6
                c62,60.8,2.5,104,52.9,130.6c50.4,26.6,91.3,97.2,31.9,127.7c-38.4,19.7-72,15.6-74.8,35.6c-5.3,37.6-4.2,60.4-38.7,70.8
                c-65.3,19.8-82.3-16.9-123-23.3C205.6,406.5,55.5,462,5.4,372.6C-24.8,318.6,82.2,268.4,51.8,239
                C19.5,207.7,22.4,138.6,75.9,124.3z"
            />
          </G>
          <G>
            <G>
              <Path
                fill="#96CEDA"
                d="M192.5,417.4c22.4-8.2,44.1-24.9,48-47.9c1.1-6.7,0.5-14-3.4-19.6s-11.8-8.7-18.2-5.8
                    c-5.5,2.4-9.2,8.6-15.1,9.5c-4.2,0.6-8.2-1.9-10.8-5.1c-2.6-3.2-3.9-7.2-5.4-11c-1.9-5-4.1-9.9-6.5-14.6
                    c-2.9-5.6-6.5-11.4-12.4-13.9c-5.9-2.5-14.5,0.1-15.7,6.4c-0.6,2.9,0.6,5.9,0.8,8.9c0.2,3-1.1,6.6-4.2,7.1
                    c-1.2,0.2-2.4-0.1-3.5-0.6c-8.5-3.3-13.6-12.1-21.8-16.3c-4-2.1-8.6-2.9-13.1-3.6c-20.4-2.9-43.1-1-58.7,12.3
                    c-3.1,2.6-5.9,5.7-7.1,9.5c-2.3,7.1,1.8,15,8,19.4c6.2,4.3,14,5.8,21.5,6.8s15.3,1.6,22.3,4.5c-4.2,4.3-9.6,7.5-15.4,9.1
                    c-3,0.8-6.4,1.4-8.4,3.8c-3.2,3.7-0.8,9.9,3.6,12.1c4.4,2.2,9.8,1.5,14.6,0.3c13-3.2,26.3-9.3,39-5.6c1.7,0.5,3.5,1.4,4.1,3
                    c0.4,1.1,0,2.4-0.5,3.5c-2.5,5.5-8.3,8.7-12.7,12.9c-1,1-2,2.1-2.4,3.4c-1.8,5.2,4.8,9.1,10.2,10.5
                    C149.7,421.6,169.8,423.9,192.5,417.4L192.5,417.4z"
              />
            </G>
            <G>
              <G>
                <Path
                  fill="#ADE5F0"
                  d="M220.1,396.9c-15.3-14.4-32.7-26.4-51.6-35.6c-18.9-9.2-39.1-15.6-59.9-18.8c-11.8-1.8-23.8-2.6-35.8-2.3
                        c-0.9,0-0.9-1.4,0-1.4c21.3-0.6,42.7,2.3,63.2,8.4c20.3,6,39.8,15,57.4,26.8c9.8,6.5,19.1,13.9,27.7,22
                        C221.8,396.6,220.8,397.6,220.1,396.9L220.1,396.9z"
                />
              </G>
            </G>
          </G>
          <G>
            <Path
              fill="#FFCFBF"
              d="M152.8,435.4c2.8,13,104.2,13.5,112.3,0c1.3-2.2,2.4-6.4,3.4-11.8c4.6-25.7,6.8-80.1,23.6-97.4c0,0,0,0,0,0
                c1.1-1.2,2.4-2.2,3.6-3c54.8-35,46.8-101.3,46.8-101.3l-144.4,27.2C200.4,275.6,150.1,422.4,152.8,435.4z"
            />
          </G>
          <G>
            <G>
              <Path
                fill="#49B2A4"
                d="M278.5,423.9c-10.9-6.2-20.7-16.7-20.7-29c0-3.6,1-7.3,3.5-9.8c2.5-2.5,6.9-3.4,9.9-1.4
                    c2.6,1.7,4,5.3,7,6.2c2.1,0.7,4.4-0.2,6-1.7c1.6-1.4,2.7-3.4,3.7-5.2c1.4-2.4,3-4.7,4.7-7c2-2.7,4.4-5.3,7.6-6.1
                    c3.3-0.8,7.5,1.3,7.5,4.7c0,1.6-0.8,3-1.2,4.5c-0.4,1.5,0,3.5,1.5,4c0.6,0.2,1.2,0.1,1.9,0c4.7-1,8.1-5.1,12.7-6.5
                    c2.3-0.7,4.7-0.8,7.1-0.7c10.8,0.3,22.4,3.2,29.3,11.5c1.4,1.6,2.5,3.5,2.8,5.5c0.6,3.9-2.3,7.6-5.8,9.3
                    c-3.6,1.7-7.7,1.8-11.7,1.6c-4-0.2-8.1-0.5-11.9,0.4c1.8,2.6,4.3,4.7,7.2,6.1c1.5,0.7,3.2,1.3,4,2.7c1.4,2.2-0.5,5.2-2.9,6
                    c-2.5,0.8-5.2-0.1-7.6-1.1c-6.4-2.8-12.8-7.1-19.7-6.3c-0.9,0.1-2,0.4-2.4,1.2c-0.3,0.6-0.2,1.2,0,1.8c0.8,3.1,3.6,5.2,5.4,7.8
                    c0.4,0.6,0.8,1.2,1,2c0.5,2.9-3.3,4.3-6.2,4.5C300.2,429.8,289.6,429.2,278.5,423.9L278.5,423.9z"
              />
            </G>
            <G>
              <G>
                <Path
                  fill="#53CEBA"
                  d="M347,394.5c-21.8-4.2-44.8-1.4-65,7.7c-5.6,2.5-11,5.5-16.1,8.9c-0.5,0.3-1-0.5-0.5-0.8
                        c18.5-12.4,40.9-18.9,63.2-18.6c6.3,0.1,12.5,0.8,18.6,2C347.9,393.7,347.6,394.6,347,394.5L347,394.5z"
                />
              </G>
            </G>
          </G>
          <G>
            <G>
              <Path
                fill="#198DA5"
                d="M227.6,301.5l91.4,11.7c3.9,0.5,7.5-2.3,8-6.2l20.5-159.9c0.5-3.9-2.3-7.5-6.2-8l-91.4-11.7
                    c-3.9-0.5-7.5,2.3-8,6.2l-20.5,159.9C220.9,297.4,223.7,301,227.6,301.5z"
              />
            </G>
            <G>
              <Path
                fill="#BEE5ED"
                d="M229.7,288.9l90.3,11.6c1.6,0.2,3.1-0.9,3.3-2.6l19.4-151.2c0.2-1.6-0.9-3.1-2.6-3.3l-90.3-11.6
                    c-1.6-0.2-3.1,0.9-3.3,2.6l-19.4,151.2C226.9,287.2,228.1,288.7,229.7,288.9z"
              />
            </G>
          </G>
          <G>
            <Path
              fill="#FFCFBF"
              d="M204.2,270.2c10.5,16.8,28.5-30.9,28.3-49.9c-0.2-12.9,2.3-25.3,4.2-33.2c3.5-14.9,4.2-28.9-6.5-33.1
                c-4.4-1.7-6.4-1-7.9,2.3c-1.3,2.8-2.2,7.3-4,13.6c-1.3,4.6-3,10.3-5.5,16.9C202.6,213.3,188.4,244.7,204.2,270.2z"
            />
          </G>
          <G>
            <G>
              <G>
                <Path
                  fill="#FFCFBF"
                  d="M352.9,186.4c-11.5,0-23,1.2-22.6,14.8c0.5,13.5,24.6,13.4,28.6,3.2C361,199.2,362.1,186.4,352.9,186.4z"
                />
              </G>
              <G>
                <Path
                  fill="#FDBAA0"
                  d="M353.6,194c0,0,0.8,7-1.2,9c-1.9,2-8.3,4-8.9,3.5c-0.6-0.6,0.7-3.9,0.4-8c-0.2-3.2-2.5-6.4-0.8-7.2
                        S353.2,191.6,353.6,194z"
                />
              </G>
            </G>
            <G>
              <G>
                <Path
                  fill="#FFCFBF"
                  d="M356.1,209.4c-12.5-0.6-25.1,0.1-25.2,14.8c-0.2,14.6,26.1,15.7,30.9,5
                        C364.2,223.5,366.1,209.8,356.1,209.4z"
                />
              </G>
              <G>
                <Path
                  fill="#FDBAA0"
                  d="M357.1,218.4c0,0,1.2,6.4-1.1,8.5c-2.2,2.1-10.5,4.2-11.1,3.5c-0.6-0.7,0.9-4.2,0.8-8.6
                        c-0.1-3.5-2.3-7-0.5-7.7C347.1,213.3,356.8,215.8,357.1,218.4z"
                />
              </G>
            </G>
            <G>
              <G>
                <Path
                  fill="#FFCFBF"
                  d="M345,231.7c-11.8,0.9-23.6,3.1-22,17c1.6,13.9,26.4,11.9,29.7,1.1C354.3,244.2,354.5,230.9,345,231.7z"
                />
              </G>
              <G>
                <Path
                  fill="#FDBAA0"
                  d="M346.5,240.3c0,0,1.8,6.6-0.1,8.9c-1.8,2.2-8.8,4.4-9.5,3.9c-0.7-0.6,0.4-4.1-0.2-8.3
                        c-0.5-3.3-3-6.4-1.4-7.3C337,236.5,345.9,237.9,346.5,240.3z"
                />
              </G>
            </G>
            <G>
              <G>
                <Path
                  fill="#FFCFBF"
                  d="M337.8,254.2c-9.1,0.9-18.3,2.8-16.8,13.6c1.5,10.8,20.7,8.8,23.1,0.3
                        C345.2,263.8,345.1,253.5,337.8,254.2z"
                />
              </G>
              <G>
                <Path
                  fill="#FDBAA0"
                  d="M339.2,260.7c0,0,1,4.7-0.4,6.5c-1.4,1.8-6.4,4.2-6.9,3.8c-0.5-0.4,0.2-3.2-0.3-6.4
                        c-0.4-2.5-2.5-4.9-1.2-5.6C331.6,258.1,338.7,258.8,339.2,260.7z"
                />
              </G>
            </G>
            <G>
              <Path
                fill="#FDBAA0"
                d="M339.3,211.2C339.3,211.2,339.3,211.2,339.3,211.2c5-1.6,15.2-1.7,15.3-1.7c0.1,0,0.2-0.1,0.2-0.2
                    c0-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0c-0.1,0-10.4,0.2-15.3,1.7c-0.1,0-0.2,0.2-0.1,0.3C339.1,211.2,339.2,211.2,339.3,211.2z"
              />
            </G>
            <G>
              <Path
                fill="#FDBAA0"
                d="M352.1,236c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-13.5-0.5-16.6-2.7c-0.1-0.1-0.2-0.1-0.3,0
                    c-0.1,0.1-0.1,0.2,0,0.3C338.5,235.5,351.6,236,352.1,236C352.1,236,352.1,236,352.1,236z"
              />
            </G>
            <G>
              <Path
                fill="#FDBAA0"
                d="M336.5,258c3.6,0,7-0.3,7.2-0.4c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-13.1,1.3-15.9-1.2
                    c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3C329,257.7,332.9,258,336.5,258z"
              />
            </G>
          </G>
          <G>
            <Path
              fill="#FDBAA0"
              d="M218.3,170c0.3,0.4,0.7,0.8,1.3,1c5.4,2.1,7.1-6,8.3-10.1s-5.6-4.5-5.6-4.5C221,159.1,220.1,163.6,218.3,170
                z"
            />
          </G>
          <G opacity={0.6}>
            <Path
              fill="#FFE1DA"
              d="M268.5,423.6c4.6-25.7,6.8-80.1,23.6-97.4c-0.4,0.1-29.2,7.4-30.5,25.8c-1.3,18.5,3.4,35-2.7,47.8
                C253.3,411.4,258.8,423.4,268.5,423.6z"
            />
          </G>
          <G>
            <Path
              fill="#FDBAA0"
              d="M281.7,326.2c5.7,0,12.1-0.9,14.3-2.7l-0.6-0.7c-3.6,3-25.4,5.1-40.3-4.9l-0.5,0.7c8.9,6,19.5,7.3,25,7.5
                C280.3,326.2,281,326.2,281.7,326.2z"
            />
          </G>
          <G>
            <Path
              fill="#FDBAA0"
              d="M212.4,299.1c0.1,0,0.3-0.1,0.4-0.2c0.1-0.2,0.1-0.5-0.1-0.6c-13.4-9.9-16.9-26.7-16.9-26.9
                c0-0.2-0.3-0.4-0.5-0.4c-0.2,0-0.4,0.3-0.4,0.5c0,0.2,3.6,17.3,17.2,27.4C212.2,299.1,212.3,299.1,212.4,299.1z"
            />
          </G>
          <G>
            <Path
              fill="#FF8F65"
              d="M284.4,439.4c0,0-3.2-27.8,0-51.6c3.2-23.8-7.7-16.6-36.6-11.2c-28.9,5.4-66.6-40.4-80.1-32.3
                c-13.5,8.1-3.1,28.6-15,49.6c-11.9,21-20.5,38.9-11,48.4C151.1,451.6,285.1,446.9,284.4,439.4z"
            />
          </G>
          <G>
            <Path
              fill="#FFCFBF"
              d="M180,306.2c0.2,0,0.5-0.1,0.6-0.4c6-11.8,8.5-32.2,8.5-32.4c0-0.4-0.2-0.7-0.6-0.7c-0.4,0-0.7,0.2-0.7,0.6
                c0,0.2-2.4,20.4-8.4,32c-0.2,0.3,0,0.7,0.3,0.9C179.8,306.1,179.9,306.2,180,306.2z"
            />
          </G>
          <G>
            <Path
              fill="#96CEDA"
              d="M318.9,278.4c0,0,3.8,14.1-3.6,15.5c-7.4,1.3-22.8-2.1-21.9-4.5c1-2.4,15.7-1.3,16.8-6.5
                C312.6,271.5,316.2,273.5,318.9,278.4z"
            />
          </G>
          <G>
            <G>
              <G>
                <G>
                  <G>
                    <Path
                      fill="#F47153"
                      d="M264.2,132.5c-6.8,3.8-12.8,10-14.5,17.6c1.5-0.8,3.1-1.5,4.6-2.3c-0.1,2.2,0.2,4.3,0.8,6.4
       c2.9-4.8,7.1-8.9,11.9-11.8c6-3.6,13-5.4,19.2-8.7c1.3-0.7,2.7-1.6,3.6-2.8s1.3-2.9,0.7-4.3c-0.6-1.4-2.1-2.7-3.4-1.9
       l-0.8,0.3C278.8,126.8,271,128.7,264.2,132.5z"
                    />
                  </G>
                  <G>
                    <Path
                      fill="#FF8D67"
                      d="M286.5,136.9c-6.9-0.1-13.8-0.9-20.6-2.3c-3.2-0.6-6.7-1.7-8.3-4.5c-1.9-3.4,0.7-8.4,4.6-8.8
       c1.4-0.1,2.9,0.2,4.3,0.2c1.5,0,3.1-0.5,3.7-1.8c0.3-0.6,0.3-1.2,0.3-1.9c-0.2-4.6-3.2-9.4-1.2-13.6c0.2-0.4,0.4-0.8,0.8-1
       c0.4-0.2,0.9-0.2,1.3-0.1c3.2,0.5,5.4,3.4,7.2,6.1c3.8,5.7,7.5,11.3,11.3,17L286.5,136.9z"
                    />
                  </G>
                  <G>
                    <Path
                      fill="#F47153"
                      d="M332.6,99.4c5.6-5.5,13.1-9.5,20.9-9.1c-1.1,1.3-2.3,2.6-3.4,3.8c2.1,0.5,4.1,1.4,5.9,2.6
       c-5.4,1.5-10.5,4.3-14.7,8.2c-5.1,4.8-8.7,11.1-13.7,16.1c-1.1,1.1-2.3,2.2-3.7,2.7c-1.4,0.5-3.2,0.5-4.4-0.5
       c-1.2-1-2-2.8-0.8-3.8l0.5-0.7C323.1,111.9,327,104.9,332.6,99.4z"
                    />
                  </G>
                  <G>
                    <Path
                      fill="#FF8D67"
                      d="M292.1,122.5c-1.4-7.1-2.1-14.2-2.2-21.4c0-2.5,0.5-5.6,2.9-6.2c1.2-0.3,2.5,0.3,3.6,0.9
       c3.1,1.5,6.3,3,9.6,3.8c3.4,0.7,7.1,0.6,10.1-0.9c2-1,3.7-2.7,5.5-4.1c1.8-1.4,3.9-2.7,6.1-2.8s4.8,1.3,5.1,3.6
       c0.2,1.2-0.2,2.3-0.6,3.4c-3,8.2-7.9,15.6-14.4,21.5L292.1,122.5z"
                    />
                  </G>
                  <G>
                    <G>
                      <Polygon
                        fill="#63BFD2"
                        points="326.2,157.6 270.2,162.8 262.8,151.8 321.7,145.6      "
                      />
                    </G>
                    <G>
                      <Polygon
                        fill="#44B3C1"
                        points="368.2,117.8 326.2,157.6 321.7,145.6 353.6,109.3      "
                      />
                    </G>
                    <G>
                      <Polygon
                        fill="#4C99AF"
                        points="353.6,109.3 321.7,145.6 262.8,151.8 297.3,109.3      "
                      />
                    </G>
                    <G>
                      <Polygon
                        fill="#7CCCDB"
                        points="297.3,109.3 306.8,117.8 270.2,162.8 262.8,151.8      "
                      />
                    </G>
                    <G>
                      <Polygon
                        fill="#5DB6C0"
                        points="306.8,117.8 368.2,117.8 359.3,109.3 297.3,109.3      "
                      />
                    </G>
                  </G>
                  <G>
                    <Path
                      fill="#69B3BD"
                      d="M291.2,137c0,0-7.8-5.2-9.3-8.8l-5.7,7c0,0,2.4,8.1,7.5,11L291.2,137z"
                    />
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FF8D67"
                        d="M287.8,103.4c-0.4-2.7-0.1-5.7,1.6-8c1.5-2,4-3.2,6.5-2.7c0.4,0.1,0.2,0.7-0.2,0.6
           c-2.3-0.4-4.7,0.9-6,2.7c-1.5,2.1-1.7,4.8-1.4,7.2C288.5,103.7,287.9,103.9,287.8,103.4L287.8,103.4z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#F47153"
                        d="M340.2,90.9c-0.4,0.7-1.4,1-2.1,1.4c-0.7,0.4-1.5,1-2.4,0.7c-1-0.4-2.8-2.6-1.3-3.3
           c0.5-0.3,1.2-0.2,1.7,0.2c0.7,0.5,0.6,1.3,0.6,2.1c-0.1,1.8-0.6,3.7-2.2,4.8c-0.4,0.2-0.7-0.3-0.3-0.6
           c1-0.7,1.5-1.7,1.7-2.8c0.2-0.9,0.6-3-0.7-3.1c-0.9-0.1-0.6,0.9-0.2,1.3c0.4,0.6,1,1.1,1.7,0.8c0.5-0.2,1-0.6,1.5-0.8
           c0.4-0.2,1.2-0.5,1.5-1C339.8,90.2,340.4,90.5,340.2,90.9L340.2,90.9z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#F47153"
                        d="M341.1,106.7c0.6-1.3,1.8-2.3,3.2-2.7c0.4-0.1,0.6,0.5,0.2,0.6c-1.2,0.4-2.2,1.2-2.8,2.4
           C341.5,107.5,340.9,107.1,341.1,106.7L341.1,106.7z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FF8D67"
                        d="M286.4,118.8c-1.2-1.9-2.3-3.8-3.5-5.7c-1-1.7-2-3.6-3.6-4.8c-0.3-0.3,0.1-0.7,0.5-0.5
           c1.5,1.2,2.5,3.1,3.5,4.8c1.2,2,2.4,3.9,3.6,5.9C287.2,118.8,286.6,119.1,286.4,118.8L286.4,118.8z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FF8D67"
                        d="M277.9,105.6c-1.9-1.7-4.1-3.2-6.7-3c-0.4,0-0.4-0.6,0-0.7c2.8-0.2,5.2,1.4,7.2,3.2
           C278.6,105.4,278.2,105.9,277.9,105.6L277.9,105.6z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FF8D67"
                        d="M262.7,120.8c-2.2-0.3-4.5,0.9-5.6,2.9c-0.2,0.4-0.8,0-0.6-0.3c1.2-2.3,3.8-3.5,6.3-3.2
           C263.3,120.3,263.2,120.9,262.7,120.8L262.7,120.8z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#F47153"
                        d="M263,147.9c-1,1.2-1.9,2.4-2.9,3.6c-0.3,0.3-0.7-0.1-0.5-0.5c1-1.2,1.9-2.4,2.9-3.6
           C262.8,147.1,263.3,147.6,263,147.9L263,147.9z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFA382"
                        d="M316.5,99.4c-5.8,3.2-12.3,4.8-18.9,4.5c-0.4,0-0.4-0.6,0-0.6c6.5,0.2,13-1.3,18.6-4.5
           C316.6,98.7,316.9,99.3,316.5,99.4L316.5,99.4z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFA382"
                        d="M275.8,122.4c-3.4,1.3-7.1,1.3-10.4,0c-0.4-0.1-0.2-0.7,0.2-0.6c3.3,1.3,6.9,1.3,10.1,0
           C276,121.6,276.2,122.2,275.8,122.4L275.8,122.4z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FF8D67"
                        d="M316.6,96.4c-3.9,3-9.3,3-13.1-0.1c-0.3-0.3,0.1-0.7,0.5-0.5c3.5,2.8,8.6,2.9,12.2,0.1
           C316.5,95.7,316.9,96.1,316.6,96.4L316.6,96.4z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFFFFF"
                        d="M306.9,118.2c-1.4-1.5-3-2.8-4.6-4c-0.2-0.2,0-0.6,0.2-0.4c1.7,1.2,3.3,2.6,4.7,4.1
           C307.5,118.1,307.1,118.4,306.9,118.2L306.9,118.2z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFFFFF"
                        d="M300.9,112.6c-0.2-0.2-0.5-0.4-0.8-0.6c-0.3-0.1,0-0.5,0.2-0.4c0.3,0.2,0.6,0.4,0.8,0.7
           C301.4,112.5,301.1,112.9,300.9,112.6L300.9,112.6z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFFFFF"
                        d="M291.3,137.1c-1.4-1-2.8-2-4.1-2.9c-0.2-0.2,0-0.6,0.2-0.4c1.4,1,2.8,2,4.1,2.9
           C291.8,136.8,291.6,137.2,291.3,137.1L291.3,137.1z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFFFFF"
                        d="M368.1,117.8c-7.7,0.3-15.4,0.4-23.1,0.4c-0.3,0-0.3-0.5,0-0.5c7.7,0,15.4-0.1,23.1-0.4
           C368.4,117.4,368.4,117.8,368.1,117.8L368.1,117.8z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFFFFF"
                        d="M339.1,118.1c-3.3-0.3-6.7-0.4-10-0.2c-0.3,0-0.3-0.4,0-0.5c3.4-0.1,6.7-0.1,10,0.2
           C339.4,117.6,339.4,118.1,339.1,118.1L339.1,118.1z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFFFFF"
                        d="M357.1,114.9c-0.8,0-0.8-1.2,0-1.2C357.9,113.7,357.9,114.9,357.1,114.9L357.1,114.9z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFFFFF"
                        d="M349.6,112.6c-0.8,0-0.8-1.2,0-1.2C350.4,111.5,350.4,112.6,349.6,112.6L349.6,112.6z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFFFFF"
                        d="M343.1,115.6c0,0-0.1,0-0.1,0c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.3,0.3-0.6,0.6-0.6
           c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4C343.7,115.4,343.5,115.6,343.1,115.6L343.1,115.6z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFFFFF"
                        d="M310.2,112.4c-0.8,0-0.8-1.2,0-1.2C310.9,111.2,310.9,112.4,310.2,112.4L310.2,112.4z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFFFFF"
                        d="M315.5,115.5c-0.8,0-0.8-1.2,0-1.2C316.3,114.3,316.3,115.5,315.5,115.5L315.5,115.5z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFFFFF"
                        d="M331.4,113.1c-0.8,0-0.8-1.2,0-1.2C332.2,111.9,332.2,113.1,331.4,113.1L331.4,113.1z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFFFFF"
                        d="M325,115.2c-0.8,0-0.8-1.2,0-1.2C325.7,114,325.7,115.2,325,115.2L325,115.2z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFFFFF"
                        d="M295.5,118.5c-0.8,0-0.8-1.2,0-1.2C296.3,117.3,296.3,118.5,295.5,118.5L295.5,118.5z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFFFFF"
                        d="M291.9,127.3c-0.8,0-0.8-1.2,0-1.2C292.7,126.1,292.7,127.3,291.9,127.3L291.9,127.3z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFFFFF"
                        d="M274.7,144c-0.8,0-0.8-1.2,0-1.2C275.5,142.8,275.5,144,274.7,144L274.7,144z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFFFFF"
                        d="M270.9,153.7c-0.8,0-0.8-1.2,0-1.2C271.7,152.5,271.7,153.7,270.9,153.7L270.9,153.7z"
                      />
                    </G>
                  </G>
                </G>
                <G>
                  <Polygon
                    fill="#198DA5"
                    points="262.7,173.2 308.3,167.8 375,192.9 330.3,200.2"
                  />
                </G>
                <G>
                  <G>
                    <G>
                      <Path
                        fill="#FFE966"
                        d="M300.3,157.1c-1.9,2-4.2,3.7-2.2,7.1c0.8,1.3,2.5,3.2,3.5,4.5c-1.5-1.3-3.1-2.2-5.7-3.8
           c-2.8-1.7-4.7-1.6-8.9,2.2c0,0,2-3.7,1.9-6.1c-0.1-2-1.8-5-3.4-6.5C289.3,157.3,294.1,160.4,300.3,157.1z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFE966"
                        d="M297.8,170.8c8.8,16.9,5.9,35.6,2.6,53.3c-0.1,0.5-0.9,0.3-0.8-0.1c3.3-17.6,6.2-36.2-2.5-52.9
           C296.9,170.7,297.6,170.4,297.8,170.8L297.8,170.8z"
                      />
                    </G>
                  </G>
                </G>
                <G>
                  <G>
                    <G>
                      <Path
                        fill="#FFE966"
                        d="M326.7,138.1c-2.4,2.1-5.1,3.8-3.6,8.1c0.6,1.7,2.1,4.2,3,5.9c-1.4-1.8-3-3.1-5.4-5.4
           c-2.7-2.5-4.7-2.6-9.8,1.3c0,0,2.8-4.2,3-7c0.2-2.4-1.1-6.3-2.5-8.3C315,136.8,319.6,141.2,326.7,138.1z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFE966"
                        d="M321.8,154.3c6.5,21.5,0.3,43.6-6.2,64.2c-0.2,0.5-1,0.3-0.8-0.3c6.5-20.5,12.7-42.4,6.2-63.8
           C320.8,153.9,321.6,153.7,321.8,154.3L321.8,154.3z"
                      />
                    </G>
                  </G>
                </G>
                <G>
                  <Path
                    fill="#FF8D67"
                    d="M255.4,235l6.6,3.4l46.3,24c15.3-20.8,22-62.2,22-62.2l-52.9-21.1l-14.7-5.9L255.4,235z"
                  />
                </G>
                <G>
                  <G>
                    <G>
                      <Path
                        fill="#FFE966"
                        d="M379.2,140.5c0.4,3.2,1.4,6.2-2.6,8.5c-1.6,0.9-4.4,1.8-6.1,2.6c2.2-0.4,4.3-0.4,7.6-0.6
           c3.7-0.1,5.3,1.1,6.6,7.4c0,0,0.6-5,2.3-7.3c1.4-2,4.9-4.1,7.3-4.7C388.9,147.1,382.6,147.4,379.2,140.5z"
                      />
                    </G>
                  </G>
                  <G>
                    <G>
                      <Path
                        fill="#FFE966"
                        d="M372.5,155.9c-18.9,12.1-28.5,32.9-36.9,52.9c-0.2,0.5,0.6,0.8,0.8,0.3c8.4-19.9,17.9-40.5,36.7-52.6
           C373.5,156.3,372.9,155.6,372.5,155.9L372.5,155.9z"
                      />
                    </G>
                  </G>
                </G>
                <G>
                  <Polygon
                    fill="#F47153"
                    points="375,192.9 330.3,200.2 308.4,262.4 351.6,253.6"
                  />
                </G>
                <G>
                  <Path
                    fill="#7CCCDB"
                    d="M280.4,217.3c2.3-11.1,5.4-22.7,11.1-32.6c3.1,1.2,6,2.4,9.3,3.7c-8.1,19.8-13.2,40.8-15.3,62.1
   l-8.1-4.2C276.6,239.4,278.5,226.7,280.4,217.3z"
                  />
                </G>
                <G>
                  <Path
                    fill="#00ADC8"
                    d="M349,197.1c-0.1,0-12.1,47.4-22.3,61.6l11.1-2.3c0,0,12.2-26.1,18.7-60.5L349,197.1z"
                  />
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M321.3,226.6c-0.7,2.1-1.3,4.3-2,6.4c-0.1,0.3-0.5,0.2-0.4-0.1c0.7-2.1,1.3-4.3,2-6.4
       C320.9,226.2,321.4,226.3,321.3,226.6L321.3,226.6z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M330.5,200.5c-1.8,4.8-3.5,9.6-5,14.5c-0.1,0.3-0.5,0.2-0.4-0.1c1.5-4.9,3.2-9.7,5-14.5
       C330.2,200.1,330.6,200.2,330.5,200.5L330.5,200.5z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#7CCCDB"
                      d="M288.3,188.4c-2.3,4.6-4.2,9.4-5.7,14.3c-0.1,0.4-0.8,0.2-0.6-0.2c1.5-5,3.4-9.8,5.8-14.4
       C287.9,187.7,288.5,188.1,288.3,188.4L288.3,188.4z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#7CCCDB"
                      d="M291.4,225.7c-0.7,2.6-1.2,5.2-1.4,7.9c0,0.4-0.7,0.4-0.7,0c0.2-2.7,0.6-5.4,1.4-8
       C290.9,225.1,291.5,225.2,291.4,225.7L291.4,225.7z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#00ADC8"
                      d="M347.3,199.6c-0.1,0.6-0.2,1.2-0.3,1.8c-0.1,0.4-0.7,0.2-0.6-0.2c0.1-0.6,0.2-1.2,0.3-1.8
       C346.7,199,347.4,199.2,347.3,199.6L347.3,199.6z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#00ADC8"
                      d="M345.7,205.2c-1.8,5.8-4,11.6-6.3,17.2c-0.2,0.4-0.8,0.2-0.6-0.2c2.4-5.6,4.5-11.4,6.3-17.2
       C345.2,204.6,345.8,204.8,345.7,205.2L345.7,205.2z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#00ADC8"
                      d="M346.6,240.1c-1.6,3.6-3.2,7.2-4.8,10.9c-0.2,0.4-0.7,0.1-0.6-0.3c1.6-3.6,3.2-7.2,4.8-10.9
       C346.2,239.4,346.8,239.7,346.6,240.1L346.6,240.1z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M314.3,207.7c-1.5,0-1.5-2.4,0-2.4C315.8,205.3,315.8,207.7,314.3,207.7L314.3,207.7z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M270.5,233.3c-1.5,0-1.5-2.4,0-2.4C272,230.9,272,233.3,270.5,233.3L270.5,233.3z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M268.1,199.5c-1.5,0-1.5-2.4,0-2.4C269.6,197.1,269.6,199.5,268.1,199.5L268.1,199.5z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M274,211.5c-1.5,0-1.5-2.4,0-2.4C275.6,209.1,275.6,211.5,274,211.5L274,211.5z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M298.8,229.6c-1.5,0-1.5-2.4,0-2.4C300.4,227.2,300.4,229.6,298.8,229.6L298.8,229.6z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M294.3,247.9c-1.5,0-1.5-2.4,0-2.4C295.9,245.5,295.9,247.9,294.3,247.9L294.3,247.9z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M306,241.1c-1.5,0-1.5-2.4,0-2.4C307.5,238.7,307.5,241.1,306,241.1L306,241.1z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M277.2,188.2c-1.5,0-1.5-2.4,0-2.4C278.7,185.8,278.7,188.2,277.2,188.2L277.2,188.2z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M300.6,213.5c-1.5,0-1.5-2.4,0-2.4C302.2,211.1,302.2,213.5,300.6,213.5L300.6,213.5z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M312.6,223.5c-1.5,0-1.5-2.4,0-2.4C314.2,221.1,314.2,223.5,312.6,223.5L312.6,223.5z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M266.6,219c-1.5,0-1.5-2.4,0-2.4C268.1,216.6,268.1,219,266.6,219L266.6,219z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M303.8,197.5c-1.5,0-1.5-2.4,0-2.4C305.4,195.1,305.4,197.5,303.8,197.5L303.8,197.5z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M363.2,204.7c-1.5,0-1.5-2.4,0-2.4C364.7,202.3,364.7,204.7,363.2,204.7L363.2,204.7z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M335.2,215.1c-1.5,0-1.5-2.4,0-2.4C336.7,212.7,336.7,215.1,335.2,215.1L335.2,215.1z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M358,222.6c-1.5,0-1.5-2.4,0-2.4C359.5,220.2,359.5,222.6,358,222.6L358,222.6z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M328.9,234.3c-1.5,0-1.5-2.4,0-2.4C330.5,231.9,330.5,234.3,328.9,234.3L328.9,234.3z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M336.8,227.6c-1.5,0-1.5-2.4,0-2.4C338.4,225.3,338.4,227.6,336.8,227.6L336.8,227.6z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M320.5,252c-1.5,0-1.5-2.4,0-2.4C322,249.6,322,252,320.5,252L320.5,252z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M350.1,237.7c-1.5,0-1.5-2.4,0-2.4C351.6,235.3,351.6,237.7,350.1,237.7L350.1,237.7z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#1E6674"
                      d="M309,171.1c-0.2,3.5-0.4,6.9-0.6,10.4c0,0.4-0.6,0.4-0.6,0c0.2-3.5,0.4-6.9,0.6-10.4
       C308.4,170.7,309,170.7,309,171.1L309,171.1z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#1E6674"
                      d="M308.2,184.7c-0.1,0.9-0.1,1.8-0.2,2.6c0,0.4-0.6,0.4-0.6,0c0.1-0.9,0.1-1.8,0.2-2.6
       C307.7,184.3,308.3,184.3,308.2,184.7L308.2,184.7z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M303,189.4c-4.6-1.7-9.1-3.5-13.6-5.3c-0.3-0.1-0.2-0.5,0.1-0.4c4.5,1.8,9.1,3.6,13.6,5.3
       C303.4,189.1,303.2,189.5,303,189.4L303,189.4z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M285.6,182.6c-3.2-1.3-6.4-2.6-9.6-3.9c-0.3-0.1-0.2-0.6,0.1-0.4c3.2,1.3,6.4,2.6,9.6,3.9
       C286,182.2,285.9,182.7,285.6,182.6L285.6,182.6z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M273.7,178.1c-1.7-0.7-3.5-1.2-5.1-2.1c-1.6-0.8-3-2-4.8-2.2c-0.3,0-0.3-0.5,0-0.5
       c1.7,0.2,3.1,1.1,4.5,2c1.7,1,3.7,1.6,5.5,2.3C274.1,177.8,274,178.2,273.7,178.1L273.7,178.1z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M360.3,195.6c-5.3,1-10.6,1.8-16,2.3c-0.3,0-0.3-0.4,0-0.5c5.3-0.5,10.6-1.3,15.8-2.3
       C360.5,195.1,360.6,195.5,360.3,195.6L360.3,195.6z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M340.9,198.7c-1.2,0.2-2.4,0.4-3.6,0.6c-0.3,0.1-0.4-0.4-0.1-0.4c1.2-0.2,2.4-0.4,3.6-0.6
       C341.1,198.2,341.2,198.7,340.9,198.7L340.9,198.7z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M323.2,197.8c-3.2-1.5-6.6-2.7-10-3.7c-0.3-0.1-0.2-0.5,0.1-0.4c3.5,1,6.8,2.3,10.1,3.7
       C323.7,197.5,323.5,197.9,323.2,197.8L323.2,197.8z"
                    />
                  </G>
                </G>
                <G>
                  <G>
                    <Path
                      fill="#FFFFFF"
                      d="M341.8,136.1c-0.9,3.3-2.7,6.3-4.9,8.9c-1.4,1.7-3.2,3.5-5.2,4.4c-0.2,0.1-0.4-0.2-0.2-0.3
       c2-0.8,3.7-2.6,5.1-4.2c2.2-2.6,4-5.6,4.9-8.9C341.5,135.8,341.9,135.9,341.8,136.1L341.8,136.1z"
                    />
                  </G>
                </G>
              </G>
            </G>
          </G>
        </G>
        <G>
          <G>
            <Path
              fill="#FF8F65"
              d="M163.2,178.4l10.5-1.3c1.8-0.2,3,1.6,2.1,3.2l-5.3,9c-0.4,0.6-0.4,1.3-0.1,2l4.5,9.4c0.8,1.6-0.7,3.3-2.4,3
                l-10.3-2.2c-0.7-0.1-1.4,0.1-1.9,0.5l-7.7,7.1c-1.3,1.2-3.4,0.4-3.6-1.3l-1.1-10.3c-0.1-0.7-0.5-1.3-1.1-1.6l-9.3-5
                c-1.6-0.8-1.5-3.1,0.2-3.8l9.6-4.2c0.6-0.3,1.1-0.9,1.2-1.5l2-10.2c0.3-1.7,2.5-2.3,3.7-1l7,7.7
                C161.8,178.2,162.5,178.5,163.2,178.4z"
            />
          </G>
          <G>
            <G>
              <Path
                fill="#FFC8B7"
                d="M153.2,173.3c1.6,1.8,3.3,3.7,3.6,6.1c0.1,0.7,1.2,0.7,1.1,0c-0.3-2.8-2.1-4.9-3.9-6.9
                    C153.5,172,152.7,172.8,153.2,173.3L153.2,173.3z"
              />
            </G>
          </G>
          <G>
            <G>
              <Path
                fill="#FFC8B7"
                d="M164.8,181.8c2.2-1,4.5-2,7-1.6c0.7,0.1,1-0.9,0.3-1.1c-2.8-0.5-5.3,0.5-7.9,1.7
                    C163.6,181.1,164.1,182.1,164.8,181.8L164.8,181.8z"
              />
            </G>
          </G>
          <G>
            <G>
              <Path
                fill="#FFC8B7"
                d="M171,198c0.6,0.8,0.9,1.7,0.9,2.7c0,0.7,1.1,0.7,1.1,0c0-1.2-0.4-2.3-1-3.2
                    C171.6,196.9,170.6,197.4,171,198L171,198z"
              />
            </G>
          </G>
        </G>
        <G>
          <G>
            <Path
              fill="#FF8F65"
              d="M382.2,300.7l4.5,5.7c0.8,1,0.1,2.4-1.1,2.4l-7.2,0c-0.5,0-0.9,0.2-1.2,0.6l-4,6c-0.7,1-2.2,0.8-2.6-0.4
                l-2.3-6.9c-0.2-0.5-0.5-0.8-1-1l-6.9-2c-1.2-0.3-1.5-1.9-0.5-2.6l5.8-4.3c0.4-0.3,0.6-0.7,0.6-1.2l-0.3-7.2
                c-0.1-1.2,1.3-2,2.3-1.2l5.9,4.2c0.4,0.3,0.9,0.4,1.4,0.2l6.7-2.4c1.1-0.4,2.3,0.7,1.9,1.8l-2.1,6.9
                C381.8,299.8,381.9,300.3,382.2,300.7z"
            />
          </G>
          <G>
            <G>
              <Path
                fill="#FFC8B7"
                d="M381.6,293c-0.5,1.6-1,3.3-2.4,4.3c-0.4,0.3,0,0.9,0.4,0.7c1.6-1.1,2.2-2.9,2.7-4.7
                    C382.5,292.7,381.8,292.5,381.6,293L381.6,293z"
              />
            </G>
          </G>
          <G>
            <G>
              <Path
                fill="#FFC8B7"
                d="M380.7,302.8c1.4,1,2.8,2,3.4,3.6c0.2,0.5,0.9,0.3,0.7-0.2c-0.7-1.8-2.2-3-3.8-4.1
                    C380.6,301.9,380.3,302.5,380.7,302.8L380.7,302.8z"
              />
            </G>
          </G>
          <G>
            <G>
              <Path
                fill="#FFC8B7"
                d="M373.3,312.3c-0.3,0.6-0.7,1.1-1.3,1.5c-0.4,0.3,0,0.9,0.4,0.7c0.7-0.4,1.2-1,1.6-1.7
                    C374.1,312.2,373.5,311.8,373.3,312.3L373.3,312.3z"
              />
            </G>
          </G>
        </G>
        <G>
          <G>
            <Ellipse fill="#FFCB8A" cx="416.1" cy="242.6" rx="19.8" ry="19.5" />
          </G>
          <G>
            <G>
              <Path
                fill="#384955"
                d="M423.3,240.4c2.4-0.4,4.7,0.8,5.9,2.9c0.4,0.6,1.4,0.1,1-0.6c-1.4-2.6-4.3-3.9-7.2-3.4
                    C422.3,239.4,422.6,240.5,423.3,240.4L423.3,240.4z"
              />
            </G>
          </G>
          <G>
            <G>
              <Path
                fill="#384955"
                d="M408.1,233.3c2.4-0.4,4.7,0.8,5.9,2.9c0.4,0.6,1.4,0.1,1-0.6c-1.4-2.6-4.3-3.9-7.2-3.4
                    C407.1,232.3,407.4,233.4,408.1,233.3L408.1,233.3z"
              />
            </G>
          </G>
          <G>
            <Path
              fill="#E9F4F6"
              d="M424.3,246.7c-4.6,1.4-11.4-1.4-16-6.4c0,0-3.5,8.5,4.6,11.9C420.4,255.4,424.3,246.7,424.3,246.7
                L424.3,246.7z"
            />
          </G>
          <G>
            <G>
              <Path
                fill="#FF8F65"
                d="M404.1,238.9c0.7,0.2,1.8,0,1.8-0.8c0-0.9-1-1.2-1.8-1.1c-0.8,0.2-1.3,1-0.7,1.7c0.5,0.6,1.7,0.7,2.3,0.4
                    c0.7-0.3,1.1-1.1,0.7-1.8c-0.4-0.7-1.2-1-2-0.8c-0.7,0.2-1.2,0.9-1.2,1.6c0,0.8,0.6,1.4,1.3,1.7c1.8,0.6,3-1.5,2-2.9
                    c-1-1.4-3.2-1.1-3.9,0.4c-0.3,0.7-0.2,1.5,0.4,2c0.7,0.5,1.9,0.6,2.7,0.2c1.6-0.9,0.8-3.3-1-3c-0.9,0.1-1.6,0.9-1.6,1.8
                    c0,1,0.9,1.5,1.7,1.5c0.9,0,1.8-0.4,2-1.3c0.2-0.9-0.4-1.7-1.2-2.2c-0.5-0.3-1.1,0.3-0.8,0.8c0.2,0.3,0.5,0.6,0.8,0.9
                    c0.5,0.5,1.3-0.3,0.8-0.8c-0.2-0.2-0.4-0.4-0.6-0.7c-0.3,0.3-0.5,0.5-0.8,0.8c0.6,0.3,1.1,1.3,0.1,1.4c-0.3,0-0.7,0-0.9-0.3
                    c-0.1-0.3,0.2-0.7,0.5-0.7c0.3-0.1,0.7,0.2,0.6,0.6c-0.1,0.4-0.6,0.4-1,0.4c-0.4,0-0.9-0.1-0.9-0.6c0-0.3,0.3-0.6,0.5-0.7
                    c0.6-0.3,1.3-0.1,1.5,0.6c0.1,0.3,0.1,0.7-0.2,0.9c-0.3,0.2-0.9,0-1.1-0.4c-0.4-0.8,0.9-0.9,0.9-0.5c0,0.5-0.9,0-1.1,0.1
                    c-0.2,0.1,0.2,0.3,0.3,0.2c0-0.1,0-0.2,0-0.3c-0.1,0-0.3,0-0.4-0.1C403.7,237.6,403.4,238.7,404.1,238.9L404.1,238.9z"
              />
            </G>
          </G>
          <G>
            <G>
              <Path
                fill="#FF8F65"
                d="M426.9,249.2c0.7,0.2,1.8,0,1.8-0.8c0-0.9-1-1.2-1.8-1.1c-0.8,0.2-1.3,1-0.7,1.7c0.5,0.6,1.7,0.7,2.3,0.4
                    c0.7-0.3,1.1-1.1,0.7-1.8c-0.4-0.7-1.2-1-2-0.8c-0.7,0.2-1.2,0.9-1.2,1.6c0,0.8,0.6,1.4,1.3,1.7c1.8,0.6,3-1.5,2-2.9
                    c-1-1.4-3.2-1.1-3.9,0.4c-0.3,0.7-0.2,1.5,0.4,2c0.7,0.5,1.9,0.6,2.7,0.2c1.6-0.9,0.8-3.3-1-3c-0.9,0.1-1.6,0.9-1.6,1.8
                    c0,1,0.9,1.5,1.7,1.5c0.9,0,1.8-0.4,2-1.3c0.2-0.9-0.4-1.7-1.2-2.2c-0.5-0.3-1.1,0.3-0.8,0.8c0.2,0.3,0.5,0.6,0.8,0.9
                    c0.5,0.5,1.3-0.3,0.8-0.8c-0.2-0.2-0.4-0.4-0.6-0.7c-0.3,0.3-0.5,0.5-0.8,0.8c0.6,0.3,1.1,1.3,0.1,1.4c-0.3,0-0.7,0-0.9-0.3
                    c-0.1-0.3,0.2-0.7,0.5-0.7c0.3-0.1,0.7,0.2,0.6,0.6c-0.1,0.4-0.6,0.4-1,0.4c-0.4,0-0.9-0.1-0.9-0.6c0-0.3,0.3-0.6,0.5-0.7
                    c0.6-0.3,1.3-0.1,1.5,0.6c0.1,0.3,0.1,0.7-0.2,0.9c-0.3,0.2-0.9,0-1.1-0.4c-0.4-0.8,0.9-0.9,0.9-0.5c0,0.5-0.9,0-1.1,0.1
                    c-0.2,0.1,0.2,0.3,0.3,0.2c0-0.1,0-0.2,0-0.3c-0.1,0-0.3,0-0.4-0.1C426.5,247.8,426.1,248.9,426.9,249.2L426.9,249.2z"
              />
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
};
