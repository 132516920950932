import { HStack, VStack, View } from "native-base";
import React, { useEffect, useState } from "react";
import { BoldTxt, H6, Txt } from "../../components/TextsComponents";
import { ProgressCircle } from "react-native-svg-charts";
import { colors } from "../../styles/GlobalStyle";
import { useTranslation } from "react-i18next";
import DimensionsHook from "../../hooks/DimensionsHook";
import { useSelector } from "react-redux";

const Cards = ({ bilanData, analysisData, setRedFlag }) => {
    const { t, i18n } = useTranslation();
    const { width } = DimensionsHook();

    const section1 = bilanData && bilanData.section1 ? bilanData.section1 : null
    const section2 = bilanData && bilanData.section2 ? bilanData.section2 : null
    const section3 = bilanData && bilanData.section3 ? bilanData.section3 : null
    const section4 = bilanData && bilanData.section4 ? bilanData.section4 : null
    const section5 = bilanData && bilanData.section5 ? bilanData.section5 : null
    const section6 = bilanData && bilanData.section6 ? bilanData.section6 : null
    const section7 = bilanData && bilanData.section7 ? bilanData.section7 : null
    const section8 = bilanData && bilanData.section8 ? bilanData.section8 : null
    const section9 = bilanData && bilanData.section9 ? bilanData.section9 : null
    const section10 = bilanData && bilanData.section10 ? bilanData.section10 : null
    const section11 = bilanData && bilanData.section11 ? bilanData.section11 : null
    const section12 = bilanData && bilanData.section12 ? bilanData.section12 : null
    const section13 = bilanData && bilanData.section13 ? bilanData.section13 : null
    const section14 = bilanData && bilanData.section14 ? bilanData.section14 : null





    const cards = [
        {
            value: 1,
            title: t("bilan.title1"),
            percent: section1 ? ((Number(section1.percent) + Number(section2.percent) + Number(section3.percent) + Number(section4.percent)) / 4).toFixed(0) : 0,
            sections: section1 ? [
                {
                    value: 0,
                    percent: Number(section1.percent).toFixed(0),
                },
                {
                    value: 1,
                    percent: Number(section2.percent).toFixed(0),
                },
                {
                    value: 2,
                    percent: Number(section3.percent).toFixed(0),
                },
                {
                    value: 3,
                    percent: Number(section4.percent).toFixed(0),
                },
            ] : [],
        },
        {
            value: 6,
            title: t("bilan.title12"),
            percent: section10 ? ((Number(section10.percent) + Number(section11.percent) + Number(section12.percent) + Number(section13.percent) + Number(section14.percent)) / 5).toFixed(0) : 0,
            sections: section10 ? [
                {
                    value: 9,

                    percent: Number(section10.percent).toFixed(0),
                },
                {
                    value: 10,

                    percent: Number(section11.percent).toFixed(0),
                },
                {
                    value: 11,

                    percent: Number(section12.percent).toFixed(0),
                },
                {
                    value: 12,

                    percent: Number(section13.percent).toFixed(0),
                },
                {
                    value: 13,

                    percent: Number(section14.percent).toFixed(0),
                },
            ] : [],
        },
        {
            value: 2,
            title: t("bilan.title6"),
            percent: section5 ? ((Number(section5.percent) + Number(section6.percent)) / 2).toFixed(0) : 0,
            sections: section5 ? [
                {
                    value: 4,

                    percent: Number(section5.percent).toFixed(0),
                },
                {
                    value: 5,

                    percent: Number(section6.percent).toFixed(0),
                },
            ] : [],
        },
        {
            value: 3,
            title: t("bilan.title9"),
            percent: section7 ? Number(section7.percent).toFixed(0) : 0,
            sections: section7 ? [
                {
                    value: 60,
                    percent: Number(((section7.answersData[0] + section7.answersData[1] + section7.answersData[2] + section7.answersData[3]) / 16) * 100).toFixed(0),
                },
                {
                    value: 61,
                    percent: Number(((section7.answersData[4] + section7.answersData[5] + section7.answersData[6] + section7.answersData[7]) / 18) * 100).toFixed(0),
                },
            ] : [],
        },
        {
            value: 4,
            percent: section8 ? Number(section8.percent).toFixed(0) : 0,
            title: t("bilan.title10"),
            sections: section8 ? [
                {
                    value: 7,
                    percent: Number(section8.percent).toFixed(0),
                },
            ] : [],
        },
        {
            value: 5,
            title: t("bilan.title11"),
            percent: section9 ? Number(section9.percent).toFixed(0) : 0,
            sections: section9 ? [
                {
                    value: 8,
                    percent: Number(section9.percent).toFixed(0),
                },
            ] : [],
        },
    ];



    const renderAnalysisText = (val, percent) => {
        const obj = analysisData.find((el) => el.value === val);

        const levelObj =
            percent < 34 ? obj.level1 : percent < 67 ? obj.level2 : obj.level3;
        if (i18n.language == "fr") {
            return `${levelObj.text_fr}`;
        } else {
            return `${levelObj.text_en}`;
        }
    };

    const renderConstantText = (c) => {
        const obj = analysisData.find((el) => el.value === c.value);
        if (obj.text_fr) {
            if (i18n.language == "fr") {
                return (
                    <Txt
                        style={{
                            fontSize: 15,
                            color: "#484848",
                            textAlign: "justify",
                            marginBottom: 5,
                        }}
                    >
                        {obj.text_fr}
                    </Txt>
                );
            } else {
                return (
                    <Txt
                        style={{
                            fontSize: 15,
                            color: "#484848",
                            textAlign: "justify",
                            marginBottom: 5,
                        }}
                    >
                        {obj.text_en}
                    </Txt>
                );
            }
        } else {
            return "";
        }
    };

    const renderSectionFourAnalysis = () => {
        if (
            bilanData.section4.answersData[0] + bilanData.section4.answersData[1] < 3 &&
            bilanData.section4.answersData[2] + bilanData.section4.answersData[3] > 3
        ) {
            return (
                <Txt style={{ fontSize: 15, color: "#484848", marginTop: 5 }}>
                    {t("analysis.text1")}
                </Txt>
            );
        } else if (
            bilanData.section4.answersData[0] + bilanData.section4.answersData[1] > 3 &&
            bilanData.section4.answersData[2] + bilanData.section4.answersData[3] < 3
        ) {
            return (
                <Txt style={{ fontSize: 15, color: "#484848", marginTop: 5 }}>
                    {t("analysis.text2")}
                </Txt>
            );
        } else if (
            bilanData.section4.answersData[0] + bilanData.section4.answersData[1] < 3 &&
            bilanData.section4.answersData[2] + bilanData.section4.answersData[3] < 3
        ) {
            return (
                <Txt style={{ fontSize: 15, color: "#484848", marginTop: 5 }}>
                    {t("analysis.text3")}
                </Txt>
            );
        } else {
            return null;
        }
    };

    const renderAnalysisSection = () => {
        useEffect(() => {
            setRedFlag(true)
        })
        return (
            <Txt
                style={{
                    fontSize: 16,
                    color: colors.red1,
                    marginTop: 10,
                    textAlign: "justify",
                    fontStyle: "italic",
                }}
            >
                {t("analysis.text4")}
            </Txt>
        );
    };




    return (
        <View w="full" >
            <HStack
                w="full"
                alignItems="stretch"
                alignSelf="center"

                justifyContent="space-between"
                flexWrap="wrap"
            >
                {cards.map((card, i) => {
                    if (card.percent == 0) {
                        return (
                            null
                        )
                    }
                    return (
                        <VStack
                            bg="#ffffff80"
                            overflow="hidden"
                            mb="3"
                            key={card.title}
                            style={{
                                width: width > 900 ? "49.5%" : "100%",

                                borderRadius: 15,
                                shadowColor: "#00000010",
                                shadowOffset: { width: 2, height: 2 },
                            }}
                            py="3"
                            px="5"
                        >
                            <VStack
                                style={{
                                    height: "100%",
                                    aspectRatio: 1 / 1,
                                    borderRadius: 1000,
                                    position: "absolute",
                                    backgroundColor:
                                        card.percent < 34
                                            ? colors.red1
                                            : card.percent < 67
                                                ? colors.yellow
                                                : colors.green2,
                                    top: "50%",
                                    left: "75%",
                                    opacity: 0.2,
                                }}
                            ></VStack>
                            <VStack style={{ borderRadius: 10 }}>
                                <HStack
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    space="2"
                                    mt="3"
                                >
                                    <VStack style={{ width: 45, height: 45 }}>
                                        <VStack>
                                            <ProgressCircle
                                                style={{ height: 45 }}
                                                progress={card.percent / 100}
                                                progressColor={
                                                    card.percent < 34 ? colors.red1 : card.percent < 67 ? colors.yellow : colors.green2
                                                }
                                                startAngle={-Math.PI * 1}
                                                endAngle={Math.PI * 1}
                                                strokeWidth={4}
                                            />
                                        </VStack>
                                        <VStack
                                            style={{
                                                width: 45,
                                                height: 45,
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                            position="absolute"
                                        >
                                            <BoldTxt
                                                style={{
                                                    fontSize: 13,
                                                    color:
                                                        card.percent < 34 ? colors.red1 : card.percent < 67 ? colors.yellow : colors.green2
                                                }}
                                            >
                                                {card.percent}
                                            </BoldTxt>
                                        </VStack>
                                    </VStack>
                                    <VStack flex={1}>
                                        <H6 style={{ fontSize: 18 }}>{card.title}</H6>
                                    </VStack>
                                </HStack>
                                <VStack style={{ marginTop: 10, marginBottom: 5 }}>
                                    <VStack mb="4">
                                        {card.sections.map((el) => {
                                            return (
                                                <VStack mt="2" key={el.value}>
                                                    {renderConstantText(el)}
                                                    <Txt
                                                        style={{
                                                            fontSize: 15,
                                                            color: "#484848",
                                                            textAlign: "justify",
                                                        }}
                                                    >
                                                        {renderAnalysisText(el.value, el.percent)}
                                                    </Txt>
                                                    {el.value === 3 && renderSectionFourAnalysis()}
                                                    {el.value === 3 && ((section4.answersData[0] + section4.answersData[1] < 3) || (section4.answersData[2] + section4.answersData[3] < 3)) && renderAnalysisSection()}
                                                    {el.value === 6 && ((section7.answersData[4] <= 1) || (section7.answersData[5] <= 1) || (section7.answersData[6] <= 1 || section7.answersData[7] <= 1)) && renderAnalysisSection()}

                                                </VStack>
                                            );
                                        })}

                                    </VStack>
                                </VStack>
                            </VStack>
                        </VStack>
                    );
                })}
            </HStack>
        </View>
    );
};

export default Cards;



