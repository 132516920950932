import { FlatList, Platform, StyleSheet, View } from "react-native";
import React from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { H6 } from "../TextsComponents";
import { useSelector } from "react-redux";
import SwiperItem from "./SwiperItem";

const CurrentTrailsSwiper = ({ data, navigation, myspace }) => {
  const { pseudo } = useSelector((state) => state.userData);
  const { t } = useTranslation();

  const { isMobile } = DimensionsHook();
  const swiperContainerStye = {
    backgroundColor: colors.white,
    width: myspace ? "100%" : "95%",
    alignSelf: "center",
    paddingTop: 15,
    zIndex: -2,
    paddingBottom: 5,
    borderRadius: 20,
    marginTop: 20,
    paddingLeft: isMobile ? 10 : 20,
    paddingRight: isMobile ? 0 : 20,
  };

  if (!data) {
    return <></>;
  }

  return (
    <View style={swiperContainerStye}>
      <H6>
        {t("home_main.continue_watching")}{" "}
        <H6
          onPress={() => navigation.navigate("Espace")}
          style={{
            textShadowColor: colors.blue3,
            textDecoration: "underline",
            textShadowRadius: 0.5,
            textShadowOffset: {
              width: 0,
              height: 0,
            },
          }}
        >
          {pseudo}
        </H6>
      </H6>
      <FlatList
        showsHorizontalScrollIndicator={Platform.OS === "web"}
        horizontal
        style={{ marginTop: 15 }}
        data={data}
        keyExtractor={(item) => item.trail_code}
        renderItem={(props) => (
          <SwiperItem
            {...props}
            type="Trail"
            navigation={navigation}
            showStateBar={true}
          />
        )}
      />
    </View>
  );
};

export default CurrentTrailsSwiper;

const styles = StyleSheet.create({});
