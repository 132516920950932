import { ImageBackground, StyleSheet, View } from "react-native";
import React, { useEffect } from "react";
import { Modal, VStack } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { BoldTxt, Txt } from "../TextsComponents";

import Subscription from "./PlansModalCards/Subscription";
import Lifetime from "./PlansModalCards/Lifetime";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../redux/Actions";
import { useTranslation } from "react-i18next";

const PlansModal = ({
  isOpen,
  setIsOpen,
  navigation,
  subscriptions,
  lifetimePlan,
}) => {
  const { isDesktop, height } = DimensionsHook();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);

  const closeHandler = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (userData.subscription || userData.lifetime_subscription) {
        setIsOpen(false);
      } else {
        dispatch(setUserData());
      }
      return clearInterval(interval);
    }, 2000);
  }, [userData]);

  return (
    <Modal focusable={false} isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={[styles.container, { maxHeight: height - 45 }]}>
        <Modal.Body>
          <ImageBackground
            source={require("../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ position: "absolute", right: 0, top: 10 }}
            />

            <VStack
              style={{
                marginHorizontal: isDesktop ? 20 : 0,
                marginBottom: 20,
                marginTop: 40,
              }}
            >
              {/** case is parrained show message */}
              {userData.parrained && (
                <Txt
                  style={{
                    textAlign: "center",
                    marginBottom: 15,
                    marginTop: 10,
                  }}
                >
                  <BoldTxt>
                    {userData.parrainedBy && userData.parrainedBy}{" "}
                  </BoldTxt>
                  {t("parrainage.text2")}
                </Txt>
              )}
              <View style={styles.cardsContainer}>
                {subscriptions.map((plan) => {
                  return (
                    <Subscription
                      key={plan._id}
                      plan={plan}
                      closeHandler={closeHandler}
                      navigation={navigation}
                    />
                  );
                })}
                <Lifetime
                  plan={lifetimePlan}
                  closeHandler={closeHandler}
                  navigation={navigation}
                />
              </View>
            </VStack>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default PlansModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "99%",
    maxWidth: 1400,
  },
  buttonsContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  badgeContainer: {
    width: "100%",
    padding: 15,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: colors.green0,
  },
  rowInputs: {
    flexDirection: "row",
    marginTop: 10,
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardsContainer: {
    width: "100%",
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
});
