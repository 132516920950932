import { ImageBackground, StyleSheet, TouchableOpacity } from "react-native";
import React, { useRef, useState } from "react";
import DimensionsHook from "../../hooks/DimensionsHook";
import { View } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import { H6 } from "../TextsComponents";
import { useHover } from "react-native-web-hooks";
import { MaterialIcons } from "@expo/vector-icons";
import { CustomTooltip } from "../Tooltips";
import SoonModal from "../modals/SoonModal";
import { useTranslation } from "react-i18next";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import SubscriptionRequiredModal from "../modals/SubscriptionRequiredModal";
import { BilanIcon, MessageOfMonthIcon, NewIcon, TutoIcon } from "../../assets/svg/Icons";

const CardItem = ({ id, title, onPress, bg }) => {
  const hover = useRef(null);
  const isHover = useHover(hover);
  const { width } = DimensionsHook();
  const { t } = useTranslation();
  return (
    <CustomTooltip
      placement="bottom"
      label={
        id == "1"
          ? ""
          : id == "2"
            ? ""
            : id == "3"
              ? t("begin.text1")
              : t("begin.text2")
      }
      bg={colors.blue3}
    >
      <TouchableOpacity
        onPress={onPress || null}
        ref={hover}
        style={{
          height: 60,
          width: width >= 1200 ? "24%" : width < 600 ? "97%" : "48%",
          alignSelf: "center",
          marginTop: 10,
          overflow: "hidden",
          borderTopRightRadius: 80,
          borderBottomLeftRadius: 80,
          backgroundColor: isHover ? colors.blue3 : bg,
        }}
      >
        <ImageBackground
          source={require("../../assets/background.png")}
          style={{ flex: 1 }}
        >
          <View
            style={{
              height: "100%",
              aspectRatio: 1 / 1,
              transform: [{ scale: 1.1 }],
              backgroundColor: "#ffffff60",
              borderRadius: 1000,
              position: "absolute",
              left: -20,
              alignItems: "flex-end",
            }}
          ></View>
          <View
            style={{
              flex: 1,
              marginLeft: 46,
              marginRight: 5,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {id == "1" ? (
              <View style={{ width: 26, height: 26 }} mx="1.5">
                <MessageOfMonthIcon
                  color={isHover ? colors.white : colors.blue3}
                />
              </View>
            ) : id == "2" ? (
              <View style={{ width: 30, height: 26 }} mx="1.5">
                <TutoIcon
                  color={isHover ? colors.white : colors.blue3}
                />
              </View>

            ) : id == "3" ? (
              <View style={{ width: 24, height: 24 }} mx="2">
                <BilanIcon
                  color={isHover ? colors.white : colors.blue3}
                />
              </View>

            ) : (
              <View style={{ width: 26, height: 40 }} mx="1.5">
                <NewIcon
                  color={isHover ? colors.white : colors.blue3}
                />
              </View>
            )}

            <H6
              style={{ fontSize: 15, letterSpacing: 1 }}
              color={isHover ? colors.white : colors.blue3}
            >
              {title}
            </H6>
          </View>
        </ImageBackground>
      </TouchableOpacity>
    </CustomTooltip>
  );
};

const ForBeginSection = ({ openTutoHandler, openMonthlyMessageHandler }) => {
  const [isSoonOpen, setIsSoonOpen] = useState(false);
  const [showNeedSubscriptionModal, setShowNeedSubscriptionModal] =
    useState(false);
  const { subscription_info } = useSelector((state) => state.userData);
  const { t, i18n } = useTranslation();
  const navigation = useNavigation();
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        marginTop: 10,
        marginBottom: 20,
      }}
    >
      {/** message du mois */}
      <CardItem
        id="1"
        bg={colors.green1}
        onPress={openMonthlyMessageHandler}
        title={t("begin.title1")}
      />

      <CardItem
        id="2"
        onPress={openTutoHandler}
        bg={colors.blue1}
        title={t("begin.title2")}
      />

      <CardItem
        onPress={() => {
          if (subscription_info == "freemium") {
            setShowNeedSubscriptionModal(true)
          } else {
            if (i18n.language == "fr") {
              navigation.navigate("BilanWelcome");
            } else {
              setIsSoonOpen(true);
            }
          }
        }}
        id="3"
        bg={colors.yellow}
        title={t("begin.title3")}
      />
      <CardItem
        onPress={() => {
          navigation.navigate("Nouveautes");
          //  setIsSoonOpen(true)
        }}
        id="4"
        bg={colors.red1}
        title={t("begin.title4")}
      />
      <SoonModal isOpen={isSoonOpen} setIsOpen={setIsSoonOpen} />
      {/** need subscription modal */}
      <SubscriptionRequiredModal
        isOpen={showNeedSubscriptionModal}
        setIsOpen={setShowNeedSubscriptionModal}
        navigation={navigation}
      />
    </View>
  );
};

export default ForBeginSection;


