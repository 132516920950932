import { Checkbox, HStack, Radio, ScrollView, VStack, View, Image } from 'native-base'
import React, { useEffect, useState } from 'react'
import { H6, Txt } from '../../components/TextsComponents'
import { colors } from '../../styles/GlobalStyle';
import { useTranslation } from 'react-i18next';
import { ImageBackground, Platform } from 'react-native';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import Spinner from '../../components/Spinner';
import BackHeader from '../../components/BackHeader';
import DimensionsHook from '../../hooks/DimensionsHook';
import { Input } from '../../components/Inputs';
import ConfettiCannon from "react-native-confetti-cannon";
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
const PreferenceScreen = ({ navigation, route }) => {
    const { t } = useTranslation()
    const { width, isDesktop, isMobile } = DimensionsHook()
    const [pref1, setPref1] = useState(route.params.bilanData && route.params.bilanData.pref1 ? route.params.bilanData.pref1 : []);
    const [pref2, setPref2] = useState(route.params.bilanData && route.params.bilanData.pref2 ? route.params.bilanData.pref2 : "");
    const [pref3, setPref3] = useState(route.params.bilanData && route.params.bilanData.pref3 ? route.params.bilanData.pref3 : "");
    const [pref4, setPref4] = useState(route.params.bilanData && route.params.bilanData.pref4 ? route.params.bilanData.pref4 : "");
    const [pref5, setPref5] = useState(route.params.bilanData && route.params.bilanData.pref5 ? route.params.bilanData.pref5 : []);
    const [showSuccess, setShowSuccess] = useState(false)
    const [pref5Data, setPref5Data] = useState(null);
    const [notificationFrequency, setNotificationFrequency] = useState("");
    const [submitLoader, setSubmitLoader] = useState(false)

    const submitHandler = async () => {
        if (!submitLoader) {
            setSubmitLoader(true);
            const TOKEN = await AsyncStorage.getItem("access_token");
            const postData = {
                pref1, pref2, pref3, pref4, pref5, notificationFrequency, access_token: TOKEN
            }
            await axios.post(`${process.env.QUESTIONNAIRES}/pref`, postData).then(() => {
                setTimeout(() => {
                    setSubmitLoader(false);
                    setShowSuccess(true);
                }, 500);
            })
        }

    }

    const RenderPref5Section = ({ answer, i }) => {
        const [checked, setChecked] = useState(false);

        useEffect(() => {
            if (pref5.includes(answer)) {
                setChecked(true);
            }
        }, []);

        return (
            <View>
                <Checkbox
                    mt="4"
                    _text={{ fontFamily: "Gotu", color: colors.blue3, fontSize: 13 }}
                    _checked={{
                        _icon: {
                            bg: colors.green2,
                            color: "white",
                            size: "xs",
                        },
                        borderColor: colors.green2,
                    }}
                    size="sm"
                    isChecked={checked}
                    onChange={(val) => {
                        setChecked(!checked);
                        if (val) {
                            let arr = pref5;
                            arr.push(answer);

                            setPref5(arr);
                        } else {
                            let arr = pref5;
                            const newArr = arr.filter((el) => el !== answer);

                            setPref5(newArr);
                        }
                    }}
                >
                    {answer}
                </Checkbox>
            </View>
        );
        /*  }*/
    };

    //render multiple answers
    const RenderMultipleChoiceAnswer = ({ answer, i }) => {
        const [checked, setChecked] = useState(false);

        useEffect(() => {
            if (pref1.includes(answer)) {
                setChecked(true);
            }
        }, []);

        return (
            <View>
                <Checkbox
                    mt="4"
                    _text={{ fontFamily: "Gotu", color: colors.blue3, fontSize: 13 }}
                    _checked={{
                        _icon: {
                            bg: colors.green2,
                            color: "white",
                            size: "xs",
                        },
                        borderColor: colors.green2,
                    }}
                    size="sm"
                    isChecked={checked}
                    onChange={(val) => {
                        setChecked(!checked);
                        if (val) {
                            let arr = pref1;
                            arr.push(answer);

                            setPref1(arr);
                        } else {
                            let arr = pref1;
                            const newArr = arr.filter((el) => el !== answer);

                            setPref1(newArr);
                        }
                    }}
                >
                    {answer}
                </Checkbox>
            </View>
        );
        /*  }*/
    };

    const firstPrefAnswers = [
        t("q_preferences.answer1"),
        t("q_preferences.answer2"),
        t("q_preferences.answer3"),
        t("q_preferences.answer4"),
        t("q_preferences.answer5"),
        t("q_preferences.answer6"),
    ];
    const secondPrefAnswers = [
        t("q_preferences.answer7"),
        t("q_preferences.answer8"),
        t("q_preferences.answer9"),
        t("q_preferences.answer10"),
    ];
    const prefSecondQuestion = [
        {
            _id: "Emotionnel/mental",
            answers: [
                t("welcome_second_answers.ans1"),
                t("welcome_second_answers.ans2"),
                t("welcome_second_answers.ans3"),
                t("welcome_second_answers.ans4"),
                t("welcome_second_answers.ans5"),
                t("welcome_second_answers.ans6"),
                t("welcome_second_answers.ans7"),
                t("welcome_second_answers.ans8"),
                t("welcome_second_answers.ans9"),
                t("welcome_second_answers.ans10"),
                t("welcome_second_answers.ans11"),
                t("welcome_second_answers.ans12"),
                t("welcome_second_answers.ans13"),
            ],
        },
        {
            _id: "Corps",
            answers: [
                t("welcome_second_answers.ans14"),
                t("welcome_second_answers.ans15"),
                t("welcome_second_answers.ans16"),
                t("welcome_second_answers.ans17"),
            ],
        },
        {
            _id: "Social-Familial",
            answers: [
                t("welcome_second_answers.ans18"),
                t("welcome_second_answers.ans19"),
                t("welcome_second_answers.ans20"),
                t("welcome_second_answers.ans21"),
                t("welcome_second_answers.ans22"),
                t("welcome_second_answers.ans23"),
                t("welcome_second_answers.ans24"),
            ],
        },
        {
            _id: "Professionnel",
            answers: [
                t("welcome_second_answers.ans25"),
                t("welcome_second_answers.ans26"),
                t("welcome_second_answers.ans27"),
                t("welcome_second_answers.ans28"),
                t("welcome_second_answers.ans29"),
                t("welcome_second_answers.ans30"),
                t("welcome_second_answers.ans31"),
                t("welcome_second_answers.ans32"),
                t("welcome_second_answers.ans33"),
            ],
        },
        {
            _id: "Spirituel",
            answers: [
                t("welcome_second_answers.ans34"),
                t("welcome_second_answers.ans35"),
            ],
        },
        {
            _id: "Financier",
            answers: [
                t("welcome_second_answers.ans36"),
                t("welcome_second_answers.ans37"),
                t("welcome_second_answers.ans38"),
            ],
        },
    ];
    const pref4Answers = [
        t("q_preferences.answer11"),
        t("q_preferences.answer12"),
        t("q_preferences.answer13"),
        t("q_preferences.answer14"),
        t("q_preferences.answer15"),
        t("q_preferences.answer16"),
    ];

    useEffect(() => {
        if (route.params.bilanData && route.params.bilanData.pref4) {
            const pref5data = prefSecondQuestion.find(el => el._id == route.params.bilanData.pref4)
            setPref5Data(pref5data);
        }
    }, [])


    return (
        <View flex={1} bg={colors.beige}>
            <ImageBackground
                source={require("../../assets/background.png")}
                style={{ flex: 1 }}
            >
                <BackHeader
                    isOpen={false}
                    setIsOpen={() => { }}
                    navigation={navigation}
                />
                {showSuccess ? (
                    <VStack overflow="hidden" flex={1} w="full">
                        <ConfettiCannon
                            autoStart
                            fallSpeed={2000}
                            colors={[
                                colors.green2,
                                colors.green3,
                                colors.red1,
                                colors.yellow,
                                colors.blue2,
                            ]}
                            count={160}
                            origin={{ x: -10, y: 0 }}
                        />
                        <VStack
                            style={{ width: "95%", alignSelf: "center", marginTop: -50 }}
                            flex={1}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Image
                                source={{
                                    uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
                                }}
                                style={{ width: 80, height: 130, marginBottom: 10 }}
                            />
                            <H6>{t("questionnaires.title1")}</H6>

                            <VStack style={{ maxWidth: 600 }} mx="4" mt="4">
                                <VStack mt="2">
                                    <Txt
                                        style={{
                                            textAlign: "center",
                                            color: "#484848",
                                            fontSize: 15,
                                        }}
                                    >
                                        {t("bilan.text2")}
                                    </Txt>
                                </VStack>
                                <VStack mt="2">
                                    <Txt
                                        style={{
                                            textAlign: "center",
                                            color: "#484848",
                                            fontSize: 15,
                                        }}
                                    >
                                        {t("bilan.text3")}
                                    </Txt>
                                </VStack>
                            </VStack>
                            <View
                                style={{
                                    flexDirection: isMobile ? "column" : "row",
                                    marginTop: 30,
                                }}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <SecondaryButton onPress={() => navigation.goBack()}>
                                    {t("bilan.navigate")}
                                </SecondaryButton>
                                {!isMobile && <VStack w="2" />}
                                <PrimaryButton
                                    style={{ marginTop: isMobile ? 10 : 0 }}
                                    onPress={() => {
                                        navigation.navigate("TabNavigation", {
                                            screen: "Espace",
                                        });
                                    }}
                                >
                                    {t("questionnaires.recommandation")}
                                </PrimaryButton>
                            </View>
                        </VStack>
                    </VStack>
                ) : (<ScrollView showsVerticalScrollIndicator={Platform.OS == "web"}>
                    <VStack mt="3" w={isDesktop ? width * 0.9 : "100%"} style={{ alignSelf: 'center' }}  >
                        {/** pref 3 & pref 4 */}
                        <HStack
                            justifyContent="space-between"
                            alignItems="stretch"
                            mt="3"
                            w="full"
                            flexWrap="wrap"
                        >
                            <VStack
                                borderRadius={width < 1000 ? "md" : "lg"}
                                mb="2"
                                p={width < 1000 ? "3" : "5"}
                                bg="white"
                                style={{ width: width < 1000 ? "100%" : "49%" }}
                            >
                                <Txt style={{ fontSize: 14 }}>{t("q_preferences.question4")}</Txt>
                                <Txt
                                    style={{
                                        color: "#484848",
                                        fontSize: 12,
                                        marginTop: 4,
                                    }}
                                >
                                    {t("questionnaires.single")}
                                </Txt>
                                {/** render question answers */}
                                <VStack ml="2" my="2" w="full">
                                    <Radio.Group
                                        w="full"
                                        name="single answer"
                                        accessibilityLabel="choose one answer"
                                        value={pref4}
                                        onChange={async (nextValue) => {
                                            setPref4(nextValue);
                                            let i = pref4Answers.indexOf(nextValue);
                                            setPref5([]);
                                            setPref5Data(prefSecondQuestion[i]);
                                        }}
                                    >
                                        {pref4Answers.map((answer, aIndex) => {
                                            return (
                                                <HStack mt="2" w="full" pr="2" key={answer}>
                                                    <Radio
                                                        _android={{ alignSelf: "flex-start" }}
                                                        _ios={{ alignSelf: "flex-start" }}
                                                        _checked={{
                                                            _icon: { color: colors.green2 },
                                                            borderColor: colors.green2,
                                                        }}
                                                        _text={{
                                                            fontFamily: "Gotu",
                                                            color: colors.blue3,
                                                            fontSize: 13,
                                                        }}
                                                        mt="2"
                                                        flex={1}
                                                        value={answer}
                                                    >
                                                        {answer}
                                                    </Radio>
                                                </HStack>
                                            );
                                        })}
                                    </Radio.Group>
                                </VStack>
                            </VStack>
                            {pref5Data ? (
                                <VStack
                                    borderRadius={width < 1000 ? "md" : "lg"}
                                    mb="2"
                                    p={width < 1000 ? "3" : "5"}
                                    bg="white"
                                    style={{ width: width < 1000 ? "100%" : "49%" }}
                                >
                                    <Txt style={{ fontSize: 14 }}>{t("q_preferences.question5")}</Txt>
                                    <Txt
                                        style={{
                                            color: "#484848",
                                            fontSize: 12,
                                            marginTop: 4,
                                        }}
                                    >
                                        {t("questionnaires.multiple")}
                                    </Txt>
                                    {/** render question answers */}
                                    {pref5Data.answers.map((answer) => (
                                        <RenderPref5Section answer={answer} key={answer} />
                                    ))}
                                </VStack>
                            ) : (
                                <VStack
                                    borderRadius={width < 1000 ? "md" : "lg"}

                                    style={{ width: width < 1000 ? "100%" : "49%" }}
                                ></VStack>
                            )}
                        </HStack>
                        {/** pref 1 & pref 2 */}
                        <HStack
                            justifyContent="space-between"
                            alignItems="stretch"
                            mt="3"
                            w="full"
                            flexWrap="wrap"
                        >
                            <VStack
                                borderRadius={width < 1000 ? "md" : "lg"}
                                mb="2"
                                p={width < 1000 ? "3" : "5"}
                                bg="white"
                                style={{ width: width < 1000 ? "100%" : "49%" }}
                            >
                                <Txt style={{ fontSize: 14 }}>{t("q_preferences.question1")}</Txt>
                                <Txt
                                    style={{
                                        color: "#484848",
                                        fontSize: 12,
                                        marginTop: 4,
                                    }}
                                >
                                    {t("questionnaires.multiple")}
                                </Txt>
                                {/** render question answers */}
                                {firstPrefAnswers.map((answer) => (
                                    <RenderMultipleChoiceAnswer answer={answer} key={answer} />
                                ))}
                            </VStack>
                            <VStack
                                borderRadius={width < 1000 ? "md" : "lg"}
                                mb="2"
                                p={width < 1000 ? "3" : "5"}
                                bg="white"
                                style={{ width: width < 1000 ? "100%" : "49%" }}
                            >
                                <Txt style={{ fontSize: 14 }}>{t("q_preferences.question2")}</Txt>
                                <Txt
                                    style={{
                                        color: "#484848",
                                        fontSize: 12,
                                        marginTop: 4,
                                    }}
                                >
                                    {t("questionnaires.single")}
                                </Txt>
                                {/** render question answers */}
                                <VStack ml="2" my="2" w="full">
                                    <Radio.Group
                                        w="full"
                                        name="single answer"
                                        accessibilityLabel="choose one answer"
                                        value={pref2}
                                        onChange={async (nextValue) => {
                                            setPref2(nextValue);
                                        }}
                                    >
                                        {secondPrefAnswers.map((answer, aIndex) => {
                                            return (
                                                <HStack w="full" pr="2" key={answer}>
                                                    <Radio
                                                        _android={{ alignSelf: "flex-start" }}
                                                        _ios={{ alignSelf: "flex-start" }}
                                                        _checked={{
                                                            _icon: { color: colors.green2 },
                                                            borderColor: colors.green2,
                                                        }}
                                                        _text={{
                                                            fontFamily: "Gotu",
                                                            color: colors.blue3,
                                                            fontSize: 13,
                                                        }}
                                                        mt="2"
                                                        flex={1}
                                                        value={answer}
                                                    >
                                                        {answer}
                                                    </Radio>
                                                </HStack>
                                            );
                                        })}
                                    </Radio.Group>
                                </VStack>
                                {/** notification frequency */}
                                <VStack px="2" mt="4" w="full">
                                    {pref2 == "" ? null : secondPrefAnswers.indexOf(pref2) == 0 ? (
                                        // case  first answer
                                        <View>
                                            <Txt style={{ fontSize: 12, textAlign: "justify" }}>
                                                {t("questionnaires_answers.text1")}
                                            </Txt>
                                            <Txt
                                                style={{
                                                    marginTop: 10,
                                                    fontSize: 12,
                                                    textAlign: "justify",
                                                }}
                                            >
                                                {t("questionnaires_answers.text2")}
                                            </Txt>
                                        </View>
                                    ) : // case  second answer
                                        secondPrefAnswers.indexOf(pref2) == 1 ? (
                                            <Txt
                                                style={{
                                                    fontSize: 12,
                                                    marginBottom: 10,
                                                    textAlign: "justify",
                                                }}
                                            >
                                                {t("questionnaires_answers.text3")}
                                            </Txt>
                                        ) : (
                                            // case  3 or 4 answer
                                            <View w="full">
                                                <Txt style={{ fontSize: 13 }}>
                                                    {t("questionnaires_answers.text4")}
                                                </Txt>

                                                <Radio.Group
                                                    mt="2"
                                                    colorScheme="success"
                                                    name="notifications"
                                                    accessibilityLabel="choose one option"
                                                    value={notificationFrequency}
                                                    onChange={(nextValue) => {
                                                        setNotificationFrequency(nextValue);
                                                    }}
                                                >
                                                    <HStack
                                                        w="full"
                                                        alignItems="stretch"
                                                        justifyContent="flex-start"
                                                        flexWrap="wrap"
                                                    >
                                                        {[
                                                            t("setting_screen.options.q"),
                                                            t("setting_screen.options.h"),
                                                            t("setting_screen.options.m"),
                                                        ].map((el) => {
                                                            return (
                                                                <HStack key={el} w="1/2" px="2">
                                                                    <Radio
                                                                        _android={{ alignSelf: "flex-start" }}
                                                                        _ios={{ alignSelf: "flex-start" }}
                                                                        _checked={{
                                                                            _icon: { color: colors.green2 },
                                                                            borderColor: colors.green2,
                                                                        }}
                                                                        _text={{
                                                                            fontFamily: "Gotu",
                                                                            color: colors.blue3,
                                                                            fontSize: 13,
                                                                            marginRight: 4,
                                                                        }}
                                                                        mt="2"
                                                                        value={el}
                                                                    >
                                                                        {el}
                                                                    </Radio>
                                                                </HStack>
                                                            );
                                                        })}
                                                    </HStack>
                                                </Radio.Group>
                                            </View>
                                        )}
                                </VStack>
                            </VStack>
                        </HStack>
                        {/** pref 3 */}
                        <VStack
                            borderRadius={width < 1000 ? "md" : "lg"}
                            mb="2"
                            p={width < 1000 ? "3" : "5"}
                            bg="white"
                            w="full"
                        >
                            <Txt style={{ fontSize: 14 }}>{t("q_preferences.question3")}</Txt>
                            <Txt
                                style={{
                                    color: "#484848",
                                    fontSize: 12,
                                    marginTop: 4,
                                }}
                            >
                                {t("questionnaires.free_multiple")}
                            </Txt>
                            <VStack style={{ marginTop: 10 }}>
                                <Input
                                    value={pref3}
                                    onChangeText={(txt) => setPref3(txt)}
                                    placeholder={t("questionnaires_answers.placeholder1")}
                                />
                            </VStack>
                        </VStack>
                        <HStack
                            mt="3"
                            mb="10"
                            mx="1"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <VStack>

                            </VStack>

                            <PrimaryButton style={{ width: 200 }} onPress={() => submitHandler(false)}>
                                {submitLoader ? (
                                    <Spinner color="white" size="small" />
                                ) : (
                                    t("bilan.save")
                                )}
                            </PrimaryButton>
                        </HStack>
                    </VStack>
                </ScrollView>)}

            </ImageBackground>
        </View>
    )
}

export default PreferenceScreen

