import {
  View,
  StyleSheet,
  ScrollView,
  ImageBackground,
  Platform,
  Linking,
  Pressable,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import BackHeader from "../../components/BackHeader";
import { colors } from "../../styles/GlobalStyle";
import Footer from "../../components/Footer";
import { BoldTxt, SmallBoldTxt, Txt } from "../../components/TextsComponents";
import { DropDown, Input } from "../../components/Inputs";
import { Checkbox } from "react-native-paper";
import { PrimaryButton } from "../../components/Buttons";
import DimensionsHook from "../../hooks/DimensionsHook";
import RadioButton from "./Components/RadioButton";
import axios from "axios";
import {
  IconeSeRelaxer,
  IconeSmile,
  TrailsIcon,
} from "../../assets/svg/Appointment";
import Head from "./Components/head";
import Spinner from "../../components/Spinner";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Localization from "expo-localization";
import moment from "moment-timezone";
import Purchases from "react-native-purchases";
import * as WebBrowser from "expo-web-browser";
import SuccessBuyCoachingViaCompany from "../../components/modals/SuccessBuyCoachingViaCompany";
import { Alert, Box, Center, CloseIcon, HStack, IconButton, Text, VStack } from "native-base";
import AlertBar from "../../components/AlertBar";

const Appointment = ({ navigation, route }) => {
  const { t } = useTranslation();
  const isGift = route.params.gift;
  const { firstname, lastname, email, customer, language, company } =
    useSelector((state) => state.userData);
    console.log('companyy',company)

  const data = [
    { label: t("appointment.trail"), value: "Trail", icon: <TrailsIcon /> },
    {
      label: t("appointment.workshop"),
      value: "Atelier",
      icon: <IconeSeRelaxer />,
    },
    { label: t("appointment.other"), value: "Autre", icon: <IconeSmile /> },
  ];

  const Langue = [
    { label: t("language.placeholder"), value: "" },
    { label: t("language.fr"), value: "fr" },
    { label: t("language.en"), value: "en" },
  ];
  const Categories = [
    {
      label: t("workshop_types.label"),
      value: "all",
    },
    {
      label: t("workshop_types.conscience"),
      value: "conscience",
    },
    {
      label: t("workshop_types.corps"),
      value: "body",
    },
    {
      label: t("workshop_types.plaisir"),
      value: "fun",
    },
    {
      label: t("workshop_types.rituels"),
      value: "rituals",
    },
    {
      label: t("workshop_types.science"),
      value: "science",
    },
    {
      label: t("workshop_types.temoignages"),
      value: "testimony",
    },
    {
      label: t("workshop_types.outils"),
      value: "tools",
    },
  ];

  const { isDesktop } = DimensionsHook();

  const userLanguage = Langue.find((lng) => lng.value == language);

  // Text Message
  const [text, setText] = useState("");
  const [coachingCode, setCoachingCode] = useState("");
  const [firstnameInput, setFirstNameInput] = useState("");
  const [lastnameInput, setLastNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [product, setProduct] = useState(null);
  const [buyViaCompany, setBuyViaCompany] = useState(false);
  const [pay, setPay] = useState(false);

  // Select Langue
  const [showLangue, setShowLangue] = useState(false);
  const [selectedLangue, setSelectedLangue] = useState(userLanguage);

  // Select TimeZone
  const [timeZoneOptions, setTimeZoneOptions] = useState([
    { label: t("placeholders.timezone"), value: "" },
  ]);
  const [showTimeZone, setShowTimeZone] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState(timeZoneOptions[0]);

  const [Data, setData] = useState([]);
  const [showSuccessBuyViaCompany, setShowSuccessBuyViaCompany] =
    useState(false);

  const scrollRef = useRef();

  const scrollToTop = () => {
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  };

  //  Function Close all select Opned
  const CloseAll = () => {
    setShowLangue(false);
    setSelectedLangue(userLanguage);
    setShowTimeZone(false);
    setText("");
    setFirstNameInput("");
    setLastNameInput("");
    setEmailInput("");
  };

  // get flash coaching product & trails data
  const getData = async () => {
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };

    if (company && !isGift) {
      const res = await axios.get(
        `${process.env.PAYMENT_APIS}/check-company-offers?company_id=${company}`,
        config
      );
      setBuyViaCompany(res.data.coaching);
    }

    const TimeZone = moment.tz.names().map((el) => {
      return { label: el, value: el };
    });
    TimeZone.unshift({ label: t("placeholders.timezone"), value: "" });
    setTimeZoneOptions(TimeZone);

    const userDefaultTimezone = TimeZone.find(
      (time) => time.value == Localization.timezone
    );
    setSelectedTimeZone(userDefaultTimezone);
    const Response = await axios.get(
      `${process.env.TRAILS_ENDPOINT}?language=fr`,
      config
    );

    const array = [{ label: t("placeholders.trail"), value: "" }];
    await Response.data.map((i) =>
      array.push({ label: i.title, value: i.trail_code })
    );
    setData(array);

    const url = process.env.PRODUCT_DATA;
    const flashCoaching = await axios.get(`${url}?type=flash_coaching`, config);
    setProduct(flashCoaching.data);
  };
  useEffect(() => {
    getData();
  }, []);

  const [LoaderForm, setLoaderForm] = useState(false);
  const [LoaderFormPoints, setLoaderFormPoints] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const SubmitForm = async (withPoints) => {
    setErrorMessage("");
    const toggleLoader = () => {
      if (withPoints) {
        setLoaderFormPoints(false);
      } else {
        setLoaderForm(false);
      }
    };
    if (!LoaderForm && !LoaderFormPoints) {
      if (withPoints) {
        setLoaderFormPoints(true);
      } else {
        setLoaderForm(true);
      }
      const re = /\S+@\S+\.\S+/;
      if (isGift && re.test(emailInput) == false) {
        setErrorMessage(t("signup.error_email"));
        scrollToTop();
        toggleLoader();
      } else if (
        selectedLangue.value &&
        (!isGift || (firstnameInput.length > 3 && lastnameInput.length > 3))
      ) {
        const TOKEN = await AsyncStorage.getItem("access_token");
        // send form

        if (Platform.OS == "ios") {
          try {
            await Purchases.purchaseProduct(product.ios_iap);
            axios
              .post(`${process.env.BASE_URL}/expert-appointment`, {
                access_token: TOKEN,
                customer,
                topic: null,
                language: selectedLangue.value,
                timezone: selectedTimeZone.value,
                trail_title: null,
                trail_code: null,
                category: null,

                message: text || "",
                email: isGift ? emailInput : email,
                firstname: isGift ? firstnameInput : firstname,
                lastname: isGift ? lastnameInput : lastname,
                isGift,
              })
              .then(() => {
                toggleLoader();
              });
          } catch (error) {
            console.log(error);
            toggleLoader();
          }
        } else {
          const url = process.env.FLASH_COACHING_SESSION;
          const body = {
            access_token: TOKEN,
            customer,
            topic: null,
            message: text || "",
            language: selectedLangue.value,
            timezone: selectedTimeZone.value,
            trail_title: null,
            trail_code: null,
            category: null,
            email: isGift ? emailInput : email,
            firstname: isGift ? firstnameInput : firstname,
            lastname: isGift ? lastnameInput : lastname,
            price: withPoints ? product.stripe_points : product.stripe,
            isGift,
            withPoints,
            points: product.points,
          };

          axios
            .post(url, body)
            .then((res) => {
              setTimeout(() => {
                toggleLoader();
                CloseAll();
                const url = res.data.session.url;
                if (Platform.OS == "web") {
                  window.open(url, "_blank");
                } else {
                  Linking.openURL(url);
                }
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
              if (err.response.status === 403) {
                setErrorMessage(t("appointment.error_points"));
                scrollToTop();
                toggleLoader();
              } else {
                setErrorMessage(t("appointment.error_connexion"));
                scrollToTop();
                toggleLoader();
              }
            });
        }
      } else {
        //  error
        setErrorMessage(t("appointment.error_data"));
        scrollToTop();
        toggleLoader();
      }
    }
  };

  const orderViaCompany = async () => {
    setLoaderForm(true);
    setErrorMessage("");
    if (
      selectedLangue.value
    ) {
      const TOKEN = await AsyncStorage.getItem("access_token");
      const urlAPI = `${process.env.PAYMENT_APIS}/buy-coaching-via-company`;
      const bodyData = {
        access_token: TOKEN,
        customer,
        topic: null,
        message: text || "",
        language: selectedLangue.value,
        timezone: selectedTimeZone.value,
        trail_title: null,
        trail_code: null,
        category: null,
      };
      console.log(bodyData);
      axios
        .post(urlAPI, bodyData)
        .then((res) => {
          setLoaderForm(false);
          setShowSuccessBuyViaCompany(true);
        })
        .catch((err) => {
          setErrorMessage(t("signup.error_connexion"));
          setLoaderForm(false);
          scrollToTop();
        });
    } else {
      //  error
      setErrorMessage(t("appointment.error_data"));
      scrollToTop();
      setLoaderForm(false);
    }
  };

  const orderViaCoachingCode = async () => {
    try {
      await axios.post(`${process.env.COACHING_PRICES}`, {
        coachee_email: email,
        message: text,
        coaching_code: coachingCode,
        company_id: company,
      }).then(async res => {
        if(res.data.coaching) {
          if(res.data.coaching.expired) {
            setErrorMessage(t("appointment.expired_code"));
            scrollToTop();
          } else {
              setLoaderForm(false);
              setShowSuccessBuyViaCompany(true);
          }} else {
            setErrorMessage(t("appointment.error_code"));
            setLoaderForm(false);
            scrollToTop();
          } 
        }).catch(err => {
        setErrorMessage(t("appointment.error_code"));
        setLoaderForm(false);
        scrollToTop();
      })
    } catch(err) {
      console.log(err)
    }
  }

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../../assets/background.png")}
        style={{ flex: 1 }}
      >
        {!product ? (
          <View style={{ flex: 1 }}>
            <BackHeader
              isOpen={false}
              setIsOpen={() => { }}
              navigation={navigation}
            />
            <View
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner size="large" />
            </View>
          </View>
        ) : (
          <ScrollView
            ref={scrollRef}
            showsVerticalScrollIndicator={Platform.OS === "web"}
          >
            <BackHeader
              isOpen={false}
              setIsOpen={() => { }}
              navigation={navigation}
            />
            <View style={{ marginHorizontal: "2.5%" }}>
              {/* Form Rendez-Vous */}
              <View style={{ maxWidth: 800, alignSelf: "center" }}>
                <Head />
              </View>
              {errorMessage.length > 0 && (
                <SmallBoldTxt
                  style={{
                    color: colors.red1,
                    textAlign: "center",
                    marginTop: 20,
                  }}
                >
                  {errorMessage}
                </SmallBoldTxt>
              )}

              <View style={[styles.SelectTrail]}>
                <Input
                  multiline={true}
                  numberOfLines={5}
                  onChangeText={(val) => setText(val)}
                  value={text}
                  placeholder={t("placeholders.message")}
                />
                </View>
              <View
                style={{
                  maxWidth: 700,
                  width: "100%",
                  alignSelf: "center",
                }}
              >
                {/* Text Appointment */}
                <View style={styles.TextAppointment}>
                  <Txt>{t("appointment.text2")}</Txt>
                </View>
                {/* time zone + language */}
                <View style={styles.TextAppointment}>
                  <Txt>{t("appointment.text3")}</Txt>
                </View>
                <View
                  style={[
                    styles.SelectTrailRow,
                    {
                      flexDirection: isDesktop ? "row" : "column",
                    },
                  ]}
                >
                  <View
                    style={{ zIndex: 15, width: isDesktop ? "48%" : "100%" }}
                  >
                    <DropDown
                      height={64}
                      placeholder={t("placeholders.language")}
                      show={showLangue}
                      setShow={() => setShowLangue(!showLangue)}
                      value={selectedLangue}
                      setValue={setSelectedLangue}
                      options={Langue}
                      disableFirstOption={true}
                    />
                  </View>
                  <View
                    style={{
                      zIndex: 5,
                      width: isDesktop ? "48%" : "100%",
                      marginTop: isDesktop ? 0 : 15,
                    }}
                  >
                    <DropDown
                      height={64}
                      placeholder={t("placeholders.timezone")}
                      show={showTimeZone}
                      setShow={() => setShowTimeZone(!showTimeZone)}
                      value={selectedTimeZone}
                      setValue={setSelectedTimeZone}
                      options={timeZoneOptions}
                      disableFirstOption={true}
                    />
                  </View>
                </View>
                {/**  informations destinataire */}
                {isGift && (
                  <View style={{ zIndex: -1 }}>
                    <View style={{ flex: 1, borderRadius: 10, paddingVertical: 15, marginTop: 20, paddingHorizontal: 10, backgroundColor: colors.red1 }}>
                      <Txt style={{ fontSize: 14 }} color="white" >{t('appointment.warning')}</Txt>
                    </View>
                    <Input
                      style={{ marginTop: 10 }}
                      onChangeText={(val) => setFirstNameInput(val)}
                      value={firstnameInput}
                      placeholder={t("placeholders.name_destinataire")}
                    />
                    <Input
                      style={{ marginTop: 10 }}
                      onChangeText={(val) => setLastNameInput(val)}
                      value={lastnameInput}
                      placeholder={t("placeholders.surname_destinataire")}
                    />
                    <Input
                      style={{ marginTop: 10 }}
                      onChangeText={(val) => setEmailInput(val)}
                      value={emailInput}
                      placeholder={t("placeholders.email_destinataire")}
                    />
                  </View>
                )}

                {/** text expert in 72H  + duraiton */}
                <VStack mt="5" zIndex={-5}>
                  <Center>
                    <Alert maxW="800" status="info" colorScheme="success" bg='#C9DCC580' >
                      <VStack space={2} flexShrink={1} w="100%">
                        <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
                          <HStack flexShrink={1} space={2} alignItems="center">
                            <Alert.Icon color={colors.blue3} />
                            <Text fontSize="md" fontWeight="bold" color={colors.blue3}>
                              Important
                            </Text>
                          </HStack>

                        </HStack>
                        <Box pl="6" >
                          <VStack>
                            <Txt style={{ fontSize: 14 }}>
                              &#x2022; {t("appointment.text4") + " "}
                              <BoldTxt style={{ fontSize: 14 }}
                                onPress={() => {
                                  WebBrowser.openBrowserAsync(
                                    "https://www.lislup.com/politique-de-confidentialite/"
                                  );
                                }}
                              >
                                {t("appointment.text5")}
                              </BoldTxt>
                            </Txt>
                          </VStack>
                          <VStack mt="2">
                            <Txt style={{ fontSize: 14 }}>&#x2022; {t("appointment.text6")}.</Txt>
                          </VStack>
                          <VStack my="2">
                            <Txt style={{ fontSize: 14 }}>
                              &#x2022; {t('coaching.duration')}
                            </Txt>
                          </VStack>

                        </Box>
                      </VStack>
                    </Alert>
                  </Center>
                </VStack>
                  <View 
                    style={{
                      zIndex: -1,
                      marginTop: 20,
                      alignSelf: isDesktop ? "auto" : "center",
                      flex: 1,
                      flexDirection: isDesktop ? "row" : "column",
                      marginBottom: 40
                    }}
                  >
                    <Input
                      numberOfLines={1}
                      onChangeText={(val) => setCoachingCode(val)}
                      value={coachingCode}
                      placeholder={t("placeholders.code_coaching")}
                    />
                      <View 
                        style={{
                          zIndex: -1,
                          marginTop: 15,
                          alignSelf: isDesktop ? "auto" : "center",
                          flex: 1,
                          flexDirection: isDesktop ? "row" : "column",
                          justifyContent: isDesktop ? "space-between" : "center",
                          marginBottom: 40
                        }}
                      >
                      <PrimaryButton
                        width={100}
                        style={{
                          textAlign: "center",
                          marginLeft: 20
                        }}
                        onPress={orderViaCoachingCode}
                      >
                        {t("appointment.button_validate_coaching")} 
                      </PrimaryButton>
                      <PrimaryButton
                        width={200}
                        style={{
                          textAlign: "center",
                          marginTop: isDesktop ? 0 : 20,
                        }}
                        onPress={() => setPay(true)}
                      >
                      {t("appointment.button_pay_coaching")} 
                      </PrimaryButton>
                  </View>
                  </View>
                {/* Buttons */}
                {/** book via company */}
                {buyViaCompany ? (
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      alignSelf: "center",
                      marginTop: 20,
                      marginBottom: 40,
                    }}
                  >
                    <PrimaryButton
                      style={{
                        textAlign: "center",
                      }}
                      onPress={orderViaCompany}
                    >
                      {LoaderForm ? (
                        <Spinner color={colors.white} size="small" />
                      ) : (
                        t("appointment.free")
                      )}
                    </PrimaryButton>
                  </View>
                ) : pay ? (
                  <View
                    style={{
                      zIndex: -1,
                      marginTop: 20,
                      alignSelf: isDesktop ? "auto" : "center",
                      flex: 1,
                      flexDirection: isDesktop ? "row" : "column",
                      justifyContent: isDesktop ? "space-evenly" : "center",
                      marginBottom: 40,
                    }}
                  >
                    {/** pay with eur */}
                    <PrimaryButton
                      width={280}
                      style={{
                        textAlign: "center",
                      }}
                      onPress={() => SubmitForm(false)}
                    >
                      {LoaderForm ? (
                        <Spinner color={colors.white} size="small" />
                      ) : (
                        `${t("appointment.button_pay")} ${product.price}€ ${t(
                          "tax"
                        )} `
                      )}
                    </PrimaryButton>
                    {/** pay with eur and points */}
                    {Platform.OS != "ios" && (
                      <PrimaryButton
                        width={280}
                        style={{
                          textAlign: "center",
                          marginTop: isDesktop ? 0 : 20,
                        }}
                        onPress={() => SubmitForm(true)}
                      >
                        {LoaderFormPoints ? (
                          <Spinner color={colors.white} size="small" />
                        ) : (
                          `${t("appointment.button_pay")} ${product.price_points
                          }€ ${t("tax")} + ${product.points} ${t(
                            "score_screen.points"
                          )}`
                        )}
                      </PrimaryButton>
                    )}
                  </View>
                ) : ""}
              </View>
            </View>
            {/*End  Form Rendez-Vous */}

            {/** modal buy via company success */}
            <SuccessBuyCoachingViaCompany
              isOpen={showSuccessBuyViaCompany}
              setIsOpen={setShowSuccessBuyViaCompany}
              navigation={navigation}
            />

            {isDesktop && <Footer />}
          </ScrollView>
        )}
      </ImageBackground>
    </View>
  );
};

export default Appointment;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },

  SelectTrail: {
    alignSelf: "center",
    marginTop: 20,
    zIndex: 5,

    width: "100%",
    maxWidth: 700,
  },
  Square: {
    marginTop: 30,
    height: 101,
    width: "100%",
    maxWidth: 500,
    alignSelf: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  TextAppointment: {
    marginTop: 20,
    zIndex: -1,
    textAlign: "justify",
  },
  SelectTrailRow: {
    alignSelf: "center",
    marginTop: 20,
    justifyContent: "space-between",
    width: "100%",
  },
  coachingCode: {
    marginTop: 20,
    width: "33%",
  },

  conditions: {
    alignSelf: "center",
    marginTop: 20,
    marginRight: 10,
    flexDirection: "row",
    zIndex: -1,
  },
});
