import { Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  BoiteIcon,
  CoachingIcon,
  ConscienceIcon,
  CorpsIcon,
  PlaisirIcon,
  RituelsIcon,
  ScienceIcon,
  TemoignageIcon,
} from "../../assets/svg/WorkshopBadgeIcons";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { Txt } from "../TextsComponents";
import SwiperItem from "./SwiperItem";

const NewWorkshopsSwiper = ({ navigation, data }) => {
  const { t, i18n } = useTranslation();
  const { isMobile } = DimensionsHook();
  const swiperContainerStyle = {
    backgroundColor: colors.white,
    width: "95%",
    alignSelf: "center",
    paddingTop: 15,
    zIndex: -2,
    paddingBottom: 5,
    borderRadius: 20,
    marginTop: 20,
    paddingLeft: isMobile ? 10 : 20,
    paddingRight: isMobile ? 0 : 20,
  };

  const Categories = [
    {
      label: t("workshop_types.conscience"),
      value: "conscience",
      textColor: colors.blue3,
      backgroundColor: colors.green2,
      icon: () => <ConscienceIcon />,
    },
    {
      label: t("workshop_types.corps"),
      value: "body",
      textColor: colors.blue3,
      backgroundColor: colors.yellow,
      icon: () => <CorpsIcon />,
    },
    {
      label: t("workshop_types.plaisir"),
      value: "fun",
      textColor: colors.blue3,
      backgroundColor: colors.green3,
      icon: () => <PlaisirIcon />,
    },
    {
      label: t("workshop_types.rituels"),
      value: "rituals",
      textColor: colors.white,
      backgroundColor: colors.red1,
      icon: () => <RituelsIcon color="white" />,
    },
    {
      label: t("workshop_types.science"),
      value: "science",
      textColor: colors.white,
      backgroundColor: colors.blue3,
      icon: () => <ScienceIcon color="white" />,
    },

    {
      label: t("workshop_types.outils"),
      value: "tools",
      textColor: colors.blue3,
      backgroundColor: colors.blue2,
      icon: () => <BoiteIcon />,
    },
    {
      label: t("workshop_types.temoignages"),
      value: "testimony",
      textColor: colors.blue3,
      backgroundColor: colors.blue0,
      icon: () => <TemoignageIcon />,
    },
    {
      label: t("workshop_types.coaching"),
      value: "coaching",
      textColor: colors.blue3,
      backgroundColor: colors.red0,
      icon: () => <CoachingIcon />,
    },
  ];
  if (data.length === 0) {
    return <></>;
  }
  return (
    <View>
      {data.map((item) => {
        const cat = Categories.find((el) => el.value === item._id);
        const workshops = item.workshops;
        return (
          <View style={swiperContainerStyle} key={item._id}>
            <View
              style={[
                styles.categoryBadge,
                { backgroundColor: cat.backgroundColor },
              ]}
            >
              <View style={{ width: 18, height: 18, marginRight: 5 }}>
                {cat.icon()}
              </View>
              <Txt color={cat.textColor}>
                {t("nouveautes.new")} {t("nouveautes.workshops")} {cat.label}
              </Txt>
            </View>
            <Txt
              style={{
                fontSize: 14,
                marginVertical: 10,
                paddingLeft: 5,
              }}
              color={colors.grayLabel}
            >
              {workshops.length +
                " " +
                t("nouveautes.new_workshops") +
                " " +
                t("nouveautes.found")}
            </Txt>
            <ScrollView
              nestedScrollEnabled
              horizontal
              showsHorizontalScrollIndicator={Platform.OS === "web"}
            >
              {workshops.map((item) => {
                return (
                  <View key={item._id}>
                    <SwiperItem
                      item={item}
                      type="Atelier"
                      navigation={navigation}
                    />
                  </View>
                );
              })}
            </ScrollView>
          </View>
        );
      })}
    </View>
  );
};

export default NewWorkshopsSwiper;

const styles = StyleSheet.create({
  categoryBadge: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-start",
    paddingHorizontal: 15,
    borderRadius: 100,
    paddingVertical: 4,
  },
});
