import { ImageBackground, StyleSheet, View } from "react-native";
import React from "react";
import { Modal, VStack } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { H2, Txt } from "../TextsComponents";
import { useTranslation } from "react-i18next";
import { Entypo } from "@expo/vector-icons";

const SuccessDonationModal = ({ isOpen, setIsOpen }) => {
  const { isMobile } = DimensionsHook();
  const closeHandler = async () => {
    setIsOpen(false);
  };
  const { t } = useTranslation();
  return (
    <Modal focusable={false} isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={styles.container}>
        <Modal.Body>
          <ImageBackground
            source={require("../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ marginRight: isMobile ? 0 : 10 }}
            />
            <VStack
              style={{
                marginTop: isMobile ? 40 : 60,
                marginHorizontal: isMobile ? 0 : 30,
              }}
            >
              <View
                style={{
                  width: 60,
                  height: 60,
                  alignSelf: "center",
                  backgroundColor: colors.green2,
                  alignItems: "center",
                  justifyContent: "center",
                  marginVertical: 20,
                  borderRadius: 40,
                }}
              >
                <Entypo name="check" size={28} color={colors.white} />
              </View>
              <H2
                style={{
                  fontSize: 22,
                  lineHeight: 28,
                  textAlign: "center",
                  color: colors.green2,
                  marginHorizontal: 20,
                }}
              >
                {t("donation.success_title")}
              </H2>
              <Txt style={{ marginTop: 20, textAlign: "center" }}>
                {t("donation.success_text1")}
              </Txt>

              <Txt style={{ marginVertical: 20, textAlign: "center" }}>
                {t("unsubscribe.success_unsubscribe.text3")}
              </Txt>
            </VStack>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default SuccessDonationModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "95%",
    maxWidth: 700,
  },
});
