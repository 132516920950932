import {
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import React, { useState, useEffect } from "react";
import { Input, DropDown, PhoneInput } from "../Inputs";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { PrimaryButton } from "../Buttons";
import { H3, Txt, H1, SmallBoldTxt } from "../TextsComponents";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Spinner from "../Spinner";
import axios from "axios";
import { setAsyncTimeout } from "../../hooks/Helpers";
import { useToast } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as WebBrowser from "expo-web-browser";
const ContactScreen = ({ navigation, scrollToTop }) => {
  const { t, i18n } = useTranslation();

  const ObjectOptions = [
    {
      label: t("contact_screen.object_placeholder"),
      value: "",
    },
    { label: t("help_screen.categories.facturation"), value: "facturation" },
    { label: t("help_screen.categories.technique"), value: "technique" },
    { label: t("help_screen.categories.trail"), value: "trail" },
    { label: t("help_screen.categories.workshop"), value: "workshop" },
    { label: t("help_screen.categories.autres"), value: "autres" },
  ];

  // options if user choose workshop in object
  const Categories = [
    {
      label: t("workshop_types.label"),
      value: "all_workshops",
    },
    {
      label: t("workshop_types.conscience"),
      value: "conscience",
    },
    {
      label: t("workshop_types.corps"),
      value: "body",
    },
    {
      label: t("workshop_types.plaisir"),
      value: "fun",
    },
    {
      label: t("workshop_types.rituels"),
      value: "rituals",
    },
    {
      label: t("workshop_types.science"),
      value: "science",
    },
    {
      label: t("workshop_types.temoignages"),
      value: "testimony",
    },
    {
      label: t("workshop_types.outils"),
      value: "tools",
    },
    {
      label: t("workshop_types.coaching"),
      value: "coaching",
    },
  ];
  // options if user choose facturation in object
  const facturationOptions = [
    { label: t("contact_screen.sub_objects.billing"), value: "billing" },
    { label: t("contact_screen.sub_objects.payment"), value: "payment" },
  ];

  // options if user choose technique in object
  const techniqueOptions = [
    {
      label: t("contact_screen.sub_objects.dysfunction"),
      value: "dysfunction",
    },
    { label: t("contact_screen.sub_objects.suggestion"), value: "suggestion" },
  ];

  const toast = useToast();

  const { width } = DimensionsHook();
  const isDesktop = width > 900;
  const userData = useSelector((state) => state.userData);

  const [lastname, setLastName] = useState(userData.lastname);
  const [firstname, setFirstName] = useState(userData.firstname);
  const [email, setEmail] = useState(userData.email);
  const [phoneCode, setPhoneCode] = useState(
    userData.phoneCode.replace("+", "")
  );
  const [phoneNumber, setPhoneNumber] = useState(userData.phoneNumber);
  const [showObject, setShowObject] = useState(false);
  const [object, setObject] = useState(ObjectOptions[0]);

  const [message, setMessage] = useState("");
  // Loader and error message
  const [submitLoader, setSubmitLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState([{ label: choose, value: "" }]);

  const [selectedValue, setSelectedValue] = useState({
    label: choose,
    value: "",
  });
  const [showValue, setShowValue] = useState(false);

  const choose =
    object.value == "trail"
      ? t("placeholders.all_trails")
      : object.value == "workshop"
      ? t("workshop_types.label")
      : t("contact_screen.sub_object_placeholder");

  const submitHandler = async () => {
    if (!submitLoader) {
      setSubmitLoader(true);
      setErrorMessage("");
      if (lastname.length < 2) {
        setSubmitLoader(false);
        setErrorMessage(t("signup.error_name"));
        scrollToTop();
        return;
      }
      if (firstname.length < 2) {
        setSubmitLoader(false);
        setErrorMessage(t("signup.error_surname"));
        scrollToTop();
        return;
      }
      if (email.length < 3) {
        setSubmitLoader(false);
        setErrorMessage(t("signup.error_email"));
        scrollToTop();
        return;
      }
      if (isNaN(phoneCode) || phoneCode.length < 2) {
        setSubmitLoader(false);
        setErrorMessage(t("signup.error_phone_code"));
        scrollToTop();
        return;
      }
      if (isNaN(phoneNumber) || phoneNumber.length < 2) {
        setSubmitLoader(false);
        setErrorMessage(t("signup.error_phone"));
        scrollToTop();
        return;
      }
      if (object.value === "") {
        setSubmitLoader(false);
        setErrorMessage(t("contact_screen.error_object"));
        scrollToTop();
        return;
      }
      if (message.length < 5) {
        setSubmitLoader(false);
        setErrorMessage(t("contact_screen.error_message"));
        scrollToTop();
        return;
      }
      // SUBMIT CONTACT FORM
      try {
        await setAsyncTimeout(() => {
          // Do stuff
        }, 1000);
        const TOKEN = await AsyncStorage.getItem("access_token");

        await axios.post(process.env.CONTACT_ENDPOINT, {
          access_token: TOKEN,
          lastname,
          firstname,
          email,
          object: object.value,
          subObject: selectedValue.value,
          message,
          phone: phoneCode + phoneNumber,
          language: i18n.language,
        });
        setSubmitLoader(false);
        setObject(ObjectOptions[0]);
        setMessage("");
        setValues([{ label: choose, value: "" }]);
        setSelectedValue({
          label: choose,
          value: "",
        });
        // SHOW SUCCESS MESSAGE
        await toast.show({
          placement: "top",
          render: () => {
            return (
              <View style={styles.toastContainer}>
                <Txt
                  style={{ paddingLeft: isDesktop ? 5 : 0 }}
                  color={colors.white}
                >
                  {t("contact_screen.success_send")}
                </Txt>
                <TouchableOpacity
                  style={{ paddingHorizontal: 5 }}
                  onPress={() => toast.closeAll()}
                >
                  <AntDesign name="close" size={16} color={colors.white} />
                </TouchableOpacity>
              </View>
            );
          },
        });
        scrollToTop();
      } catch (error) {
        setSubmitLoader(false);
        if (error.response.status === 403) {
          setErrorMessage(t("signup.error_email"));
          scrollToTop();
        } else {
          setErrorMessage(t("validation.error_connexion"));
          scrollToTop();
        }
      }
    }
  };

  const getData = async () => {
    setSelectedValue({
      label: choose,
      value: "",
    });
    // case user select trail
    if (object.value == "trail") {
      setLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      const url = `${process.env.TRAILS_ENDPOINT}/search?language=${i18n.language}`;

      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const t = [{ label: choose, value: "all_trails" }];
      const Response = await axios.get(url, config);
      Response.data.forEach(async (val) => {
        t.push({
          label: val.title,
          value: val.title,
        });
      });
      setValues(t);
      setSelectedValue(t[0]);
      setLoader(false);
      // case user select workshop
    } else if (object.value == "workshop") {
      setValues(Categories);
      setSelectedValue(Categories[0]);
      // case user select facturation
    } else if (object.value == "facturation") {
      setValues([{ label: choose, value: "" }, ...facturationOptions]);
    } else if (object.value == "technique") {
      setValues([{ label: choose, value: "" }, ...techniqueOptions]);
    }
  };

  useEffect(() => {
    getData();
  }, [object]);

  return (
    <TouchableWithoutFeedback onPress={() => setShowObject(false)}>
      <View
        style={{
          marginTop: isDesktop ? 0 : 20,
          marginBottom: isDesktop ? 20 : 200,
        }}
      >
        <H3 style={{ fontSize: isDesktop ? 22 : 18, marginBottom: 10 }}>
          {t("contact_screen.title1")}
        </H3>
        <H1> {t("contact_screen.title2")}</H1>
        <SmallBoldTxt
          style={{
            color: colors.red1,
            textAlign: "center",
          }}
        >
          {errorMessage.length > 0 ? errorMessage : " "}
        </SmallBoldTxt>
        <View style={styles.container}>
          {/** lastname */}
          <View style={{ width: isDesktop ? "49%" : "100%", marginTop: 15 }}>
            <Input
              placeholder={t("contact_screen.name")}
              value={lastname}
              onChangeText={(val) => setLastName(val)}
            />
          </View>

          {/** firstname */}
          <View style={{ width: isDesktop ? "49%" : "100%", marginTop: 15 }}>
            <Input
              placeholder={t("contact_screen.surname")}
              value={firstname}
              onChangeText={(val) => setFirstName(val)}
            />
          </View>
          {/** email */}
          <View style={{ width: isDesktop ? "49%" : "100%", marginTop: 15 }}>
            <Input
              placeholder="Email"
              value={email}
              onChangeText={(val) => setEmail(val)}
            />
          </View>
          {/** phone  */}
          <View style={{ width: isDesktop ? "49%" : "100%", marginTop: 20 }}>
            <View>
              <PhoneInput
                codePlaceholder="33"
                placeholder="01 23 45 67 89"
                phoneCode={phoneCode}
                setPhoneCode={setPhoneCode}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
            </View>
          </View>
        </View>
        <View>
          {/** object */}
          <View style={{ width: "100%", marginTop: 20, zIndex: 100 }}>
            <DropDown
              height={58}
              placeholder=""
              show={showObject}
              setShow={() => setShowObject(!showObject)}
              value={object}
              disableFirstOption={true}
              setValue={setObject}
              options={ObjectOptions}
            />
          </View>
          {/** subObject */}
          {object.value.length > 0 && object.value != "autres" && (
            <View style={{ marginTop: 20, zIndex: 50 }}>
              {loader ? (
                <Spinner size="large" />
              ) : (
                <DropDown
                  height={58}
                  placeholder=""
                  show={showValue}
                  setShow={() => setShowValue(!showValue)}
                  value={selectedValue}
                  disableFirstOption={
                    object.value != "trail" && object.value != "workshop"
                  }
                  setValue={setSelectedValue}
                  options={values}
                />
              )}
            </View>
          )}
          {/** message */}
          <View style={{ width: "100%", marginTop: 15, zIndex: -1 }}>
            <Input
              placeholder={t("contact_screen.your_message")}
              multiline={true}
              numberOfLines={6}
              value={message}
              onChangeText={(val) => setMessage(val)}
            />
          </View>
        </View>
        <Txt style={{ textAlign: "justify", marginVertical: 20 }}>
          {t("contact_screen.terms")}{" "}
          <Txt
            onPress={() =>
              WebBrowser.openBrowserAsync(
                "https://www.lislup.com/politique-de-confidentialite/"
              )
            }
            style={{ textDecorationLine: "underline" }}
          >
            {t("contact_screen.terms_link")}
          </Txt>
        </Txt>

        {/** buttons */}
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: isDesktop ? "flex-end" : "center",
            marginBottom: isDesktop ? 0 : 20,
          }}
        >
          <PrimaryButton onPress={submitHandler} width={211}>
            {submitLoader ? (
              <Spinner
                color={submitLoader ? colors.white : colors.blue3}
                size="small"
              />
            ) : (
              t("contact_screen.send")
            )}
          </PrimaryButton>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default ContactScreen;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  divider: {
    height: 1,
    width: "100%",
    alignSelf: "center",
    backgroundColor: colors.grayBorder,
    marginVertical: 20,
  },
  toastContainer: {
    backgroundColor: colors.green2,
    flexDirection: "row",
    padding: 10,
    alignItems: "center",
    borderRadius: 20,
    marginTop: 30,
  },
});
