import { ImageBackground, StyleSheet, View } from "react-native";
import React from "react";
import { Modal, VStack } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { H1 } from "../TextsComponents";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import { useTranslation } from "react-i18next";
import { SoonGift } from "../../assets/svg/Icons";

const SoonModal = ({ isOpen, setIsOpen, don }) => {
  const { isMobile } = DimensionsHook();
  const { t } = useTranslation();
  const closeHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal focusable={false} isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={styles.container}>
        <Modal.Body style={{ flex: 1 }}>
          <ImageBackground
            source={require("../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ marginRight: isMobile ? 0 : 10 }}
            />
            <VStack
              style={{
                alignItems: "center",
                justifyContent: "center",
                marginTop: 80,
                flex: 1,
              }}
            >
              <View style={styles.logoContainer}>
                {don ? (
                  <AntDesign name="gift" size={80} color={colors.blue3} />
                ) : (
                  <MaterialIcons
                    name="personal-video"
                    size={80}
                    color={colors.blue3}
                  />
                )}
              </View>

              <H1 color={colors.blue3}>{t("soon")}</H1>
            </VStack>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default SoonModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "90%",
    maxWidth: 500,
    height: 300,
  },
  logoContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
});
