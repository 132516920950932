import { StyleSheet, View } from "react-native";
import React, { useRef, useState } from "react";
import DimensionsHook from "../../../../hooks/DimensionsHook";
import { useHover } from "react-native-web-hooks";
import { BoldTxt, H4, H7 } from "../../../TextsComponents";
import { PrimaryButton } from "../../../Buttons";
import { Surface } from "react-native-paper";
import { colors } from "../../../../styles/GlobalStyle";
import { useSelector } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import Spinner from "../../../Spinner";
import { useTranslation } from "react-i18next";
import * as WebBrowser from "expo-web-browser";

const Plan = ({ plan, closeHandler, email, setEmail, GoHome }) => {
  const { width } = DimensionsHook();
  const { t, i18n } = useTranslation();
  const isDesktop = width > 1000;
  const isMobile = width < 600;
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const userData = useSelector((state) => state.userData);
  const [loader, setLoader] = useState(false);

  const paymentHandler = async () => {
    if (!loader) {
      setLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      const url = process.env.CREATE_GIFT_SUBSCRIPTION;
      const data = {
        customer: userData.customer,
        access_token: TOKEN,
        price: plan.stripe,
        email,
        link: plan.link,
      };

      axios
        .post(url, data)
        .then((res) => {
          const url = res.data.session.url;
          WebBrowser.openBrowserAsync(url);
          closeHandler();
          if (GoHome) {
            window.location.href = "/";
          }
          setEmail("");
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  return (
    <Surface
      style={[
        styles.cardContainer,
        {
          width: isMobile ? "95%" : isDesktop ? "24%" : "45%",
          marginTop: isMobile ? 20 : 10,
          backgroundColor: isHovered
            ? colors.blue2
            : plan.popular
            ? colors.blue3
            : colors.beige,
          cursor: "pointer",
        },
      ]}
      elevation={4}
    >
      <View style={{ width: "100%", alignItems: "center" }} ref={hoverRef}>
        {/** duration plan */}
        <View style={{ alignItems: "center", marginTop: 20 }}>
          <H7
            color={
              isHovered
                ? colors.blue3
                : plan.popular
                ? colors.white
                : colors.blue3
            }
          >
            LISL UP
          </H7>
          <H4
            color={
              isHovered
                ? colors.blue3
                : plan.popular
                ? colors.white
                : colors.blue3
            }
          >
            {i18n.language == "fr" ? plan.title_fr : plan.title_en}
          </H4>
        </View>
        {/** price */}
        <View style={{ alignItems: "center", paddingTop: 20 }}>
          <View
            style={[
              styles.priceContainer,
              { backgroundColor: isHovered ? "#ffffff15" : "#88C1A315" },
            ]}
          >
            <BoldTxt
              color={isHovered ? colors.white : colors.green2}
              style={{ fontSize: 20 }}
            >
              {plan.price}€ {t("tax")}
            </BoldTxt>
          </View>
        </View>

        {/** subscribe button */}

        <PrimaryButton
          width="90%"
          style={{ marginVertical: 20 }}
          onPress={paymentHandler}
        >
          {loader ? (
            <Spinner color={colors.white} />
          ) : (
            t("buy_trails.pay_button")
          )}
        </PrimaryButton>
      </View>
    </Surface>
  );
};

export default Plan;

const styles = StyleSheet.create({
  cardContainer: {
    marginTop: 15,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 10,
  },
  priceContainer: {
    paddingVertical: 6,
    paddingHorizontal: 20,
    borderRadius: 10,
  },
});
