import {
  ImageBackground,
  StyleSheet,
  ScrollView,
  View,
  Platform,
  Image,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { colors } from "../../styles/GlobalStyle";
import { PrimaryButton } from "../../components/Buttons";
import DimensionsHook from "../../hooks/DimensionsHook";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { resetUser, setSavedEpisodes, setUserActivity, setUserData, setWatchedEpisodes } from "../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ValidationEmail from "./ValidationEmail";
import { H2, H6, SmallBoldTxt, SmallTxt, Txt } from "../../components/TextsComponents";
import { FontAwesome5 } from "@expo/vector-icons";
import ValidationPhone from "./ValidationPhone";
import Spinner from "../../components/Spinner";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Recaptcha from 'react-native-recaptcha-that-works';

import { VStack } from "native-base";

const Validation = ({ navigation }) => {
  const { isDesktop } = DimensionsHook();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true)

  const userData = useSelector(state => state.userData)
  const [submitLoader, setSubmitLoader] = useState(false)





  const dispatch = useDispatch();

  const logoutHandler = async () => {
    await AsyncStorage.removeItem("access_token");
    dispatch(resetUser());

    navigation.reset({
      index: 0,
      routes: [{ name: "Loader" }],
    });
  };


  const submitHandler = async () => {
    if (!submitLoader) {
      setSubmitLoader(true)
      const TOKEN = await AsyncStorage.getItem("access_token");
      axios
        .post(process.env.USER_VALIDATE_ACCOUNT, {
          access_token: TOKEN,

        })
        .then(async (res) => {
          await dispatch(setUserData());
          await dispatch(setUserActivity());
          await dispatch(setWatchedEpisodes());
          await dispatch(setSavedEpisodes());
          setSubmitLoader(false);
          if (userData.company) {
            if (
              userData.company_payment == "prepaid" ||
              userData.company_payment == "test"
            ) {
              navigation.replace("TabNavigation");
            } else {
              navigation.replace("PlansCompanies");
            }
          } else {
            navigation.replace("Plans");
          }

        })
        .catch((err) => {
          console.log(err)
          setSubmitLoader(false);
        });
    }
  }



  if (Platform.OS == "web") {

    function onChange(value) {
      if (value) {
        setDisabled(false)
      }
    }
    return (
      <View style={styles.container}>
        <ImageBackground
          source={require("../../assets/background.png")}
          style={{ flex: 1 }}
        >
          <View style={{ alignSelf: "flex-end", marginVertical: 20, marginRight: 15 }}>
            <PrimaryButton onPress={logoutHandler}>
              <MaterialCommunityIcons
                name="logout"
                size={24}
                color={colors.white}
              />
            </PrimaryButton>
          </View>
          <View style={{ flex: 1, alignItems: "center", }}>
            <VStack mb="5">
              <Image
                source={{
                  uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
                }}
                style={{
                  height: 160,
                  width: 98,
                }}
              />
            </VStack>
            <VStack>
              <H6 style={{ textAlign: 'center' }}>{t('validation.title1')}</H6>
            </VStack>
            <VStack mb="5">
              <Txt color='#484848' style={{ fontSize: 15, marginTop: 5, textAlign: 'center' }}>{t('validation.text1')}</Txt>
            </VStack>
            <ReCAPTCHA
              sitekey="6Lc3yA4pAAAAAHycSEOo41l8KMk-qLixmKPmuiyW"
              onChange={onChange}
            />
            <VStack mt="5">
              <PrimaryButton onPress={submitHandler} disabled={disabled} style={{ width: 200 }}>
                {submitLoader ? (
                  <Spinner
                    color={submitLoader ? colors.white : colors.blue3}
                    size="small"
                  />
                ) : (
                  t('questionnaires.continue')
                )}
              </PrimaryButton>
            </VStack>
          </View>
        </ImageBackground></View>
    )
  }

  const recaptcha = useRef();

  const send = () => {
    console.log('send!');
    recaptcha.current.open();
  }

  const onVerify = token => {
    setDisabled(false)
  }

  const onExpire = () => {
    console.warn('expired!');
    if (recaptcha) {
      send()
    }
  }

  useEffect(() => {
    if (recaptcha) {
      send()
    }
  }, [recaptcha])


  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../../assets/background.png")}
        style={{ flex: 1 }}
      >
        <View style={{ alignSelf: "flex-end", marginVertical: 20, marginRight: 15 }}>
          <PrimaryButton onPress={logoutHandler}>
            <MaterialCommunityIcons
              name="logout"
              size={24}
              color={colors.white}
            />
          </PrimaryButton>
        </View>
        <View style={{ flex: 1, alignItems: "center", }}>
          <VStack mb="5">
            <Image
              source={{
                uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
              }}
              style={{
                height: 160,
                width: 98,
              }}
            />
          </VStack>
          <VStack mx="3">
            <H6 style={{ textAlign: 'center' }} >{t('validation.title1')}</H6>
          </VStack>
          <VStack mx="3" mb="5">
            <Txt color='#484848' style={{ fontSize: 15, marginTop: 5, textAlign: 'center' }}>{t('validation.text1')}</Txt>
          </VStack>
          <Recaptcha
            ref={recaptcha}
            siteKey="6Lc3yA4pAAAAAHycSEOo41l8KMk-qLixmKPmuiyW"
            baseUrl="https://app.lislup.com"
            onVerify={onVerify}
            onExpire={onExpire}
            size="normal"
          />

          <VStack mt="5">
            <PrimaryButton onPress={submitHandler} disabled={disabled} style={{ width: 200 }}>
              {submitLoader ? (
                <Spinner
                  color={submitLoader ? colors.white : colors.blue3}
                  size="small"
                />
              ) : (
                t('questionnaires.continue')
              )}
            </PrimaryButton>
          </VStack>
        </View>
      </ImageBackground></View>
  );

};

export default Validation;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.beige,
  },
  image: {
    position: "absolute",
    bottom: 0,
    right: 20,
    height: 230,
    width: 140,
  },
  toastContainer: {
    backgroundColor: colors.green2,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 20,
  },

  choiceContainer: {
    padding: 20,
    borderRadius: 20,
    width: "100%",
    backgroundColor: colors.white,
    marginTop: 30,
  },

  row: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  textContainer: { marginLeft: 10, width: "100%" },
  buttonContainer: { flexDirection: "row-reverse", marginTop: 20 },
});
