import {
  ImageBackground,
  Platform,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useState } from "react";
import { Image, Modal, VStack, useToast } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { useTranslation } from "react-i18next";
import { H5, Txt } from "../TextsComponents";
import { Snackbar, Surface } from "react-native-paper";
import { PrimaryButton } from "../Buttons";
import * as WebBrowser from "expo-web-browser";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Spinner from "../Spinner";
import axios from "axios";

const AssociationCard = ({ association, setIsOpen, setShowSuccess }) => {
  const { isDesktop, isMobile, isBigScreen } = DimensionsHook();
  const { t, i18n } = useTranslation();
  const [submitLoader, setSubmitLoader] = useState(false);
  //const hoverRef = useRef(null);
  //const isHovered = useHover(hoverRef);

  const [visible, setVisible] = React.useState(false);

  const onToggleSnackBar = () => setVisible(!visible);

  const onDismissSnackBar = () => setVisible(false);

  const donationHandler = async () => {
    if (!submitLoader) {
      setSubmitLoader(true);
      setVisible(false);
      setTimeout(async () => {
        try {
          const TOKEN = await AsyncStorage.getItem("access_token");
          axios
            .post(process.env.ASSOCIATIONS_ENDPOINT, {
              access_token: TOKEN,
              association_id: association._id,
            })
            .then((res) => {
              setSubmitLoader(false);
              setShowSuccess(true);
              setIsOpen(false);
            })
            .catch((error) => {
              console.log(error);
              setSubmitLoader(false);
              if (error.response.status === 403) {
                onToggleSnackBar();
              } else {
                console.log("connexion problem");
              }
            });
        } catch (error) {
          setSubmitLoader(false);
        }
      }, 2000);
    }
  };

  return (
    <Surface
      style={[
        styles.cardContainer,
        {
          width: isMobile ? "95%" : isBigScreen ? "32%" : "48%",
          marginTop: isMobile ? 20 : 10,
        },
      ]}
      elevation={4}
    >
      <View
        style={{
          width: "100%",
          alignItems: "center",
          flex: 1,
        }}
        //ref={hoverRef}
      >
        <Pressable
          onPress={() => {
            if (association.website) {
              Platform.OS == "web"
                ? window.open(association.website, "_blank")
                : WebBrowser.openBrowserAsync(association.website);
            }
          }}
        >
          <Image
            style={{ width: 100, height: 100 }}
            source={{ uri: association.image }}
            alt="logo"
          />
        </Pressable>
        <View
          style={{
            flex: 1,
          }}
        >
          <View style={{ padding: isDesktop ? 20 : 10, flex: 1 }}>
            <H5
              style={{
                fontSize: 20,
                lineHeight: 26,
                textAlign: "center",
                marginBottom: 15,
              }}
            >
              {i18n.language == "fr"
                ? association.name_fr
                : association.name_en}
            </H5>
            <View
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Txt
                style={{
                  fontSize: 14,
                  textAlign: "center",

                  color: "#8a8686",
                }}
              >
                {i18n.language == "fr"
                  ? association.description_fr
                  : association.description_en}
              </Txt>
            </View>
          </View>
        </View>
        <View
          style={{
            alignItems: "center",
            width: "100%",
            paddingBottom: 15,
          }}
        >
          <PrimaryButton
            onPress={() =>
              Platform.OS == "web"
                ? window.open(association.don_link, "_blank")
                : WebBrowser.openBrowserAsync(association.don_link)
            }
            style={{ width: "90%", marginTop: 10 }}
          >
            {t("donation.button1")}
          </PrimaryButton>
          <PrimaryButton
            onPress={donationHandler}
            style={{ width: "90%", marginTop: 10 }}
          >
            {submitLoader ? (
              <Spinner color={colors.white} size="small" />
            ) : (
              t("donation.button2")
            )}
          </PrimaryButton>
        </View>
      </View>
      <Snackbar
        visible={visible}
        duration={1500}
        onDismiss={onDismissSnackBar}
        style={{
          borderRadius: 100,
          bottom: 5,
          backgroundColor: colors.red1,
        }}
        action={{
          label: t("tuto.close"),
          color: "#ffffff",
          labelStyle: { fontSize: 13, textTransform: "capitalize" },
          onPress: () => {
            // Do something
            setVisible(false);
          },
        }}
      >
        {t("buy_trails.error_points")}
      </Snackbar>
    </Surface>
  );
};

const DonationsModal = ({
  isOpen,
  setIsOpen,
  associations,
  setShowSuccess,
}) => {
  const { isDesktop, height } = DimensionsHook();
  const { t } = useTranslation();

  const closeHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal focusable={false} isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={[styles.container, { maxHeight: height - 45 }]}>
        <Modal.Body>
          <ImageBackground
            source={require("../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ position: "absolute", right: 0, top: 10 }}
            />

            <VStack
              style={{
                marginHorizontal: isDesktop ? 20 : 0,
                marginBottom: 20,
                marginTop: 40,
              }}
            >
              <View style={styles.cardsContainer}>
                {associations.map((asso) => {
                  return (
                    <AssociationCard
                      setShowSuccess={setShowSuccess}
                      setIsOpen={setIsOpen}
                      key={asso._id}
                      association={asso}
                    />
                  );
                })}
              </View>
            </VStack>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default DonationsModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "99%",
    maxWidth: 1400,
  },
  buttonsContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  badgeContainer: {
    width: "100%",
    padding: 15,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: colors.green0,
  },
  rowInputs: {
    flexDirection: "row",
    marginTop: 10,
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardsContainer: {
    width: "100%",
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  cardContainer: {
    marginTop: 10,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 10,
  },
  toastContainer: {
    backgroundColor: colors.red1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 20,
    marginTop: 30,
  },
});
