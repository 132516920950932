import { Linking, StyleSheet, } from 'react-native'
import React, { useEffect, useState } from 'react'
import { HStack, View, } from 'native-base'
import { H2, SmallBoldTxt, Txt } from '../components/TextsComponents'
import axios from 'axios';
import { colors } from '../styles/GlobalStyle';
import Spinner from '../components/Spinner';
import { Ionicons } from '@expo/vector-icons';
import { PrimaryButton, SecondaryButton } from '../components/Buttons';
import { useTranslation } from 'react-i18next';
import { Input } from '../components/Inputs';
import { TextInput } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Image } from 'react-native';




const getCurrentUrl = async () => {
    const url = await Linking.getInitialURL();
    return url;
};

const ResetUserPassword = ({ navigation }) => {
    const { t } = useTranslation()
    const [isExpired, setIsExpired] = useState(false)
    const [loader, setLoader] = useState(true)
    const [token, setToken] = useState('');
    const [user, setUser] = useState(null)
    const [password, setPassword] = useState("");
    const [isSecure, setIsSecure] = useState(true);
    const [isConfirmSecure, setIsConfirmSecure] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [submitLoader, setSubmitLoader] = useState(false);



    const toggleSecure = () => setIsSecure(!isSecure);
    const toggleConfirmSecure = () => setIsConfirmSecure(!isConfirmSecure);


    const submitHandler = async () => {
        try {
            setSubmitLoader(true);
            setErrorMessage("");
            const passwordTest = password.length >= 8;

            if (passwordTest == false) {
                setSubmitLoader(false);
                setErrorMessage(t("signup.error_password"));
                return;
            }
            if (confirmPassword != password) {
                setSubmitLoader(false);
                setErrorMessage(t("signup.error_confirm"));
                return;
            }
            const payload = { user: user._id, password };

            axios.post(`${process.env.USER_DATA}/user-new-password`, payload).then(async (res) => {
                await AsyncStorage.setItem('access_token', res.data)
                const currentUrl = await getCurrentUrl();
                const link = currentUrl.split("/reset");
                window.history.pushState({ path: link[0] }, '', link[0]);
                window.location.href = window.location.href;


            })


        } catch (err) {
            console.log(err)
            setErrorMessage(t("reset_pass_screen.error_connexion"));

        }

        setSubmitLoader(false);
    };


    useEffect(() => {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const tokenFromQuery = params.get('token'); // 'some_value'

        setToken(tokenFromQuery);
        axios.get(`${process.env.USER_DATA}/check-user-token`, {
            headers: {
                Authorization: `Bearer ${tokenFromQuery}`,
            },
        }).then(res => {
            setUser(res.data)
            setLoader(false)
        }).catch(err => {
            setIsExpired(true)
            setLoader(false)
        })
    }, [])
    return (
        <View flex={1} bg={colors.beige} alignItems='center' justifyContent='center'>
            {
                loader ? <Spinner size="large" /> : <View>
                    {isExpired ? <View alignSelf='center' maxW="600" mx="4" alignItems="center">
                        <Ionicons name="warning" size={100} color={colors.blue3} />

                        <Txt style={{ textAlign: 'center', marginTop: 20, marginBottom: 40, fontSize: 17 }}>{t('reset_password.expired')}</Txt>
                        <HStack alignItems='center' space="2">
                            <PrimaryButton onPress={() => navigation.replace('ResetPassword', true)}>
                                {t('reset_password.try')}
                            </PrimaryButton>
                            <SecondaryButton onPress={async () => {
                                const currentUrl = await getCurrentUrl();
                                const link = currentUrl.split("/reset");

                                window.history.pushState({ path: link[0] }, '', link[0]);
                                navigation.replace('Login')
                            }}>
                                {t('reset_password.login')}
                            </SecondaryButton>
                        </HStack>

                    </View> : <View flex={1}>
                        <View
                            style={{
                                flex: 1,
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: -50,
                            }}
                        >
                            <Image
                                source={{
                                    uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
                                }}
                                style={{
                                    height: 115,
                                    width: 70,
                                }}
                            />
                            <View alignItems="center" style={{ maxWidth: 500, marginTop: 15 }}>
                                <H2 style={{ textAlign: "center", fontSize: 23, lineHeight: 28 }}>
                                    {t("reset_password.text1")}
                                </H2>
                            </View>

                            <SmallBoldTxt
                                style={{
                                    color: colors.red1,
                                    textAlign: "center",
                                    marginTop: 10,
                                }}
                            >
                                {errorMessage ? errorMessage : ""}
                            </SmallBoldTxt>
                            <View style={{ maxWidth: 900, minWidth: 500, marginVertical: 20 }}>

                                {/** password */}
                                <Input
                                    mode="outlined"

                                    placeholder={t("placeholders.new_password")}
                                    secureTextEntry={isSecure}
                                    value={password}
                                    onChangeText={(val) => setPassword(val)}
                                    right={
                                        isSecure ? (
                                            <TextInput.Icon
                                                forceTextInputFocus={false}
                                                onPress={toggleSecure}
                                                name={"eye"}
                                                size={24}
                                                color={colors.blue3}
                                            />
                                        ) : (
                                            <TextInput.Icon
                                                forceTextInputFocus={false}
                                                onPress={toggleSecure}
                                                name="eye-off"
                                                size={24}
                                                color={colors.blue3}
                                            />
                                        )
                                    }
                                />
                                {/** confirm password */}
                                <Input
                                    mode="outlined"
                                    style={{ marginTop: 10 }}
                                    placeholder={t("placeholders.confirm_new_password")}
                                    secureTextEntry={isConfirmSecure}
                                    value={confirmPassword}
                                    onChangeText={(val) => setConfirmPassword(val)}
                                    right={
                                        isConfirmSecure ? (
                                            <TextInput.Icon
                                                forceTextInputFocus={false}
                                                onPress={toggleConfirmSecure}
                                                name={"eye"}
                                                size={24}
                                                color={colors.blue3}
                                            />
                                        ) : (
                                            <TextInput.Icon
                                                forceTextInputFocus={false}
                                                onPress={toggleConfirmSecure}
                                                name="eye-off"
                                                size={24}
                                                color={colors.blue3}
                                            />
                                        )
                                    }
                                />
                                <PrimaryButton
                                    onPress={submitHandler}
                                    style={{ marginVertical: 30 }}
                                >
                                    {submitLoader ? (
                                        <Spinner
                                            color={submitLoader ? colors.white : colors.blue3}
                                            size="small"
                                        />
                                    ) : (
                                        t("reset_pass_screen.button1")
                                    )}
                                </PrimaryButton>
                            </View>
                        </View>


                    </View>}
                </View>
            }
        </View>
    )
}

export default ResetUserPassword


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: colors.beige,
    },
    image: {
        position: "absolute",
        bottom: 0,
        right: 20,
        height: 230,
        width: 140,
    },

    toastContainer: {
        backgroundColor: colors.green2,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        paddingVertical: 10,
        paddingHorizontal: 30,
        borderRadius: 20,
    },
});
