import { Tooltip } from "native-base";
import React from "react";
import { Platform } from "react-native";

export const CustomTooltip = ({
  label,
  bg,
  placement,
  color,
  children,
  style,
}) => {
  if (Platform.OS == "web") {
    return (
      <Tooltip
        label={label}

        fontFamily="heading"
        openDelay={0}
        placement={placement || "bottom"}
        bg={bg}
        style={style ? { maxWidth: 500, ...style } : { maxWidth: 500 }}
        px="4"
        _text={{
          color: color,
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        {children}
      </Tooltip>
    );
  }
  return <>{children}</>;
};
