export const userDataReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_USER_DATA":
      return action.payload;
    case "TOGGLE_NOTIFICATION":
      return { ...state, notification: action.payload };
    case "COMMUNITY_CONDITIONS":
      return { ...state, community_visit: true };
    default:
      return state;
  }
};

export const userActivityReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_USER_ACTIVITY":
      return action.payload;
    default:
      return state;
  }
};

export const watchedEpisodesReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_WATCHED_EPISODES":
      return action.payload;
    default:
      return state;
  }
};
export const savedEpisodesReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_SAVED_EPISODES":
      return action.payload;
    default:
      return state;
  }
};

export const monthlyMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_MESSAGE":
      return action.payload;
    default:
      return state;
  }
};

export const questionnairesDataReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_QUESTIONNAIRES":
      return action.payload;
    default:
      return state;
  }
};
export const answeredQuestionnairesReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_ANSWERED_QUESTIONNAIRES":
      return action.payload;
    default:
      return state;
  }
};

export const dominosReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_DOMINOS":
      return action.payload;
    default:
      return state;
  }
}

export const cardsReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_CARDS":
      return action.payload;
    default:
      return state;
  }
}
