import {
  View,
  StyleSheet,
  Animated,
  Easing,
  TouchableOpacity,
  Pressable,
} from "react-native";
import React, { useState, useEffect, useRef } from "react";
import {
  H6,
  SmallLightTxt,
  SmallBoldTxt,
  SmallTxt,
} from "../../../../components/TextsComponents";
import { colors } from "../../../../styles/GlobalStyle";

import { useSelector } from "react-redux";
import DimensionsHook from "../../../../hooks/DimensionsHook";
import { useTranslation } from "react-i18next";
import { Ionicons } from "@expo/vector-icons";
import HelpModal from "../HelpModal";

const Score = () => {
  const { width } = DimensionsHook();
  const { t } = useTranslation();
  const userData = useSelector((state) => state.userData);
  const [barWidth, setBarWidth] = useState(0);
  const [showScoreHelpModal, setShowScoreHelpModal] = useState(false);
  const [ready, setReady] = useState(false);
  const progressBarWidth = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (ready) {
      if (userData.current_points >= 2000) {
        Animated.timing(progressBarWidth, {
          toValue: barWidth * 0.8 + 10,
          duration: 700,
          easing: Easing.linear,
          useNativeDriver: false,
          delay: 400,
        }).start();
      } else {
        const progress =
          userData.current_points < 1000
            ? barWidth * 0.8 * (userData.current_points / 1000)
            : barWidth * 0.8 * ((userData.current_points - 1000) / 1000);
        if (barWidth > 0) {
          Animated.timing(progressBarWidth, {
            toValue: userData.current_points < 50 ? progress : progress + 5,
            duration: 700,
            easing: Easing.linear,
            useNativeDriver: false,
            delay: 400,
          }).start();
        }
      }
    }
  }, [userData.current_points, ready, barWidth]);

  return (
    <Pressable
      onPress={() => setShowScoreHelpModal(true)}
      style={[
        styles.score,
        {
          width: width <= 790 ? "100%" : "49%",
          marginBottom: width <= 790 ? 20 : 0,
          height: width <= 790 ? 200 : 236,
          overflow: "hidden",
        },
      ]}
    >
      <H6
        style={{
          color: colors.beige,
          alignSelf: "center",
          position: "absolute",
          top: 30,
        }}
      >
        Score
      </H6>

      <View
        onLayout={(e) => {
          const w = e.nativeEvent.layout.width;
          setBarWidth(w);
          setReady(true);
        }}
        style={{
          width: "100%",

          marginTop: 20,
          flexDirection: "row",
        }}
      >
        {/** progres bar */}
        {ready && (
          <View style={styles.progressBar}>
            {/** the bar to animate */}
            <Animated.View
              style={[
                styles.bar,
                {
                  width: progressBarWidth,
                },
              ]}
            />
            <View style={styles.blueLines} />
            <View style={styles.blueLines} />
            <View style={styles.blueLines} />
          </View>
        )}
        {/** circles & label points */}
        {ready && (
          <View style={{ marginLeft: 2 }}>
            <View style={[styles.circle, { marginLeft: -8 }]} />

            <SmallLightTxt>
              {userData.current_points < 1000 ? "" : "1000"}
            </SmallLightTxt>
          </View>
        )}
        {ready && (
          <View style={{ position: "absolute", left: barWidth * 0.2 }}>
            <View style={styles.circle} />
            <SmallLightTxt style={{ textAlign: "center" }}>
              {userData.current_points < 1000 ? "250" : "1250"}{" "}
              {t("score_screen.pts")}
            </SmallLightTxt>
          </View>
        )}
        {ready && (
          <View style={{ position: "absolute", left: barWidth * 0.4 }}>
            <View style={styles.circle} />
            <SmallLightTxt>
              {userData.current_points < 1000 ? "500" : "1500"}{" "}
              {t("score_screen.pts")}
            </SmallLightTxt>
          </View>
        )}
        {ready && (
          <View style={{ position: "absolute", left: barWidth * 0.8 }}>
            <View style={styles.circle} />
            <SmallLightTxt>
              {userData.current_points < 1000 ? "1000" : "2000"}{" "}
              {t("score_screen.pts")}
            </SmallLightTxt>
          </View>
        )}
      </View>
      {ready && (
        <View
          style={{
            position: "absolute",
            alignItems: "center",
            bottom: width <= 790 ? 20 : 40,
          }}
        >
          <View style={styles.badgeContainer}>
            <SmallTxt>
              <SmallBoldTxt>{t("my_space.level")}</SmallBoldTxt>
              {" ( " +
                userData.current_points +
                " " +
                t("score_screen.points") +
                ")"}
            </SmallTxt>
          </View>
        </View>
      )}
      {/** help button -> SHOW POP UP */}
      <TouchableOpacity
        onPress={() => setShowScoreHelpModal(true)}
        style={styles.helpButton}
      >
        <Ionicons name="help-circle-outline" size={24} color={colors.white} />
      </TouchableOpacity>
      {/** help modal */}
      <HelpModal
        isOpen={showScoreHelpModal}
        setIsOpen={setShowScoreHelpModal}
        text={t("my_space.text2")}
        title="Score"
      />
    </Pressable>
  );
};

export default Score;

const styles = StyleSheet.create({
  score: {
    position: "relative",
    width: 343,
    marginBottom: 0,
    borderRadius: 20,
    backgroundColor: colors.blue2,
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  triangle: {
    width: 0,

    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
  },

  arrowUp: {
    borderTopWidth: 0,
    borderRightWidth: 10,
    borderBottomWidth: 10,
    borderLeftWidth: 10,
    borderTopColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: colors.white,
    borderLeftColor: "transparent",
  },

  progressBar: {
    width: "100%",
    height: 16,
    position: "absolute",
    top: -3,
    backgroundColor: colors.white,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  bar: {
    left: 0,
    height: 16,
    backgroundColor: colors.blue3,

    position: "absolute",
  },
  blueLines: {
    width: 4,
    height: 16,
    backgroundColor: colors.blue2,
    marginRight: 3,
  },
  circle: {
    width: 10,
    height: 10,
    backgroundColor: colors.blue2,
    borderRadius: 5,
    marginBottom: 8,
  },
  badgeContainer: {
    backgroundColor: colors.white,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
    marginTop: -2,
  },
  helpButton: {
    position: "absolute",
    padding: 5,
    top: 10,
    right: 10,
  },
});
