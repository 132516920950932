import { StyleSheet, ImageBackground } from "react-native";
import React from "react";
import { Image, Modal, VStack, View } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import { BoldTxt, H2, Txt } from "../TextsComponents";
import DimensionsHook from "../../hooks/DimensionsHook";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../Buttons";

const GoFlashCoachingModal = ({ isOpen, setIsOpen, navigation }) => {
  const { t } = useTranslation();
  const { isMobile, width } = DimensionsHook();

  const closeHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={styles.container}>
        <Modal.Body>
          <ImageBackground
            source={require("../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ marginRight: isMobile ? 0 : 10 }}
            />
            <VStack
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 55, marginBottom: width > 1100 ? 50 : 20 }}
            >
              <H2
                style={{
                  textAlign: "center",
                  lineHeight: isMobile ? 32 : 40,
                }}
              >
                {t("flash_coaching_modal.title")}
              </H2>

              <Txt
                style={{
                  marginTop: 20,
                  marginHorizontal: 15,
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                {t("flash_coaching_modal.text1")}
              </Txt>
              <BoldTxt
                style={{
                  marginTop: 10,
                  marginHorizontal: 15,
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                {t("flash_coaching_modal.text2")}
              </BoldTxt>
              <Txt
                style={{
                  marginTop: 20,
                  marginHorizontal: 15,
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                {t("flash_coaching_modal.text3")}
              </Txt>

              <PrimaryButton
                onPress={() => {
                  setIsOpen(false);
                  navigation.navigate("Coaching");
                }}
                style={{ marginVertical: 20 }}
              >
                {t("flash_coaching_modal.button")}
              </PrimaryButton>
            </VStack>

            {width > 1100 && (
              <Image
                alt="mascotte"
                source={{
                  uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
                }}
                style={{
                  width: 80,
                  height: 130,
                  position: "absolute",
                  bottom: 5,
                  right: 5,
                }}
              />
            )}
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default GoFlashCoachingModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "95%",
    maxWidth: 900,
    maxHeight: 5000,
  },
});
