import React, { useState, useEffect } from "react";
import { StyleSheet, FlatList, Platform, View } from "react-native";
import SwiperItem from "./SwiperItem";
import axios from "axios";
import LoaderItem from "./LoaderItem";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { H1 } from "../TextsComponents";
import { colors } from "../../styles/GlobalStyle";
import { useTranslation } from "react-i18next";
import { CompleteLoaderAvatar } from "../../assets/svg/Logo";

const Swiper = ({ type, endpoint, navigation, showStateBar }) => {
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const { t } = useTranslation();

  const getData = async () => {
    setLoader(true);
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    try {
      const Response = await axios.get(endpoint, config);

      await Response.data.sort((a, b) => Number(b.free) - Number(a.free));

      setData(Response.data);

      setLoader(false);
    } catch (error) {
      setData([]);
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [endpoint]);

  // 2 Loader item to show
  if (loader) {
    return (
      <FlatList
        showsHorizontalScrollIndicator={Platform.OS === "web"}
        horizontal
        style={{ marginTop: 15 }}
        data={[0, 1]}
        keyExtractor={(item) => item}
        renderItem={() => <LoaderItem />}
      />
    );
  }

  if (Data.length === 0) {
    return (
      <View style={styles.noDataContainer}>
        <View
          style={{
            alignSelf: "center",
            width: 80,
            height: 80,
            marginBottom: 20,
          }}
        >
          <CompleteLoaderAvatar color={colors.grayLabel} />
        </View>

        <View style={{ marginHorizontal: 15 }}>
          <H1
            style={{ fontSize: 20, lineHeight: 27, textAlign: "center" }}
            color={colors.grayLabel}
          >
            {t("home_main.no_result")}
          </H1>
        </View>
      </View>
    );
  }

  // FLATLIST WITH DATA

  return (
    <FlatList
      showsHorizontalScrollIndicator={Platform.OS === "web"}
      horizontal
      style={{ marginTop: 15 }}
      data={Data}
      keyExtractor={(item) =>
        type == "Trail" ? item.trail_code : item.workshop_code
      }
      renderItem={(props) => (
        <SwiperItem
          {...props}
          type={type}
          navigation={navigation}
          showStateBar={showStateBar === true ? true : false}
        />
      )}
    />
  );
};

export default Swiper;

const styles = StyleSheet.create({
  noDataContainer: {
    height: 240,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    borderTopWidth: 1,
    borderTopColor: colors.grayBorder,
    marginVertical: 20,
  },
});
