import { FlatList, Platform, View } from "react-native";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useSelector } from "react-redux";
import { H5 } from "../TextsComponents";
import SwiperItem from "../swiper/SwiperItem";
import Spinner from "../Spinner";

const RecommandedSwiper = ({ navigation, season_code, closeTrailModal }) => {
  const { t, i18n } = useTranslation();
  const userActivity = useSelector((state) => state.userActivity);
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(true);

  const swiperContainerStyle = {
    width: "100%",
    alignSelf: "center",
    borderRadius: 20,
  };

  const getData = async () => {
    if (Data.length === 0) {
      setLoader(true);
    }
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const endpoint = process.env.RECOMMANDED_WORKSHOPS;
    const Response = await axios.get(
      endpoint + `?language=${i18n.language}&season_code=${season_code}`,
      config
    );

    setData(Response.data);

    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [userActivity, season_code]);

  if (loader) {
    return (
      null
    );
  }

  if (Data.length === 0) {
    return <View />;
  }

  return (
    <View style={swiperContainerStyle}>
      <H5 >{t("trail.suggestion")}</H5>
      <FlatList
        showsHorizontalScrollIndicator={Platform.OS === "web"}
        horizontal
        style={{ marginTop: 10 }}
        data={Data}
        keyExtractor={(item) => item.workshop_code}
        renderItem={(props) => (
          <SwiperItem
            {...props}
            type="Atelier"
            navigation={navigation}
            closeTrailModal={closeTrailModal}
          />
        )}
      />
    </View>
  );
};

export default RecommandedSwiper;
