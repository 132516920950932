import { Platform, StyleSheet, TouchableOpacity } from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { Txt } from "../TextsComponents";
import { View } from "native-base";
import { GoogleLogo } from "../../assets/svg/Icons";
import * as Google from "expo-auth-session/providers/google";
import * as WebBrowser from "expo-web-browser";
import Spinner from "../Spinner";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  setSavedEpisodes,
  setUserActivity,
  setUserData,
  setWatchedEpisodes,
} from "../../redux/Actions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Purchases from "react-native-purchases";
import { useNavigation } from "@react-navigation/native";
import { useHover } from "react-native-web-hooks";
import { colors } from "../../styles/GlobalStyle";

const GoogleSignIn = ({ signup }) => {
  const [loader, setLoader] = useState(false);
  const { t, i18n } = useTranslation();
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  // google sign in
  const [request, response, promptAsync] = Google.useAuthRequest({
    androidClientId: process.env.GOOGLE_ANDROID,
    iosClientId: process.env.GOOGLE_IOS,
    expoClientId: process.env.GOOGLE_EXPO,
    webClientId: process.env.GOOGLE_WEB,
  });

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const googleSignIn = async () => {
    //console.log("reponse is : ", response);
    if (
      response?.type === "cancel" ||
      response?.type === "dismiss" ||
      response?.type === "error"
    ) {
      setLoader(false);
    }
    if (response?.type === "success") {
      setLoader(true);

      const auth = response.authentication;
      // info provided by google
      let userInfoResponse = await fetch(
        "https://www.googleapis.com/userinfo/v2/me",
        {
          headers: { Authorization: `Bearer ${auth.accessToken}` },
        }
      );

      userInfoResponse.json().then(async (data) => {
        console.log("réponse from google", data);
        // sign up user = get user data and generated token

        axios
          .post(process.env.USER_SIGNIN_WITH_PROVIDER, {
            email: data.email,
            firstname: data.given_name,
            lastname: data.family_name,
            language: i18n.language,
          })
          .then(async (serverResponse) => {
            const token = serverResponse.data.access_token;

            await AsyncStorage.setItem("access_token", token);
            await dispatch(setUserData());
            await dispatch(setUserActivity());
            await dispatch(setWatchedEpisodes());
            await dispatch(setSavedEpisodes());
            console.log("la réponse du serveur est :  ", serverResponse.data);
            if (Platform.OS == "ios") {
              Purchases.setDebugLogsEnabled(true);
              Purchases.configure({
                apiKey: "appl_qsunokDQSTJoiKMlhDkpJNeQDjY",
                appUserID: serverResponse.data.customer,
              });
            }

            if (serverResponse.data.lifetime_subscription) {
              navigation.replace("TabNavigation");
            } else if (serverResponse.data.subscription) {
              if (serverResponse.data.subscription_status == "active") {
                navigation.replace("TabNavigation");
              } else {
                navigation.replace("Plans");
              }
            } else if (serverResponse.data.trial_used) {
              navigation.replace("TabNavigation");
              setSubmitLoader(false);
            } else {
              // NO PLANS AND NO TRIAL
              navigation.replace("Plans");
              setSubmitLoader(false);
            }

            setLoader(false);
          });
      });
    }
  };

  useEffect(() => {
    googleSignIn();
  }, [response]);
  return (
    <View ref={hoverRef} mb="3">
      <TouchableOpacity
        onPress={() => {
          setLoader(true);
          promptAsync({ useProxy: false, showInRecents: true });
        }}
        style={[
          styles.googleButton,
          {
            backgroundColor: isHovered ? colors.blue3 : "white",
            borderColor: isHovered ? "transparent" : colors.grayBorder,
          },
        ]}
      >
        {!loader && <GoogleLogo />}
        {!loader && (
          <Txt color={isHovered ? colors.white : colors.blue3}>{`  ${signup ? t('signup.google_button1') : t(
            "signup.google_button"
          )}`}</Txt>
        )}
        {loader && <Spinner color={isHovered ? colors.white : colors.blue3} />}
      </TouchableOpacity>
    </View>
  );
};

export default GoogleSignIn;

const styles = StyleSheet.create({
  googleButton: {
    height: 48,
    borderRadius: 100,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    borderWidth: 1,
  },
});
