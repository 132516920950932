import { StyleSheet, TouchableOpacity, View, Platform } from "react-native";
import React from "react";
import { ArrowBack } from "../assets/svg/Icons";
import { colors } from "../styles/GlobalStyle";
import { Txt } from "./TextsComponents";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Modal } from "native-base";
import NavigationMenu from "./myaccount/NavigationMenu";
import { CloseIcon, LislUp } from "../assets/svg/Logo";
import Constants from "expo-constants";
import { useTranslation } from "react-i18next";

const BackHeader = ({ navigation, myaccount, active, isOpen, setIsOpen, closeEpisodeHandler }) => {
  // onPress={() => changeHandler(false)}
  const { t } = useTranslation();

  const onBackPress = () => {
    if (closeEpisodeHandler) {
      closeEpisodeHandler()
    } else {
      navigation.goBack();
    }
  };

  const closeHandler = () => {
    setIsOpen(false);
  };

  return (
    <View style={styles.wrapper}>
      <View style={styles.container}>
        <View style={styles.container}>
          <TouchableOpacity onPress={onBackPress} style={styles.button}>
            <View style={{ width: 24, height: 24 }}>
              <ArrowBack />
            </View>
          </TouchableOpacity>
          <Txt style={{ marginLeft: 20 }}>{t("back")}</Txt>
        </View>
      </View>

      {/** open my account menu on small screens */}
      {myaccount && (
        <TouchableOpacity
          style={{ paddingRight: 10 }}
          onPress={() => setIsOpen(true)}
        >
          <MaterialCommunityIcons name="menu" size={30} color={colors.blue3} />
        </TouchableOpacity>
      )}

      {/** my account menu modal */}
      <Modal size="full" isOpen={isOpen} onClose={closeHandler}>
        <Modal.Content
          style={[
            styles.modalContainer,

            {
              height: "100%",
              paddingTop: Platform.OS == "ios" ? Constants.statusBarHeight : 0,
              backgroundColor: colors.beige,
            },
          ]}
        >
          <Modal.Body style={{ marginBottom: 30 }}>
            <View style={styles.menuContainer}>
              <View style={styles.logoContainer}>
                <LislUp />
              </View>
              <TouchableOpacity onPress={closeHandler} style={styles.closeIcon}>
                <CloseIcon color={colors.blue3} />
              </TouchableOpacity>
            </View>
            <NavigationMenu active={active} navigation={navigation} />
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </View>
  );
};

export default BackHeader;

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: colors.beige,
    borderBottomColor: colors.grayBorder,
    borderBottomWidth: 1,
  },
  container: {
    height: 70,
    alignItems: "center",
    flexDirection: "row",
  },
  modalContainer: {
    backgroundColor: colors.white,
    maxWidth: 1000,
    maxHeight: 5000,
    borderRadius: 0,
  },
  button: {
    width: 70,
    height: 70,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1B496515",
  },
  logoContainer: {
    width: 50,
    height: 50,
  },
  closeIcon: {
    width: 25,
    height: 25,
    padding: 5,
    marginRight: 5,
  },
  menuContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 60,
    borderBottomWidth: 1,
    borderColor: colors.grayBorder,
  },
});
