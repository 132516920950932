import {
    VictoryArea,
    VictoryChart,
    VictoryPolarAxis,
    VictoryTheme,
} from "victory-native";
import { useNavigation } from "@react-navigation/native";
import { Platform } from "react-native";
import { AlertDialog, Pressable, ScrollView, VStack, View } from "native-base";
import { PrimaryButton } from "../../components/Buttons";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { BoldTxt, Txt } from "../../components/TextsComponents";
import { Fontisto } from "@expo/vector-icons";
import { colors } from "../../styles/GlobalStyle";

export default function Radar({ bilanData, companyQuestions, currentCompagne }) {
    const navigation = useNavigation();
    const { t, i18n } = useTranslation();
    const [showDimensionsModal, setShowDimensionsModal] = useState(false);

    const cancelRef = useRef(null);
    const onClose = () => setShowDimensionsModal(false);

    const data = [
        {
            dimension: "dimension6",
            x: `${t('bilan.title12')}\n\n`,
            labell: t('bilan.title12'),
            y:
                bilanData && bilanData.section10
                    ? Number(
                        bilanData.section10.percent +
                        bilanData.section11.percent +
                        bilanData.section12.percent +
                        bilanData.section13.percent +
                        bilanData.section14.percent
                    ) / 5
                    : 1,
            finished: bilanData && bilanData.section10 ? true : false,
        },
        {
            dimension: "dimension2",
            x: i18n.language == "fr" ? `Relations\nsociales\n` : `Social\nrelationships\n`,
            labell: t('bilan.title6'),
            y:
                bilanData && bilanData.section5
                    ? Number(bilanData.section5.percent + bilanData.section6.percent) / 2
                    : 1,
            finished: bilanData && bilanData.section5 ? true : false,
        },
        {
            dimension: "dimension3",
            x: `${t('bilan.title9')}\n`,
            labell: t('bilan.title9'),
            y: bilanData && bilanData.section7 ? bilanData.section7.percent : 1,
            finished: bilanData && bilanData.section7 ? true : false,
        },
        {
            dimension: "dimension4",
            x: `${t('bilan.title10')}\n\n`,
            labell: t('bilan.title10'),
            y: bilanData && bilanData.section8 ? bilanData.section8.percent : 1,
            finished: bilanData && bilanData.section8 ? true : false,
        },
        {
            dimension: "dimension5",
            x: `\n${t('bilan.title11')}`,
            labell: t('bilan.title11'),
            y: bilanData && bilanData.section9 ? bilanData.section9.percent : 1,
            finished: bilanData && bilanData.section9 ? true : false,
        },

        {
            dimension: "dimension1",
            x: i18n.language == "fr" ? `\nEmotionnel,\nmental` : `\nEmotional,\nmental`,
            labell: t('bilan.title1'),
            y:
                bilanData && bilanData.section1
                    ? Number(
                        bilanData.section1.percent +
                        bilanData.section2.percent +
                        bilanData.section3.percent +
                        bilanData.section4.percent
                    ) / 4
                    : 1,
            finished: bilanData && bilanData.section1 ? true : false,
        },
    ];

    const copieOfData = data
    const navigateToSection = (section) => {
        setShowDimensionsModal(false)
        navigation.navigate("Section", { ...section, bilanData, companyQuestions, currentCompagne });
    };

    const DimensionsModal = () => {
        return (
            <AlertDialog
                leastDestructiveRef={cancelRef}
                isOpen={showDimensionsModal}
                onClose={onClose}
            >
                <AlertDialog.Content style={{ width: "95%", maxWidth: 1000 }}>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Header>
                        <BoldTxt style={{ fontSize: 15 }}>Choisir une Dimension</BoldTxt>
                    </AlertDialog.Header>
                    <AlertDialog.Body>
                        <View style={{ paddingHorizontal: 10, paddingVertical: 20 }}>
                            <ScrollView showsVerticalScrollIndicator={false}>
                                {copieOfData.sort((a, b) => {
                                    // Custom sorting logic based on the 'finished' property
                                    if (a.finished === b.finished) {
                                        return 0;
                                    } else if (a.finished) {
                                        return -1; // 'a' comes first if 'a' is finished
                                    } else {
                                        return 1; // 'b' comes first if 'b' is finished
                                    }
                                }).map((d, i) => {
                                    return (
                                        <Pressable
                                            style={{

                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                            px="2.5"
                                            py="4"
                                            bg={d.finished ? colors.green2 : colors.beige}
                                            mt="1"
                                            key={d.dimension}
                                            onPress={() => navigateToSection(d)}
                                        >
                                            <VStack mr="2">
                                                {d.finished ? (
                                                    <Fontisto
                                                        name="checkbox-active"
                                                        size={22}
                                                        color={colors.white}
                                                    />
                                                ) : (
                                                    <Fontisto
                                                        name="checkbox-passive"
                                                        size={22}
                                                        color="black"
                                                    />
                                                )}
                                            </VStack>
                                            <Txt color={d.finished ? colors.white : colors.blue3} style={{ fontSize: 14 }}>{d.labell}</Txt>
                                        </Pressable>
                                    );
                                })}
                            </ScrollView>
                        </View>
                    </AlertDialog.Body>
                </AlertDialog.Content>
            </AlertDialog>
        );
    };

    return (
        <VStack>
            <DimensionsModal />
            <VictoryChart theme={VictoryTheme.material} polar>
                {data.map((d, i) => {
                    return (
                        <VictoryPolarAxis
                            dependentAxis
                            key={i}
                            label={`${d.x}`}
                            labelDy={50}
                            labelOrientation="vertical"
                            theme={VictoryTheme.material}
                            labelPlacement="vertical"
                            events={
                                Platform.OS == "web"
                                    ? [
                                        {
                                            target: "axisLabel",
                                            eventHandlers: {
                                                onPressIn: () => {
                                                    // Handle onPress for the specific label (d.x)
                                                    navigateToSection(d);
                                                },
                                                onClick: () => {
                                                    // Handle onPress for the specific label (d.x)
                                                    navigateToSection(d);
                                                },
                                            },
                                        },
                                        {
                                            target: "tickLabels",
                                            eventHandlers: {
                                                onPressIn: () => {
                                                    // Handle onPress for the specific label (d.x)
                                                    navigateToSection(d);
                                                },
                                                onClick: () => {
                                                    // Handle onPress for the specific label (d.x)
                                                    navigateToSection(d);
                                                },
                                            },
                                        },
                                    ]
                                    : []
                            }
                            style={{
                                axisLabel: {
                                    fill: d.finished ? "#729e24" : "#0000ee",
                                    textDecoration: "underline",
                                    fontFamily: "OxygenBold",
                                    fontSize: 11,
                                    cursor: "pointer",
                                },
                                tickLabels: {
                                    fill: "#484848",
                                    fontFamily: "OxygenBold",
                                    fontSize: 10,
                                    opacity: 0,
                                },
                                axis: { stroke: "#48484830", strokeWidth: 0.7 },
                                grid: {
                                    stroke: i == data.length - 1 ? "#48484810" : "#48484830", //CHANGE COLOR OF X-AXIS GRID LINES
                                    strokeDasharray: 4,
                                    strokeWidth: 0.5,
                                },
                            }}
                            axisValue={d.x}
                            domain={[0, 100]} // Set the domain to be 0-100
                            tickValues={[33, 66, 100]} // Specify custom tick values
                            tickFormat={(t) => (i % 2 != 0 ? t : "")} // Show label only for 100
                        />
                    );
                })}

                <VictoryArea
                    style={{
                        data: { fill: "#A8C49250", stroke: "#A8C492", strokeWidth: 2 },
                    }}
                    animate={{
                        onExit: {
                            duration: 1000,
                            before: () => ({ _y: 0 }),
                        },
                    }}
                    data={data}
                />
            </VictoryChart>
            {Platform.OS != "web" && (
                <VStack mb="3" w="90%" alignSelf="center">
                    <PrimaryButton onPress={() => setShowDimensionsModal(true)}>
                        {" "}
                        {t("bilan.chart1_mobile")}
                    </PrimaryButton>
                </VStack>
            )}
        </VStack>
    );
}
