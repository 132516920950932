import { TouchableOpacity, View } from "react-native";
import React, { useEffect, useState } from "react";
import { H6, SmallBoldTxt, SmallTxt, Txt } from "../../TextsComponents";
import { useTranslation } from "react-i18next";
import { EmailIcon } from "../../../assets/svg/MyAccountIcons";
import { Input } from "../../Inputs";
import { useSelector } from "react-redux";
import DimensionsHook from "../../../hooks/DimensionsHook";
import { PrimaryButton, SecondaryButton } from "../../Buttons";
import Spinner from "../../Spinner";
import { colors } from "../../../styles/GlobalStyle";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ValidateChangeEmail from "./ValidateChangeEmail";
import { CloseIcon } from "../../../assets/svg/Appointment";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import ValidateEmail from "./ValidateEmail";
import { Check } from "../../../assets/svg/Coaching";
import { useToast } from "native-base";

const ChangeEmail = ({ scrollToTop, setErrorMessage }) => {
  const { email, status } = useSelector((state) => state.userData);

  const { t } = useTranslation();
  const { width } = DimensionsHook();
  const toast = useToast()
  const isDesktop = width > 900;
  const [emailValue, setEmailValue] = useState(email);
  const [isDisabled, setIsDisabled] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [showValidateEmail, setShowValidateEmail] = useState(false);
  const [showWarningMessage, setShowWarningMessage] = useState(true);
  const [submitVerifLoder, setSubmitVerifLoader] = useState(false);
  const [showVerifModal, setShowVerifModal] = useState(false);
  const verifHandler = async () => {
    if (!submitVerifLoder) {
      setSubmitVerifLoader(true);
      setErrorMessage("");
      const TOKEN = await AsyncStorage.getItem("access_token");
      axios
        .post(process.env.USER_SEND_LINK, {
          access_token: TOKEN,
        })
        .then(() => {
          setSubmitVerifLoader(false)
          toast.show({
            placement: "top",
            render: () => {
              return (
                <View
                  style={{
                    marginTop: 40,
                    backgroundColor: colors.blue3,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: width * 0.95,
                    alignSelf: "center",
                    maxWidth: 800,
                    borderRadius: 10,
                    padding: 15,
                  }}
                >
                  {isDesktop && (
                    <Ionicons
                      name="checkmark-circle"
                      size={28}
                      color={colors.white}
                      style={{ marginRight: 5 }}
                    />
                  )}
                  <Txt
                    color={colors.white}
                    style={{ marginLeft: 10, fontSize: 15 }}
                  >
                    {t("account_details_screen.confirm_mail")}
                  </Txt>
                  {isDesktop && (
                    <TouchableOpacity
                      style={{ marginLeft: 20 }}
                      onPress={() => toast.closeAll()}
                    >
                      <AntDesign
                        name="close"
                        size={24}
                        color={colors.white}
                      />
                    </TouchableOpacity>
                  )}
                </View>
              );
            },
          });

        })
        .catch((err) => {
          setSubmitVerifLoader(false);
          console.log(err);
          setErrorMessage(t("validation.error_connexion"));
        });


    }
  };



  const submitHandler = async () => {
    if (!submitLoader) {
      setErrorMessage("");
      setSubmitLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      axios
        .post(process.env.USER_CHANGE_EMAIL, {
          access_token: TOKEN,
          email: emailValue.toLowerCase().trim(),
        })
        .then((res) => {
          setSubmitLoader(false);
          setShowValidateEmail(true);
        })
        .catch((err) => {
          //   console.log(err);
          if (err.response.status === 401) {
            setSubmitLoader(false);
            setErrorMessage(t("signup.error_email"));
            scrollToTop();
          } else if (err.response.status === 409) {
            setSubmitLoader(false);
            setErrorMessage(t("account_details_screen.error_email_used"));
            scrollToTop();
          } else {
            setErrorMessage(t("signin.error_connexion"));
            setSubmitLoader(false);
            scrollToTop();
          }
        });
    }
  };

  useEffect(() => {
    if (email.trim() == emailValue.trim() || emailValue.trim() == "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [emailValue, email, setIsDisabled]);

  return (
    <View style={{ marginBottom: 20 }}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <EmailIcon />
        <H6 style={{ marginLeft: 5 }}>{t("account_details_screen.email")}</H6>
        {status == "active" && (
          <View
            style={{ width: 14, height: 14, marginLeft: 2, marginTop: -15 }}
          >
            <Check />
          </View>
        )}
      </View>
      {/** message appear if email is not verified */}
      {status != "active" && showWarningMessage && (
        <View
          style={{
            padding: 10,
            backgroundColor: colors.red1,
            borderRadius: 10,
            marginVertical: 10,
          }}
        >
          <TouchableOpacity
            style={{
              width: 25,
              height: 25,
              alignSelf: "flex-end",
              padding: 5,
            }}
            onPress={() => setShowWarningMessage(false)}
          >
            <CloseIcon color={colors.white} />
          </TouchableOpacity>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Ionicons name="ios-warning-outline" size={20} color="white" />
            <View style={{ marginLeft: 4 }}>
              <SmallBoldTxt color={colors.white}>
                {t("account_details_screen.text6")} | {email}
              </SmallBoldTxt>
            </View>
          </View>
          <SmallTxt style={{ marginTop: 5 }} color={colors.white}>
            {t("account_details_screen.text7")}
          </SmallTxt>
          {/** verification number button */}
          <SecondaryButton
            width={200}
            onPress={verifHandler}
            isWhite={true}
            style={{ alignSelf: "flex-end", marginTop: 15 }}
          >
            {submitVerifLoder ? (
              <Spinner color="white" size="small" />
            ) : (
              t("account_details_screen.verif")
            )}
          </SecondaryButton>
        </View>
      )}
      <View
        style={{
          flexDirection: isDesktop ? "row" : "column",
          alignItems: isDesktop ? "center" : "stretch",
          justifyContent: isDesktop ? "space-between" : "flex-start",
        }}
      >
        <View style={{ flex: 1, marginRight: isDesktop ? 30 : 0 }}>
          <Input
            disabled={true}
            placeholder={t("placeholders.email")}
            value={emailValue}
            onChangeText={(val) => setEmailValue(val)}
          />
        </View>

        {/*<PrimaryButton
          disabled={isDisabled}
          style={{ alignSelf: "center", marginTop: isDesktop ? 10 : 20 }}
          width={200}
          onPress={submitHandler}
        >
          {submitLoader ? (
            <Spinner
              color={submitLoader ? colors.white : colors.blue3}
              size="small"
            />
          ) : (
            t("account_details_screen.change_email")
          )}
          </PrimaryButton>*/}
      </View>
      {/** pop up to put the validation code for email change  */}
      <ValidateChangeEmail
        isOpen={showValidateEmail}
        setIsOpen={setShowValidateEmail}
        newEmail={emailValue}
        setEmailValue={setEmailValue}
      />
      {/** pop up to validate current email address */}

    </View>
  );
};

export default ChangeEmail;
