import { StyleSheet, View, Platform, Linking } from "react-native";
import React, { useState, useEffect } from "react";
import DimensionsHook from "../../../hooks/DimensionsHook";
import { BoldTxt, H1, H2, Txt } from "../../TextsComponents";
import { colors } from "../../../styles/GlobalStyle";
import axios from "axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import NumberOfTrails from "./NumberOfTrails";
import InvoiceItem from "./InvoiceItem";
import { useNavigation } from "@react-navigation/native";
import moment from "moment";

const GiftPlan = () => {
  const { width } = DimensionsHook();
  const navigation = useNavigation();
  const isDesktop = width > 1000;
  const [loaderInvoice, setLoaderInvoice] = useState(false);
  const { gift_payment, customer, subscription, gift_end } = useSelector(
    (state) => state.userData
  );

  const { t } = useTranslation();
  const downloadInvoiceLifeTimePlanHandler = async () => {
    if (!loaderInvoice) {
      const url = process.env.PAYMENT_INTENT_ENDPOINT;

      setLoaderInvoice(true);
      axios
        .get(`${url}?paymentId=${gift_payment}`)
        .then((res) => {
          setTimeout(() => {
            console.log(res.data);
            const invoiceUrl = res.data.charges.data[0].receipt_url;
            if (Platform.OS == "web") {
              setLoaderInvoice(false);
              window.open(invoiceUrl, "_blank");
            } else {
              setLoaderInvoice(false);
              Linking.openURL(invoiceUrl);
            }
          }, 500);
        })
        .catch((err) => setLoaderInvoice(false));
    }
  };

  // data = invoices
  const [data, setData] = useState([]);

  useEffect(() => {
    const invoiceUrl = process.env.USER_INVOICES;
    axios.get(`${invoiceUrl}?customerId=${customer}`).then((InvoicesRes) => {
      setData(InvoicesRes.data);
      /*   console.log("invoices :", res.data);*/
    });
  }, []);

  return (
    <View>
      <View
        style={{
          marginTop: isDesktop ? 0 : 20,
        }}
      >
        <H1>
          {t("plans.your_subscription")} -{" "}
          {subscription == "sub_annual_gift"
            ? t("plans.annual")
            : subscription == "sub_one_month_gift"
              ? t("plans.monthly")
              : t("plans.three_months")}
        </H1>

        <Txt style={{ marginVertical: 15 }}>
          {subscription == "sub_annual_gift"
            ? t("plans_gift.annual")
            : subscription == "sub_one_month_gift"
              ? t("plans_gift.monthly")
              : t("plans_gift.three_months")}
        </Txt>
        <BoldTxt style={{ marginTop: isDesktop ? 20 : 0 }}>
          {t("plans.subscription_end1")}{" "}
          {moment(gift_end).format("DD/MM/YYYY [à] HH:mm")}.
        </BoldTxt>
      </View>

      <View style={styles.divider} />
      {/** num of allowed trails */}
      <NumberOfTrails navigation={navigation} />
      {/** historique factures */}
      {data.length > 0 && (
        <View style={{ marginTop: 20 }}>
          <H2>{t("plans.invoices")}</H2>
          {data.map((invoice) => {
            return <InvoiceItem setData={setData} key={invoice._id} invoice={invoice} />;
          })}
        </View>
      )}
    </View>
  );
};

export default GiftPlan;

const styles = StyleSheet.create({
  badge: {
    backgroundColor: colors.green2,
    paddingHorizontal: 15,
    borderRadius: 100,
    paddingVertical: 5,
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: colors.grayBorder,
    marginTop: 20,
  },
});
