import {
    StyleSheet,
    View,
    ScrollView,
    Platform,
    ImageBackground,
    Image,
} from "react-native";
import React, { useState, useEffect, useRef } from "react";
import BackHeader from "../components/BackHeader";
import { colors } from "../styles/GlobalStyle";

import DimensionsHook from "../hooks/DimensionsHook";
import { BoldTxt, H3, H5, Txt } from "../components/TextsComponents";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";

import { SimpleLineIcons } from "@expo/vector-icons";
import { Divider } from "react-native-elements";
import { VStack, Pressable, HStack, PresenceTransition } from "native-base";
import { useHover } from "react-native-web-hooks";
import ChatEnterModal from "../components/modals/ChatEnterModal";
import ChatModal from "../components/modals/ChatModal";
import { PrimaryButton } from "../components/Buttons";
import { LinearGradient } from "expo-linear-gradient";

const Recommandations = ({ navigation, route }) => {
    const redFlag = route.params.redFlag;
    const { isDesktop, isMobile, width } = DimensionsHook();
    const { t, i18n } = useTranslation();
    const [trails, setTrails] = useState([]);
    const [workshops, setWorkshops] = useState([]);
    const [loader, setLoader] = useState(true);
    const [showChatModal, setShowChatModal] = useState(false);
    const [showChatEnterModal, setShowChatEnterModal] = useState(false);
    const [times, setTimes] = useState([]);
    const [loaderTime, setLoaderTime] = useState(false);
    const [showTrailText, setShowTrailText] = useState(false)

    const getData = async () => {
        setLoader(true);

        const TOKEN = await AsyncStorage.getItem("access_token");
        const config = {
            headers: {
                Authorization: `Bearer ${TOKEN}`,
            },
        };
        const TrailsResponse = await axios.get(
            `${process.env.USER_RECOMMANDED_TRAILS}?language=${i18n.language}`,
            config
        );
        const Workshopsresponse = await axios.get(
            `${process.env.USER_RECOMMANDED_WORKSHOPS}?language=${i18n.language}`,
            config
        );
        setTrails(TrailsResponse.data);
        setWorkshops(Workshopsresponse.data);
        setLoader(false);
    };

    const getChatTimes = async () => {
        setLoaderTime(true);
        const TOKEN = await AsyncStorage.getItem("access_token");
        const config = {
            headers: {
                Authorization: `Bearer ${TOKEN}`,
            },
        };
        axios
            .get(process.env.CHAT_TIME, config)
            .then((res) => {
                setTimes(res.data);
                setLoaderTime(false);
                setShowChatEnterModal(true);
            })
            .catch((err) => {
                console.log(err);
                setLoaderTime(false);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const RenderTrailItem = ({ el }) => {
        const hoverRef = useRef();
        const isHovered = useHover(hoverRef);
        return (
            <Pressable
                ref={hoverRef}
                onPress={() => navigation.navigate("Trail", el)}
                style={{
                    borderWidth: 1,
                    borderColor: colors.beige,
                    backgroundColor: isHovered ? colors.green0 : colors.beigeWithOpacity,
                    marginTop: 16,
                    marginHorizontal: isMobile ? 5 : 16,
                    padding: 10,
                    paddingLeft: 5,
                    borderRadius: 5,
                    flexDirection: "row",
                }}
            >
                <VStack
                    style={{
                        width: 3,
                        height: "100%",
                        backgroundColor: isHovered ? colors.green2 : "#484848",
                        borderRadius: 10,
                        marginRight: 10,
                    }}
                ></VStack>
                <VStack style={{ marginRight: 5, flex: 1 }}>
                    <HStack style={{ flex: 1 }}>
                        {width > 700 && (
                            <VStack>
                                <Image
                                    source={{ uri: el.poster_link }}
                                    style={{ height: 100, width: 150 }}
                                />
                                <LinearGradient
                                    colors={["#1B496500", "#1B496566"]}
                                    style={{ height: 100, width: 150, position: "absolute" }}
                                />
                            </VStack>
                        )}
                        <VStack style={{ flex: 1, marginHorizontal: 10 }}>
                            <Txt>{el.title}</Txt>
                            <BoldTxt
                                style={{ fontSize: 13, color: "#484848", marginBottom: 5 }}
                            >{`${el.episodes_number} ${t("episodes")} - ${el.seasons.length
                                } ${el.seasons.length < 2 ? t("trail.season") : t("trail.seasons")
                                }`}</BoldTxt>

                            <Txt style={{ fontSize: 13, color: "#383636" }}>
                                {el.description}
                            </Txt>
                        </VStack>
                    </HStack>
                </VStack>
            </Pressable>
        );
    };
    const RenderWorkshopItem = ({ el, i }) => {
        const hoverRef = useRef();
        const isHovered = useHover(hoverRef);
        return (
            <Pressable
                ref={hoverRef}
                onPress={() => navigation.navigate("Workshop", el)}
                style={{
                    borderWidth: 1,
                    borderColor: colors.beige,
                    backgroundColor: isHovered ? colors.green0 : colors.beigeWithOpacity,
                    marginTop: 16,
                    marginHorizontal: isMobile ? 5 : 16,
                    padding: 10,
                    paddingLeft: 5,
                    borderRadius: 5,
                    flexDirection: "row",
                    alignItems: 'center'
                }}
            >
                <VStack
                    style={{
                        width: 3,
                        height: "100%",
                        backgroundColor: isHovered ? colors.green2 : "#484848",
                        borderRadius: 10,
                        marginRight: 10,
                    }}
                ></VStack>
                <VStack mx="2">
                    <H5 style={{ color: isHovered ? colors.green2 : "#484848", }}>{i + 1}</H5>
                </VStack>
                <HStack style={{ flex: 1 }}>
                    {width > 700 && (
                        <VStack>
                            <Image
                                source={{ uri: el.poster_link }}
                                style={{ height: 100, width: 150 }}
                            />
                            <LinearGradient
                                colors={["#1B496500", "#1B496566"]}
                                style={{ height: 100, width: 150, position: "absolute" }}
                            />
                        </VStack>
                    )}
                    <VStack style={{ flex: 1, marginHorizontal: 10 }}>
                        <Txt>{el.title}</Txt>
                        <BoldTxt
                            style={{ fontSize: 13, color: "#484848", marginBottom: 10 }}
                        >{`${el.duration} min`}</BoldTxt>
                        <Txt style={{ fontSize: 13, color: "#383636" }}>
                            {el.description}
                        </Txt>
                    </VStack>
                </HStack>
            </Pressable>
        );
    };

    return (
        <View style={styles.container}>
            <ImageBackground
                source={require("../assets/background.png")}
                style={{ flex: 1 }}
            >
                {loader || loaderTime ? (
                    <VStack flex={1}>
                        <BackHeader
                            isOpen={false}
                            setIsOpen={() => { }}
                            navigation={navigation}
                        />
                        <VStack flex={1} alignItems="center" justifyContent="center">
                            <Spinner size="large" />
                        </VStack>
                    </VStack>
                ) : (
                    <ScrollView
                        style={{ flex: 1 }}
                        showsVerticalScrollIndicator={Platform.OS === "web"}
                    >
                        <BackHeader
                            isOpen={false}
                            setIsOpen={() => { }}
                            navigation={navigation}
                        />

                        {/** trails list */}
                        <View
                            style={[styles.whiteContainer, { padding: isDesktop ? 30 : 12 }]}
                        >
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <View>
                                    <SimpleLineIcons
                                        name="magic-wand"
                                        size={isDesktop ? 30 : 26}
                                        color={colors.blue3}
                                        style={{ marginRight: isDesktop ? 10 : 5 }}
                                    />
                                </View>
                                <H3 style={{ marginTop: 4 }}>{t("bilan.recommandations")}</H3>
                            </View>
                            <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
                                <View style={{ flex: 1, marginLeft: 8 }}>
                                    <View>
                                        <Txt
                                            style={{ color: "#383636", marginTop: 10, fontSize: 16 }}
                                        >
                                            {t("recommandations.text2")}
                                        </Txt>
                                    </View>
                                    <View>
                                        <Txt
                                            style={{ marginTop: 8, color: "#383636", fontSize: 16 }}
                                        >
                                            {t("recommandations.text3")}
                                        </Txt>
                                    </View>
                                    <View>
                                        <Txt
                                            style={{ marginTop: 8, color: "#383636", fontSize: 16 }}
                                        >
                                            {t("recommandations.text4")}
                                        </Txt>
                                    </View>
                                    <View>
                                        <Txt
                                            color={colors.red1}
                                            style={{ marginTop: 8, color: "#383636", fontSize: 16 }}
                                        >
                                            {t("recommandations.text1")}{" "}
                                            <BoldTxt
                                                color={colors.red1}
                                                onPress={() => {
                                                    if (!loaderTime) {
                                                        getChatTimes();
                                                    }
                                                }}
                                                style={{
                                                    marginTop: 8,
                                                    color: colors.blue3,
                                                    fontSize: 16,
                                                    textDecorationLine: "underline",
                                                }}
                                            >
                                                {t("recommandations.here")}
                                            </BoldTxt>
                                        </Txt>
                                    </View>
                                    {redFlag && (
                                        <View>
                                            <Txt
                                                style={{
                                                    marginTop: 12,
                                                    color: colors.red1,
                                                    fontSize: 16,
                                                }}
                                            >
                                                {t("recommandations.text5")}
                                            </Txt>
                                            <PrimaryButton
                                                style={{
                                                    width: isMobile ? "100%" : 240,
                                                    marginTop: 15,
                                                }}
                                                onPress={() => {
                                                    if (!loaderTime) {
                                                        getChatTimes();
                                                    }
                                                }}
                                            >
                                                {t("bilan.button_chat")}
                                            </PrimaryButton>
                                        </View>
                                    )}
                                </View>
                                {isDesktop && (
                                    <View style={{ marginLeft: 10 }}>
                                        <Image
                                            source={{
                                                uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
                                            }}
                                            style={{
                                                width: 100,
                                                height: 165,
                                            }}
                                        />
                                    </View>
                                )}
                            </View>
                            <Divider style={{ marginTop: 16 }} />
                            {/** trails */}
                            <View>
                                <View>
                                    <H5
                                        style={{
                                            fontSize: 22,
                                            lineHeight: 30,
                                            letterSpacing: -1,
                                            marginTop: 25,
                                        }}
                                    >
                                        {t("recommandations.title1")}
                                    </H5>
                                </View>
                                <VStack style={{ marginTop: 10, marginBottom: 2 }}>

                                    {showTrailText ? <PresenceTransition
                                        visible={showTrailText}
                                        initial={{
                                            translateY: -5,
                                            opacity: 0,
                                        }}
                                        animate={{
                                            translateY: 0,
                                            opacity: 1,

                                            transition: {
                                                type: "timing",
                                                duration: 300,
                                                useNativeDriver: Platform.OS === "web" ? false : true,
                                            },
                                        }}

                                    >
                                        <View>
                                            <Txt
                                                style={{ marginTop: 8, color: "#383636", fontSize: 16 }}
                                            >
                                                {t("recommandations.text1_2")}
                                            </Txt>
                                        </View>
                                        <View>
                                            <Txt
                                                style={{ marginTop: 8, color: "#383636", fontSize: 16 }}
                                            >
                                                {t("recommandations.text1_3")}
                                            </Txt>
                                        </View>
                                        <View>
                                            <Txt
                                                style={{ marginTop: 8, marginLeft: 8, color: "#383636", fontSize: 16 }}
                                            >
                                                • {t("recommandations.text1_3_1")}
                                            </Txt>
                                        </View>
                                        <View>
                                            <Txt
                                                style={{ marginTop: 8, marginLeft: 8, color: "#383636", fontSize: 16 }}
                                            >
                                                • {t("recommandations.text1_3_2")}
                                            </Txt>
                                        </View>
                                        <View>
                                            <Txt
                                                style={{ marginTop: 8, marginLeft: 8, color: "#383636", fontSize: 16 }}
                                            >
                                                • {t("recommandations.text1_3_3")}
                                            </Txt>
                                        </View>
                                    </PresenceTransition> : <View>
                                        <PrimaryButton onPress={() => setShowTrailText(true)}
                                            style={{ alignSelf: 'flex-start' }}
                                        >
                                            {t("recommandations.text1_1")}
                                        </PrimaryButton>
                                    </View>}
                                    <View>
                                        <Txt
                                            style={{ marginTop: 15, color: "#383636", fontSize: 16, marginLeft: 4 }}
                                        >
                                            {t("recommandations.text1_4")}
                                        </Txt>
                                    </View>
                                    <View>
                                        {trails.map((el) => {
                                            return <RenderTrailItem el={el} key={el._id} />;
                                        })}
                                    </View>
                                </VStack>
                            </View>
                            <Divider style={{ marginTop: 16 }} />
                            {/** workshops */}
                            <View>
                                <View>
                                    <H5
                                        style={{
                                            fontSize: 22,
                                            lineHeight: 30,
                                            letterSpacing: -1,
                                            marginTop: 25,
                                        }}
                                    >
                                        {t("recommandations.title2")}
                                    </H5>
                                </View>
                                <View>
                                    <Txt
                                        style={{ color: "#383636", marginTop: 10, fontSize: 16 }}
                                    >
                                        {t("recommandations.text2_1")}
                                    </Txt>
                                </View>
                                <View>
                                    <Txt style={{ marginTop: 8, color: "#383636", fontSize: 16 }}>
                                        {t("recommandations.text2_2")}
                                    </Txt>
                                </View>
                                <View>
                                    {workshops.map((el, i) => {
                                        return <RenderWorkshopItem el={el} key={el._id} i={i} />;
                                    })}
                                </View>
                            </View>
                            {/** finish texts */}
                            <VStack
                                style={{ marginTop: 16, marginBottom: 20, alignSelf: 'center', marginHorizontal: 15 }}
                            >
                                <View>
                                    <Txt
                                        style={{ color: "#383636", marginTop: 10, fontSize: 16, textAlign: 'center' }}
                                    >
                                        {t("recommandations.text6")}
                                    </Txt>
                                </View>
                            </VStack>
                        </View>
                        {/** chat enter modal */}
                        <ChatEnterModal
                            setShowChatModal={setShowChatModal}
                            isOpen={showChatEnterModal}
                            setIsOpen={setShowChatEnterModal}
                            navigation={navigation}
                            times={times}
                        />

                        {Platform.OS == "web" && (
                            <ChatModal isOpen={showChatModal} setIsOpen={setShowChatModal} />
                        )}

                        {isDesktop && <Footer />}
                    </ScrollView>
                )}
            </ImageBackground>
        </View>
    );
};

export default Recommandations;

const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.beige,
        flex: 1,
    },
    searchBarContainer: {
        flexDirection: "row",
        alignItems: "center",

        marginTop: 10,
    },
    whiteContainer: {
        width: "98%",
        alignSelf: "center",
        backgroundColor: colors.white,
        borderRadius: 16,
        padding: 12,
        marginTop: 12,
    },
});
