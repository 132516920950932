import { AlertDialog, Button } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../styles/GlobalStyle";
import Spinner from "../Spinner";
import { BoldTxt, SmallBoldTxt, Txt } from "../TextsComponents";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import DimensionsHook from "../../hooks/DimensionsHook";
import { useHover } from "react-native-web-hooks";
import { useSelector } from "react-redux";

const CancelAppointmentModal = ({
    isOpen,
    setIsOpen,
    appointment,
    setData,
    moreThanTwoDays,
}) => {
    const { t } = useTranslation();

    const { customer } = useSelector((state) => state.userData);
    const [loader, setLoader] = useState(false);
    const { isMobile } = DimensionsHook();
    const [errorMessage, setErrorMessage] = useState(null);

    const hoverRef = React.useRef(null);
    const isHover = useHover(hoverRef);
    const cancelRef = React.useRef(null);

    const isHover1 = useHover(cancelRef);

    const onClose = () => {
        if (!loader) {
            setIsOpen(false);
            setErrorMessage(null);
        }
    };

    const cancelAppointmentHandler = async () => {
        if (!loader) {
            const TOKEN = await AsyncStorage.getItem("access_token");
            setLoader(true);
            setErrorMessage(null);
            const invoiceUrl = process.env.USER_INVOICES;
            const url = process.env.PAYMENT_APIS;
            axios
                .post(`${url}/cancel-appointment`, {
                    access_token: TOKEN,
                    appointment_id: appointment._id,
                    moreThanTwoDays,
                })
                .then(() => {
                    axios
                        .get(`${invoiceUrl}?customerId=${customer}`)
                        .then((InvoicesRes) => {
                            setData(InvoicesRes.data);
                            setLoader(false);
                            setIsOpen(false);
                        });
                })
                .catch((err) => {
                    console.log(err);
                    setErrorMessage(t("unsubscribe.error_connexion"));
                    setLoader(false);
                });
        }
    };

    return (
        <AlertDialog
            leastDestructiveRef={cancelRef}
            isOpen={isOpen}
            onClose={onClose}
        >
            <AlertDialog.Content
                style={{
                    width: "95%",
                    maxWidth: 600,
                    backgroundColor: colors.beige,
                }}
            >
                <AlertDialog.CloseButton focusable={false} />

                <AlertDialog.Header>
                    <BoldTxt style={{ fontSize: 16 }}>{t("appointment.cancel")}</BoldTxt>
                </AlertDialog.Header>
                <AlertDialog.Body style={{ paddingHorizontal: isMobile ? 5 : 30 }}>
                    <SmallBoldTxt
                        style={{
                            color: colors.red1,
                            fontSize: 12,
                            textAlign: "center",
                            marginTop: 10,
                            marginBottom: isMobile ? 0 : 10,
                        }}
                    >
                        {errorMessage ? errorMessage : " "}
                    </SmallBoldTxt>
                    <Txt
                        style={{
                            fontSize: isMobile ? 15 : 16,
                            textAlign: "center",
                            marginTop: isMobile ? 10 : 0,
                        }}
                    >
                        {appointment.companyPayment
                            ? t("appointment.cancel_company")
                            : t("appointment.cancel_user")}
                    </Txt>

                    {!moreThanTwoDays && (
                        <Txt
                            style={{
                                fontSize: isMobile ? 15 : 14,
                                textAlign: "center",
                                marginTop: 15,
                                padding: 10,
                                paddingVertical: 15,
                                backgroundColor: colors.red1,
                                color: colors.white,
                                borderRadius: 10
                            }}
                        >
                            Attention:  {t("appointment.not_remboursed")}
                        </Txt>
                    )}

                    <Button.Group style={{ marginTop: 20 }} alignSelf="center" space={2}>
                        <Button
                            focusable={false}
                            style={{
                                backgroundColor: isHover1 ? colors.blue3 : colors.green2,
                                color: colors.white,
                                height: 40,
                                width: 100,
                            }}
                            onPress={onClose}
                            ref={cancelRef}
                        >
                            <Txt style={{ fontSize: 14, color: colors.white }}>
                                {t("tuto.close")}
                            </Txt>
                        </Button>
                        <Button
                            ref={hoverRef}
                            focusable={false}
                            style={{
                                borderColor: colors.red1,
                                borderWidth: 1,
                                borderRadius: 5,
                                backgroundColor: isHover ? colors.red1 : "transparent",
                                color: colors.white,
                                width: 100,
                                height: 40,
                            }}
                            onPress={cancelAppointmentHandler}
                        >
                            {loader ? (
                                <Spinner color={isHover ? colors.white : colors.red1} />
                            ) : (
                                <Txt
                                    style={{
                                        fontSize: 14,
                                        color: isHover ? colors.white : colors.red1,
                                        textAlign: "center",
                                    }}
                                >
                                    {t("appointment.cancel_button")}
                                </Txt>
                            )}
                        </Button>
                    </Button.Group>
                </AlertDialog.Body>
            </AlertDialog.Content>
        </AlertDialog>
    );
};

export default CancelAppointmentModal;
