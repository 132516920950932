import { ImageBackground, StyleSheet, View } from "react-native";
import React from "react";
import { Modal, VStack } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { BoldTxt, H2, Txt } from "../TextsComponents";

import { useTranslation } from "react-i18next";
import { Entypo } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import * as Animatable from "react-native-animatable";

const SuccessBuyBookViaCompany = ({ isOpen, setIsOpen, navigation }) => {
  const { isMobile } = DimensionsHook();
  const { email } = useSelector((state) => state.userData);
  const closeHandler = async () => {
    setIsOpen(false);
    navigation.goBack();
  };
  const { t } = useTranslation();
  return (
    <Modal focusable={false} isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={styles.container}>
        <Modal.Body>
          <ImageBackground
            source={require("../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ marginRight: isMobile ? 0 : 10 }}
            />
            <VStack
              style={{
                marginTop: 30,
                marginHorizontal: isMobile ? 0 : 20,
              }}
            >
              <Animatable.View
                animation="pulse"
                duration={1200}
                delay={0}
                iterationCount="infinite"
                iterationDelay={0}
                style={{
                  width: 55,
                  height: 55,
                  alignSelf: "center",
                  backgroundColor: colors.green2,
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 10,
                  marginBottom: 30,
                  borderRadius: 40,
                }}
              >
                <Entypo name="check" size={28} color={colors.white} />
              </Animatable.View>
              <H2
                style={{
                  fontSize: 22,
                  lineHeight: 28,
                  textAlign: "center",

                  marginHorizontal: 20,
                }}
              >
                {t("books.success")}
              </H2>
              <VStack my="4">
                <Txt
                  style={{
                    color: "#484848",
                    fontSize: 16,
                    marginHorizontal: 15,
                    textAlign: "center",
                  }}
                >
                  {t("books.text1")}
                  <BoldTxt
                    style={{
                      color: "#484848",
                      fontSize: 16,

                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {email}.
                  </BoldTxt>
                </Txt>
                <VStack mt="3">
                  <Txt
                    style={{
                      color: "#484848",
                      fontSize: 16,
                      marginHorizontal: 15,
                      textAlign: "center",
                    }}
                  >
                    {t("books.text2")}
                  </Txt>
                </VStack>
              </VStack>
            </VStack>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default SuccessBuyBookViaCompany;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "95%",
    maxWidth: 600,
  },
});
