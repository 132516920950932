import React, { useEffect, useRef, useState } from "react";
import { BoldTxt, H6, Txt } from "../../components/TextsComponents";
import {
    VStack,
    View,
    Image,
    PresenceTransition,
    HStack,
    Pressable,
    Checkbox,
    Radio,
} from "native-base";
import BackHeader from "../../components/BackHeader";
import { Alert, Easing, ImageBackground, Platform } from "react-native";
import { colors } from "../../styles/GlobalStyle";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/Spinner";
import axios from "axios";
import { ProgressBar } from "react-native-paper";
import ConfettiCannon from "react-native-confetti-cannon";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import DimensionsHook from "../../hooks/DimensionsHook";
import { useHover } from "react-native-web-hooks";
import { useSelector } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Input } from "../../components/Inputs";
import { ScrollView } from "react-native";

const Section = ({ navigation, route }) => {
    const companyQuestions = route.params.dimension == "dimension6" ? route.params.companyQuestions : []
    const currentCompagne = route.params.currentCompagne

    const { t, i18n } = useTranslation();
    const { isMobile, isDesktop, width } = DimensionsHook();
    const { company } = useSelector((state) => state.userData);
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);
    const [progress, setProgress] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [answersData, setAnswersData] = useState([]);
    const [step, setStep] = useState(0);
    const [showSuccess, setShowSuccess] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [workStressAnswer, setWorkStressAnswer] = useState([]);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [maxScore, setMaxScore] = useState(0);
    const [pref1, setPref1] = useState(route.params.bilanData && route.params.bilanData.pref1 ? route.params.bilanData.pref1 : []);
    const [pref2, setPref2] = useState(route.params.bilanData && route.params.bilanData.pref2 ? route.params.bilanData.pref2 : "");
    const [pref3, setPref3] = useState(route.params.bilanData && route.params.bilanData.pref3 ? route.params.bilanData.pref3 : "");
    const [pref4, setPref4] = useState(route.params.bilanData && route.params.bilanData.pref4 ? route.params.bilanData.pref4 : "");
    const [pref5, setPref5] = useState(route.params.bilanData && route.params.bilanData.pref5 ? route.params.bilanData.pref5 : []);
    const [pref5Data, setPref5Data] = useState(null);
    const [notificationFrequency, setNotificationFrequency] = useState("");

    // Calculate the sum of max values in each "answers" array
    const sumOfMaxValues = (a) =>
        a.reduce((sum, question) => {
            // Extract the "answers" array from the question object
            const answers = question.answers;

            // Find the maximum value in the "answers" array
            const maxAnswerValue = Math.max(
                ...answers
                    .filter((answer) => answer.value !== undefined)
                    .map((answer) => answer.value)
            );

            // Add the maximum value to the sum (if there's at least one valid "value" key)
            return maxAnswerValue !== -Infinity ? sum + maxAnswerValue : sum;
        }, 0);

    const getQuestions = async () => {
        setLoader(true);
        // GET DIMENSION
        const url = `${process.env.QUESTIONNAIRES}/bilan-data?language=${i18n.language}&dimension=${route.params.dimension}`;
        const questions = await axios.get(url);
        const companyQuestionsFiltered = companyQuestions.filter((el) => el.hidden === false);
        const arr = [...questions.data, ...companyQuestionsFiltered];
        const ans = new Array(arr.length).fill("");
        if (route.params.dimension == "dimension6") {
            ans[34] = "multiple";

        }

        setMaxScore(
            route.params.dimension == "dimension6"
                ? sumOfMaxValues(arr) + 5
                : sumOfMaxValues(arr)
        );
        setAnswersData(ans);

        setData(arr);
        if (!route.params.bilanData) {
            setStep(1 / (arr.length + 1));
            setProgress(1 / (arr.length + 1));

        } else {
            setStep(1 / (arr.length));
            setProgress(1 / (arr.length));

        }

        setIsOpen(true);
        setLoader(false);
    };

    useEffect(() => {
        getQuestions();
    }, []);

    const currentQuestion = data[currentIndex];

    const previousHandler = () => {
        if (!submitLoader) {
            setIsOpen(false);
            if (data[currentIndex - 1] && data[currentIndex - 1].hidden) {
                setProgress((current) => current - (2 * step));
                setCurrentIndex((current) => current - 2);
            } else {
                setProgress((current) => current - step);
                setCurrentIndex((current) => current - 1);
            }

            setIsOpen(true);
        }
    };

    const nextHandler = () => {

        if ((currentIndex == (answersData.length - 1)) && route.params.bilanData) {
            submitHandler()
        } else {
            setIsOpen(false);
            if (data[currentIndex + 1] && data[currentIndex + 1].hidden) {
                setProgress((current) => current + (2 * step));
                setCurrentIndex((current) => current + 2);
            } else {
                setProgress((current) => current + step);
                setCurrentIndex((current) => current + 1);
            }

            setIsOpen(true);
        }
    };

    function sumOfNumbers(arr) {
        return arr.reduce((sum, element) => {
            // Convert the element to a number (ignoring non-numeric values)
            const numericValue = parseFloat(element);

            // Check if the conversion is successful and the value is not NaN
            if (!isNaN(numericValue)) {
                // Add the numeric value to the sum
                return sum + numericValue;
            }

            // If the element is not numeric, just return the current sum
            return sum;
        }, 0);
    }

    function copyArrayPortion(originalArray, i, j) {
        // Use the slice method to create a copy of the array from index i to j
        return originalArray.slice(i, j + 1);
    }

    const submitHandler = async () => {
        setSubmitLoader(true);
        const TOKEN = await AsyncStorage.getItem("access_token");
        const dimension = route.params.dimension;

        const score =
            dimension == "dimension6"
                ? sumOfNumbers(copyArrayPortion(answersData, 0, 34)) + 5 - workStressAnswer.length
                : sumOfNumbers(answersData);
        const total = maxScore;
        // dimension percent
        const percent = Number((score / maxScore) * 100).toFixed(0);

        const postData = {
            compagne: currentCompagne ? currentCompagne : null,
            company,
            access_token: TOKEN,
            language: i18n.language,
            pref1, pref2, pref3, pref4, pref5, notificationFrequency: notificationFrequency ? notificationFrequency : null
        };
        if (dimension == "dimension3") {
            postData.section7 = { score, total, percent: Number(percent), answersData };
        } else if (dimension == "dimension4") {
            postData.section8 = { score, total, percent: Number(percent), answersData };
        } else if (dimension == "dimension5") {
            postData.section9 = { score, total, percent: Number(percent), answersData };
        } else if (dimension == "dimension2") {
            const section5Answers = copyArrayPortion(answersData, 0, 4);
            const section5Questions = data.filter((el) => el.section == "section5");
            const scoreSection5 = sumOfNumbers(section5Answers);
            const totalSection5 = sumOfMaxValues(section5Questions);
            const percentSection5 = ((scoreSection5 / totalSection5) * 100).toFixed(
                0
            );
            postData.section5 = {
                score: scoreSection5,
                total: totalSection5,
                percent: Number(percentSection5),
                answersData: section5Answers,
            };

            const section6Answers = copyArrayPortion(answersData, 5, 10);
            const section6Questions = data.filter((el) => el.section == "section6");
            const scoreSection6 = sumOfNumbers(section6Answers);
            const totalSection6 = sumOfMaxValues(section6Questions);
            const percentSection6 = ((scoreSection6 / totalSection6) * 100).toFixed(
                0
            );
            postData.section6 = {
                score: scoreSection6,
                total: totalSection6,
                percent: Number(percentSection6),
                answersData: section6Answers,
            };
        } else if (dimension == "dimension1") {
            const section1Answers = copyArrayPortion(answersData, 0, 5);
            const section1Questions = data.filter((el) => el.section == "section1");
            const scoreSection1 = sumOfNumbers(section1Answers);
            const totalSection1 = sumOfMaxValues(section1Questions);
            const percentSection1 = ((scoreSection1 / totalSection1) * 100).toFixed(
                0
            );
            postData.section1 = {
                score: scoreSection1,
                total: totalSection1,
                percent: Number(percentSection1),
                answersData: section1Answers,
            };

            const section2Answers = copyArrayPortion(answersData, 6, 10);
            const section2Questions = data.filter((el) => el.section == "section2");
            const scoreSection2 = sumOfNumbers(section2Answers);
            const totalSection2 = sumOfMaxValues(section2Questions);
            const percentSection2 = ((scoreSection2 / totalSection2) * 100).toFixed(
                0
            );
            postData.section2 = {
                score: scoreSection2,
                total: totalSection2,
                percent: Number(percentSection2),
                answersData: section2Answers,
            };

            const section3Answers = copyArrayPortion(answersData, 11, 14);
            const section3Questions = data.filter((el) => el.section == "section3");
            const scoreSection3 = sumOfNumbers(section3Answers);
            const totalSection3 = sumOfMaxValues(section3Questions);
            const percentSection3 = ((scoreSection3 / totalSection3) * 100).toFixed(
                0
            );
            postData.section3 = {
                score: scoreSection3,
                total: totalSection3,
                percent: Number(percentSection3),
                answersData: section3Answers,
            };

            const section4Answers = copyArrayPortion(answersData, 15, 21);
            const section4Questions = data.filter((el) => el.section == "section4");
            const scoreSection4 = sumOfNumbers(section4Answers);
            const totalSection4 = sumOfMaxValues(section4Questions);
            const percentSection4 = ((scoreSection4 / totalSection4) * 100).toFixed(
                0
            );
            postData.section4 = {
                score: scoreSection4,
                total: totalSection4,
                percent: Number(percentSection4),
                answersData: section4Answers,
            };
        } else if (dimension == "dimension6") {
            postData.workStressAnswer = workStressAnswer;

            const companyAnswers = companyQuestions.map((question, index) => {
                const answerIndex = 35 + index - companyQuestions.slice(0, index).filter(q => q.hidden).length;
                return !question.hidden ? answersData[answerIndex] : "not published";
            });

            const maxScoreOfCompanyQuestions = Number(
                sumOfMaxValues(companyQuestions.filter((el) => el.hidden == false))
            );
            const companyScore = sumOfNumbers(companyAnswers)
            const percentCompany = maxScoreOfCompanyQuestions == 0 ? 0 : Number(Number((companyScore / maxScoreOfCompanyQuestions) * 100).toFixed(0))

            postData.sectionCompany = {
                score: companyScore,
                total: maxScoreOfCompanyQuestions,
                percent: Number(percentCompany),
                answersData: companyAnswers,
            }

            const section10Answers = copyArrayPortion(answersData, 0, 5);
            const section10Questions = data.filter((el) => el.section == "section10");
            const scoreSection10 = sumOfNumbers(section10Answers);
            const totalSection10 = sumOfMaxValues(section10Questions);
            const percentSection10 = (
                (scoreSection10 / totalSection10) *
                100
            ).toFixed(0);
            postData.section10 = {
                score: scoreSection10,
                total: totalSection10,
                percent: Number(percentSection10),
                answersData: section10Answers,
            };

            const section11Answers = copyArrayPortion(answersData, 6, 15);
            const section11Questions = data.filter((el) => el.section == "section11");
            const scoreSection11 = sumOfNumbers(section11Answers);
            const totalSection11 = sumOfMaxValues(section11Questions);
            const percentSection11 = (
                (scoreSection11 / totalSection11) *
                100
            ).toFixed(0);
            postData.section11 = {
                score: scoreSection11,
                total: totalSection11,
                percent: Number(percentSection11),
                answersData: section11Answers,
            };

            const section12Answers = copyArrayPortion(answersData, 16, 21);
            const section12Questions = data.filter((el) => el.section == "section12");
            const scoreSection12 = sumOfNumbers(section12Answers);
            const totalSection12 = sumOfMaxValues(section12Questions);
            const percentSection12 = (
                (scoreSection12 / totalSection12) *
                100
            ).toFixed(0);
            postData.section12 = {
                score: scoreSection12,
                total: totalSection12,
                percent: Number(percentSection12),
                answersData: section12Answers,
            };

            const section13Answers = copyArrayPortion(answersData, 22, 28);
            const section13Questions = data.filter((el) => el.section == "section13");
            const scoreSection13 = sumOfNumbers(section13Answers);
            const totalSection13 = sumOfMaxValues(section13Questions);
            const percentSection13 = (
                (scoreSection13 / totalSection13) *
                100
            ).toFixed(0);
            postData.section13 = {
                score: scoreSection13,
                total: totalSection13,
                percent: Number(percentSection13),
                answersData: section13Answers,
            };

            const section14Answers = copyArrayPortion(answersData, 29, 34);
            const section14Questions = data.filter((el) => el.section == "section14");
            const scoreSection14 =
                sumOfNumbers(section14Answers) + 5 - workStressAnswer.length;
            const totalSection14 = sumOfMaxValues(section14Questions) + 5;
            const percentSection14 = (
                (scoreSection14 / totalSection14) *
                100
            ).toFixed(0);
            postData.section14 = {
                score: scoreSection14,
                total: totalSection14,
                percent: Number(percentSection14),
                answersData: section14Answers,
            };
        }



        await axios.post(`${process.env.QUESTIONNAIRES}/bilan-answer`, postData).then(() => {
            setTimeout(() => {
                setSubmitLoader(false);
                setShowSuccess(true);
            }, 500);
        })
    };

    //render multiple answers
    const RenderWorkStressChoiceAnswer = ({ answer, i }) => {
        const [checked, setChecked] = useState(false);

        useEffect(() => {
            if (workStressAnswer.includes(answer)) {
                setChecked(true);
            }
        }, []);

        return (
            <View>
                <Checkbox
                    mt="4"
                    _text={{
                        fontFamily: "Gotu",
                        color: colors.blue3,
                        fontSize: isMobile ? 15 : 17,
                    }}
                    _checked={{
                        _icon: {
                            bg: colors.green2,
                            color: "white",
                            size: "xs",
                        },
                        borderColor: colors.green2,
                    }}
                    size="sm"
                    isChecked={checked}
                    onChange={(val) => {
                        setChecked(!checked);
                        if (val) {
                            let arr = workStressAnswer;
                            arr.push(answer);
                            setWorkStressAnswer(arr);
                        } else {
                            let arr = workStressAnswer;
                            const newArr = arr.filter((el) => el !== answer);
                            setWorkStressAnswer(newArr);
                        }
                    }}
                >
                    {answer}
                </Checkbox>
            </View>
        );
        /*  }*/
    };

    const RenderCircleButton = ({ el, selectedValue, setSelectedValue }) => {
        const hoverRef = useRef(null);
        const isHovered = useHover(hoverRef);

        return (
            <VStack>
                <Pressable
                    ref={hoverRef}
                    onPress={() => {
                        const arr = answersData;
                        arr[currentIndex] = el.value;
                        setAnswersData(arr);
                        setSelectedValue(el.value);
                        setTimeout(() => {
                            nextHandler();
                        }, 200);

                    }}

                    borderRadius="full"
                    key={el.value}
                    style={{
                        backgroundColor: isHovered
                            ? colors.green1
                            : selectedValue === el.value
                                ? colors.green2
                                : "#f5f5f4",
                        borderColor: isHovered
                            ? colors.green1
                            : selectedValue === el.value
                                ? colors.green2
                                : '#6e6e6e',
                        borderWidth: 0.5,
                        width: isMobile ? 35 : 40,
                        height: isMobile ? 35 : 40,
                        borderRadius: 25,
                    }}
                ></Pressable>
            </VStack>
        );
    };

    const RenderFreeQuestion = () => {
        const [value, setValue] = useState(answersData[currentIndex])
        return (
            <VStack w="full">
                <Input placeholder={t('questionnaires.free')} multiline={true} numberOfLines={3} value={value} onBlur={() => nextHandler()} onChangeText={(val) => {
                    const arr = answersData;
                    arr[currentIndex] = val;
                    setAnswersData(arr);
                    setValue(val)
                    console.log('val', val)
                    console.log('arr', arr)
                }} />


            </VStack>
        )
    }

    const RenderQuestionAnswers = () => {
        const [selectedValue, setSelectedValue] = useState(
            answersData[currentIndex]
        );

        useEffect(() => {
            if (answersData[currentIndex] != "") {
                setSelectedValue(answersData[currentIndex]);
            }
        }, [selectedValue]);

        return (
            <VStack w="full">
                <HStack
                    alignItems="center"
                    pb="3"
                    pt="1"
                    justifyContent="center"
                    space="4"
                >
                    {currentQuestion.answers.map((el, i) => {
                        return (
                            <RenderCircleButton
                                key={el.label}
                                el={el}
                                selectedValue={selectedValue}
                                setSelectedValue={setSelectedValue}
                            />
                        );
                    })}
                </HStack>
            </VStack>
        );
    };

    const RenderPref5Section = ({ answer, i }) => {
        const [checked, setChecked] = useState(false);

        useEffect(() => {
            if (pref5.includes(answer)) {
                setChecked(true);
            }
        }, []);

        return (
            <View>
                <Checkbox
                    mt="4"
                    _text={{ fontFamily: "Gotu", color: colors.blue3, fontSize: 13 }}
                    _checked={{
                        _icon: {
                            bg: colors.green2,
                            color: "white",
                            size: "xs",
                        },
                        borderColor: colors.green2,
                    }}
                    size="sm"
                    isChecked={checked}
                    onChange={(val) => {
                        setChecked(!checked);
                        if (val) {
                            let arr = pref5;
                            arr.push(answer);

                            setPref5(arr);
                        } else {
                            let arr = pref5;
                            const newArr = arr.filter((el) => el !== answer);

                            setPref5(newArr);
                        }
                    }}
                >
                    {answer}
                </Checkbox>
            </View>
        );
        /*  }*/
    };

    //render multiple answers
    const RenderMultipleChoiceAnswer = ({ answer, i }) => {
        const [checked, setChecked] = useState(false);

        useEffect(() => {
            if (pref1.includes(answer)) {
                setChecked(true);
            }
        }, []);

        return (
            <View>
                <Checkbox
                    mt="4"
                    _text={{ fontFamily: "Gotu", color: colors.blue3, fontSize: 13 }}
                    _checked={{
                        _icon: {
                            bg: colors.green2,
                            color: "white",
                            size: "xs",
                        },
                        borderColor: colors.green2,
                    }}
                    size="sm"
                    isChecked={checked}
                    onChange={(val) => {
                        setChecked(!checked);
                        if (val) {
                            let arr = pref1;
                            arr.push(answer);

                            setPref1(arr);
                        } else {
                            let arr = pref1;
                            const newArr = arr.filter((el) => el !== answer);

                            setPref1(newArr);
                        }
                    }}
                >
                    {answer}
                </Checkbox>
            </View>
        );
        /*  }*/
    };

    const firstPrefAnswers = [
        t("q_preferences.answer1"),
        t("q_preferences.answer2"),
        t("q_preferences.answer3"),
        t("q_preferences.answer4"),
        t("q_preferences.answer5"),
        t("q_preferences.answer6"),
    ];
    const secondPrefAnswers = [
        t("q_preferences.answer7"),
        t("q_preferences.answer8"),
        t("q_preferences.answer9"),
        t("q_preferences.answer10"),
    ];
    const prefSecondQuestion = [
        {
            _id: "Emotionnel/mental",
            answers: [
                t("welcome_second_answers.ans1"),
                t("welcome_second_answers.ans2"),
                t("welcome_second_answers.ans3"),
                t("welcome_second_answers.ans4"),
                t("welcome_second_answers.ans5"),
                t("welcome_second_answers.ans6"),
                t("welcome_second_answers.ans7"),
                t("welcome_second_answers.ans8"),
                t("welcome_second_answers.ans9"),
                t("welcome_second_answers.ans10"),
                t("welcome_second_answers.ans11"),
                t("welcome_second_answers.ans12"),
                t("welcome_second_answers.ans13"),
            ],
        },
        {
            _id: "Corps",
            answers: [
                t("welcome_second_answers.ans14"),
                t("welcome_second_answers.ans15"),
                t("welcome_second_answers.ans16"),
                t("welcome_second_answers.ans17"),
            ],
        },
        {
            _id: "Social-Familial",
            answers: [
                t("welcome_second_answers.ans18"),
                t("welcome_second_answers.ans19"),
                t("welcome_second_answers.ans20"),
                t("welcome_second_answers.ans21"),
                t("welcome_second_answers.ans22"),
                t("welcome_second_answers.ans23"),
                t("welcome_second_answers.ans24"),
            ],
        },
        {
            _id: "Professionnel",
            answers: [
                t("welcome_second_answers.ans25"),
                t("welcome_second_answers.ans26"),
                t("welcome_second_answers.ans27"),
                t("welcome_second_answers.ans28"),
                t("welcome_second_answers.ans29"),
                t("welcome_second_answers.ans30"),
                t("welcome_second_answers.ans31"),
                t("welcome_second_answers.ans32"),
                t("welcome_second_answers.ans33"),
            ],
        },
        {
            _id: "Spirituel",
            answers: [
                t("welcome_second_answers.ans34"),
                t("welcome_second_answers.ans35"),
            ],
        },
        {
            _id: "Financier",
            answers: [
                t("welcome_second_answers.ans36"),
                t("welcome_second_answers.ans37"),
                t("welcome_second_answers.ans38"),
            ],
        },
    ];
    const pref4Answers = [
        t("q_preferences.answer11"),
        t("q_preferences.answer12"),
        t("q_preferences.answer13"),
        t("q_preferences.answer14"),
        t("q_preferences.answer15"),
        t("q_preferences.answer16"),
    ];

    useEffect(() => {
        if (route.params.bilanData && route.params.bilanData.pref4) {
            const pref5data = prefSecondQuestion.find(el => el._id == route.params.bilanData.pref4)
            setPref5Data(pref5data);
        }
    }, [])

    // render preferences section
    const RenderPreferenceSection = () => {




        return (
            <VStack mt="3" >
                {/** pref 3 & pref 4 */}
                <HStack
                    justifyContent="space-between"
                    alignItems="stretch"
                    mt="3"
                    w="full"
                    flexWrap="wrap"
                >
                    <VStack
                        borderRadius={width < 1000 ? "md" : "lg"}
                        mb="2"
                        p={width < 1000 ? "3" : "5"}
                        bg="white"
                        style={{ width: width < 1000 ? "100%" : "49%" }}
                    >
                        <Txt style={{ fontSize: 14 }}>{t("q_preferences.question4")}</Txt>
                        <Txt
                            style={{
                                color: "#484848",
                                fontSize: 12,
                                marginTop: 4,
                            }}
                        >
                            {t("questionnaires.single")}
                        </Txt>
                        {/** render question answers */}
                        <VStack ml="2" my="2" w="full">
                            <Radio.Group
                                w="full"
                                name="single answer"
                                accessibilityLabel="choose one answer"
                                value={pref4}
                                onChange={async (nextValue) => {
                                    setPref4(nextValue);
                                    let i = pref4Answers.indexOf(nextValue);
                                    setPref5([]);
                                    setPref5Data(prefSecondQuestion[i]);
                                }}
                            >
                                {pref4Answers.map((answer, aIndex) => {
                                    return (
                                        <HStack mt="2" w="full" pr="2" key={answer}>
                                            <Radio
                                                _android={{ alignSelf: "flex-start" }}
                                                _ios={{ alignSelf: "flex-start" }}
                                                _checked={{
                                                    _icon: { color: colors.green2 },
                                                    borderColor: colors.green2,
                                                }}
                                                _text={{
                                                    fontFamily: "Gotu",
                                                    color: colors.blue3,
                                                    fontSize: 13,
                                                }}
                                                mt="2"
                                                flex={1}
                                                value={answer}
                                            >
                                                {answer}
                                            </Radio>
                                        </HStack>
                                    );
                                })}
                            </Radio.Group>
                        </VStack>
                    </VStack>
                    {pref5Data ? (
                        <VStack
                            borderRadius={width < 1000 ? "md" : "lg"}
                            mb="2"
                            p={width < 1000 ? "3" : "5"}
                            bg="white"
                            style={{ width: width < 1000 ? "100%" : "49%" }}
                        >
                            <Txt style={{ fontSize: 14 }}>{t("q_preferences.question5")}</Txt>
                            <Txt
                                style={{
                                    color: "#484848",
                                    fontSize: 12,
                                    marginTop: 4,
                                }}
                            >
                                {t("questionnaires.multiple")}
                            </Txt>
                            {/** render question answers */}
                            {pref5Data.answers.map((answer) => (
                                <RenderPref5Section answer={answer} key={answer} />
                            ))}
                        </VStack>
                    ) : (
                        <VStack
                            borderRadius={width < 1000 ? "md" : "lg"}

                            style={{ width: width < 1000 ? "100%" : "49%" }}
                        ></VStack>
                    )}
                </HStack>
                {/** pref 1 & pref 2 */}
                <HStack
                    justifyContent="space-between"
                    alignItems="stretch"
                    mt="3"
                    w="full"
                    flexWrap="wrap"
                >
                    <VStack
                        borderRadius={width < 1000 ? "md" : "lg"}
                        mb="2"
                        p={width < 1000 ? "3" : "5"}
                        bg="white"
                        style={{ width: width < 1000 ? "100%" : "49%" }}
                    >
                        <Txt style={{ fontSize: 14 }}>{t("q_preferences.question1")}</Txt>
                        <Txt
                            style={{
                                color: "#484848",
                                fontSize: 12,
                                marginTop: 4,
                            }}
                        >
                            {t("questionnaires.multiple")}
                        </Txt>
                        {/** render question answers */}
                        {firstPrefAnswers.map((answer) => (
                            <RenderMultipleChoiceAnswer answer={answer} key={answer} />
                        ))}
                    </VStack>
                    <VStack
                        borderRadius={width < 1000 ? "md" : "lg"}
                        mb="2"
                        p={width < 1000 ? "3" : "5"}
                        bg="white"
                        style={{ width: width < 1000 ? "100%" : "49%" }}
                    >
                        <Txt style={{ fontSize: 14 }}>{t("q_preferences.question2")}</Txt>
                        <Txt
                            style={{
                                color: "#484848",
                                fontSize: 12,
                                marginTop: 4,
                            }}
                        >
                            {t("questionnaires.single")}
                        </Txt>
                        {/** render question answers */}
                        <VStack ml="2" my="2" w="full">
                            <Radio.Group
                                w="full"
                                name="single answer"
                                accessibilityLabel="choose one answer"
                                value={pref2}
                                onChange={async (nextValue) => {
                                    setPref2(nextValue);
                                }}
                            >
                                {secondPrefAnswers.map((answer, aIndex) => {
                                    return (
                                        <HStack w="full" pr="2" key={answer}>
                                            <Radio
                                                _android={{ alignSelf: "flex-start" }}
                                                _ios={{ alignSelf: "flex-start" }}
                                                _checked={{
                                                    _icon: { color: colors.green2 },
                                                    borderColor: colors.green2,
                                                }}
                                                _text={{
                                                    fontFamily: "Gotu",
                                                    color: colors.blue3,
                                                    fontSize: 13,
                                                }}
                                                mt="2"
                                                flex={1}
                                                value={answer}
                                            >
                                                {answer}
                                            </Radio>
                                        </HStack>
                                    );
                                })}
                            </Radio.Group>
                        </VStack>
                        {/** notification frequency */}
                        <VStack px="2" mt="4" w="full">
                            {pref2 == "" ? null : secondPrefAnswers.indexOf(pref2) == 0 ? (
                                // case  first answer
                                <View>
                                    <Txt style={{ fontSize: 12, textAlign: "justify" }}>
                                        {t("questionnaires_answers.text1")}
                                    </Txt>
                                    <Txt
                                        style={{
                                            marginTop: 10,
                                            fontSize: 12,
                                            textAlign: "justify",
                                        }}
                                    >
                                        {t("questionnaires_answers.text2")}
                                    </Txt>
                                </View>
                            ) : // case  second answer
                                secondPrefAnswers.indexOf(pref2) == 1 ? (
                                    <Txt
                                        style={{
                                            fontSize: 12,
                                            marginBottom: 10,
                                            textAlign: "justify",
                                        }}
                                    >
                                        {t("questionnaires_answers.text3")}
                                    </Txt>
                                ) : (
                                    // case  3 or 4 answer
                                    <View w="full">
                                        <Txt style={{ fontSize: 13 }}>
                                            {t("questionnaires_answers.text4")}
                                        </Txt>

                                        <Radio.Group
                                            mt="2"
                                            colorScheme="success"
                                            name="notifications"
                                            accessibilityLabel="choose one option"
                                            value={notificationFrequency}
                                            onChange={(nextValue) => {
                                                setNotificationFrequency(nextValue);
                                            }}
                                        >
                                            <HStack
                                                w="full"
                                                alignItems="stretch"
                                                justifyContent="flex-start"
                                                flexWrap="wrap"
                                            >
                                                {[
                                                    t("setting_screen.options.q"),
                                                    t("setting_screen.options.h"),
                                                    t("setting_screen.options.m"),
                                                ].map((el) => {
                                                    return (
                                                        <HStack key={el} w="1/2" px="2">
                                                            <Radio
                                                                _android={{ alignSelf: "flex-start" }}
                                                                _ios={{ alignSelf: "flex-start" }}
                                                                _checked={{
                                                                    _icon: { color: colors.green2 },
                                                                    borderColor: colors.green2,
                                                                }}
                                                                _text={{
                                                                    fontFamily: "Gotu",
                                                                    color: colors.blue3,
                                                                    fontSize: 13,
                                                                    marginRight: 4,
                                                                }}
                                                                mt="2"
                                                                value={el}
                                                            >
                                                                {el}
                                                            </Radio>
                                                        </HStack>
                                                    );
                                                })}
                                            </HStack>
                                        </Radio.Group>
                                    </View>
                                )}
                        </VStack>
                    </VStack>
                </HStack>
                {/** pref 3 */}
                <VStack
                    borderRadius={width < 1000 ? "md" : "lg"}
                    mb="2"
                    p={width < 1000 ? "3" : "5"}
                    bg="white"
                    w="full"
                >
                    <Txt style={{ fontSize: 14 }}>{t("q_preferences.question3")}</Txt>
                    <Txt
                        style={{
                            color: "#484848",
                            fontSize: 12,
                            marginTop: 4,
                        }}
                    >
                        {t("questionnaires.free_multiple")}
                    </Txt>
                    <VStack style={{ marginTop: 10 }}>
                        <Input
                            value={pref3}
                            onChangeText={(txt) => console.log(txt)}
                            placeholder={t("questionnaires_answers.placeholder1")}
                        />
                    </VStack>
                </VStack>
                <HStack
                    mt="3"
                    mb="10"
                    mx="1"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <SecondaryButton onPress={() => previousHandler()}>
                        {t("questionnaires.back")}
                    </SecondaryButton>

                    <PrimaryButton onPress={() => submitHandler(false)}>
                        {submitLoader ? (
                            <Spinner color="white" size="small" />
                        ) : (
                            t("questionnaires.finish")
                        )}
                    </PrimaryButton>
                </HStack>
            </VStack>
        );
    };




    return (
        <View flex={1} bg={colors.beige}>
            <ImageBackground
                source={require("../../assets/background.png")}
                style={{ flex: 1 }}
            >

                <VStack bg={colors.beige} flex={1}>
                    <BackHeader
                        isOpen={false}
                        setIsOpen={() => { }}
                        navigation={navigation}
                    />
                    {loader || submitLoader ? (
                        <VStack flex={1} alignItems="center" justifyContent="center">
                            <Spinner size="large" />
                        </VStack>
                    ) : // success
                        showSuccess ? (
                            <VStack overflow="hidden" flex={1} w="full">
                                <ConfettiCannon
                                    autoStart
                                    fallSpeed={2000}
                                    colors={[
                                        colors.green2,
                                        colors.green3,
                                        colors.red1,
                                        colors.yellow,
                                        colors.blue2,
                                    ]}
                                    count={160}
                                    origin={{ x: -10, y: 0 }}
                                />
                                <VStack
                                    style={{ width: "95%", alignSelf: "center", marginTop: -50 }}
                                    flex={1}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Image
                                        source={{
                                            uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
                                        }}
                                        style={{ width: 80, height: 130, marginBottom: 10 }}
                                    />
                                    <H6>{t("questionnaires.title1")}</H6>

                                    <VStack style={{ maxWidth: 600 }} mx="4" mt="4">
                                        <VStack mt="2">
                                            <Txt
                                                style={{
                                                    textAlign: "center",
                                                    color: "#484848",
                                                    fontSize: 15,
                                                }}
                                            >
                                                {t("bilan.text2")}
                                            </Txt>
                                        </VStack>
                                        <VStack mt="2">
                                            <Txt
                                                style={{
                                                    textAlign: "center",
                                                    color: "#484848",
                                                    fontSize: 15,
                                                }}
                                            >
                                                {t("bilan.text3")}
                                            </Txt>
                                        </VStack>
                                    </VStack>
                                    <View
                                        style={{
                                            flexDirection: isMobile ? "column" : "row",
                                            marginTop: 30,
                                        }}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <PrimaryButton onPress={() => navigation.goBack()}>
                                            {t("bilan.navigate")}
                                        </PrimaryButton>
                                        {/* !isMobile && <VStack w="2" />
                                        <PrimaryButton
                                            style={{ marginTop: isMobile ? 10 : 0 }}
                                            onPress={() => {
                                                navigation.navigate("TabNavigation", {
                                                    screen: "Espace",
                                                });
                                            }}
                                        >
                                            {t("questionnaires.recommandation")}
                                        </PrimaryButton>*/}
                                    </View>
                                </VStack>
                            </VStack>
                        ) : (
                            // main
                            <ScrollView showsVerticalScrollIndicator={Platform.OS == "web"}>
                                <VStack flex={1} alignItems="center">

                                    {/** progress section */}
                                    <VStack w="3/5" mt="4" alignSelf="center">
                                        <View mb="2">
                                            <BoldTxt style={{ textAlign: "center", fontSize: 14 }}>
                                                {currentIndex + 1} / {data.length + (!route.params.bilanData ? 1 : 0)}
                                            </BoldTxt>
                                        </View>
                                        <ProgressBar
                                            progress={progress}
                                            color={colors.green2}
                                            style={{
                                                height: 8,
                                                borderRadius: 500,
                                                backgroundColor: colors.grayBorder,
                                            }}
                                        />
                                    </VStack>
                                    {
                                        currentIndex == data.length ? <VStack flex={1} bg={colors.beige}>

                                            <VStack w={isDesktop ? width * 0.9 : "100%"} style={{ alignSelf: 'center' }}>
                                                <VStack mx="2">
                                                    <RenderPreferenceSection />
                                                </VStack>
                                            </VStack>

                                        </VStack> :
                                            /** question response section */
                                            <VStack
                                                w="full"
                                                style={{
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    flex: 1,
                                                    marginTop: 20,
                                                }}
                                            >
                                                <PresenceTransition
                                                    visible={isOpen}
                                                    initial={{
                                                        opacity: 0,
                                                        scale: 0,
                                                        translateY: -300,
                                                    }}
                                                    animate={{
                                                        opacity: 1,
                                                        scale: 1,
                                                        translateY: 0,
                                                        transition: {
                                                            duration: 400,
                                                            delay: 200,
                                                            easing: Easing.linear,
                                                        },
                                                    }}
                                                >
                                                    <VStack
                                                        my="5"
                                                        style={{
                                                            width: isDesktop ? 800 : "100%",
                                                            alignSelf: "center",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            backgroundColor: "#ffffff50",
                                                            borderRadius: 15,
                                                            paddingHorizontal: isDesktop ? 20 : 10,
                                                            marginBottom: 40,
                                                            paddingVertical: 15,
                                                        }}
                                                    >
                                                        <VStack style={{ width: "100%" }}>
                                                            <H6
                                                                style={{
                                                                    textAlign: "center",
                                                                    fontSize: isDesktop ? 18 : 15,
                                                                }}
                                                            >
                                                                {currentQuestion.label}
                                                            </H6>
                                                        </VStack>
                                                    </VStack>
                                                    {currentQuestion.value == 98 ? (
                                                        <VStack>
                                                            <VStack
                                                                style={{
                                                                    alignSelf: "center",
                                                                    width: isMobile ? "96%" : "85%",
                                                                }}
                                                            >
                                                                {currentQuestion.answers.map((answer, i) => (
                                                                    <RenderWorkStressChoiceAnswer
                                                                        key={answer}
                                                                        answer={answer}
                                                                        i={i}
                                                                    />
                                                                ))}
                                                            </VStack>
                                                        </VStack>
                                                    ) : (
                                                        <VStack>
                                                            <VStack
                                                                style={{
                                                                    borderRadius: 15,
                                                                    alignSelf: "center",
                                                                    width: isMobile ? "96%" : "85%",
                                                                }}
                                                            >
                                                                {currentQuestion.type == "free" ? <RenderFreeQuestion /> : <RenderQuestionAnswers />}
                                                            </VStack>

                                                            {currentQuestion.type != "free" && <HStack
                                                                style={{
                                                                    borderColor: "#c22222",
                                                                    justifyContent: "space-between",
                                                                    marginHorizontal: isDesktop ? 100 : 0,
                                                                }}
                                                            >
                                                                <VStack
                                                                    style={{
                                                                        width: 150,
                                                                        borderRadius: 10,
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        paddingVertical: 5,
                                                                        backgroundColor: "#ffffff30",
                                                                    }}
                                                                >
                                                                    <Txt
                                                                        style={{
                                                                            fontSize: isDesktop ? 16 : 14,
                                                                            color: "#484848",
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        {currentQuestion.answers[0].label}
                                                                    </Txt>
                                                                </VStack>
                                                                <VStack
                                                                    style={{
                                                                        width: 150,
                                                                        borderRadius: 10,
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        paddingVertical: 5,
                                                                        backgroundColor: "#ffffff30",
                                                                    }}
                                                                >
                                                                    <Txt
                                                                        style={{
                                                                            fontSize: isDesktop ? 16 : 14,
                                                                            color: "#484848",
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        {
                                                                            currentQuestion.answers[
                                                                                currentQuestion.answers.length - 1
                                                                            ].label
                                                                        }
                                                                    </Txt>
                                                                </VStack>
                                                            </HStack>}
                                                        </VStack>
                                                    )}
                                                </PresenceTransition>
                                                <VStack w="full">
                                                    {/** buttons */}
                                                    <HStack
                                                        my="16"
                                                        w="full"
                                                        alignSelf="center"
                                                        px="3"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                        style={{ maxWidth: 1000 }}
                                                    >
                                                        <SecondaryButton
                                                            disabled={currentIndex == 0}
                                                            onPress={() => previousHandler()}
                                                        >
                                                            {t("questionnaires.back")}
                                                        </SecondaryButton>

                                                        {currentQuestion.value == 98 || currentQuestion.type == "free" ? (
                                                            <PrimaryButton onPress={() => {
                                                                if (!route.params.bilanData || (currentIndex != (data.length - 1))) {
                                                                    nextHandler()
                                                                } else {
                                                                    submitHandler()
                                                                }
                                                            }}>
                                                                {(!route.params.bilanData || (currentIndex != (data.length - 1))) ? t('questionnaires.next') : t("questionnaires.finish")}
                                                            </PrimaryButton>
                                                        ) : answersData[currentIndex] == "" ? (
                                                            <VStack></VStack>
                                                        ) : (
                                                            <PrimaryButton onPress={() => nextHandler()}>
                                                                {t("questionnaires.next")}
                                                            </PrimaryButton>
                                                        )}
                                                    </HStack>
                                                </VStack>
                                            </VStack>
                                    }

                                </VStack>
                            </ScrollView>
                        )}
                </VStack>

            </ImageBackground>
        </View >
    );
};

export default Section;
