import {
    StyleSheet,
    View,
    ScrollView,
    ImageBackground,
    Image,

} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import BackHeader from "../../components/BackHeader";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { useTranslation } from "react-i18next";
import { DomPiece, DominosGameIcon } from "../../assets/dominos/DominosIcon";
import { HStack, VStack, Text, Button, Pressable } from "native-base";
import { H2, Txt } from "../../components/TextsComponents";
import { FontAwesome } from "@expo/vector-icons";
import { useHover } from "react-native-web-hooks";
import { useDispatch, useSelector } from "react-redux";
import { getDominos } from "../../redux/Actions";
import Spinner from "../../components/Spinner";

const Dominos = ({ navigation }) => {
    const { isBigScreen, isMobile, height } = DimensionsHook();
    const { t } = useTranslation();
    const dominos = useSelector(state => state.dominos)
    const [loader, setLoader] = useState(true)
    const dispatch = useDispatch()


    useEffect(() => {
        setLoader(true)
        const unsubscribe = navigation.addListener("focus", () => {
            dispatch(getDominos());
            setLoader(false)
        });

        return unsubscribe;
    }, [navigation]);




    const RenderStars = ({ value }) => {

        return (
            <HStack space="0.5" alignSelf="center" alignItems='center' justifyContent="center" >
                <FontAwesome color={value == 0 ? '#484848' : colors.yellow} style={{ fontSize: 18 }} name={value == 0 ? "star-o" : "star"} />
                <FontAwesome color={value > 1 ? colors.yellow : '#484848'} style={{ fontSize: 18 }} name={value > 1 ? "star" : "star-o"} />
                <FontAwesome color={value != 3 ? '#484848' : colors.yellow} style={{ fontSize: 18 }} name={value != 3 ? "star-o" : "star"} />
            </HStack>
        )
    }


    const LevelItem = ({ el, i }) => {
        const hoverRef = useRef(null)
        const isHover = useHover(hoverRef)
        return (
            <Pressable
                ref={hoverRef}
                focusable={false}
                onPress={() => navigation.navigate('DominoGame', { ...el, number: i + 1 })}
                my="3"
                key={el._id}
                borderRadius="md"
                borderWidth="1" borderColor={colors.grayBorder}
                bg={isHover ? '#A8C49270' : 'transparent'}
                style={{ width: isMobile ? '48%' : 120, }}
                alignItems="center"
                justifyContent="space-around"
                py="3"
            >
                <VStack style={{ width: 40, height: 40, marginBottom: 10 }}>
                    <DominosGameIcon />
                </VStack>
                <View>
                    <RenderStars value={el.answer_data.length > 0 ? el.answer_data[0].star : 0} />
                </View>
                <VStack mt="1" >
                    <Txt style={{ fontSize: 14 }} color="#484848">
                        {t('dominos.level')} {i + 1}
                    </Txt>

                </VStack>

            </Pressable>
        )
    }


    return (
        <View style={styles.container}>
            <ImageBackground
                source={require("../../assets/background.png")}
                style={{ flex: 1 }}
            >
                <ScrollView>
                    <BackHeader
                        isOpen={false}
                        setIsOpen={() => { }}
                        navigation={navigation}
                    />
                    {loader ? <VStack style={{ height: height - 80, alignItems: 'center', justifyContent: 'center' }}>
                        <Spinner size="large" />
                    </VStack> : <VStack style={{ width: "95%", alignSelf: "center", paddingTop: 20 }}>

                        <H2>{t('dominos.title')}</H2>
                        <HStack
                            mt="4"
                            w="full"
                            space="3"
                            alignItems="center"
                            justifyContent="flex-start"
                            flexWrap="wrap"
                        >
                            {dominos.map((el, i) => {
                                return (
                                    <LevelItem key={el._id} el={el} i={i} />
                                );
                            })}
                        </HStack>
                    </VStack>}
                </ScrollView>
                {isBigScreen && (
                    <Image
                        source={{
                            uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
                        }}
                        style={styles.image}
                    />
                )}
            </ImageBackground>
        </View>
    );
};

export default Dominos;

const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.beige,
        flex: 1,
    },

    image: {
        position: "absolute",
        bottom: 0,
        right: 20,
        height: 200,
        width: 120,
    },
});
