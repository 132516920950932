import { HStack, ScrollView, VStack, View } from "native-base";
import React, { useEffect, useState } from "react";
import BackHeader from "../../components/BackHeader";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import { BoldTxt, H6, Txt } from "../../components/TextsComponents";
import { useTranslation } from "react-i18next";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import Spinner from "../../components/Spinner";
import { ImageBackground, Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import Charts from "./Charts";
import moment from "moment";
import Radar from "./Radar";
import ChatEnterModal from "../../components/modals/ChatEnterModal";
import ChatModal from "../../components/modals/ChatModal";

const BilanWelcome = ({ navigation }) => {
    const [bilanData, setBilanData] = useState(null);
    const [analysisData, setAnalysisData] = useState(null);
    const [loader, setLoader] = useState(true);
    const [companyQuestions, setCompanyQuestions] = useState([]);
    const [currentCompagne, setCurrentCompagne] = useState(null);
    const [showChatModal, setShowChatModal] = useState(false);
    const [showChatEnterModal, setShowChatEnterModal] = useState(false);
    const [loaderTime, setLoaderTime] = useState(false);
    const [redFlag, setRedFlag] = useState(false)
    const [times, setTimes] = useState([]);

    const { t, i18n } = useTranslation();
    const { isMobile, width } = DimensionsHook();
    const swiperContainerStyle = {
        width: "98%",
        alignSelf: "center",
        paddingTop: 15,
        paddingBottom: 5,
        borderRadius: 20,
        marginTop: 20,
        paddingLeft: isMobile ? 10 : 20,
        paddingRight: isMobile ? 10 : 20,
        paddingBottom: 10,
    };

    const getChatTimes = async () => {
        setLoaderTime(true);
        const TOKEN = await AsyncStorage.getItem("access_token");
        const config = {
            headers: {
                Authorization: `Bearer ${TOKEN}`,
            },
        };
        axios
            .get(process.env.CHAT_TIME, config)
            .then((res) => {
                setTimes(res.data);
                setLoaderTime(false);
                setShowChatEnterModal(true);
            })
            .catch((err) => {
                console.log(err);
                setLoaderTime(false);
            });
    };

    useEffect(() => {
        const getData = async () => {
            setLoader(true);
            const TOKEN = await AsyncStorage.getItem("access_token");
            const config = {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                },
            };
            try {
                const Response = await axios.get(
                    `${process.env.QUESTIONNAIRES}/get-bilan?language=${i18n.language}`,
                    config
                );
                // get bilan analysis text data
                const Response2 = await axios.get(
                    `${process.env.QUESTIONNAIRES}/get-bilan-analysis`,
                    config
                );

                setCurrentCompagne(
                    Response.data && Response.data.compagne
                        ? Response.data.compagne
                        : null
                );

                if (Response.data && Response.data.compagne) {
                    const ResponseCompany = await axios.get(
                        `${process.env.QUESTIONNAIRES}/company-questions?language=${i18n.language}&compagne=${Response.data.compagne}`
                    );
                    setCompanyQuestions(ResponseCompany.data);
                }
                setBilanData(
                    Response.data && Response.data.language ? Response.data : null
                );
                setAnalysisData(Response2.data);

                setLoader(false);
            } catch (error) {
                console.log(error);
            }
        };
        const unsubscribe = navigation.addListener("focus", () => {
            getData();

        });

        // Return the function to unsubscribe from the event so it gets removed on unmount
        return unsubscribe;
    }, [navigation]);

    const widthCondition = width < 1000 ? { width: "100%" } : { flex: 1 };

    return (
        <VStack bg={colors.beige} flex={1}>
            <ImageBackground
                source={require("../../assets/background.png")}
                style={{ flex: 1 }}
            >
                {loader || loaderTime ? (
                    <VStack bg={colors.beige} flex={1}>
                        <BackHeader
                            isOpen={false}
                            setIsOpen={() => { }}
                            navigation={navigation}
                        />
                        <VStack flex={1} alignItems="center" justifyContent="center">
                            <Spinner size="large" />
                        </VStack>
                    </VStack>
                ) : (
                    <ScrollView showsVerticalScrollIndicator={Platform.OS == "web"}>
                        <BackHeader
                            isOpen={false}
                            setIsOpen={() => { }}
                            navigation={navigation}
                        />

                        <VStack
                            style={{ marginTop: -15 }}
                            flex={1}
                            w="full"
                            alignSelf="center"
                        >
                            <View style={swiperContainerStyle}>
                                {/** header section */}
                                <View style={{ flex: 1 }}>
                                    <HStack
                                        w="full"
                                        alignItems="stretch"
                                        justifyContent="center"
                                        flexWrap="wrap"
                                        bg="transparent"
                                        style={{ borderRadius: 40 }}
                                    >
                                        <VStack
                                            style={{
                                                ...widthCondition,
                                                marginRight: width < 1000 ? 0 : 15,
                                                justifyContent: "center",
                                                backgroundColor: "#ffffff70",
                                                shadowColor: "#00000010",
                                                shadowOffset: { width: 2, height: 2 },
                                                paddingHorizontal: 5,
                                                paddingVertical: 10,
                                            }}
                                        >
                                            <VStack
                                                style={{
                                                    marginBottom: 20,
                                                    marginTop: 10,
                                                    paddingHorizontal: isMobile ? 0 : 10,
                                                }}
                                            >
                                                <H6
                                                    style={{
                                                        marginLeft: 5,
                                                        fontSize: 26,
                                                    }}
                                                >
                                                    {t("questionnaires.title")}
                                                </H6>
                                                {bilanData && bilanData.currentCompagne && (
                                                    <H6
                                                        style={{
                                                            marginLeft: 5,
                                                            fontSize: 24,
                                                        }}
                                                    >
                                                        {`${t("bilan.compaign")} ${t(
                                                            "bilan.from"
                                                        )} ${moment(
                                                            bilanData.currentCompagne.startDate
                                                        ).format("DD-MM-YYYY")} ${t("bilan.to")} ${moment(
                                                            bilanData.currentCompagne.endDate
                                                        ).format("DD-MM-YYYY")}`}
                                                    </H6>
                                                )}
                                                {bilanData && (
                                                    <VStack
                                                        alignSelf={width < 900 ? "center" : "flex-start"}
                                                        style={{ marginRight: width < 900 ? 0 : 10 }}
                                                    >
                                                        <BoldTxt
                                                            style={{
                                                                fontSize: 14,
                                                                color: "#484848",
                                                                marginLeft: 5,
                                                                marginTop: 5,
                                                            }}
                                                        >
                                                            {t("bilan.text")} -{" "}
                                                            {bilanData.updatedAt
                                                                ? moment(bilanData.updatedAt).format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : moment(bilanData.createdAt).format(
                                                                    "DD/MM/YYYY"
                                                                )}
                                                        </BoldTxt>
                                                    </VStack>
                                                )}
                                                <VStack
                                                    style={{ marginTop: width < 900 ? 0 : 10 }}
                                                ></VStack>
                                                <Txt
                                                    color={"#484848"}
                                                    style={{
                                                        marginRight: 10,
                                                        marginLeft: 5,

                                                        fontSize: 15,
                                                        marginTop: 10,
                                                        textAlign: "justify",
                                                    }}
                                                >
                                                    {t("questionnaires.text0")}
                                                </Txt>
                                                <Txt
                                                    color={"#484848"}
                                                    style={{
                                                        marginRight: 10,
                                                        marginLeft: 5,

                                                        fontSize: 15,
                                                        marginTop: 10,
                                                        textAlign: "justify",
                                                    }}
                                                >
                                                    {t("questionnaires.text1")}
                                                </Txt>
                                                <Txt
                                                    color={"#484848"}
                                                    style={{
                                                        marginRight: 10,
                                                        marginLeft: 5,

                                                        textAlign: "justify",
                                                        fontSize: 15,
                                                        marginTop: 10,
                                                    }}
                                                >
                                                    {t("questionnaires.text2")}
                                                </Txt>

                                                {bilanData && bilanData.mean === undefined && (
                                                    <VStack mt="5">
                                                        <VStack
                                                            py="3"
                                                            px={isMobile ? "2" : "5"}
                                                            alignSelf="center"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            bg={colors.green1}
                                                        >
                                                            <Txt
                                                                style={{ fontSize: 14, color: colors.blue3 }}
                                                            >
                                                                <BoldTxt
                                                                    style={{ fontSize: 14, color: colors.blue3 }}
                                                                >
                                                                    {t("bilan.chart_text1")}
                                                                </BoldTxt>{" "}
                                                                {t("bilan.chart_text2")}.
                                                            </Txt>
                                                        </VStack>
                                                    </VStack>
                                                )}
                                                <VStack mt="2">
                                                    {!redFlag ? (
                                                        <Txt
                                                            style={{
                                                                marginRight: 10,
                                                                marginLeft: 5,

                                                                textAlign: "justify",
                                                                fontSize: 15,
                                                                marginTop: 10,
                                                            }}
                                                        >
                                                            {t("bilan.chat1")}{" "}
                                                            <BoldTxt
                                                                onPress={() => {
                                                                    if (!loaderTime) {
                                                                        getChatTimes();
                                                                    }
                                                                }}
                                                                style={{
                                                                    marginRight: 10,
                                                                    textDecorationLine: 'underline',
                                                                    textAlign: "justify",
                                                                    fontSize: 15,
                                                                    marginTop: 10,
                                                                }}
                                                            >
                                                                {t("bilan.chat2")}
                                                            </BoldTxt>
                                                        </Txt>
                                                    ) : (
                                                        <Txt
                                                            color={colors.red1}
                                                            style={{
                                                                marginRight: 10,
                                                                marginLeft: 5,

                                                                textAlign: "justify",
                                                                fontSize: 15,
                                                                marginTop: 10,
                                                            }}
                                                        >
                                                            {t("bilan.chat3")}{" "}
                                                            <BoldTxt
                                                                color={colors.red1}
                                                                onPress={() => {
                                                                    if (!loaderTime) {
                                                                        getChatTimes();
                                                                    }
                                                                }}
                                                                style={{
                                                                    marginRight: 10,
                                                                    textDecorationLine: 'underline',
                                                                    textAlign: "justify",
                                                                    fontSize: 15,
                                                                    marginTop: 10,
                                                                }}
                                                            >
                                                                {t("bilan.chat2")}
                                                            </BoldTxt>

                                                        </Txt>

                                                    )}
                                                </VStack>
                                                {bilanData && (
                                                    <View
                                                        mt="6"
                                                        style={{
                                                            flexDirection: isMobile ? 'column' : "row",
                                                            alignItems: 'center',
                                                            justifyContent: isMobile ? 'center' : 'flex-end',
                                                            flex: 1,

                                                        }}
                                                    >
                                                        <PrimaryButton isSmallText
                                                            style={{ marginBottom: isMobile ? 10 : 0, marginRight: isMobile ? 0 : 10, width: isMobile ? 280 : 220 }}
                                                            onPress={() =>
                                                                navigation.navigate("Recommandations", {
                                                                    redFlag
                                                                })
                                                            }
                                                        >
                                                            {t("bilan.recommandations")}
                                                        </PrimaryButton>
                                                        <PrimaryButton isSmallText
                                                            style={{ marginBottom: isMobile ? 10 : 0, marginRight: isMobile ? 0 : 10, width: isMobile ? 280 : 240 }}
                                                            onPress={() => {
                                                                if (!loaderTime) {
                                                                    getChatTimes();
                                                                }
                                                            }}
                                                        >
                                                            {t("bilan.button_chat")}
                                                        </PrimaryButton>

                                                        <SecondaryButton isWhite={true}
                                                            style={{ width: isMobile ? 280 : 240, borderColor: colors.grayBorder }}
                                                            onPress={() =>
                                                                navigation.navigate("PreferenceScreen", {
                                                                    bilanData,
                                                                })
                                                            }
                                                        >
                                                            <Txt style={{ fontSize: 14 }}>{t("bilan.button1")}</Txt>
                                                        </SecondaryButton>

                                                    </View>
                                                )}
                                            </VStack>
                                        </VStack>
                                        {
                                            <VStack
                                                style={{
                                                    width: width < 1000 ? "100%" : 430,
                                                    backgroundColor: "#ffffff70",
                                                    shadowColor: "#00000010",
                                                    shadowOffset: { width: 2, height: 2 },
                                                    borderRadius: 15,
                                                    maxWidth: 420,
                                                    alignSelf: "center",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <VStack
                                                    mt={Platform.OS == "web" ? "3" : "0"}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    mx="10"
                                                >
                                                    {Platform.OS == "web" && (
                                                        <Txt style={{ fontSize: 16, textAlign: "center" }}>
                                                            {t("bilan.chart1")}
                                                        </Txt>
                                                    )}
                                                </VStack>

                                                <Radar
                                                    currentCompagne={currentCompagne}
                                                    companyQuestions={companyQuestions}
                                                    bilanData={bilanData}
                                                />
                                            </VStack>
                                        }
                                    </HStack>
                                </View>

                                <View w="full">
                                    <Charts
                                        companyQuestions={companyQuestions}
                                        bilanData={bilanData}
                                        analysisData={analysisData}
                                        setRedFlag={setRedFlag}
                                    />
                                </View>
                            </View>
                        </VStack>
                    </ScrollView>
                )}
            </ImageBackground>
            {/** chat enter modal */}
            <ChatEnterModal
                setShowChatModal={setShowChatModal}
                isOpen={showChatEnterModal}
                setIsOpen={setShowChatEnterModal}
                navigation={navigation}
                times={times}
            />

            {Platform.OS == "web" && (
                <ChatModal isOpen={showChatModal} setIsOpen={setShowChatModal} />
            )}
        </VStack>
    );
};

export default BilanWelcome;
