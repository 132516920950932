import {
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import BackHeader from "../components/BackHeader";
import Spinner from "../components/Spinner";
import DimensionsHook from "../hooks/DimensionsHook";
import { colors } from "../styles/GlobalStyle";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { H6, Txt } from "../components/TextsComponents";
import * as Animatable from "react-native-animatable";
import NewTrailsSwiper from "../components/swiper/NewTrailsSwiper";
import NewWorkshopsSwiper from "../components/swiper/NewWorkshopsSwiper";
import { PrimaryButton } from "../components/Buttons";
import { CompleteLoaderAvatar } from "../assets/svg/Logo";

const Nouveautes = ({ navigation }) => {
  const [loader, setLoader] = useState(true);
  const [trails, setTrails] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const { height } = DimensionsHook();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const getData = async () => {
      setLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const url = `${process.env.WORKSHOPS_ENDPOINT}/nouveautes?language=${i18n.language}`;
      axios
        .get(url, config)
        .then((res) => {
          setTrails(res.data.trails);
          setWorkshops(res.data.workshops);

          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, []);

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView showsVerticalScrollIndicator={Platform.OS === "web"}>
          <View>
            <BackHeader
              isOpen={false}
              setIsOpen={() => {}}
              navigation={navigation}
            />
            {loader ? (
              <View
                style={{
                  height: height - 140,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spinner size="large" />
              </View>
            ) : trails.length == 0 && workshops.length == 0 ? (
              <View
                style={{
                  height: height - 140,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    width: "95%",
                    alignSelf: "center",
                    maxWidth: 600,
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <Animatable.View
                    animation="tada"
                    delay={500}
                    iterationCount="infinite"
                    direction="alternate-reverse"
                    duration={1500}
                    easing="linear"
                    style={{
                      width: 100,
                      height: 100,

                      alignSelf: "center",
                      marginBottom: 40,
                    }}
                  >
                    <CompleteLoaderAvatar />
                  </Animatable.View>
                  <H6 style={{ textAlign: "center", marginBottom: 10 }}>
                    {t("nouveautes.no_content")}
                  </H6>
                  <Txt
                    style={{
                      textAlign: "center",
                      color: "#969696",
                      fontSize: 16,
                    }}
                  >
                    {t("nouveautes.no_content1")}
                  </Txt>
                  <View
                    style={{ width: 200, alignSelf: "center", marginTop: 20 }}
                  >
                    <PrimaryButton onPress={() => navigation.goBack()}>
                      {t("back")}
                    </PrimaryButton>
                  </View>
                </View>
              </View>
            ) : (
              <View>
                <NewTrailsSwiper navigation={navigation} data={trails} />
                <NewWorkshopsSwiper navigation={navigation} data={workshops} />
              </View>
            )}
          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

export default Nouveautes;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.beige,
  },
});
