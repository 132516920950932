import { Path, Rect, Svg, Circle, G } from "react-native-svg";
import { colors } from "../../styles/GlobalStyle";

export const DomPiece = ({ isCorrectAnswer, up, down, choice }) => {
    const renderTop = () => {
        switch (up) {
            case 1:
                return (
                    <G>
                        <Circle id="path1793-2" cx="27" cy="27" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );
            case 2:
                return (
                    <G>
                        <Circle id="path1801-4" cx="13" cy="13" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1785-0" cx="41" cy="41" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );
            case 3:
                return (
                    <G>
                        <Circle id="path1801-4" cx="13" cy="13" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1793-2" cx="27" cy="27" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1785-0" cx="41" cy="41" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );
            case 4:
                return (
                    <G>
                        <Circle id="path1801-4" cx="13" cy="13" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1797-2" cx="41" cy="13" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1789-6" cx="13" cy="41" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1785-0" cx="41" cy="41" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );
            case 5:
                return (
                    <G>
                        <Circle id="path1801-4" cx="13" cy="13" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1797-2" cx="41" cy="13" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1793-2" cx="27" cy="27" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1789-6" cx="13" cy="41" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1785-0" cx="41" cy="41" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );
            case 6:
                return (
                    <G>
                        <Circle id="path1801-4" cx="13" cy="13" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1797-2" cx="41" cy="13" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1795-4" cx="13" cy="27" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1791-0" cx="41" cy="27" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1789-6" cx="13" cy="41" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1785-0" cx="41" cy="41" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );
            case 7:
                return (
                    <G>
                        <Circle id="path1801-4" cx="13" cy="13" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1797-2" cx="41" cy="13" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1795-4" cx="13" cy="27" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1793-2" cx="27" cy="27" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1791-0" cx="41" cy="27" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1789-6" cx="13" cy="41" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1785-0" cx="41" cy="41" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );
            case 8:
                return (
                    <G>
                        <Circle id="path1801-4" cx="13" cy="13" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1799-8" cx="27" cy="13" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1797-2" cx="41" cy="13" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1795-4" cx="13" cy="27" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1791-0" cx="41" cy="27" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1789-6" cx="13" cy="41" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1787-7" cx="27" cy="41" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1785-0" cx="41" cy="41" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );
            case 9:
                return (
                    <G>
                        <Circle id="path1801-4" cx="13" cy="13" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1799-8" cx="27" cy="13" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1797-2" cx="41" cy="13" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1795-4" cx="13" cy="27" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1793-2" cx="27" cy="27" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1791-0" cx="41" cy="27" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1789-6" cx="13" cy="41" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1787-7" cx="27" cy="41" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1785-0" cx="41" cy="41" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );

            default:
                <G></G>;
        }
    };

    const renderDown = () => {
        switch (down) {
            case 1:
                return (
                    <G>
                        <Circle id="path1793-2" cx="27" cy="77" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );
            case 2:
                return (
                    <G>
                        <Circle id="path1801-4" cx="13" cy="63" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1785-0" cx="41" cy="91" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );
            case 3:
                return (
                    <G>
                        <Circle id="path1801-4" cx="13" cy="63" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1793-2" cx="27" cy="77" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1785-0" cx="41" cy="91" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );
            case 4:
                return (
                    <G>
                        <Circle id="path1801-4" cx="13" cy="63" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1797-2" cx="41" cy="63" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1789-6" cx="13" cy="91" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1785-0" cx="41" cy="91" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );
            case 5:
                return (
                    <G>
                        <Circle id="path1801-4" cx="13" cy="63" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1797-2" cx="41" cy="63" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1793-2" cx="27" cy="77" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1789-6" cx="13" cy="91" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1785-0" cx="41" cy="91" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );
            case 6:
                return (
                    <G>
                        <Circle id="path1801-4" cx="13" cy="63" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1797-2" cx="41" cy="63" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1795-4" cx="13" cy="77" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1791-0" cx="41" cy="77" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1789-6" cx="13" cy="91" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1785-0" cx="41" cy="91" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );
            case 7:
                return (
                    <G>
                        <Circle id="path1801-4" cx="13" cy="63" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1797-2" cx="41" cy="63" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1795-4" cx="13" cy="77" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1793-2" cx="27" cy="77" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1791-0" cx="41" cy="77" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1789-6" cx="13" cy="91" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1785-0" cx="41" cy="91" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );
            case 8:
                return (
                    <G>
                        <Circle id="path1801-4" cx="13" cy="63" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1799-8" cx="27" cy="63" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1797-2" cx="41" cy="63" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1795-4" cx="13" cy="77" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />

                        <Circle id="path1791-0" cx="41" cy="77" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1789-6" cx="13" cy="91" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1787-7" cx="27" cy="91" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1785-0" cx="41" cy="91" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );
            case 9:
                return (
                    <G>
                        <Circle id="path1801-4" cx="13" cy="63" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1799-8" cx="27" cy="63" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1797-2" cx="41" cy="63" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1795-4" cx="13" cy="77" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1793-2" cx="27" cy="77" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1791-0" cx="41" cy="77" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1789-6" cx="13" cy="91" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1787-7" cx="27" cy="91" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                        <Circle id="path1785-0" cx="41" cy="91" r="5" fill={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white} />
                    </G>
                );

            default:
                <G></G>;
        }

    };

    return (
        <Svg
            id="Calque_1"
            data-name="Calque 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 54 104"
        >
            <Rect
                id="rect1212"
                x="2"
                y="2"
                width="50"
                height="100"
                rx="10"
                fill={!choice || isCorrectAnswer == null ? '#fff' : isCorrectAnswer ? colors.green2 : colors.red1}
                stroke={!choice || isCorrectAnswer == null ? colors.blue3 : isCorrectAnswer ? colors.beige : colors.red1}
                strokeLinecap="square"
                strokeWidth="3"
            />
            <Path
                id="path1783"
                d="M46.06,52.32h-38"
                transform="translate(-0.06 -0.32)"
                fill="none"
                stroke={!choice || isCorrectAnswer == null ? colors.blue3 : colors.white}
                strokeLinecap="round"
                strokeWidth="3"
            />

            {renderTop()}

            {renderDown()}
        </Svg>
    );
};


export const DominosGameIcon = () => {
    return (
        <Svg fill="#484848" strokeWidth="2" height="100%" width="100%" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512" space="preserve">
            <G>
                <Path d="M184,224c4.418,0,8-3.582,8-8s-3.582-8-8-8H40c-4.418,0-8,3.582-8,8s3.582,8,8,8H184z" />
                <Path d="M168,184c8.822,0,16-7.178,16-16s-7.178-16-16-16s-16,7.178-16,16S159.178,184,168,184z" />
                <Path d="M56,72c8.822,0,16-7.178,16-16s-7.178-16-16-16s-16,7.178-16,16S47.178,72,56,72z" />
                <Path d="M112,128c8.822,0,16-7.178,16-16s-7.178-16-16-16s-16,7.178-16,16S103.178,128,112,128z" />
                <Path d="M168,248c-8.822,0-16,7.178-16,16s7.178,16,16,16s16-7.178,16-16S176.822,248,168,248z" />
                <Path d="M168,360c-8.822,0-16,7.178-16,16s7.178,16,16,16s16-7.178,16-16S176.822,360,168,360z" />
                <Path d="M56,248c-8.822,0-16,7.178-16,16s7.178,16,16,16s16-7.178,16-16S64.822,248,56,248z" />
                <Path d="M56,360c-8.822,0-16,7.178-16,16s7.178,16,16,16s16-7.178,16-16S64.822,360,56,360z" />
                <Path d="M488,288H224V24c0-13.233-10.766-24-24-24H24C10.766,0,0,10.767,0,24v384c0,13.233,10.766,24,24,24h56v56
		c0,13.233,10.766,24,24,24h384c13.234,0,24-10.767,24-24V312C512,298.767,501.234,288,488,288z M16,408V24c0-4.411,3.589-8,8-8h176
		c4.411,0,8,3.589,8,8v384c0,4.411-3.589,8-8,8H24C19.589,416,16,412.411,16,408z M496,488c0,4.411-3.589,8-8,8H104
		c-4.411,0-8-3.589-8-8v-56h104c13.234,0,24-10.767,24-24V304h264c4.411,0,8,3.589,8,8V488z"/>
                <Path d="M296,320c-4.418,0-8,3.582-8,8v144c0,4.418,3.582,8,8,8s8-3.582,8-8V328C304,323.582,300.418,320,296,320z" />
                <Path d="M248,328c-8.822,0-16,7.178-16,16s7.178,16,16,16s16-7.178,16-16S256.822,328,248,328z" />
                <Path d="M136,440c-8.822,0-16,7.178-16,16s7.178,16,16,16s16-7.178,16-16S144.822,440,136,440z" />
                <Path d="M344,328c-8.822,0-16,7.178-16,16s7.178,16,16,16s16-7.178,16-16S352.822,328,344,328z" />
                <Path d="M456,328c-8.822,0-16,7.178-16,16s7.178,16,16,16s16-7.178,16-16S464.822,328,456,328z" />
                <Path d="M400,384c-8.822,0-16,7.178-16,16s7.178,16,16,16s16-7.178,16-16S408.822,384,400,384z" />
                <Path d="M344,440c-8.822,0-16,7.178-16,16s7.178,16,16,16s16-7.178,16-16S352.822,440,344,440z" />
                <Path d="M456,440c-8.822,0-16,7.178-16,16s7.178,16,16,16s16-7.178,16-16S464.822,440,456,440z" />
            </G>
        </Svg>
    )
} 