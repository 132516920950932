import { FlatList, Platform, ScrollView, StyleSheet, View } from "react-native";
import React from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { H6, Txt } from "../TextsComponents";
import SwiperItem from "./SwiperItem";

const NewTrailsSwiper = ({ data, navigation }) => {
  const { t } = useTranslation();

  const { isMobile } = DimensionsHook();
  const swiperContainerStyle = {
    backgroundColor: colors.white,
    width: "95%",
    alignSelf: "center",
    paddingTop: 15,
    zIndex: -2,
    paddingBottom: 5,
    borderRadius: 20,
    marginTop: 20,
    paddingLeft: isMobile ? 10 : 20,
    paddingRight: isMobile ? 0 : 20,
  };

  if (data.length === 0) {
    return <></>;
  }

  return (
    <View style={swiperContainerStyle}>
      <H6>{t("nouveautes.title1")}</H6>
      <Txt
        style={{
          fontSize: 14,
          marginBottom: 10,
          paddingLeft: 5,
          textTransform: "lowercase",
        }}
        color={colors.grayLabel}
      >
        {data.length +
          " " +
          t("nouveautes.title1") +
          " " +
          t("nouveautes.found")}
      </Txt>
      <View>
        <ScrollView
          showsHorizontalScrollIndicator={Platform.OS === "web"}
          horizontal
        >
          {data.map((trail) => (
            <SwiperItem
              type="Trail"
              item={trail}
              key={trail._id}
              navigation={navigation}
            />
          ))}
        </ScrollView>
      </View>
    </View>
  );
};

export default NewTrailsSwiper;

const styles = StyleSheet.create({});
