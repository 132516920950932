import { ImageBackground, Image, StyleSheet, View } from "react-native";
import React, { useState } from "react";
import { H2, H6, SmallBoldTxt, SmallTxt, Txt } from "../components/TextsComponents";
import { colors } from "../styles/GlobalStyle";
import { Input } from "../components/Inputs";
import { PrimaryButton } from "../components/Buttons";
import BackHeader from "../components/BackHeader";
import Spinner from "../components/Spinner";
import DimensionsHook from "../hooks/DimensionsHook";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { VStack } from "native-base";

const ResetPassword = ({ navigation, route }) => {
  const { width } = DimensionsHook();
  const hiddenHeader = route.params
  const [email, setEmail] = useState("");
  const { isDesktop } = DimensionsHook();
  const [errorMessage, setErrorMessage] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false)
  const { t } = useTranslation();



  const submitHandler = async () => {
    try {
      setSubmitLoader(true);
      setErrorMessage("");
      if (email.length < 3) {
        setSubmitLoader(false);
        setErrorMessage(t("reset_pass_screen.error_email"));
        return;
      }
      const payload = { email: email.trim().toLocaleLowerCase() };

      axios.post(
        process.env.USER_SEND_FORGOT_PASSWORD,
        payload
      ).then(res => {

        setShowSuccess(true)
      })


    } catch (err) {
      if (err.response.status === 400 || err.response.status === 404) {
        setErrorMessage(t("reset_pass_screen.error_email"));
        setSubmitLoader(false)
      } else {
        setErrorMessage(t("reset_pass_screen.error_connexion"));
        setSubmitLoader(false)
      }
    }


  };

  return (
    <View style={styles.container}>
      {!hiddenHeader && <BackHeader isOpen={false} setIsOpen={() => { }} navigation={navigation} />}
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        {showSuccess ? <VStack overflow="hidden" flex={1} w="full">
          <VStack
            style={{ width: "95%", alignSelf: "center", marginTop: -50 }}
            flex={1}
            alignItems="center"
            justifyContent="center"
          >
            <Image
              source={{
                uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
              }}
              style={{ width: 80, height: 130, marginBottom: 10 }}
            />
            <H6>{t("reset_pass_screen.title1")}</H6>

            <VStack style={{ maxWidth: 600 }} mx="4" mt="4">
              <VStack mt="2">
                <Txt
                  style={{
                    textAlign: "center",
                    color: "#484848",
                    fontSize: 15,
                  }}
                >
                  {t("reset_pass_screen.text4")}
                </Txt>
              </VStack>

            </VStack>
            <View
              style={{

                marginTop: 30,
              }}

            >
              <PrimaryButton onPress={() => navigation.goBack()}>
                {t("back")}
              </PrimaryButton>

            </View>
          </VStack>
        </VStack> : <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            marginTop: -20,
          }}
        >
          <H2 style={{ textAlign: "center" }}>{t("signin.forgot_password")}</H2>
          <SmallBoldTxt
            style={{
              color: colors.red1,
              textAlign: "center",
              marginTop: 10,
            }}
          >
            {errorMessage.length > 0 ? errorMessage : " "}
          </SmallBoldTxt>
          <View style={{ width: "95%", maxWidth: 500, marginVertical: 20 }}>
            <Txt style={{ marginBottom: 10 }}>
              {t("reset_pass_screen.text1")}
            </Txt>
            <Input
              placeholder={t("placeholders.email")}
              value={email}
              onChangeText={(val) => setEmail(val)}
            />
            <SmallTxt style={{ textAlign: "justify", marginTop: 30 }}>
              {t("reset_pass_screen.text2") + " "}
              <SmallBoldTxt>{t("reset_pass_screen.button")}</SmallBoldTxt>,{" "}
              {t("reset_pass_screen.text3") + " "}
            </SmallTxt>
            <PrimaryButton
              onPress={submitHandler}
              style={{ marginVertical: 30 }}
            >
              {submitLoader ? (
                <Spinner

                  size="small"
                />
              ) : (
                t("reset_pass_screen.button")
              )}
            </PrimaryButton>
          </View>
        </View>}

      </ImageBackground>
      {!showSuccess && width > 1000 && (
        <Image
          source={{
            uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
          }}
          style={styles.image}
        />
      )}
    </View>
  );
};

export default ResetPassword;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.beige,
  },
  image: {
    position: "absolute",
    bottom: 0,
    right: 20,
    height: 230,
    width: 140,
  },
  toastContainer: {
    backgroundColor: colors.green2,
    flexDirection: "row",
    padding: 10,
    alignItems: "center",
    borderRadius: 20,
  },
});
