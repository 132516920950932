import {
  ImageBackground,
  StyleSheet,
  ScrollView,
  View,
  Platform, Pressable,
  Linking,
  TouchableOpacity,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { BoldTxt, H2, H6, SmallTxt, Txt } from "../components/TextsComponents";
import { colors } from "../styles/GlobalStyle";
import DimensionsHook from "../hooks/DimensionsHook";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDispatch, useSelector } from "react-redux";
import LifeTimeCard from "./Plans/LifeTimeCard";
import TrialCard from "./Plans/TrialCard";
import SubscriptionCard from "./Plans/SubscriptionCard";
import { resetUser, setUserData } from "../redux/Actions";
import { PrimaryButton, SecondaryButton } from "../components/Buttons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useTranslation } from "react-i18next";
import Spinner from "../components/Spinner";
import axios from "axios";
import EulaModal from "../components/modals/EulaModal";
import CompanyCodeModal from "../components/modals/CompanyCodeModal";

import { FontAwesome } from '@expo/vector-icons';
import { useHover } from "react-native-web-hooks";

const Plans = ({ navigation }) => {
  const { t } = useTranslation();
  const { height, width, isDesktop, isMobile } = DimensionsHook();
  const userData = useSelector((state) => state.userData);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [lifetimeData, setLifetimeData] = useState({});
  const [showEula, setShowEula] = useState(true);
  const [userGift, setUserGift] = useState(null);
  // choose company user or particular
  const [isCompanyUser, setIsCompanyUser] = useState(null);
  const [showCompanyModal, setShowCompanyModal] = useState(false);

  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const companyHoverRef = useRef(null);
  const isCompanyHovered = useHover(companyHoverRef);

  const getCurrentUrl = async () => {
    const url = await Linking.getInitialURL();
    return url;
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      dispatch(setUserData());
      if (
        (userData.subscription && userData.subscription_status == "active") ||
        userData.lifetime_subscription
      ) {
        const currentUrl = await getCurrentUrl();
        if (Platform.OS == "web" && currentUrl.includes("/gift")) {
          navigation.replace("GiftWeb");
        } else if (Platform.OS == "web" && currentUrl.includes("/sponsor")) {
          navigation.replace("ParrainageWeb");
        } else {
          navigation.replace("TabNavigation");
        }
      }
      return clearInterval(interval);
    }, 1500);
  }, [userData]);

  const logoutHandler = async () => {
    await AsyncStorage.removeItem("access_token");
    dispatch(resetUser());

    navigation.reset({
      index: 0,
      routes: [{ name: "Loader" }],
    });
  };

  useEffect(() => {
    const giftUrl = process.env.GIFT_DATA;
    const url = process.env.SUBSCRIPTIONS_LIST;
    setLoader(true);
    axios
      .get(`${giftUrl}?email=${userData.email}`)
      .then((res) => {
        if (res.data == null) {
          setUserGift(null);
        } else {
          setUserGift(res.data);
        }
        axios
          .get(url)
          .then((res) => {
            const subs = res.data.filter(
              (product) => product.lifetime == false
            );
            const lifetime = res.data.find(
              (product) => product.lifetime == true
            );
            setLoader(false);
            setSubscriptions(subs);
            setLifetimeData(lifetime);
          })
          .catch((err) => {
            navigation.replace("NoConnection");
          });
      })
      .catch((err) => {
        navigation.replace("NoConnection");
      });
  }, []);

  if (loader) {
    return (
      <View style={styles.container}>
        <ImageBackground
          source={require("../assets/background.png")}
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <Spinner size="large" />
        </ImageBackground>
      </View>
    );
  }
  if (isCompanyUser == null || isCompanyUser) {
    return (
      <View style={styles.container}>
        <ImageBackground
          source={require("../assets/background.png")}
          style={{ flex: 1 }}
        >
          <ScrollView showsVerticalScrollIndicator={Platform.OS == "web"}>



            <View >
              <View style={styles.logoutContainer}>
                <PrimaryButton
                  onPress={logoutHandler}
                  style={{ flexDirection: "row", alignItems: "center" }}
                >
                  <SmallTxt color={colors.white}>
                    {t("setting_screen.logout_button")}
                  </SmallTxt>
                </PrimaryButton>
              </View>
              <H2
                style={{
                  textAlign: "center",

                  marginTop: isDesktop ? 0 : 30,
                  marginHorizontal: 20,
                  fontSize: 28,
                  lineHeight: 34,
                }}
              >
                {t("plans.choose_sub")}
              </H2>
              <View style={{ flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', marginTop: 10, flexWrap: 'wrap', marginBottom: 20 }}>
                {/** indiv */}
                <TouchableOpacity ref={hoverRef} onPress={() => {
                  setIsCompanyUser(false)
                }}

                  style={[
                    styles.cardContainer,
                    {
                      width: isMobile ? "100%" : "48%",
                      maxWidth: 380,
                      cursor: "pointer",
                      marginHorizontal: isMobile ? 0 : "1%",
                      backgroundColor: isHovered ? colors.green0 : colors.white
                    },
                  ]}

                >
                  <View style={{ width: "100%", alignItems: "center" }} >
                    <FontAwesome name="user" size={50} color={colors.blue3} />
                    <H6 style={{ textAlign: 'center', marginTop: 15, marginBottom: 10 }}>{t('plans.title1')}</H6>
                    <View style={{ marginHorizontal: 10 }}>
                      <Txt style={{ color: '#484848', textAlign: 'center', fontSize: 14 }}>{t('plans.text1')}</Txt>
                    </View>

                  </View>
                </TouchableOpacity>
                {/** company */}
                <TouchableOpacity ref={companyHoverRef} onPress={() => {
                  setIsCompanyUser(true)
                  setShowCompanyModal(true)
                }}

                  style={[
                    styles.cardContainer,
                    {
                      width: isMobile ? "100%" : "48%",
                      maxWidth: 380,
                      cursor: "pointer",
                      backgroundColor: isCompanyHovered ? colors.green0 : colors.white
                    },
                  ]}

                >
                  <View style={{ width: "100%", alignItems: "center" }} >
                    <FontAwesome name="group" size={50} color={colors.blue3} />
                    <H6 style={{ textAlign: 'center', marginTop: 15, marginBottom: 10 }}>{t('plans.title2')}</H6>
                    <View style={{ marginHorizontal: 10 }}>
                      <Txt style={{ color: '#484848', textAlign: 'center', fontSize: 14 }}>{t('plans.text2')}</Txt>
                    </View>

                  </View>
                </TouchableOpacity>
              </View>

            </View>
          </ScrollView>
        </ImageBackground>
        <CompanyCodeModal fromPlans={true} navigation={navigation} isOpen={showCompanyModal} setIsOpen={setShowCompanyModal} />
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView
          style={{ flex: 1 }}
          showsVerticalScrollIndicator={Platform.OS === "web"}
        >
          <View style={[styles.plansContainer, { minHeight: height }]}>
            <View style={styles.buttonsContainer}>

              <SecondaryButton
                onPress={() => {
                  setShowCompanyModal(false)
                  setIsCompanyUser(null)
                }}

              >

                {t("back")}

              </SecondaryButton>
              <PrimaryButton
                onPress={logoutHandler}

              >

                {t("setting_screen.logout_button")}

              </PrimaryButton>
            </View>
            <View>
              <H2
                style={{
                  textAlign: "center",

                  marginTop: isDesktop ? 0 : 30,
                  marginHorizontal: 20,
                  fontSize: 28,
                  lineHeight: 34,
                }}
              >
                {t("plans.choose_plan")}
              </H2>
              {/** stripe break point to add code */}

              {Platform.OS != "ios" && width < 992 && (
                <Txt style={{ textAlign: "center", marginHorizontal: 15 }}>
                  {t("plans.code_text")}
                </Txt>
              )}
              {/** case is parrained show message */}
              {userData.parrained && (
                <Txt
                  style={{
                    textAlign: "center",
                    marginBottom: 15,
                    marginTop: 15,
                  }}
                >
                  <BoldTxt>
                    {userData.parrainedBy && userData.parrainedBy}{" "}
                  </BoldTxt>
                  {t("parrainage.text2")}
                </Txt>
              )}
            </View>

            {/** cards container */}
            <View style={styles.cardsContainer}>
              <TrialCard userGift={userGift} navigation={navigation} />

              {subscriptions.map((plan) => {
                return (
                  <SubscriptionCard
                    navigation={navigation}
                    key={plan._id}
                    plan={plan}
                  />
                );
              })}
              <LifeTimeCard navigation={navigation} plan={lifetimeData} />
            </View>
            <View style={{ height: 60 }} />
          </View>
        </ScrollView>
        <EulaModal
          //  isOpen={showEula && Platform.OS != "web"}
          isOpen={false}
          setIsOpen={setShowEula}
        />
      </ImageBackground>
    </View>
  );
};

export default Plans;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.beige,
  },
  cardsContainer: {
    width: "95%",
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: 10,
  },
  buttonsContainer: {
    flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
    marginHorizontal: 30, marginTop: 30,
  },
  cardContainer: {
    padding: 15, marginTop: 20, paddingVertical: 25, borderRadius: 15, backgroundColor: '#ffffff99'
  },
  logoutContainer: {
    alignSelf: "flex-end",
    paddingTop: 30,
    paddingRight: 30,
  },
});
