import { HStack, VStack, View } from "native-base";
import React, { useEffect, useState } from "react";
import {
    Image,
    ImageBackground,
    Platform,
    Pressable,
    ScrollView,
} from "react-native";
import Animated, {
    interpolate,
    useAnimatedStyle,
    useSharedValue,
    withTiming,
} from "react-native-reanimated";
import BackHeader from "../../components/BackHeader";
import { colors } from "../../styles/GlobalStyle";
import { H6, Txt } from "../../components/TextsComponents";
import DimensionsHook from "../../hooks/DimensionsHook";
import { LislUp } from "../../assets/svg/Logo";
import { useTranslation } from "react-i18next";
import ConfettiCannon from "react-native-confetti-cannon";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import Spinner from "../../components/Spinner";
import { CircleIcon, DiamondIcon, HeartIcon, PentagonIcon, PlusIcon, SquareIcon, StarIcon, TriangleIcon } from "./CardIcons";
import { Check, } from "../../assets/svg/Coaching";
import { LinearGradient } from "expo-linear-gradient";
import { AntDesign } from "@expo/vector-icons";

const c = [
    {
        id: "11",
        value: 6,
        finished: false,
        image: () => <HeartIcon />,
    },
    {
        id: "1",
        value: 1,
        finished: false,
        image: () => <CircleIcon />,
    },


    {
        id: "4",
        value: 2,
        finished: false,
        image: () => <TriangleIcon />,
    },

    {
        id: "5",
        value: 3,
        finished: false,
        image: () => <SquareIcon />,
    },
    {
        id: "10",
        value: 5,
        finished: false,
        image: () => <PentagonIcon />,
    },
    {
        id: "6",
        value: 3,
        finished: false,
        image: () => <SquareIcon />,
    },
    {
        id: "2",
        value: 1,
        finished: false,
        image: () => <CircleIcon />,
    },
    {
        id: "7",
        value: 4,
        finished: false,
        image: () => <PlusIcon />,
    },
    {
        id: "8",
        value: 4,
        finished: false,
        image: () => <PlusIcon />,
    },
    {
        id: "14",
        value: 7,
        finished: false,
        image: () => <StarIcon />,
    },
    {
        id: "9",
        value: 5,
        finished: false,
        image: () => <PentagonIcon />,
    },

    {
        id: "3",
        value: 2,
        finished: false,
        image: () => <TriangleIcon />,
    },

    {
        id: "12",
        value: 6,
        finished: false,
        image: () => <HeartIcon />,
    },

    {
        id: "13",
        value: 7,
        finished: false,
        image: () => <StarIcon />,
    },

    {
        id: "15",
        value: 8,
        finished: false,
        image: () => <DiamondIcon />,
    },
    {
        id: "16",
        value: 8,
        finished: false,
        image: () => <DiamondIcon />,
    },
];

function shuffleArray(arr) {
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
}

const CardFlip = ({ navigation }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile } = DimensionsHook();
    let selected = null;
    let loader = false

    const [cards, setCards] = useState([])


    useEffect(() => {
        setCards(shuffleArray(c))
    }, [])


    const [showSuccess, setShowSuccess] = useState(false);
    const [gameLoader, setGameLoader] = useState(false)

    const [finished, setFinished] = useState(false)
    const [finished1, setFinished1] = useState(false)
    const [finished2, setFinished2] = useState(false)
    const [finished3, setFinished3] = useState(false)
    const [finished4, setFinished4] = useState(false)
    const [finished5, setFinished5] = useState(false)
    const [finished6, setFinished6] = useState(false)
    const [finished7, setFinished7] = useState(false)
    const [finished8, setFinished8] = useState(false)
    const [finished9, setFinished9] = useState(false)
    const [finished10, setFinished10] = useState(false)
    const [finished11, setFinished11] = useState(false)
    const [finished12, setFinished12] = useState(false)
    const [finished13, setFinished13] = useState(false)
    const [finished14, setFinished14] = useState(false)
    const [finished15, setFinished15] = useState(false)
    const [finished16, setFinished16] = useState(false)

    const spin1 = useSharedValue(0);
    const spin2 = useSharedValue(0);
    const spin3 = useSharedValue(0);
    const spin4 = useSharedValue(0);
    const spin5 = useSharedValue(0);
    const spin6 = useSharedValue(0);
    const spin7 = useSharedValue(0);
    const spin8 = useSharedValue(0);
    const spin9 = useSharedValue(0);
    const spin10 = useSharedValue(0);
    const spin11 = useSharedValue(0);
    const spin12 = useSharedValue(0);
    const spin13 = useSharedValue(0);
    const spin14 = useSharedValue(0);
    const spin15 = useSharedValue(0);
    const spin16 = useSharedValue(0);


    const finishedHandler = (val) => {
        if (val == 0) {
            return finished1
        }
        else if (val === 1) {
            return finished2
        }
        else if (val === 2) {
            return finished3
        }
        else if (val === 3) {
            return finished4
        }
        else if (val === 4) {
            return finished5
        }
        else if (val === 5) {
            return finished6
        }
        else if (val === 6) {
            return finished7
        }
        else if (val === 7) {
            return finished8
        }
        else if (val === 8) {
            return finished9
        }
        else if (val === 9) {
            return finished10
        }
        else if (val === 10) {
            return finished11
        }
        else if (val === 11) {
            return finished12
        }
        else if (val === 12) {
            return finished13
        }
        else if (val === 13) {
            return finished14
        }
        else if (val === 14) {
            return finished15
        } else {
            return finished16
        }
    }

    const setFinishedHandler = (valOfCard, valueOfFinished) => {
        if (valOfCard == 0) {
            setFinished1(valueOfFinished)
        }
        else if (valOfCard === 1) {
            setFinished2(valueOfFinished)
        }
        else if (valOfCard === 2) {
            setFinished3(valueOfFinished)
        }
        else if (valOfCard === 3) {
            setFinished4(valueOfFinished)
        }
        else if (valOfCard === 4) {
            setFinished5(valueOfFinished)
        }
        else if (valOfCard === 5) {
            setFinished6(valueOfFinished)
        }
        else if (valOfCard === 6) {
            setFinished7(valueOfFinished)
        }
        else if (valOfCard === 7) {
            setFinished8(valueOfFinished)
        }
        else if (valOfCard === 8) {
            setFinished9(valueOfFinished)
        }
        else if (valOfCard === 9) {
            setFinished10(valueOfFinished)
        }
        else if (valOfCard === 10) {
            setFinished11(valueOfFinished)
        }
        else if (valOfCard === 11) {
            setFinished12(valueOfFinished)
        }
        else if (valOfCard === 12) {
            setFinished13(valueOfFinished)
        }
        else if (valOfCard === 13) {
            setFinished14(valueOfFinished)
        }
        else if (valOfCard === 14) {
            setFinished15(valueOfFinished)
        } else {
            return setFinished16(valueOfFinished)
        }
    }

    const spinHandler = (val) => {
        if (val == 0) {
            return spin1
        }
        else if (val === 1) {
            return spin2
        }
        else if (val === 2) {
            return spin3
        }
        else if (val === 3) {
            return spin4
        }
        else if (val === 4) {
            return spin5
        }
        else if (val === 5) {
            return spin6
        }
        else if (val === 6) {
            return spin7
        }
        else if (val === 7) {
            return spin8
        }
        else if (val === 8) {
            return spin9
        }
        else if (val === 9) {
            return spin10
        }
        else if (val === 10) {
            return spin11
        }
        else if (val === 11) {
            return spin12
        }
        else if (val === 12) {
            return spin13
        }
        else if (val === 13) {
            return spin14
        }
        else if (val === 14) {
            return spin15
        } else {
            return spin16
        }
    }

    const setNumberOfFinishedHandler = () => {
        if (finished == 7) {
            setTimeout(() => {
                setShowSuccess(true)
            }, 100);
        } else {
            setFinished(finished + 1)
        }
    }

    const playAgainHandler = async () => {
        const number = Math.random()

        if (number < 0.5) {
            if (!gameLoader) {
                setGameLoader(true)
                const TOKEN = await AsyncStorage.getItem("access_token");
                const config = {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                    },
                };
                axios
                    .get(
                        `${process.env.GAMES}/random-game`,
                        config
                    )
                    .then((res) => {

                        setGameLoader(false);
                        navigation.replace('DominoGame', res.data)
                    })
                    .catch((err) => {
                        setGameLoader(false);

                    });
            }
        } else {
            navigation.replace('CardFlip')
        }

    };


    const CardRender = ({ card, i, spin, finishedCard }) => {
        const rStyle = useAnimatedStyle(() => {
            const spinVal = interpolate(spin.value, [0, 1], [0, 180]);
            return {
                transform: [
                    {
                        rotateY: withTiming(`${spinVal}deg`, { duration: 400 }),
                    },
                ],
            };
        }, []);

        const bStyle = useAnimatedStyle(() => {
            const spinVal = interpolate(spin.value, [0, 1], [180, 360]);
            return {
                transform: [
                    {
                        rotateY: withTiming(`${spinVal}deg`, { duration: 400 }),
                    },
                ],
            };
        }, []);

        const pressHandler = () => {
            if (selected && selected.i == i || finishedCard) {
                // same card bug fix and finished one
            } else {
                if (!loader) {
                    loader = true
                    if (spin.value == 0) {
                        if (selected == null) {
                            spin.value = 1;
                            selected = { ...card, i };
                            loader = false
                        } else {
                            if (selected.value == card.value) {
                                spin.value = 1;

                                setFinishedHandler(i, true)
                                setFinishedHandler(selected.i, true)
                                setNumberOfFinishedHandler()
                                selected = null;
                                loader = false


                            } else {
                                spin.value = 1;
                                setTimeout(() => {
                                    spin.value = 0;
                                    const firstSelectedSpin = spinHandler(Number(selected.i))
                                    firstSelectedSpin.value = 0
                                    selected = null
                                    loader = false
                                }, 500);
                            }
                        }
                    }
                }
            }

        };

        return (
            <View

                style={{
                    width: "23%",
                    marginTop: 10,
                }}
            >
                <View>
                    <Animated.View
                        style={[
                            rStyle,
                            {
                                aspectRatio: 3 / 4,
                                width: "100%",
                                backgroundColor: colors.grayLabel,
                                position: "absolute",
                                backfaceVisibility: "hidden",
                                borderRadius: 16,
                            },
                        ]}
                    >
                        <ImageBackground
                            source={require("../../assets/background.png")}
                            style={{
                                flex: 1,
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <VStack style={{ width: "50%", aspectRatio: 1 / 1, opacity: 1 }}>
                                <LislUp color="#f5f5f4" />
                            </VStack>
                        </ImageBackground>

                    </Animated.View>
                </View>
                <Pressable onPress={pressHandler}>
                    <Animated.View
                        style={[
                            bStyle,
                            {
                                aspectRatio: 3 / 4,
                                width: "100%",
                                backgroundColor: colors.beige,
                                backfaceVisibility: "hidden",
                                borderRadius: 16,
                                opacity: finishedCard ? 0.6 : 1
                            },
                        ]}
                    >
                        <ImageBackground
                            source={require("../../assets/background.png")}
                            style={{
                                flex: 1,
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <VStack alignSelf='center' style={{ width: '40%', aspectRatio: 1 / 1, alignItems: "center", justifyContent: 'center' }}>
                                {card.image()}

                            </VStack>

                        </ImageBackground>

                    </Animated.View>
                    {finishedCard && <VStack position='absolute' style={{ top: -4, right: -4, borderRadius: 100, backgroundColor: '#fff' }}>
                        <AntDesign name="checkcircle" size={20} color={colors.green3} />
                    </VStack>}
                </Pressable>
            </View>
        );
    };


    if (cards.length == 0) {
        return (
            <View flex={1} bg={colors.beige}>
                <BackHeader
                    isOpen={false}
                    setIsOpen={() => { }}
                    navigation={navigation}
                />
                <ImageBackground
                    source={require("../../assets/background.png")}
                    style={{ flex: 1 }}
                >

                </ImageBackground>
            </View>
        )
    }
    return (
        <View flex={1} bg={colors.beige}>
            <ImageBackground
                source={require("../../assets/background.png")}
                style={{ flex: 1 }}
            >
                {showSuccess ? (
                    <VStack overflow="hidden" flex={1} w="full">
                        <BackHeader
                            isOpen={false}
                            setIsOpen={() => { }}
                            navigation={navigation}
                        />
                        <ConfettiCannon
                            autoStart
                            fallSpeed={2000}
                            colors={[
                                colors.green2,
                                colors.green3,
                                colors.red1,
                                colors.yellow,
                                colors.blue2,
                            ]}
                            count={160}
                            origin={{ x: -10, y: 0 }}
                        />
                        <VStack
                            style={{ width: "95%", alignSelf: "center", marginTop: -50 }}
                            flex={1}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Image
                                source={{
                                    uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
                                }}
                                style={{ width: 80, height: 130, marginBottom: 10 }}
                            />
                            <H6>{t('card_game.text3')}</H6>

                            <VStack style={{ maxWidth: 600 }} mt="4">
                                <Txt style={{ textAlign: "center" }}>
                                    {t('card_game.text4')}
                                </Txt>
                            </VStack>
                            <HStack
                                style={{ marginTop: 30 }}
                                alignItems="center"
                                justifyContent="center"
                                space="4"
                            >
                                <SecondaryButton onPress={() => navigation.goBack()}>
                                    {t("challenge_modal.back")}
                                </SecondaryButton>
                                <PrimaryButton
                                    onPress={playAgainHandler}
                                >
                                    {gameLoader ? <Spinner color="white" /> : t('card_game.play')}
                                </PrimaryButton>
                            </HStack>
                        </VStack>
                    </VStack>
                ) : (
                    <ScrollView showsVerticalScrollIndicator={Platform.OS == "web"}>
                        <BackHeader
                            isOpen={false}
                            setIsOpen={() => { }}
                            navigation={navigation}
                        />
                        <VStack style={{ maxWidth: "95%", alignSelf: "center" }}>
                            <View
                                style={{
                                    flexDirection: isTablet || isMobile ? "column" : "row",
                                }}
                                alignItems="stretch"
                                justifyContent="space-between"
                            >
                                <VStack
                                    style={{ marginTop: isTablet || isMobile ? 15 : -20 }}
                                    w={isTablet || isMobile ? "full" : "1/2"}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <VStack>
                                        <Image
                                            source={{
                                                uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
                                            }}
                                            style={{
                                                height: 130,
                                                width: 80,
                                                marginBottom: 15,
                                            }}
                                        />
                                    </VStack>
                                    <H6>{t("card_game.title")}</H6>
                                    <VStack mt="3">
                                        <Txt
                                            style={{
                                                textAlign: "center",
                                                fontSize: 15,
                                                marginTop: 10,
                                            }}
                                        >
                                            {t("card_game.text1")}
                                        </Txt>
                                    </VStack>
                                    <VStack mt="2">
                                        <Txt
                                            style={{
                                                textAlign: "center",
                                                fontSize: 15,
                                                marginTop: 15,
                                            }}
                                        >
                                            {t("card_game.text2")}
                                        </Txt>
                                    </VStack>
                                </VStack>
                                <VStack
                                    w={isTablet || isMobile ? "full" : "1/2"}
                                    alignContent="center"
                                    justifyContent="center"
                                    mb={isTablet || isMobile ? "10" : "0"}
                                >
                                    <LinearGradient
                                        colors={['#9e989880', '#9e989840', '#9e989880']}
                                        start={{ x: 0.5, y: 0.5 }} // Center
                                        end={{ x: 1, y: 1 }} // Bottom right corner
                                        style={{
                                            alignSelf: "center",
                                            width: "100%",
                                            maxWidth: 450,
                                            paddingVertical: 15,
                                            paddingHorizontal: isTablet || isMobile ? 10 : 30,
                                            marginVertical: isTablet || isMobile ? 20 : 50,

                                            borderRadius: 15,
                                        }}
                                    >
                                        <View
                                            style={{
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                flexWrap: "wrap",
                                            }}
                                        >
                                            {cards.map((card, i) => {
                                                return (
                                                    <CardRender
                                                        key={card.id}
                                                        i={i}
                                                        card={card}
                                                        spin={
                                                            spinHandler(i)

                                                        }
                                                        finishedCard={finishedHandler(i)}

                                                    />
                                                );
                                            })}
                                        </View>
                                    </LinearGradient>
                                </VStack>
                            </View>
                        </VStack>
                    </ScrollView>
                )}
            </ImageBackground>
        </View>
    );
};

export default CardFlip;
