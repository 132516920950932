import { ImageBackground, StyleSheet, View } from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { ScrollView } from "react-native";
import { BoldTxt, H4, Txt } from "../components/TextsComponents";
import { colors } from "../styles/GlobalStyle";
import DimensionsHook from "../hooks/DimensionsHook";
import { LislUp } from "../assets/svg/Logo";
import { GiftScreenImage } from "../assets/svg/Gift";
import { Input } from "../components/Inputs";
import { useTranslation } from "react-i18next";
import { PrimaryButton, SecondaryButton } from "../components/Buttons";
import Spinner from "../components/Spinner";
import axios from "axios";
import GiftPlansModal from "../components/myaccount/BuyScreen/modals/GiftPlansModal";
import { TouchableOpacity } from "react-native";
import { useHover } from "react-native-web-hooks";
import LanguageModal from "../components/modals/LanguageModals";
import { LanguageIcon } from "../assets/svg/Icons";
import AsyncStorage from "@react-native-async-storage/async-storage";

const GiftWeb = () => {
  const { height, width } = DimensionsHook();
  const isDesktop = width > 1000;
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const languageRef = useRef(null);
  const isLanguageHovered = useHover(languageRef);
  const [showGiftPlansModal, setShowGiftPlansModal] = useState(false);
  const [gifts, setGifts] = useState([]);
  const [errorEmail, setErrorEmail] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const submitHandler = () => {
    if (!submitLoader) {
      setSubmitLoader(true);
      setErrorMessage("");
      axios
        .get(process.env.GIFTS_LIST)
        .then((res) => {
          setGifts(res.data);
          // check email
          axios
            .post(process.env.GIFT_CHECK_EMAIL, {
              email: email.toLowerCase().trim(),
            })
            .then((res) => {
              setSubmitLoader(false);
              setShowGiftPlansModal(true);
            })
            .catch((err) => {
              setSubmitLoader(false);
              if (err.response.status === 409) {
                setErrorMessage(t("plans_gift.error_email"));
              } else {
                setErrorMessage("parrainage.error_connexion");
              }
            });
        })
        .catch((err) => {
          setSubmitLoader(false);
          setErrorMessage("parrainage.error_connexion");
        });
    }
  };

  const removeFromStorage = async () => {
    await AsyncStorage.removeItem("gift");
  };
  useEffect(() => {
    removeFromStorage();
  }, []);

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView>
          <View style={{ minHeight: height }}>
            <View style={styles.header}>
              <View style={styles.logoContainer}>
                <LislUp />
              </View>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <TouchableOpacity
                  ref={languageRef}
                  style={[
                    styles.openLanguageModalButton,
                    {
                      backgroundColor: isLanguageHovered
                        ? colors.blue3
                        : colors.beige,
                    },
                  ]}
                  onPress={() => setShowLanguageModal(true)}
                >
                  <View style={{ width: 24, height: 24 }}>
                    <LanguageIcon
                      color={isLanguageHovered ? colors.white : colors.blue3}
                    />
                  </View>
                </TouchableOpacity>
                <SecondaryButton
                  onPress={() => {
                    window.location.href = "/";
                  }}
                >
                  {t("go_app")}
                </SecondaryButton>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                {/** left section */}
                <View
                  style={{
                    display: isDesktop ? "flex" : "none",
                    height: "100%",
                    alignItems: "center",

                    width: "40%",
                  }}
                >
                  <View
                    style={{
                      width: "95%",
                      aspectRatio: 1 / 1,
                    }}
                  >
                    <GiftScreenImage />
                  </View>
                </View>
                {/** right section */}
                <View
                  style={{
                    height: "100%",
                    alignItems: "center",
                    width: isDesktop ? "60%" : "100%",
                  }}
                >
                  <View
                    style={{
                      width: "95%",
                      alignSelf: "center",
                      marginVertical: 20,
                      marginHorizontal: 10,
                      paddingTop: isDesktop ? 50 : 20,
                      paddingHorizontal: isDesktop ? 20 : 0,
                    }}
                  >
                    <BoldTxt
                      style={{
                        fontSize: 15,
                        color: colors.red1,
                        textAlign: "center",
                        marginTop: -20,
                        marginBottom: 20,
                      }}
                    >
                      {errorMessage === "" ? "  " : errorMessage}
                    </BoldTxt>
                    <H4>{t("buy_screen.lower.text2")}</H4>
                    <Txt
                      style={{ fontSize: 15, marginTop: 10, lineHeight: 20 }}
                    >
                      {t("plans_gift.text1")}
                    </Txt>
                    <BoldTxt style={{ fontSize: 14, marginTop: 20 }}>
                      {t("placeholders.email_destinataire")}
                    </BoldTxt>

                    <View>
                      <Input
                        onBlur={() => {
                          if (!errorEmail && email.length > 3) {
                            submitHandler();
                          }
                        }}
                        textContentType="emailAddress"
                        kayboardType="email-address"
                        placeholder={t("placeholders.email")}
                        value={email}
                        error={errorEmail}
                        onChangeText={(val) => {
                          // CHECK IF THE EMAIL IS VALID
                          setEmail(val);
                          const re = /\S+@\S+\.\S+/;
                          const isValid = re.test(val);
                          setErrorEmail(!isValid);
                        }}
                      />
                    </View>
                    <PrimaryButton
                      onPress={submitHandler}
                      disabled={errorEmail || email.length == 0}
                      style={{
                        marginTop: 20,
                        marginBottom: 40,
                        height: 50,
                        width: 300,
                        alignSelf: "flex-end",
                      }}
                    >
                      {submitLoader ? (
                        <Spinner color="white" />
                      ) : (
                        t("plans_gift.button1")
                      )}
                    </PrimaryButton>
                  </View>
                </View>
              </View>
            </View>
            {/** gifts plan modal */}
            <GiftPlansModal
              isOpen={showGiftPlansModal}
              setIsOpen={setShowGiftPlansModal}
              email={email}
              gifts={gifts}
              setEmail={setEmail}
              GoHome={true}
            />
            {/** language modal */}
            <LanguageModal
              showModal={showLanguageModal}
              setShowModal={setShowLanguageModal}
              withRedirect={true}
            />
          </View>
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

export default GiftWeb;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.beige,
  },
  header: {
    height: 90,
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 20,

    justifyContent: "space-between",
  },
  logoContainer: {
    width: 70,
    height: 70,
  },
  openLanguageModalButton: {
    width: 42,
    height: 42,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 15,
    elevation: 4,
    shadowColor: "black",
    shadowOffset: { width: 0, height: 0.5 * 4 },
    shadowOpacity: 0.25,
    shadowRadius: 0.8 * 4,
  },
});
