import { StyleSheet, View, ScrollView, ImageBackground } from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { colors } from "../../styles/GlobalStyle";
import BackHeader from "../../components/BackHeader";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import Spinner from "../../components/Spinner";
import { H2, SmallBoldTxt } from "../../components/TextsComponents";
import DimensionsHook from "../../hooks/DimensionsHook";
import { useTranslation } from "react-i18next";
import Book from "./Book";
import AlertBar from "../../components/AlertBar";
import { useSelector } from "react-redux";
import SuccessBuyBookViaCompany from "../../components/modals/SuccessBuyBookViaCompany";

const Books = ({ navigation, route }) => {
  const { gift } = route.params;
  // console.log(gift);
  const { isMobile } = DimensionsHook();
  const [loader, setLoader] = useState(true);
  const { email, company } = useSelector((state) => state.userData);
  const [books, setBooks] = useState([]);
  const [buyViaCompany, setBuyViaCompany] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSuccessPayment, setShowSuccessPayment] = useState(false);
  const [showSuccessBuyViaCompany, setShowSuccessBuyViaCompany] =
    useState(false);
  const { t } = useTranslation();

  const scrollRef = useRef();

  const scrollToTop = () => {
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  };

  const getBooks = async () => {
    const url = process.env.BOOKS_ENDPOINT;
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };

    if (company && !gift) {
      const res = await axios.get(
        `${process.env.PAYMENT_APIS}/check-company-offers?company_id=${company}`,
        config
      );
      setBuyViaCompany(res.data.ebooks);
    }

    await axios.get(url, config).then((res) => {
      setBooks(res.data);

      setLoader(false);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      getBooks();
    }, 0);
  }, []);

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../../assets/background.png")}
        style={{ flex: 1 }}
      >
        <BackHeader
          isOpen={false}
          setIsOpen={() => {}}
          navigation={navigation}
        />
        {loader ? (
          <View style={styles.loaderContainer}>
            <Spinner size="large" />
          </View>
        ) : (
          <ScrollView
            ref={scrollRef}
            keyboardShouldPersistTaps="handled"
            showsVerticalScrollIndicator={false}
          >
            <View style={{ marginHorizontal: "2.5%" }}>
              <H2 style={{ marginTop: isMobile ? 20 : 25 }}>
                {gift ? t("books.gift_title") : t("books.title")}
              </H2>
              <SmallBoldTxt
                style={{
                  color: colors.red1,
                  textAlign: "center",
                  marginTop: 10,
                }}
              >
                {errorMessage ? errorMessage : " "}
              </SmallBoldTxt>
              <View style={styles.booksContainer}>
                {books.map((book) => {
                  return (
                    <Book
                      buyViaCompany={buyViaCompany}
                      setShowSuccessPayment={setShowSuccessPayment}
                      setErrorMessage={setErrorMessage}
                      scrollToTop={scrollToTop}
                      isGift={gift}
                      book={book}
                      key={book._id}
                      setShowSuccessBuyViaCompany={setShowSuccessBuyViaCompany}
                    />
                  );
                })}
              </View>
            </View>
          </ScrollView>
        )}
        <AlertBar
          isOpen={showSuccessPayment}
          setIsOpen={setShowSuccessPayment}
          text={`${t("books.ios_success")} ${email}`}
        />
        {/** modal buy via company success */}
        <SuccessBuyBookViaCompany
          isOpen={showSuccessBuyViaCompany}
          setIsOpen={setShowSuccessBuyViaCompany}
          navigation={navigation}
        />
      </ImageBackground>
    </View>
  );
};

export default Books;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },
  loaderContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  booksContainer: {
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    marginBottom: 20,
  },
});
