import { StyleSheet, Image, TouchableOpacity, Pressable } from "react-native";
import React, { useRef, useState, useEffect } from "react";
import DimensionsHook from "../../hooks/DimensionsHook";
import { colors } from "../../styles/GlobalStyle";
import { BoldTxt, H1, H5, LightTxt, Txt } from "../TextsComponents";
import { LinearGradient } from "expo-linear-gradient";
import { useHover } from "react-native-web-hooks";
import { FavoriteIcon, LikeIcon, PlayIcon } from "../../assets/svg/Icons";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import FirstVisitModal from "../modals/FirstVisitModal";
import ChooseVideoModal from "../modals/ChooseVideoModal";
import ChallengeModal from "../modals/ChallengeModal";
import FinishEpisodeModal from "../modals/FinishEpisodeModal";
import GreenModal from "../modals/GreenModal";
import BuyTrailModal from "../modals/EpisodesModal/BuyTrailModal";
import NeedSubscriptionModal from "../modals/EpisodesModal/NeedSubscriptionModal";
import MaxEpisodesWatchedModal from "../modals/EpisodesModal/MaxEpisodesWatchedModal";
import NoAccessModal from "../modals/EpisodesModal/NoAccessModal";
import { Entypo, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import NavigateToQuestionnaireModal from "../modals/QuestionnairesModal/NavigateToQuestionnaireModal";
import NavigateToPostQuestionnaireModal from "../modals/QuestionnairesModal/NavigateToPostQuestionnaireModal";

import { Divider, HStack, VStack, View } from "native-base";
import { setSavedEpisodes, setUserActivity } from "../../redux/Actions";
import RecommandedSwiper from "./RecommandedSwiper";
import OutsidePressHandler from "react-native-outside-press";
import { DropDown } from "../Inputs";
import Footer from "../Footer";
import { CustomTooltip } from "../Tooltips";
import { ImageBackground } from "react-native";
import { Check } from "../../assets/svg/Coaching";

const UpperSection = ({
  item,
  selectedSeason,
  sequenceEpisodes,
  setIsOpenFirstVisit,
  isOpenFirstVisit,
  setShowPrealableVideoModal,
  seasons,
  navigation,
  setSelectedEpisode,
  setShowTrailVideoModal,
  selectedEpisode,
  setIsOpenChooseModal,
  setIsOpenGreenModal,
  postQuestionnaire,
  setShowPostQuestionnaire,
  showPostQuestionnaire,
  isOpenChooseModal,
  isOpenGreenModal,
  setSelectedWorkshop,
  setShowMovementVideoModal,
  loader,
  setSelectedSeason,
  showSeason,
  setShowSeason,
  seasonOptions,
  setSeasonOptions,
  currentSeason,
  setCurrentSeason,
  episodes,
  backFromEpisode, setBackFromEpisode


}) => {
  const { width, isDesktop, isBigScreen, isTablet } =
    DimensionsHook();
  const { i18n, t } = useTranslation();

  const watched_episodes = useSelector((state) => state.watchedEpisodes);
  const { trails } = useSelector((state) => state.userActivity);
  const questionnairesData = useSelector((state) => state.questionnairesData);
  const amontQuestionnaire = questionnairesData.find((el) => {
    return el.trail == item.trail_code && el.amont == true;
  });

  const likeRef = useRef(null);
  const favoriteRef = useRef(null);
  const likeHover = useHover(likeRef);
  const favoriteHover = useHover(favoriteRef);

  const container = {
    width: isBigScreen ? "90%" : "95%",
    alignSelf: "center",
  };

  useEffect(() => {
    let s = [];
    Array.from(Array(seasons.length).keys()).forEach((i) => {
      s.push({ label: `${t("trail.season")} ${i + 1}`, value: i });
    });
    setSeasonOptions(s);

    const fromSeason = seasons.indexOf(selectedSeason);

    if (fromSeason > -1) {
      setCurrentSeason(s[fromSeason]);
    }
  }, [seasons, selectedSeason]);


  const saved_episodes = useSelector((state) => state.savedEpisodes);

  const [isOpenChallenge, setIsOpenChallenge] = useState(false);

  const [isOpenFinishEpisode, setIsOpenFinishEpisode] = useState(false);
  const [isOpenAmontQuestionnaire, setIsOpenAmontQuestionnaire] =
    useState(false);

  const [isOpenMovementGreenModal, setIsOpenMovementGreenModal] =
    useState(false);
  const [isOpenBuyTrailModal, setIsOpenBuyTrailModal] = useState(false);
  const [isOpenNeedSubscriptionModal, setIssOpenNeedSubscriptionModal] =
    useState(false);
  const [isOpenMaxEpisodesModal, setIsOpenMaxEpisodesModal] = useState(false);

  const [showNoAccessModal, setShowNoAccessModal] = useState(false);




  useEffect(() => {
    if (item.backFromQuestionnaire) {
      setShowTrailVideoModal(true);
      setIsOpenChooseModal(false);
      setIsOpenFirstVisit(false);
    }
  }, [item]);

  const EpisodeItem = ({ ep, index }) => {
    const hoverRef = useRef(null);
    const isHovered = useHover(hoverRef);
    const favoriteEpRef = useRef(null);
    const favoriteEpHover = useHover(favoriteEpRef);
    const playRef = useRef(null);
    const playHover = useHover(playRef);
    const inSavedList = saved_episodes.includes(ep.episode_code);
    const [isSaved, setIsSaved] = useState(inSavedList);

    const indexOfEpisode = sequenceEpisodes.indexOf(ep.episode_code);
    const previousEpisode = sequenceEpisodes[indexOfEpisode - 1];
    // FOR EXAMPLE TRAIL FREE || TRAIL IS UNLOCKED WE CAN WATCH START ANY SEASON
    const isFirstEpisodeAccessible =
      ep.episode_code.includes("E1") &&
      (item.free || trails.includes(item.trail_code));

    const accessible = ep.episode_code.includes("S1-E1")
      ? true
      : isFirstEpisodeAccessible
        ? true
        : watched_episodes.includes(previousEpisode)
          ? true
          : false;
    const isTheLastEpisode =
      sequenceEpisodes[sequenceEpisodes.length - 1] == ep.episode_code;
    const isWatched = watched_episodes.includes(ep.episode_code);

    const isLastEpisodeInSeason =
      selectedSeason.episodes[selectedSeason.episodes.length - 1] ==
      ep.episode_code;

    const addToSavedHandler = async () => {
      setIsSaved(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      axios
        .post(process.env.USER_SAVED_EPISODES, {
          access_token: TOKEN,
          episode_code: ep.episode_code,
        })
        .then(() => dispatch(setSavedEpisodes()));
    };
    const removeFromSavedHandler = async () => {
      setIsSaved(false);
      const TOKEN = await AsyncStorage.getItem("access_token");
      axios
        .put(process.env.USER_SAVED_EPISODES, {
          access_token: TOKEN,
          episode_code: ep.episode_code,
        })
        .then(() => dispatch(setSavedEpisodes()));
    };

    return (
      <HStack w="full" space="2" alignItems="center">

        <VStack borderLeftWidth="4" borderRadius="full" borderColor={!accessible ? '#48484860' : isHovered ? colors.blue3 : isWatched ? colors.green2 : '#484848'} h="1/2"></VStack>

        <Pressable
          disabled={!accessible}
          onPress={() => {

            setSelectedEpisode({
              ...ep,
              number: index + 1,
              isTheLastEpisode,
              isLastEpisodeInSeason,
              trail_code: item.trail_code,
              trail_free: item.free,
              daily_limit: item.daily_limit,
              poster_link: item.poster_link,
            });
            // if it s the first season first episode then show prealable first episode modal
            if (ep.episode_code.includes("S1-E1")) {
              setIsOpenFirstVisit(true);
            } else {
              if (isWatched) {
                setIsOpenChooseModal(true);
              } else {
                setIsOpenChallenge(true);
              }
            }
          }}
          ref={hoverRef}
          style={{
            flex: 1,
            height: 150,
            marginTop: 10,
            borderRadius: 10,
            overflow: 'hidden',
            cursor: accessible ? "pointer" : "not-allowed",
            backgroundColor: !accessible
              ? colors.white
              : isHovered
                ? '#ffffff90'
                : isWatched
                  ? colors.white
                  : colors.white,
          }}
        >
          <ImageBackground
            source={require("../../assets/background.png")}
            style={{ flex: 1, padding: 10 }}
          >
            <HStack h="full" w="full" alignItems="center" space="3">
              {width > 650 && <VStack style={{ overflow: 'hidden', borderRadius: 15 }}>
                <Image source={{ uri: selectedSeason.poster_link }} style={{ height: 120, aspectRatio: 16 / 9, }} />
                <LinearGradient
                  colors={["#00000020", "#00000090"]}
                  style={{
                    height: 120, aspectRatio: 16 / 9,
                    position: "absolute",
                  }}
                />
              </VStack>}
              <VStack flex={1} >

                {/** title and duration */}
                <HStack
                  flex={1}
                  w="full"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <VStack>
                    <HStack>
                      <H5
                        style={{ fontSize: 14, color: '#484848' }}
                      /*color={
                        !accessible
                          ? colors.white
                          : isHovered
                            ? colors.green2
                            : isWatched
                              ? colors.blue3
                              : colors.white
                      }*/
                      >
                        {currentSeason.label} - {t("trail_modal.episode")} {index + 1}
                      </H5>
                      {isWatched && (
                        <View
                          style={{
                            width: 14,
                            height: 14,
                            marginLeft: 5,
                          }}
                        >
                          <Check
                            color={colors.green2}
                            fillColor={colors.white}
                          />
                        </View>
                      )}
                    </HStack>
                    <VStack mt="2">
                      <H5 style={{ fontSize: 16 }}>{ep.title}</H5>

                    </VStack>
                  </VStack>
                  {ep.duration && (
                    <Txt
                      style={{ fontSize: 14 }}
                    /* color={
                       !accessible
                         ? colors.white
                         : isHovered
                           ? colors.green2
                           : isWatched
                             ? "#484848"
                             : colors.white
                     }*/
                    >
                      <MaterialCommunityIcons
                        name="clock-fast"
                        size={16}
                        /*color={
                          !accessible
                            ? colors.white
                            : isHovered
                              ? colors.green2
                              : isWatched
                                ? "#484848"
                                : colors.white
                        }*/
                        color='#1b4965'
                      />
                      {` ${ep.duration} min`}
                    </Txt>
                  )}
                </HStack>
                {/** buttons */}
                <VStack style={{ flex: 1, }}>
                  <HStack alignItems='flex-end' flex={1} justifyContent="space-between">
                    {/** play button */}
                    <TouchableOpacity
                      disabled={!accessible}
                      ref={playRef}
                      onPress={() => {

                        setSelectedEpisode({
                          ...ep,
                          number: index + 1,
                          isTheLastEpisode,
                          isLastEpisodeInSeason,
                          trail_code: item.trail_code,
                          trail_free: item.free,
                          daily_limit: item.daily_limit,
                          poster_link: item.poster_link,
                        });
                        // if it s the first season first episode then show prealable first episode modal
                        if (ep.episode_code.includes("S1-E1")) {
                          setIsOpenFirstVisit(true);
                        } else {
                          if (isWatched) {
                            setIsOpenChooseModal(true);
                          } else {
                            setIsOpenChallenge(true);
                          }
                        }
                      }}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: !accessible ? '#48484830' : playHover
                          ? colors.blue3
                          : colors.green2,
                        borderRadius: 100,
                        height: 35,
                        width: 140,
                      }}
                    >
                      <Entypo
                        name="controller-play"
                        size={24}
                        color="white"
                        style={{ marginRight: 4 }}
                      />
                      <BoldTxt style={{ fontSize: 13, color: "#ffffff" }}>
                        {t("trail_modal.play")}
                      </BoldTxt>
                    </TouchableOpacity>
                    {!accessible ? (
                      <View style={{ height: 50, justifyContent: 'flex-end' }}>
                        <Ionicons
                          name="lock-closed"
                          size={24}
                          color={"#484848"}
                        />
                      </View>
                    ) : isWatched ? (
                      <View  >
                        {isSaved ? (
                          <View >
                            <CustomTooltip
                              placement="left"
                              bg={colors.red1}
                              color={colors.white}
                              label={t("saved.remove")}
                            >
                              <TouchableOpacity
                                ref={favoriteEpRef}
                                onPress={removeFromSavedHandler}
                                style={[
                                  styles.button2,
                                  {
                                    borderColor: favoriteEpHover
                                      ? colors.red0
                                      : colors.red1,
                                    backgroundColor: favoriteEpHover
                                      ? colors.red0
                                      : colors.red1,
                                  },
                                ]}
                              >
                                <FavoriteIcon color={colors.white} />
                              </TouchableOpacity>
                            </CustomTooltip>
                          </View>
                        ) : (
                          <View  >
                            <CustomTooltip
                              placement="left"
                              bg={colors.red1}
                              color={colors.white}
                              label={t("saved.add")}
                            >
                              <TouchableOpacity
                                ref={favoriteEpRef}
                                onPress={addToSavedHandler}
                                style={[
                                  styles.button2,
                                  {
                                    borderColor: favoriteEpHover
                                      ? colors.red0

                                      : "#484848",
                                    backgroundColor: favoriteEpHover
                                      ? colors.red0
                                      : "transparent",
                                  },
                                ]}
                              >
                                <FavoriteIcon
                                  color={
                                    favoriteEpHover
                                      ? colors.white
                                      :
                                      "#484848"
                                  }
                                />
                              </TouchableOpacity>
                            </CustomTooltip>
                          </View>
                        )}
                      </View>
                    ) : <View style={{ height: 50 }}></View>}
                  </HStack>

                </VStack>

              </VStack>

            </HStack>
          </ImageBackground>
        </Pressable>
      </HStack >
    );
  };

  // liked and favorite user lists
  const userActivity = useSelector((state) => state.userActivity);
  const liked = userActivity.liked_trails;
  const favourite = userActivity.favourite_trails;

  // like favorite states
  const [isLiked, setIsLiked] = useState(liked.includes(item.trail_code));
  const [isFavorite, setIsFavorite] = useState(
    favourite.includes(item.trail_code)
  );
  // PRESS LIKE & FAVOURITE FUNCTIONS
  const dispatch = useDispatch();
  const likePressHandler = async () => {
    setIsLiked(!isLiked);
    const TOKEN = await AsyncStorage.getItem("access_token");

    axios
      .post(process.env.USER_LIKE_TRAILS, {
        access_token: TOKEN,
        trail_code: item.trail_code,
      })
      .then(() => dispatch(setUserActivity()));
  };
  const favoritePressHandler = async () => {
    setIsFavorite(!isFavorite);
    const TOKEN = await AsyncStorage.getItem("access_token");
    axios
      .post(process.env.USER_FAVOURITE_TRAILS, {
        access_token: TOKEN,
        trail_code: item.trail_code,
      })
      .then(() => dispatch(setUserActivity()));
  };

  const [details, setDetails] = useState({});
  const getDetails = async () => {
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const url = `${process.env.TRAILS_ENDPOINT}/details?trail_code=${item.trail_code}&language=${i18n.language}`;
    try {
      const Response = await axios.get(url, config);

      setDetails(Response.data);
    } catch (error) {
      setDetails({});
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  const renderDetail = (array, label) => {
    if (array.length > 0) {
      const values = array.map((element) => {
        if (i18n.language == "fr") {
          return element.code.length > 0
            ? { title: element.code }
            : {
              name: `${element.firstname} ${element.lastname}`,
              title: element.title_fr,
            };
        } else {
          return element.code_en.length > 0
            ? { title: element.code_en }
            : {
              name: `${element.firstname} ${element.lastname}`,
              title: element.title_en,
            };
        }
      });

      return (
        <View
          style={{
            width: "100%",
            marginTop: 3,
          }}
        >
          <BoldTxt style={{ fontSize: 13, marginBottom: 3 }}>{label}</BoldTxt>
          {values.map((el, i) => {
            return (
              <View key={i.toString()}>
                {el.name ? (
                  <BoldTxt
                    style={{
                      fontSize: 12,
                      color: "#484848",
                    }}
                  >
                    • {el.name} -{" "}
                    <LightTxt style={{ fontSize: 12, color: "#484848" }}>
                      {el.title}.
                    </LightTxt>
                  </BoldTxt>
                ) : (
                  <Txt style={{ fontSize: 12, color: "#484848" }}>
                    • {el.title}.
                  </Txt>
                )}
              </View>
            );
          })}
        </View>
      );
    } else {
      return <></>;
    }
  };

  return (
    <View style={container}>
      {/** trail presentation */}
      <View
        w="full"
        my="3"
        style={{ flexDirection: isDesktop ? "row" : "column" }}
      >
        <VStack
          style={{
            width: isBigScreen ? 350 : isDesktop ? 300 : "100%",
            borderRadius: 5,
            overflow: "hidden",
          }}
        >
          <Image
            source={{ uri: item.poster_link }}
            style={{ width: "100%", aspectRatio: 16 / 9 }}
          />
          <LinearGradient
            colors={["#00000020", "#00000090"]}
            style={{
              width: "100%",
              aspectRatio: 16 / 9,
              position: "absolute",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <VStack mx="5">
              <H5
                style={{ fontSize: 20, textAlign: "center" }}
                color={colors.white}
              >
                {item.title}
              </H5>

              <VStack alignSelf="center">
                <Txt style={{ fontSize: 12 }} color={colors.white}>
                  {item.seasons.length +
                    ` ${t("trail.season")}${item.seasons.length > 1 ? "s" : ""
                    } - ${item.episodes_number} ${t("episode")}`}
                </Txt>
              </VStack>
            </VStack>
          </LinearGradient>
        </VStack>
        <VStack
          bg="transparent"
          ml={isDesktop ? "3" : "0"}
          justifyContent="center"
          mt={isDesktop ? "0" : "3"}
          flex={1}
          borderColor={colors.blue3}
        >
          <VStack>
            <BoldTxt style={{ fontSize: isDesktop ? 26 : 20 }}>
              {item.title}
            </BoldTxt>

            <VStack mt="2">
              <Txt style={{ fontSize: 16 }} color="#484848">
                {item.description}
              </Txt>
            </VStack>
          </VStack>
          {/** like favorite buttons */}
          <HStack
            alignItems="center"
            style={{ marginTop: isDesktop ? 15 : 5 }}
            space="2"
            alignSelf="flex-end"
          >
            {/** like button */}
            <Pressable
              onPress={likePressHandler}
              ref={likeRef}
              style={[
                styles.likeButton,
                {
                  borderWidth: 1,
                  borderColor: isLiked
                    ? colors.blue2
                    : likeHover
                      ? colors.blue0
                      : "#484848",
                  backgroundColor: isLiked
                    ? colors.blue2
                    : likeHover
                      ? colors.blue0
                      : "transparent",
                },
              ]}
            >
              <LikeIcon
                color={
                  isLiked ? colors.white : likeHover ? colors.white : "#484848"
                }
              />
            </Pressable>
            {/** favorite button */}
            <Pressable
              onPress={favoritePressHandler}
              ref={favoriteRef}
              style={[
                styles.favoriteButton,
                {
                  borderWidth: 1,
                  borderColor: isFavorite
                    ? colors.red1
                    : favoriteHover
                      ? colors.red0
                      : "#484848",
                  backgroundColor: isFavorite
                    ? colors.red1
                    : favoriteHover
                      ? colors.red0
                      : "transparent",
                },
              ]}
            >
              <FavoriteIcon
                color={
                  isFavorite
                    ? colors.white
                    : favoriteHover
                      ? colors.white
                      : "#484848"
                }
              />
            </Pressable>
          </HStack>
        </VStack>
      </View>

      <Divider />
      <VStack mx={isDesktop ? "10" : "0"} mt="5">
        <View
          w="full"
          style={{
            flexDirection: isDesktop ? "row-reverse" : "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/** dropdown season */}
          <View
            style={{
              flexDirection: "row-reverse",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 10,
              width: '100%'
            }}
          >
            {/** DROPDOWN TO CHANGE SEASON */}

            <View
              onPress={() => setShowSeason(!showSeason)}
              style={{

                width: isDesktop ? 200 : isTablet ? 150 : 160,
              }}
            >
              <OutsidePressHandler onOutsidePress={() => setShowSeason(false)}>
                <DropDown
                  height={50}
                  placeholder=""
                  show={showSeason}
                  setShow={() => setShowSeason(!showSeason)}
                  value={currentSeason}
                  seasons={seasons}
                  setSelectedSeason={setSelectedSeason}
                  setValue={setCurrentSeason}
                  options={seasonOptions}
                  seasonDropDown={true}
                />
              </OutsidePressHandler>
            </View>
          </View>

        </View>
        <View style={{ zIndex: -1 }}>
          {/** season title */}
          <H1 style={{ fontSize: 24 }}>
            {selectedSeason.title}
          </H1>
        </View>
        {/** season info */}
        <VStack style={{ zIndex: -1 }}>
          <Txt style={{ color: "#484848" }}>{selectedSeason.description}</Txt>
        </VStack>
        <VStack style={{ zIndex: -1, marginVertical: 15 }}>
          {episodes.map((ep, index) => {
            return <EpisodeItem key={ep._id} ep={ep} index={index} />;
          })}
        </VStack>
        {/** recommandations */}
        {selectedSeason.season_code && (
          <RecommandedSwiper
            navigation={navigation}
            season_code={selectedSeason.season_code}
          />
        )}
        {/** details */}
        {/** infos container : author, voice, scriptwriter ... */}

        <View
          style={{
            flexDirection: isDesktop ? "row" : "column",
            justifyContent: isDesktop ? "space-evenly" : "flex-start",

            paddingVertical: isDesktop ? 10 : 5,
            borderRadius: isDesktop ? 10 : 5,
            marginTop: 20,
          }}
        >
          <View
            style={{
              width: isDesktop ? "45%" : "95%",
              alignSelf: isDesktop ? "flex-start" : "center",
            }}
          >
            {details.author && renderDetail(details.author, t("trail.author"))}
            {/** source */}
            {item.source && (
              <View
                style={{
                  width: "100%",
                  marginTop: 3,
                }}
              >
                <BoldTxt style={{ fontSize: 13, marginBottom: 3 }}>
                  Sources
                </BoldTxt>

                <Txt style={{ fontSize: 12, color: "#484848" }}>
                  {item.source}
                </Txt>
              </View>
            )}
            {details.scriptwriter &&
              renderDetail(details.scriptwriter, t("trail.scriptwriter"))}
          </View>
          {isDesktop && (
            <View
              style={{
                height: "100%",
                width: 2,
                backgroundColor: colors.grayBorder,
              }}
            ></View>
          )}
          <View
            style={{ width: isDesktop ? "45%" : "95%", alignSelf: "center" }}
          >
            {details.voice && renderDetail(details.voice, t("trail.voice"))}
            {details.audio_studio &&
              renderDetail(details.audio_studio, t("trail.studio_audio"))}
            {details.image_studio &&
              renderDetail(details.image_studio, t("trail.studio_image"))}
            {details.translation_agency &&
              renderDetail(
                details.translation_agency,
                t("trail.translation_agency")
              )}
          </View>
        </View>
        <VStack mt="5" />
        {!loader && isDesktop && <Footer />}
      </VStack>

      {/** choose watch episode / watch movement */}
      {selectedEpisode && (
        <ChooseVideoModal
          selectedEpisode={selectedEpisode}
          isOpen={isOpenChooseModal}
          setSelectedWorkshop={setSelectedWorkshop}
          setIsOpen={setIsOpenChooseModal}
          setShowTrailVideoModal={setShowTrailVideoModal}
          setShowMovementVideoModal={setShowMovementVideoModal}
          setIsOpenBuyTrailModal={setIsOpenBuyTrailModal}
          setIssOpenNeedSubscriptionModal={setIssOpenNeedSubscriptionModal}
          setIsOpenMaxEpisodesModal={setIsOpenMaxEpisodesModal}
          setShowNoAccessModal={setShowNoAccessModal}
          setIsOpenAmontQuestionnaire={setIsOpenAmontQuestionnaire}
          trail_code={item.trail_code}
          postQuestionnaire={postQuestionnaire}
          setShowPostQuestionnaire={setShowPostQuestionnaire}
          backFromEpisode={backFromEpisode}
          setBackFromEpisode={setBackFromEpisode}

        />
      )}

      <NavigateToPostQuestionnaireModal
        postQuestionnaire={postQuestionnaire}
        isOpen={showPostQuestionnaire}
        setIsOpen={setShowPostQuestionnaire}
        navigation={navigation}
        trail_code={item.trail_code}


      />
      {/** modal if finish trail */}
      <GreenModal
        isOpen={isOpenGreenModal}
        setIsOpen={setIsOpenGreenModal}
        title={t("modals.title1")}
        text={t("modals.text1")}
      />
      {/** modal if finish movement */}
      <GreenModal
        isOpen={isOpenMovementGreenModal}
        setIsOpen={setIsOpenMovementGreenModal}
        title={t("modals.title1")}
        text={t("modals.text3")}
      />
      {/** T4_1 */}

      {selectedEpisode && (
        <FirstVisitModal
          setIsOpenAmontQuestionnaire={setIsOpenAmontQuestionnaire}
          isOpen={isOpenFirstVisit}
          setIsOpen={setIsOpenFirstVisit}
          selectedEpisode={selectedEpisode}
          setShowFirstVideo={setShowTrailVideoModal}
          setShowPrealableVideo={setShowPrealableVideoModal}
          trail_code={item.trail_code}

        />
      )}
      {/** show challenge popup */}
      {selectedEpisode && isOpenChallenge && (
        <ChallengeModal
          isOpen={isOpenChallenge}
          setIsOpen={setIsOpenChallenge}
          sequenceEpisodes={sequenceEpisodes}
          selectedEpisode={selectedEpisode}
          setIsOpenChooseModal={setIsOpenChooseModal}
        />
      )}
      {selectedEpisode && (
        <FinishEpisodeModal
          isOpen={isOpenFinishEpisode}
          setIsOpen={setIsOpenFinishEpisode}
          item={selectedEpisode}
        />
      )}
      {/** modal to go to amont questionnaire */}

      <NavigateToQuestionnaireModal
        episodeData={selectedEpisode}
        seasonData={selectedSeason}
        trail={item}
        setShowTrailVideoModal={setShowTrailVideoModal}
        isOpen={isOpenAmontQuestionnaire}
        setIsOpen={setIsOpenAmontQuestionnaire}
        navigation={navigation}
        amontQuestionnaire={amontQuestionnaire ? amontQuestionnaire : null}
        trail_code={item.trail_code}

      />

      {/**  modal if not allowed to unlock trail */}
      <BuyTrailModal
        isOpen={isOpenBuyTrailModal}
        setIsOpen={setIsOpenBuyTrailModal}
        navigation={navigation}
      />
      {/** modal if not allowed to unlock trail and user is freemium -> can't buy trail */}
      <NeedSubscriptionModal
        isOpen={isOpenNeedSubscriptionModal}
        setIsOpen={setIssOpenNeedSubscriptionModal}
        navigation={navigation}
      />
      {/** modal if watched already 2 episodes */}
      <MaxEpisodesWatchedModal
        isOpen={isOpenMaxEpisodesModal}
        setIsOpen={setIsOpenMaxEpisodesModal}
        navigation={navigation}
      />
      {/** freemium no access to season 2 modal */}
      <NoAccessModal
        isOpen={showNoAccessModal}
        setIsOpen={setShowNoAccessModal}
        navigation={navigation}
      />

    </View>
  );
};

export default UpperSection;

const styles = StyleSheet.create({
  playIconContainer: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginTop: 10,
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  likeButton: {
    width: 44,
    height: 44,
    borderWidth: 1,

    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
  },
  favoriteButton: {
    width: 44,
    height: 44,
    borderWidth: 1,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
  },
  button2: {
    borderWidth: 1,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    width: 40,
    height: 40,
    borderRadius: 25,
    padding: 10,
  },
});
