import { StyleSheet, ImageBackground } from "react-native";
import React, { useEffect } from "react";
import { Modal, VStack, View } from "native-base";
import { colors } from "../../../styles/GlobalStyle";
import { H5, Txt } from "../../TextsComponents";
import DimensionsHook from "../../../hooks/DimensionsHook";

import { useTranslation } from "react-i18next";
import { PrimaryButton, SecondaryButton } from "../../Buttons";
import { AntDesign } from "@expo/vector-icons";

const NavigateToPostQuestionnaireModal = ({
  isOpen,
  setIsOpen,
  navigation,
  postQuestionnaire,
  setBackFromEpisode
}) => {
  const { t } = useTranslation();
  const { isMobile } = DimensionsHook();

  const closeHandler = () => {
    setIsOpen(false);

  };


  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={styles.container}>
        <Modal.Body>
          <ImageBackground
            source={require("../../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ marginRight: isMobile ? 0 : 10 }}
            />
            <VStack
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 40 }}
            >
              <VStack>
                <AntDesign
                  name="questioncircle"
                  size={50}
                  color={colors.blue3}
                />
              </VStack>
              <H5
                style={{
                  marginTop: 20,
                  marginBottom: 10,
                  textAlign: "center",
                  marginHorizontal: isMobile ? 20 : 30,
                }}
              >
                {t("questionnaires.post_title")}
              </H5>
              <Txt
                style={{
                  textAlign: "center",
                  marginHorizontal: isMobile ? 20 : 30,
                }}
              >
                {t("questionnaires.post_text")}
              </Txt>
              <View style={{ marginVertical: 30 }}>
                <PrimaryButton
                  onPress={() => {
                    setIsOpen(false);

                    navigation.navigate("Questionnaire", postQuestionnaire);
                  }}
                >
                  {t("questionnaires.button")}
                </PrimaryButton>
                <SecondaryButton
                  style={{ marginTop: 15 }}
                  onPress={closeHandler}
                >
                  {t("tuto.close")}
                </SecondaryButton>
              </View>
            </VStack>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default NavigateToPostQuestionnaireModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "95%",
    maxWidth: 600,
    maxHeight: 5000,
  },
});
