import { Modal, View } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BoldTxt } from "../TextsComponents";
import DimensionsHook from "../../hooks/DimensionsHook";
import Spinner from "../Spinner";
import { colors } from "../../styles/GlobalStyle";

const ChatModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const { height, width, isDesktop } = DimensionsHook();
  const [link, setLink] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      setLink(
        "https://secure.livechatinc.com/customer/action/open_chat?license_id=15144921"
      );
    } else {
      setLink(null);
    }
  }, [isOpen]);

  return (
    <Modal safeAreaTop isOpen={isOpen} onClose={onClose}>
      <Modal.Content
        style={{
          minWidth: isDesktop ? width * 0.8 : width * 0.95,
          minHeight: height * 0.95,
        }}
      >
        <Modal.CloseButton />
        <Modal.Header>
          <BoldTxt style={{ fontSize: 15 }}> </BoldTxt>
        </Modal.Header>
        <Modal.Body
          style={{
            minHeight: height * 0.9 - 40,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <iframe
            frameBorder="0"
            onLoad={() => setIsLoading(false)}
            width="100%"
            height="100%"
            src={link}
            //src="https://tawk.to/chat/644a6e4331ebfa0fe7fabd0a/1gv1btedl"
          ></iframe>
          {isLoading && (
            <View style={{ position: "absolute" }}>
              <Spinner size="large" color={colors.green2} />
            </View>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default ChatModal;
