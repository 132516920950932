import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";

// GET MONTHLY MESSAGE
export const setMonthlyMessage = () => async (dispatch) => {
  const TOKEN = await AsyncStorage.getItem("access_token");
  const config = {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
  };
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth() + 1;

  const response = await axios.get(
    `${process.env.MONTHLY_MESSAGE}?month=${month}&year=${year}`,
    config
  );

  const message = response.data;

  dispatch({
    type: "SET_MESSAGE",
    payload: message,
  });
};

// GET USER WATCHED EPISODES AND STORE IT IN watchedEpisodes State

export const setWatchedEpisodes = () => async (dispatch) => {
  const TOKEN = await AsyncStorage.getItem("access_token");
  const config = {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
  };
  const response = await axios.get(process.env.USER_WATCHED_EPISODES, config);
  const watchedEpisodes = response.data;

  dispatch({
    type: "SET_WATCHED_EPISODES",
    payload: watchedEpisodes,
  });
};

// GET QUESTIONNAIRES DATA AND STORE IT IN questionnairesData State

export const setQuestionnaires = (url) => async (dispatch) => {
  const TOKEN = await AsyncStorage.getItem("access_token");
  const config = {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
  };
  const response = await axios.get(url, config);
  const questionnaires = response.data;

  dispatch({
    type: "SET_QUESTIONNAIRES",
    payload: questionnaires,
  });
};

export const getDominos = () => async (dispatch) => {
  const TOKEN = await AsyncStorage.getItem("access_token");
  const config = {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
  };
  const response = await axios.get(`${process.env.GAMES}/dominos-data`, config);
  const levels = response.data;

  dispatch({
    type: "SET_DOMINOS",
    payload: levels,
  });
};


export const setCards = (cs) => async (dispatch) => {
  dispatch({
    type: "SET_CARDS",
    payload: cs
  })
}

// GET USER SAVED EPISODES AND STORE IT IN savedEpisodes State

export const setSavedEpisodes = () => async (dispatch) => {
  const TOKEN = await AsyncStorage.getItem("access_token");
  const config = {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
  };
  const response = await axios.get(process.env.USER_SAVED_EPISODES, config);
  const savedEpisodes = response.data;

  dispatch({
    type: "SET_SAVED_EPISODES",
    payload: savedEpisodes,
  });
};

// GET USER DATA and store it in userData State
export const setUserData = () => async (dispatch) => {
  const TOKEN = await AsyncStorage.getItem("access_token");
  // SEND TOKEN ON HEADER
  const config = {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
  };
  const response = await axios.get(process.env.USER_DATA, config);
  const userData = response.data;
  dispatch({
    type: "SET_USER_DATA",
    payload: userData,
  });
};

export const toggleNotificationAction = (value) => async (dispatch) => {
  dispatch({
    type: "TOGGLE_NOTIFICATION",
    payload: value,
  });
};

export const signCommunityConditions = () => async (dispatch) => {
  dispatch({
    type: "COMMUNITY_CONDITIONS",
  });
};

// GET USER ACTIVITY and store it in userActivity State
export const setUserActivity = () => async (dispatch) => {
  const TOKEN = await AsyncStorage.getItem("access_token");
  // SEND TOKEN ON HEADER
  const config = {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
  };
  const response = await axios.get(process.env.USER_ACTIVITY, config);
  const userActivity = response.data;
  dispatch({
    type: "SET_USER_ACTIVITY",
    payload: userActivity,
  });
};

// RESET USER-INFO on Logout
export const resetUser = () => async (dispatch) => {
  await AsyncStorage.removeItem("access_token");
  dispatch({
    type: "SET_USER_DATA",
    payload: {},
  });
  dispatch({
    type: "SET_USER_ACTIVITY",
    payload: {},
  });
  dispatch({
    type: "SET_WATCHED_EPISODES",
    payload: {},
  });
};

// GET ANSWERED QUESTIONAIRES AND STORE IT IN answeredQuestionnaire State

export const setAnsweredQuestionnaires = () => async (dispatch) => {
  const TOKEN = await AsyncStorage.getItem("access_token");
  const config = {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
  };
  const response = await axios.get(process.env.USER_ANSWERS, config);
  const questionnaires = response.data;

  dispatch({
    type: "SET_ANSWERED_QUESTIONNAIRES",
    payload: questionnaires,
  });
};
