import { StyleSheet, View } from "react-native";
import React, { useEffect, useState } from "react";
import PlansModal from "../modals/PlansModal";
import { useSelector } from "react-redux";
import LifePlan from "./PaymentScreen/LifePlan";
import SubscriptionPlan from "./PaymentScreen/SubscriptionPlan";
import TrialPlan from "./PaymentScreen/TrialPlan";
import Spinner from "../Spinner";
import axios from "axios";
import CompanyUser from "./PaymentScreen/CompanyUser";
import GiftPlan from "./PaymentScreen/GiftPlan";
import UpdatePlanModal from "../modals/UpdatePlanModal";
import CompanySubscription from "./PaymentScreen/CompanySubscription";
import CompanyFreemium from "./PaymentScreen/CompanyFreemium";
import CompanyPlansModal from "../modals/CompanyPlansModal";

const PaymentScreen = ({ navigation }) => {
  const [plansModal, setPlansModal] = useState(false);
  const [companyModal, setCompanyModal] = useState(false);
  const [updatePlansModal, setUpdatePlansModal] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [lifetimeData, setLifetimeData] = useState({});
  const [loader, setLoader] = useState(false);
  const [companiesSubs, setCompaniesSubs] = useState([]);

  const userData = useSelector((state) => state.userData);

  const getData = async () => {
    const url = process.env.SUBSCRIPTIONS_LIST;
    setLoader(true);

    axios
      .get(url + (userData.company ? '-companies' : ""))
      .then((response) => {
        const CSubs = response.data.filter(
          (product) => product.lifetime == false
        );
        setCompaniesSubs(CSubs);
      })
      .then(() => {
        axios.get(url).then((res) => {
          const subs = res.data.filter((product) => product.lifetime == false);
          const lifetime = res.data.find((product) => product.lifetime == true);

          setSubscriptions(subs);
          setLifetimeData(lifetime);
          setLoader(false);
        });
      });
  };

  useEffect(() => {
    getData();
  }, []);

  if (loader || !subscriptions) {
    return (
      <View style={[styles.loadingContainer, { minHeight: 250 }]}>
        <Spinner size="large" />
      </View>
    );
  }
  return (
    <View>
      {/** show depend if user in trial, in subscription or in lifetime plan */}
      {userData.company && (userData.company_payment == "prepaid" || userData.company_payment == "test") ? (
        <CompanyUser navigation={navigation} />
      ) : userData.lifetime_subscription ? (
        <LifePlan lifetimePlan={lifetimeData} navigation={navigation} />
      ) : userData.subscription && userData.subscription.endsWith("_gift") ? (
        <GiftPlan />
      ) : userData.company && userData.subscription ? (
        <CompanySubscription
          setUpdatePlansModal={setCompanyModal}
          navigation={navigation}
        />
      ) : userData.subscription ? (
        <SubscriptionPlan
          setUpdatePlansModal={setUpdatePlansModal}
          navigation={navigation}
        />
      ) : userData.company && userData.subscription_info == "freemium" ? (
        <CompanyFreemium setPlansModal={setCompanyModal} />
      ) : (
        <TrialPlan setPlansModal={setPlansModal} />
      )}
      {/** payment modal */}
      {plansModal && (
        <PlansModal
          subscriptions={subscriptions}
          lifetimePlan={lifetimeData}
          navigation={navigation}
          isOpen={plansModal}
          setIsOpen={setPlansModal}
        />
      )}
      {companyModal && (
        <CompanyPlansModal
          subscriptions={companiesSubs}
          lifetimePlan={lifetimeData}
          navigation={navigation}
          isOpen={companyModal}
          setIsOpen={setCompanyModal}
        />
      )}
      {companyModal && (
        <CompanyPlansModal
          subscriptions={companiesSubs}
          lifetimePlan={lifetimeData}
          navigation={navigation}
          isOpen={companyModal}
          setIsOpen={setCompanyModal}
        />
      )}
      {/** payment modal */}
      {updatePlansModal && (
        <UpdatePlanModal
          getData={getData}
          subscriptions={companiesSubs}
          lifetimePlan={lifetimeData}
          navigation={navigation}
          isOpen={updatePlansModal}
          setIsOpen={setUpdatePlansModal}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default PaymentScreen;
