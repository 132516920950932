import {
  StyleSheet,
  View,
  Pressable,
  ScrollView,
  ImageBackground,
  Platform,
  Linking,
  Alert,
} from "react-native";
import React, { useState, useRef, useEffect } from "react";
import { colors } from "../styles/GlobalStyle";
import { H2 } from "../components/TextsComponents";
import SignIn from "../components/login/SignIn";
import Individual from "../components/login/signup/Individual";
import DimensionsHook from "../hooks/DimensionsHook";
import { LislUpByMT } from "../assets/svg/Logo";
import { useTranslation } from "react-i18next";
import { EnLogo, FrLogo } from "../assets/svg/Icons";
import { PresenceTransition } from "native-base";
import AsyncStorage from "@react-native-async-storage/async-storage";
import TutoVideo from "../components/videos/TutoVideo";
import { PrimaryButton, SecondaryButton } from "../components/Buttons";

const Login = ({ navigation, route }) => {
  const { t, i18n } = useTranslation();


  const { isMobile, isTablet, isDesktop } = DimensionsHook();
  const [signIn, setSignIn] = useState(null);
  const [redirectError, setRedirectError] = useState("");
  const [showTuto, setShowTuto] = useState(false)

  // Select Langue

  const scrollRef = useRef();

  const scrollToTop = () => {
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  };
  const changeLanguage = async (lng) => {
    await AsyncStorage.setItem("lng", lng);
    i18n.changeLanguage(lng);
  };

  const getCurrentUrl = async () => {
    const url = await Linking.getInitialURL();
    return url;
  };

  // check link
  const checkLink = async () => {
    const currentUrl = await getCurrentUrl();
    if (Platform.OS == "web" && currentUrl.includes("/gift")) {
      setRedirectError(`${t("login_alert.text")} ${t("login_alert.gift")}`);
    }
    if (Platform.OS == "web" && currentUrl.includes("/sponsor")) {
      setRedirectError(
        `${t("login_alert.text")} ${t("login_alert.parrainage")}`
      );
    }
    const loggedBefore = await AsyncStorage.getItem('logged')
    if (loggedBefore == "true") {
      setSignIn(true)

    } else {
      setSignIn(false)
    }
  };

  useEffect(() => {
    checkLink();
  }, []);

  if (signIn == null) {
    return (
      <View style={styles.container}>
        <ImageBackground
          source={require("../assets/background.png")}
          style={{ flex: 1 }}
        ></ImageBackground></View>
    )
  }
  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView
          nestedScrollEnabled={true}
          ref={scrollRef}
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator
        >
          {/** language dropdown */}
          <View style={styles.languagesContainer}>
            <Pressable
              onPress={() => changeLanguage("fr")}
              style={[
                styles.languageButton,
                {
                  borderColor:
                    i18n.language == "fr" ? colors.green2 : colors.grayBorder,
                  opacity: i18n.language == "fr" ? 1 : 0.5,
                },
              ]}
            >
              <FrLogo />
            </Pressable>
            <Pressable
              onPress={() => changeLanguage("en")}
              style={[
                styles.languageButton,
                {
                  borderColor:
                    i18n.language == "en" ? colors.green2 : colors.grayBorder,
                  opacity: i18n.language == "en" ? 1 : 0.5,
                },
              ]}
            >
              <EnLogo />
            </Pressable>
          </View>
          {/** logo lislup by mt */}
          <View
            style={{
              alignSelf: "center",

              width: isDesktop ? 250 : 200,
              height: isDesktop ? 120 : 95,
            }}
          >
            <LislUpByMT />
          </View>
          <SecondaryButton style={{ alignSelf: 'center', width: '100%', maxWidth: 320, marginVertical: 5 }} onPress={() => setShowTuto(true)}>{t('signup.button_trailer')}</SecondaryButton>
          {/** SignIn & Signup Buttons */}
          <View style={styles.linksButton}>
            <Pressable
              style={{
                borderBottomWidth: signIn ? 2 : 0,
                borderBottomColor: colors.blue3,
              }}
              onPress={() => setSignIn(true)}
            >
              <H2 style={{ opacity: signIn === true ? 1 : 0.75 }}>
                {t("login.connexion")}
              </H2>
            </Pressable>
            <View style={styles.divider} />

            <Pressable
              style={{
                borderBottomWidth: signIn === false ? 2 : 0,
                borderBottomColor: colors.blue3,
              }}
              onPress={() => setSignIn(false)}
            >
              <H2 style={{ opacity: signIn === false ? 1 : 0.75 }}>
                {t("login.inscription")}
              </H2>
            </Pressable>
          </View>
          <View
            style={{
              alignSelf: "center",
              width: isMobile ? "90%" : isTablet ? "80%" : signIn === false ? 900 : 500,
            }}
          >
            {signIn == null ? (
              <></>
            ) : (
              <PresenceTransition
                visible={signIn != null}
                initial={{
                  opacity: 1,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 800,
                    delay: 0,
                    useNativeDriver: Platform.OS != "web",
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    duration: 200,
                    delay: 0,
                    useNativeDriver: Platform.OS != "web",
                  },
                }}
              >
                {signIn == true ? (
                  <SignIn
                    redirectError={redirectError}
                    scrollToTop={scrollToTop}
                    navigation={navigation}
                  />
                ) : (
                  <Individual
                    redirectError={redirectError}
                    scrollToTop={scrollToTop}
                    navigation={navigation}
                  />
                )}
              </PresenceTransition>
            )}
          </View>
        </ScrollView>
      </ImageBackground>
      {/** modal tuto */}
      <TutoVideo isOpen={showTuto} setIsOpen={setShowTuto} />
    </View>
  );
};

export default Login;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },
  linksButton: {
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
  },
  divider: {
    backgroundColor: colors.grayBorder,
    width: 2,
    height: "80%",
    marginHorizontal: 10,
  },
  languagesContainer: {
    borderColor: "red",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-end",
    marginTop: 20,
    marginRight: 10,
    marginBottom: -10,
  },
  languageButton: {
    width: 38,
    height: 32,

    marginRight: 6,
  },
});
