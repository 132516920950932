import { StyleSheet, View } from "react-native";
import React, { useRef, useState } from "react";
import DimensionsHook from "../../../hooks/DimensionsHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHover } from "react-native-web-hooks";
import axios from "axios";
import { colors } from "../../../styles/GlobalStyle";
import { BoldTxt, H4, SmallTxt } from "../../TextsComponents";
import { PrimaryButton } from "../../Buttons";
import { Surface } from "react-native-paper";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Spinner from "../../Spinner";
import * as WebBrowser from "expo-web-browser";

const Lifetime = ({ plan }) => {
  const { t, i18n } = useTranslation();
  const { width } = DimensionsHook();
  const isDesktop = width > 1000;
  const isMobile = width < 600;
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const userData = useSelector((state) => state.userData);
  const [loader, setLoader] = useState(false);

  const paymentHandler = async () => {
    if (!loader) {
      setLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      const url = process.env.CREATE_LIFTIME_SESSION;
      axios
        .post(url, {
          customer: userData.customer,
          access_token: TOKEN,
          price: plan.stripe,
          parrained: userData.parrained,
          subToCancel: true,
        })
        .then((res) => {
          const url = res.data.session.url;

          WebBrowser.openBrowserAsync(url);

          setLoader(false);
        })
        .catch((err) => {
          //console.log(err);
          setLoader(false);
        });
    }
  };

  return (
    <Surface
      style={[
        styles.cardContainer,
        {
          width: isMobile ? "95%" : isDesktop ? "100%" : "48%",
          backgroundColor: isHovered ? colors.blue2 : colors.blue3,
          marginTop: isMobile ? 20 : 10,
          cursor: "pointer",
        },
      ]}
      elevation={4}
    >
      <View style={{ width: "100%", alignItems: "center" }} ref={hoverRef}>
        {/** duration plan */}
        <View style={{ alignItems: "center", marginTop: 10 }}>
          <H4 color={isHovered ? colors.blue3 : colors.white}>
            {i18n.language == "fr" ? plan.title_fr : plan.title_en}
          </H4>
        </View>
        {/** price */}
        <View style={{ alignItems: "center", paddingTop: 20 }}>
          <View
            style={[
              styles.priceContainer,
              { backgroundColor: isHovered ? "#ffffff15" : "#88C1A315" },
            ]}
          >
            <BoldTxt
              color={isHovered ? colors.white : colors.green2}
              style={{ fontSize: 20 }}
            >
              {plan.price}€ {t("tax")}
            </BoldTxt>
          </View>
        </View>

        {/** subscribe button */}

        <PrimaryButton
          width={200}
          style={{ marginVertical: 20 }}
          //onPress={() => navigation.navigate("Payment", plan)}
          onPress={() => paymentHandler()}
        >
          {loader ? <Spinner color={colors.white} /> : t("plans.choose")}
        </PrimaryButton>
        <View style={{ marginHorizontal: 15, marginBottom: 30, marginTop: 15 }}>
          <SmallTxt
            style={{ textAlign: "center" }}
            color={isHovered ? colors.blue3 : colors.white}
          >
            {i18n.language == "fr" ? plan.description_fr : plan.description_en}
          </SmallTxt>
        </View>
      </View>
    </Surface>
  );
};

export default Lifetime;

const styles = StyleSheet.create({
  cardContainer: {
    marginTop: 10,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",

    borderRadius: 10,
  },
  priceContainer: {
    paddingVertical: 6,
    paddingHorizontal: 20,
    borderRadius: 10,
  },
});
