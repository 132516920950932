import { View, StyleSheet } from "react-native";
import React from "react";
import { H5, Txt } from "../../../components/TextsComponents";
import { useTranslation } from "react-i18next";

const Head = () => {
  const { t } = useTranslation();
  return (
    <View>
      <View style={styles.TitleWrapper}>
        <H5
          style={{
            textAlign: "center",
          }}
        >
          {t("appointment.title")}
        </H5>
      </View>
      <View style={[styles.TextWrapper]}>
        <Txt
          style={{
            textAlign: "center",
          }}
        >
          {t("coaching.expert_text")}
        </Txt>
        <Txt
          style={{
            textAlign: "center",
            marginTop: 20,
          }}
        >
          {t("appointment.text1")}
        </Txt>
      </View>
    </View>
  );
};

export default Head;
const styles = StyleSheet.create({
  TitleWrapper: {
    marginTop: 40,
    alignSelf: "center",
  },
  TextWrapper: {
    marginTop: 20,
    alignSelf: "center",
  },
});
