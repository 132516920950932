import {
  View,
  TouchableOpacity,
  StyleSheet,
  Keyboard,
  Platform,
  Linking,
} from "react-native";
import React, { useEffect, useState } from "react";
import { TextInput } from "react-native-paper";
import { colors } from "../../styles/GlobalStyle";
import { BoldTxt, SmallBoldTxt, Txt } from "../TextsComponents";
import { PrimaryButton } from "../Buttons";
import Spinner from "../Spinner";
import { Input } from "../Inputs";
import AsyncStorage from "@react-native-async-storage/async-storage";

import axios from "axios";
import { useDispatch } from "react-redux";
import {
  setSavedEpisodes,
  setUserActivity,
  setUserData,
  setWatchedEpisodes,
} from "../../redux/Actions";
import { setAsyncTimeout } from "../../hooks/Helpers";
import { useTranslation } from "react-i18next";
import Purchases from "react-native-purchases";
import GoogleSignIn from "./GoogleSignIn";
import moment from "moment";
import AppleSignIn from "./AppleSignIn";

const SignIn = ({ navigation, scrollToTop, redirectError }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSecure, setIsSecure] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const dispatch = useDispatch();

  const getCurrentUrl = async () => {
    const url = await Linking.getInitialURL();
    return url;
  };

  const goMain = async () => {
    const currentUrl = await getCurrentUrl();
    if (Platform.OS == "web" && currentUrl.includes("/gift")) {
      navigation.replace("GiftWeb");
    } else if (Platform.OS == "web" && currentUrl.includes("/sponsor")) {
      navigation.replace("ParrainageWeb");
    } else {
      navigation.replace("TabNavigation");
    }
  };

  useEffect(() => {
    setErrorMessage(redirectError);
  }, [redirectError]);

  const toggleSecure = () => setIsSecure(!isSecure);
  const submitHandler = async () => {
    setSubmitLoader(true);
    setErrorMessage("");
    if (email.length < 3) {
      setSubmitLoader(false);
      setErrorMessage(t("signin.error_email"));
      scrollToTop();
      return;
    }
    /*if (password.length < 6) {
      setSubmitLoader(false);
      setErrorMessage(t("signin.error_password"));
      scrollToTop();
      return;
    }*/
    Keyboard.dismiss();
    await setAsyncTimeout(() => { }, 1000);
    axios
      .post(process.env.USER_SIGNIN, {
        email: email.trim().toLocaleLowerCase(),
        password,
      })
      .then(async (res) => {
        // GET TOKEN AND USER STATUS

        const token = res.data.access_token;

        await AsyncStorage.setItem("access_token", token);
        await dispatch(setUserData());
        await dispatch(setUserActivity());
        await dispatch(setWatchedEpisodes());
        await dispatch(setSavedEpisodes());

        if (Platform.OS == "ios") {
          Purchases.setDebugLogsEnabled(true);
          Purchases.configure({
            apiKey: "appl_qsunokDQSTJoiKMlhDkpJNeQDjY",
            appUserID: res.data.customer,
          });
        }

        // CHECK IF ACCOUNT IS NOT VERIFIED (pending status) =>  GO TO VALIDATION SCREEN
        if (
          res.data.validation != true &&
          res.data.status === "pending" &&
          !res.data.verify_number &&
          res.data.company_typologie != "prospect"
        ) {
          navigation.replace("Validation");
        } else {
          // USER IS COMPANY USER ?
          if (res.data.company) {
            // company  active
            if (res.data.company_status == "active") {
              if (
                res.data.company_payment == "prepaid" ||
                res.data.company_payment == "test"
              ) {
                goMain();
              } else {
                if (res.data.lifetime_subscription) {
                  goMain();
                } else if (res.data.subscription) {
                  if (res.data.subscription.endsWith("_gift")) {
                    const endDate = moment(res.data.gift_end);
                    // Get the current date as a Moment.js object
                    const currentDate = moment();

                    // Calculate the difference between the MongoDB date and today's date in days
                    const diffInDays = endDate.diff(currentDate, "days");

                    if (diffInDays > 0) {
                      goMain();
                    } else {
                      navigation.replace("PlansCompanies");
                    }
                  } else if (res.data.subscription_status == "active") {
                    goMain();
                  } else {
                    navigation.replace("PlansCompanies");
                  }
                } else if (res.data.subscription_info == "freemium") {
                  goMain();
                } else {
                  navigation.replace("PlansCompanies");
                }
              }
            }
            // company not active
            else {
              setSubmitLoader(false);
              setErrorMessage(t("signin.error_company_inactive"));
              scrollToTop();
            }
          }
          // USER HAS LIFETIME SUBSCRIPTION ?
          else if (res.data.lifetime_subscription) {
            goMain();
          } else if (res.data.subscription) {
            if (res.data.subscription.endsWith("_gift")) {
              const endDate = moment(res.data.gift_end);
              // Get the current date as a Moment.js object
              const currentDate = moment();

              // Calculate the difference between the MongoDB date and today's date in days
              const diffInDays = endDate.diff(currentDate, "days");
              console.log(diffInDays);
              if (diffInDays > 0) {
                goMain();
              } else {
                navigation.replace("Plans");
              }
            } else if (res.data.subscription_status == "active") {
              goMain();
            } else {
              navigation.replace("Plans");
            }
          } else if (res.data.trial_used) {
            goMain();
            setSubmitLoader(false);
          } else {
            // NO PLANS AND NO TRIAL
            navigation.replace("Plans");
            setSubmitLoader(false);
          }
        }
      })
      .catch((err) => {
        setTimeout(() => {
          setSubmitLoader(false);
        }, 500);
        console.log(err);
        if (err.response.status === 480) {
          setErrorMessage(t("signin.error_google"));
          scrollToTop();
        } else if (err.response.status === 490) {
          setErrorMessage(t("signin.error_apple"));
          scrollToTop();
        } else if (
          err.response.status === 400 ||
          err.response.status === 403 ||
          err.response.status === 401
        ) {
          setErrorMessage(t("signin.error_invalid_data"));
          scrollToTop();
        } else {
          setErrorMessage(t("signin.error_connexion"));
          scrollToTop();
        }
      });
  };
  return (
    <View>
      <SmallBoldTxt
        style={{
          color: colors.red1,
          textAlign: "center",
          marginVertical: 15,
        }}
      >
        {errorMessage.length > 0 ? errorMessage : " "}
      </SmallBoldTxt>

      <Input
        textContentType="emailAddress"
        kayboardType="email-address"
        placeholder={t("placeholders.email")}
        value={email}
        onChangeText={(val) => setEmail(val)}
      />
      <Input
        textContentType="password"
        mode="outlined"
        style={{ marginTop: 10 }}
        placeholder={t("placeholders.password")}
        secureTextEntry={isSecure}
        value={password}
        onSubmitEditing={submitHandler}
        onChangeText={(val) => setPassword(val)}
        right={
          isSecure ? (
            <TextInput.Icon
              forceTextInputFocus={false}
              onPress={toggleSecure}
              name={"eye"}
              size={24}
              color={colors.blue3}
            />
          ) : (
            <TextInput.Icon
              forceTextInputFocus={false}
              onPress={toggleSecure}
              name="eye-off"
              size={24}
              color={colors.blue3}
            />
          )
        }
      />
      <PrimaryButton
        onPress={submitHandler}
        style={{
          marginTop: 20,
        }}
      >
        {submitLoader ? (
          <Spinner
            color={submitLoader ? colors.white : colors.blue3}
            size="small"
          />
        ) : (
          t("signin.button")
        )}
      </PrimaryButton>
      <TouchableOpacity
        onPress={() => navigation.navigate("ResetPassword")}
        style={{ alignSelf: "center", marginVertical: 30 }}
      >
        <BoldTxt>{t("signin.forgot_password")}</BoldTxt>
      </TouchableOpacity>
      {/** google button */}
      <GoogleSignIn />
      {Platform.OS == "ios" && <AppleSignIn />}
    </View>
  );
};

export default SignIn;

const styles = StyleSheet.create({});
