import { StyleSheet, View, Image } from "react-native";
import React, { useEffect, useState } from "react";
import { H2, H1, Txt } from "../TextsComponents";
import DimensionsHook from "../../hooks/DimensionsHook";
import { colors } from "../../styles/GlobalStyle";
import { ScoreCup, ScoreFestival } from "../../assets/svg/MyAccountIcons";
import { PrimaryButton } from "../Buttons";
import { useDispatch, useSelector } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import StatusUpgradeModal from "../modals/StatusUpgradeModal";
import { useTranslation } from "react-i18next";
import SoonModal from "../modals/SoonModal";
import { setUserData } from "../../redux/Actions";
import PointsUpgradeModal from "../modals/PointsUpgradeModal";
import Scores from "./ScoreScreen/Scores";

const ScoreScreen = ({ navigation }) => {
  const { t } = useTranslation();
  const { width, isDesktop, isTablet } = DimensionsHook();
  const isDesktopCustom = width > 1100;
  const { total_points, current_points } = useSelector(
    (state) => state.userData
  );

  const [upgradeModalShow, setUpgradeModalShow] = useState(false);
  const [showSoonModal, setShowSoonModal] = useState(false);
  const [showPointsModal, setShowModalPoints] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const dispatch = useDispatch();

  const getRankFromPoints = () => {
    if (total_points >= 2000) {
      return `${t("ranks.rank3")}`;
    } else if (total_points >= 1000) {
      return `${t("ranks.rank2")}`;
    } else {
      return `${t("ranks.rank1")}`;
    }
  };

  const showUpgradeModalHandler = async () => {
    const currentRank = getRankFromPoints();
    const rankFromStorage = await AsyncStorage.getItem("rank");
    console.log(currentRank);
    console.log(rankFromStorage);
    // if rank value  from storage is different from rank from the backend then show the upgrade rank modal
    if (rankFromStorage !== currentRank && total_points >= 1000) {
      await AsyncStorage.setItem("rank", currentRank);
      setUpgradeModalShow(true);
    }
    if (total_points > 0) {
      setShowModalPoints(true);
    }
  };

  useEffect(() => {
    dispatch(setUserData());
  }, []);

  useEffect(() => {
    setTimeout(() => {
      showUpgradeModalHandler();
      setShowHistory(true);
    }, 500);
  }, [total_points]);

  return (
    <View style={{ marginTop: isDesktopCustom ? 0 : 20 }}>
      <H1>{t("score_screen.score")}</H1>
      <Txt style={{ marginVertical: 20 }}>{t("score_screen.text2")}</Txt>
      {/** cards */}
      <View style={styles.cardsContainer}>
        {/** jetons card */}
        <View
          style={[
            styles.card,
            {
              width: isDesktopCustom ? "49%" : "100%",
              backgroundColor: colors.green0,
            },
          ]}
        >
          <View style={styles.festivalContainer}>
            <ScoreFestival />
          </View>
          <View
            style={{
              position: "absolute",
              right: 14,
              height: isDesktopCustom ? 200 : 150,
              width: isDesktopCustom ? 200 : 150,
              top: isDesktopCustom ? -8 : 17,
            }}
          >
            <ScoreCup />
          </View>
          <View style={{ marginLeft: isTablet ? 40 : isDesktop ? 40 : 10 }}>
            <Txt>{t("score_screen.text3")}</Txt>
            <View
              style={[styles.textContainer, { backgroundColor: colors.green1 }]}
            >
              <H2>
                {current_points} {t("score_screen.points")}
              </H2>
            </View>
          </View>
        </View>
        {/** status card */}
        <View
          style={[
            styles.card,
            {
              width: isDesktopCustom ? "49%" : "100%",
              backgroundColor: colors.blue1,
            },
          ]}
        >
          <View style={styles.festivalContainer}>
            <ScoreFestival />
          </View>
          <View
            style={{
              position: "absolute",
              right: 14,
              height: isDesktopCustom ? 200 : 150,
              width: isDesktopCustom ? 200 : 150,
              top: isDesktopCustom ? -8 : 17,
            }}
          >
            <Image
              source={{
                uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/scoreski.png?alt=media&token=4d595210-1ede-4dee-8437-2ded6dadfdf3",
              }}
              style={{
                height: isDesktopCustom ? 200 : 150,
                width: isDesktopCustom ? 200 : 150,
              }}
            />
          </View>

          <View style={{ marginLeft: isTablet ? 40 : isDesktop ? 40 : 10 }}>
            <Txt>{t("score_screen.status")}</Txt>
            <View
              style={[styles.textContainer, { backgroundColor: colors.white }]}
            >
              <H2>{getRankFromPoints()}</H2>
            </View>
          </View>
        </View>
        {/** use jetons */}
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 20,
          flexWrap: "wrap",
        }}
      >
        <Txt style={{ marginVertical: 10 }}>{t("score_screen.text4")}</Txt>
        {/** use jetons options commented (below the file) */}
        <PrimaryButton
          style={{ marginVertical: 10 }}
          onPress={() => navigation.navigate("AccountBuy")}
        >
          {t("score_screen.button")}
        </PrimaryButton>
      </View>

      <Txt style={{ marginTop: 20, marginBottom: isDesktop ? 0 : 20 }}>
        {t("score_screen.text5")}
      </Txt>
      <View style={styles.divider} />
      {/** User Scores History */}
      {showHistory && (
        <View style={{ marginVertical: 20 }}>
          <Scores />
        </View>
      )}
      {/** rank upgrade pop up */}
      <StatusUpgradeModal
        isOpen={upgradeModalShow}
        setIsOpen={setUpgradeModalShow}
      />
      {/** soon modal */}
      <SoonModal
        don={true}
        isOpen={showSoonModal}
        setIsOpen={setShowSoonModal}
      />
      {/** soon modal */}
      <PointsUpgradeModal
        isOpen={showPointsModal}
        setIsOpen={setShowModalPoints}
      />
    </View>
  );
};

export default ScoreScreen;

const styles = StyleSheet.create({
  cardsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  card: {
    height: 184,
    marginTop: 20,
    borderRadius: 20,
    justifyContent: "center",
  },
  festivalContainer: { position: "absolute", width: "90%", height: "100%" },
  textContainer: {
    alignSelf: "flex-start",
    paddingHorizontal: 10,
    borderRadius: 100,
    marginTop: 10,
  },
  useCard: {
    height: 300,
    borderRadius: 20,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
    overflow: "hidden",
    paddingBottom: 15,
  },
  badge: {
    backgroundColor: colors.green1,
    paddingHorizontal: 8,
    paddingVertical: 3,
    marginVertical: 10,
    borderRadius: 30,
  },
  circle: {
    width: 150,
    height: 150,
    paddingBottom: 30,
    borderRadius: 75,
    backgroundColor: "#C9DCC525",
    position: "absolute",
    top: -75,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  contentContainer: {
    flex: 1,
    marginTop: 100,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: colors.grayBorder,
    marginTop: 20,
  },
});
