import { StyleSheet, View, TouchableOpacity, Text } from "react-native";
import React, { useState } from "react";
import { H2, SmallBoldTxt, Txt } from "../../components/TextsComponents";
import { colors } from "../../styles/GlobalStyle";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import DimensionsHook from "../../hooks/DimensionsHook";
import axios from "axios";
import Spinner from "../../components/Spinner";
import { useToast } from "native-base";
import {
  AntDesign,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  setSavedEpisodes,
  setUserActivity,
  setUserData,
  setWatchedEpisodes,
} from "../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setAsyncTimeout } from "../../hooks/Helpers";
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";

const ValidationEmail = ({ navigation, setValidationMethod }) => {
  const CELL_COUNT = 6;
  const { isDesktop } = DimensionsHook();
  const { t } = useTranslation();

  const toast = useToast();
  const userData = useSelector((state) => state.userData);
  const [validationLoader, setValidationLoader] = useState(false);
  const [timerCount, setTimerCount] = useState(59);
  const [errorMessage, setErrorMessage] = useState("");
  const [allowResend, setAllowResend] = useState(true);
  const [value, setValue] = useState("");
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });
  const dispatch = useDispatch();

  const validationHandler = async () => {
    if (!validationLoader) {
      setErrorMessage("");
      setValidationLoader(true);
      if (value.length != 6 || isNaN(value)) {
        setErrorMessage(t("validation.error_code"));
        setValidationLoader(false);
        return;
      }
      await setAsyncTimeout(() => {}, 1000);
      const TOKEN = await AsyncStorage.getItem("access_token");

      axios
        .post(process.env.USER_VALIDATE, {
          access_token: TOKEN,
          code: value,
        })
        .then(async (res) => {
          await dispatch(setUserData());
          await dispatch(setUserActivity());
          await dispatch(setWatchedEpisodes());
          await dispatch(setSavedEpisodes());
          setValidationLoader(false);
          if (userData.company) {
            if (
              userData.company_payment == "prepaid" ||
              userData.company_payment == "test"
            ) {
              navigation.replace("TabNavigation");
            } else {
              navigation.replace("PlansCompanies");
            }
          } else {
            navigation.replace("Plans");
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setErrorMessage(t("validation.error_code"));

            setValidationLoader(false);
            return;
          }
          setErrorMessage(t("validation.error_connexion"));
          setValidationLoader(false);
        });
    }
  };

  const resendHandler = async () => {
    if (allowResend) {
      countDownHandler();
      setErrorMessage("");
      const TOKEN = await AsyncStorage.getItem("access_token");
      axios
        .post(process.env.USER_SEND_CODE, {
          access_token: TOKEN,
        })
        .then(() => {
          toast.show({
            placement: "bottom",
            render: () => {
              return (
                <View style={styles.toastContainer}>
                  <Ionicons
                    name="checkmark-circle"
                    size={24}
                    color={colors.white}
                    style={{ marginRight: 5 }}
                  />
                  <Txt color={colors.white}>
                    {t("validation.success_resend")}
                  </Txt>
                  <TouchableOpacity
                    style={{ marginLeft: 20 }}
                    onPress={() => toast.closeAll()}
                  >
                    <AntDesign name="close" size={24} color={colors.white} />
                  </TouchableOpacity>
                </View>
              );
            },
          });
        })
        .catch((err) => {
          setErrorMessage(t("validation.error_connexion"));
        });
    }
  };

  const countDownHandler = async () => {
    setAllowResend(false);
    let interval = await setInterval(() => {
      setTimerCount((lastTimerCount) => {
        if (lastTimerCount <= 1) {
          clearInterval(interval);
          setAllowResend(true);
          setTimerCount(59);
        }
        return lastTimerCount - 1;
      });
    }, 1000); //each count lasts for a second
  };

  return (
    <View style={styles.container}>
      <View style={{ width: "100%", maxWidth: 600, alignSelf: "center" }}>
        <View>
          <H2 style={{ textAlign: "center" }}>{t("validation.title")}</H2>
          <View style={{ alignSelf: "center", marginTop: 15 }}>
            <MaterialCommunityIcons
              name="email-fast"
              size={40}
              color={colors.blue3}
            />
          </View>
          <Txt
            style={{
              textAlign: "center",
              marginBottom: 20,
              marginHorizontal: 10,
              lineHeight: 28,
              fontSize: 18,
            }}
          >
            {t("validation.text1_mail")}
            {"\n"}
            {t("validation.text2_mail")}
          </Txt>

          {/** error message */}
          <SmallBoldTxt
            style={{
              color: colors.red1,
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            {errorMessage.length > 0 ? errorMessage : " "}
          </SmallBoldTxt>
          {/** validation code input */}
          <View style={{ width: "90%", maxWidth: 300, alignSelf: "center" }}>
            <CodeField
              ref={ref}
              {...props}
              // Use `caretHidden={false}` when users can't paste a text value, because context menu doesn't appear
              value={value}
              onChangeText={setValue}
              cellCount={CELL_COUNT}
              rootStyle={styles.codeFieldRoot}
              keyboardType="number-pad"
              useBlurOnFulfill
              textContentType="oneTimeCode"
              renderCell={({ index, symbol, isFocused }) => (
                <Text
                  key={index}
                  style={[
                    styles.cell,
                    isFocused && styles.focusCell,
                    symbol.length > 0 && styles.filledCell,
                  ]}
                  onLayout={getCellOnLayoutHandler(index)}
                >
                  {symbol || (isFocused ? <Cursor /> : null)}
                </Text>
              )}
            />
          </View>

          {/** buttons verify - resend */}

          <View style={{ width: "100%", alignItems: "center" }}>
            <PrimaryButton
              onPress={validationHandler}
              style={{ marginTop: 20 }}
              width={isDesktop ? 400 : "70%"}
            >
              {validationLoader ? (
                <Spinner
                  color={validationLoader ? colors.white : colors.blue3}
                  size="small"
                />
              ) : (
                t("validation.button1")
              )}
            </PrimaryButton>
            <SecondaryButton
              onPress={resendHandler}
              style={{ marginTop: 15 }}
              width={isDesktop ? 400 : "70%"}
            >
              {allowResend ? t("validation.button2") : `0:${timerCount}`}
            </SecondaryButton>
            <View>
              <TouchableOpacity
                onPress={() => setValidationMethod(null)}
                style={styles.backButton}
              >
                <MaterialIcons
                  name="keyboard-backspace"
                  size={13}
                  color={colors.blue3}
                />
                <Txt style={{ fontSize: 13, marginLeft: 3 }}>{t("back")}</Txt>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default ValidationEmail;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  toastContainer: {
    backgroundColor: colors.green2,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 20,
  },
  codeFieldRoot: { marginBottom: 20 },
  cell: {
    width: 40,
    height: 40,
    lineHeight: 36,
    fontSize: 18,
    borderRadius: 4,
    borderWidth: 2,
    borderColor: colors.grayBorder,
    textAlign: "center",
    color: colors.green3,
    fontFamily: "OxygenBold",
  },
  focusCell: {
    borderColor: colors.blue3,
    color: colors.blue3,
  },
  filledCell: {
    backgroundColor: colors.blue3,
    borderColor: colors.blue3,
    color: colors.white,
  },
  backButton: {
    borderBottomWidth: 1,
    borderColor: colors.blue3,
    marginTop: 30,
    flexDirection: "row",
    alignItems: "center",
  },
});
