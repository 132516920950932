import { StyleSheet, View } from "react-native";
import React, { useRef, useState } from "react";
import DimensionsHook from "../../../hooks/DimensionsHook";
import { useHover } from "react-native-web-hooks";
import {
  BoldTxt,
  H4,
  Txt,
  SmallTxt,
  SmallBoldTxt,
} from "../../TextsComponents";
import { PrimaryButton } from "../../Buttons";
import { Surface } from "react-native-paper";
import { colors } from "../../../styles/GlobalStyle";
import { useSelector } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import Spinner from "../../Spinner";
import { useTranslation } from "react-i18next";

const Subscription = ({ plan, current, navigation }) => {
  const { width } = DimensionsHook();
  const { t, i18n } = useTranslation();
  const isDesktop = width > 1000;
  const isMobile = width < 600;
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const userData = useSelector((state) => state.userData);
  const [loader, setLoader] = useState(false);

  const paymentHandler = async () => {
    if (!loader) {
      setLoader(true);
      setTimeout(() => {
        const url = process.env.CHANGE_PLAN;

        axios
          .post(url, {
            sub: userData.subscription,
            newPlan: plan.stripe,
            link: plan.ios_iap,
            customer: userData.customer,
          })
          .then((res) => {
            setLoader(false);
            navigation.replace("AccountPayment");
          })
          .catch((err) => {
            //console.log(err);

            setLoader(false);
          });
      }, 1500);
    }
  };

  return (
    <Surface
      style={[
        styles.cardContainer,
        {
          width: isMobile ? "95%" : isDesktop ? "32%" : "48%",
          marginTop: isMobile ? 20 : 10,
          backgroundColor: isHovered ? colors.blue2 : colors.beige,
          cursor: "pointer",
        },
      ]}
      elevation={4}
    >
      <View style={{ width: "100%", alignItems: "center" }} ref={hoverRef}>
        {/** duration plan */}
        <View style={{ alignItems: "center", marginTop: 10 }}>
          <H4 color={isHovered ? colors.blue3 : colors.blue3}>
            {i18n.language == "fr" ? plan.title_fr : plan.title_en}
          </H4>
        </View>
        {/** price */}
        <View style={{ alignItems: "center", paddingTop: 8 }}>
          <View
            style={[
              styles.priceContainer,

              {
                backgroundColor: isHovered ? "#ffffff15" : "#88C1A315",
                marginBottom: 10,
              },
            ]}
          >
            {/** ${plan.months} ×  */}
            <Txt style={{ fontSize: 15 }}>
              {`${plan.price_month}${t("plans.eur_per_month")}`}
            </Txt>
          </View>
          <View
            style={[
              styles.priceContainer,
              { backgroundColor: isHovered ? "#ffffff15" : "#88C1A315" },
            ]}
          >
            <BoldTxt
              color={isHovered ? colors.white : colors.green2}
              style={{ fontSize: 20 }}
            >
              {plan.price}€ {t("tax")}
            </BoldTxt>
          </View>
        </View>

        {/** subscribe button */}

        <PrimaryButton
          disabled={current}
          width={200}
          style={{ marginVertical: 20 }}
          onPress={paymentHandler}
        >
          {loader ? <Spinner color={colors.white} /> : t("plans.choose")}
        </PrimaryButton>
        <View style={{ marginHorizontal: 15, marginBottom: 30, marginTop: 15 }}>
          <SmallTxt
            style={{ textAlign: "center" }}
            color={isHovered ? colors.blue3 : colors.blue3}
          >
            {i18n.language == "fr" ? plan.description_fr : plan.description_en}
          </SmallTxt>
        </View>
      </View>
      {/** popular badge */}
      {plan.popular && (
        <View style={styles.badgeContainer}>
          <View style={[styles.triangle, styles.triangle2]} />
          <View>
            <Surface elevation={2} style={styles.badge}>
              <SmallBoldTxt
                style={{ textAlign: "center", fontSize: 13 }}
                color={colors.white}
              >
                {t("plans.popular")}
              </SmallBoldTxt>
            </Surface>
          </View>
        </View>
      )}
    </Surface>
  );
};

export default Subscription;

const styles = StyleSheet.create({
  cardContainer: {
    marginTop: 10,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 10,
  },
  priceContainer: {
    paddingVertical: 6,
    paddingHorizontal: 20,
    borderRadius: 10,
  },
  badgeContainer: {
    position: "absolute",
    top: -10,
    right: 5,
    flexDirection: "row",
    alignItems: "flex-start",
  },
  badge: {
    backgroundColor: colors.green2,
    width: 100,
    paddingHorizontal: 10,
    paddingVertical: 20,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 1,
    borderTopRightRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: -1,
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
  },
  triangle2: {
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 10,
    borderLeftWidth: 10,
    borderTopColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: "#5FA47F",
    borderLeftColor: "transparent",
  },
});
