import {
  Pressable,
  StyleSheet,
  View,
  Platform,
  ImageBackground,
} from "react-native";
import React from "react";
import { Modal } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { ArrowBack } from "../../assets/svg/Icons";
import Constants from "expo-constants";
import RenderHTML from "react-native-render-html";
import WebView from "react-native-webview";
import IframeRenderer, { iframeModel } from "@native-html/iframe-plugin";
import { useTranslation } from "react-i18next";
import { H6 } from "../TextsComponents";
import { SecondaryButton } from "../Buttons";
import { CloseIcon } from "../../assets/svg/Logo";

const renderers = {
  iframe: IframeRenderer,
};

const customHTMLElementModels = {
  iframe: iframeModel,
};

const TutoVideo = ({ isOpen, setIsOpen }) => {
  const { isMobile, width, height } = DimensionsHook();
  const { t } = useTranslation();
  const WIDTH =
    Platform.OS == "macos"
      ? 800
      : isMobile
        ? width
        : width < 1000
          ? width * 0.9
          : width * 0.6;

  const closeHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content
        style={[
          styles.modalContainer,
          {
            backgroundColor: isMobile ? colors.beige : colors.white,
            height: Platform.OS == "macos" ? 550 : isMobile ? "100%" : "auto",
            paddingTop: Platform.OS == "ios" ? Constants.statusBarHeight : 0,
            borderRadius: isMobile ? 0 : 20,
            width: Platform.OS == "macos" ? 900 : isMobile ? WIDTH : WIDTH + 50,
          },
        ]}
      >
        <ImageBackground
          source={require("../../assets/background.png")}
          style={{ flex: 1 }}
        >
          {isMobile ? (
            <View style={styles.backContainer}>
              <Pressable onPress={closeHandler} style={styles.backButton}>
                <View onPress={closeHandler} style={{ width: 24, height: 24 }}>
                  <ArrowBack />
                </View>
              </Pressable>
            </View>
          ) : (
            <></>

          )}
          <Modal.Body
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",

              height: isMobile ? height - 70 : "100%",
              marginTop: Platform.OS === "ios" ? -Constants.statusBarHeight : 0,
            }}
          >
            {Platform.OS != "ios" && (
              <H6 style={{ textAlign: "center", marginTop: 20 }}>
                {t("home_main.tuto")}
              </H6>
            )}
            {Platform.OS == "web" && !isMobile && <Pressable onPress={closeHandler} style={{ position: 'absolute', top: 20, right: 20, alignSelf: 'flex-end', }}>
              <CloseIcon color={colors.grayLabel} width={16} height={16} />
            </Pressable>}
            <View
              style={{
                borderRadius: !isMobile ? 15 : 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                flex: 1,
                alignItems: "center",
                marginTop: isMobile ? 0 : 10,
                justifyContent: "center",
              }}
            >
              {/** iframe bunny */}
              {Platform.OS == "web" && isOpen ? (
                <View
                  style={{
                    alignSelf: "center",
                    width: WIDTH,
                    aspectRatio: "16/9",

                    cursor: "pointer",
                    backgroundColor: colors.grayBackground,
                    alignSelf: "center",
                    alignItems: "center",
                    justifyContent: "center",

                    overflow: "hidden",
                  }}
                >
                  <iframe
                    allowFullScreen
                    loading="eager"
                    id="a"
                    src="https://iframe.mediadelivery.net/embed/68396/23c5e59a-1490-46e6-9216-00f7ff453880?autoplay=true"
                    style={{ width: "100%", height: "100%" }}
                  ></iframe>
                </View>
              ) : isOpen ? (
                <View style={{ flex: 1 }}>
                  <RenderHTML
                    renderers={renderers}
                    WebView={WebView}
                    contentWidth={width}
                    source={{
                      html: `
      <div style="width:100%; height:100%; background-color:transparent; align-items:center;justify-content:center;">
      <iframe src="https://iframe.mediadelivery.net/embed/68396/23c5e59a-1490-46e6-9216-00f7ff453880?autoplay=true" 
    loading="eager" style="border: none;  width: 100%;  color:green;" 
    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true">
</iframe>
</div>
`,
                    }}
                    customHTMLElementModels={customHTMLElementModels}
                    defaultWebViewProps={{}}
                    renderersProps={{
                      iframe: {
                        scalesPageToFit: true,
                        webViewProps: {},
                      },
                    }}
                  />

                </View>
              ) : (
                <></>
              )}
              {Platform.OS == "ios" && (
                <H6 style={{ textAlign: "center", marginBottom: 10 }}>
                  {t("home_main.tuto")}
                </H6>
              )}
              {/*  <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexWrap: "wrap",
                  marginTop: 10,
                  width: "100%",
                }}
              >
               <View style={{ marginVertical: 15 }}>
                  <SecondaryButton onPress={noShowTutoHandler}>
                    {t("tuto.not_show_anymore")}
                  </SecondaryButton>
                </View>
                {!isMobile && (
                  <View style={{ marginVertical: 15, marginLeft: 10 }}>
                    <PrimaryButton onPress={closeHandler}>
                      {t("tuto.close")}
                    </PrimaryButton>
                  </View>
                )}
              </View>*/}
            </View>

          </Modal.Body>
        </ImageBackground>
      </Modal.Content>
    </Modal>
  );
};

export default TutoVideo;

const styles = StyleSheet.create({
  backContainer: {
    height: 70,
    width: "100%",
    flexDirection: "row",
    backgroundColor: colors.beige,
    borderBottomWidth: 1,
    borderColor: colors.grayBorder,
  },
  backButton: {
    height: 70,
    width: 70,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1B496515",
  },

  modalContainer: {
    maxWidth: 1000,
    maxHeight: 5000,
  },
});
