import {
  View,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  ImageBackground,
  Platform,
  Alert,
} from "react-native";
import React, { useEffect, useState } from "react";
import HeaderComponent from "../../components/HeaderComponent";
import { BoldTxt, H2, H6, H7 } from "../../components/TextsComponents";
import DimensionsHook from "../../hooks/DimensionsHook";
import Coaching from "./Components/coaching";
import Status from "./Components/Status";
import Score from "./Components/Score";
import GreenCards from "./Components/GreenCard";
import { colors } from "../../styles/GlobalStyle";
import FavorisSwiper from "./Components/FavorisSwiper";
import Footer from "../../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import CurrentTrailsSwiper from "../../components/swiper/CurrentTrailsSwiper";
import Spinner from "../../components/Spinner";
import FinishedSwiper from "./Components/FinishedSwiper";
import SoonModal from "../../components/modals/SoonModal";
import { setUserActivity, setUserData } from "../../redux/Actions";
import SavedSwiper from "./Components/SavedSwiper";
import ChatModal from "../../components/modals/ChatModal";
import EpisodeModal from "../../components/videos/EpisodeModal";
import VideoMovement from "../../components/videos/VideoMovement";
import RecommandationsSwiper from "./Components/RecommandationsSwiper";
import { HStack } from "native-base";
import { AntDesign } from "@expo/vector-icons";
const Espace = ({ navigation }) => {
  const { isDesktop, isMobile, width, height } = DimensionsHook();
  const { t, i18n } = useTranslation();
  const userActivity = useSelector((state) => state.userActivity);
  const [data, setData] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [currentTrails, setCurrentTrails] = useState([]);
  const [soonModal, setSoonModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [showEpisode, setShowEpisode] = useState(false);
  const [starsCount, setStarsCount] = useState(0)

  const getCurrentTrails = async () => {
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(`${process.env.CURRENT_TRAILS}?language=${i18n.language}`, config)
      .then((res) => {
        // remove finished trails from current trails
        const data = res.data.filter(
          (trail) => !userActivity.finished_trails.includes(trail.trail_code)
        );
        setCurrentTrails(data);
        setLoader(false);
      });
  };

  useEffect(() => {
    getCurrentTrails();
  }, [userActivity, i18n.language]);

  const getStarsNumber = async () => {
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios.get(process.env.STARS_NUMBER, config).then(res => {
      setStarsCount(res.data)
      console.log(res.data)
    })
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      dispatch(setUserData());
      dispatch(setUserActivity())
      getStarsNumber()

    });

    return unsubscribe;
  }, [navigation]);

  // section Status & score & cards
  const ContainerC1 = {
    backgroundColor: colors.beige,
    width: "100%",
    alignSelf: "center",
    paddingTop: 15,
    paddingBottom: 5,
    borderRadius: 20,
    flexDirection: width <= 1300 ? "column" : "row",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: !isDesktop ? 5 : 0,
    paddingBottom: !isDesktop ? 15 : 0,
  };
  const BoxA = {
    flexDirection: width >= 790 ? "row" : "column",
    width: width <= 1300 ? "100%" : "50%",
    alignItems: "center",
    justifyContent: "space-evenly",
  };
  const BoxB = {
    flexDirection: width >= 1300 ? "row" : "column",
    width: !isDesktop ? "100%" : width <= 1300 ? "100%" : "50%",
    alignItems: "center",
    justifyContent: "space-between",
    overflow: "hidden",
  };
  // section-Trails & favoris
  const Trails = {
    backgroundColor: colors.beige,
    width: "100%",
    alignSelf: "center",
    borderRadius: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch",
    flexDirection: width <= 1300 ? "column" : "row",
    marginTop: 20,
  };
  const TrailsContainer = {
    backgroundColor: colors.white,
    width: width <= 1300 ? `100%` : `49.5%`,
    borderRadius: 20,
    marginLeft: 5,
    paddingTop: 8,
    marginBottom: 20,
  };

  return (
    <View style={styles.container}>
      <SoonModal isOpen={soonModal} setIsOpen={setSoonModal} />
      <ImageBackground
        source={require("../../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView showsVerticalScrollIndicator={Platform.OS === "web"}>
          <HeaderComponent
            name={t("tab_navigation.my_space")}
            navigation={navigation}
          />
          <View style={{ marginHorizontal: "2.5%" }}>
            <HStack style={{ marginTop: 20 }} alignItems='center' justifyContent="space-between">
              <H2 >{t("tab_navigation.my_space")}</H2>
              {/*starsCount > 0 && <HStack style={{ borderWidth: 1, borderColor: colors.yellow, backgroundColor: colors.beige, marginLeft: isDesktop ? 10 : 0 }} py="0.5" borderRadius="md" px="4" space="1" alignItems="center" justifyContent="center" >
                <BoldTxt style={{ fontSize: 16 }} color={colors.yellow} >{starsCount}</BoldTxt>
                <AntDesign name="star" size={20} color={colors.yellow} />
  </HStack>*/}
            </HStack>
            {/** cards section */}
            <View style={ContainerC1}>
              <View style={BoxA}>
                <Status />
                <Score />
              </View>
              <View style={BoxB}>
                <GreenCards

                  data={data} setData={setData} showVideoModal={showVideoModal} setShowVideoModal={setShowVideoModal}
                  setShowChatModal={setShowChatModal}
                  setSoonModal={setSoonModal}
                  navigation={navigation}
                />
                <Coaching navigation={navigation} setSoonModal={setSoonModal} />
              </View>
            </View>

            {/* Trails */}
            {loader ? (
              <View style={{ marginVertical: 20 }}>
                <Spinner size="large" />
              </View>
            ) : (
              currentTrails.length > 0 && (
                <CurrentTrailsSwiper
                  data={currentTrails}
                  navigation={navigation}
                  myspace={true}
                />
              )
            )}
            {/** saved episodes */}
            <SavedSwiper setSelectedEpisode={setSelectedEpisode} setShowEpisode={setShowEpisode} />

            {/** favoris  */}
            <View style={Trails}>
              {/** favoris trails */}
              <View style={TrailsContainer}>
                <View style={styles.row}>
                  <H6 style={{ paddingLeft: isMobile ? 10 : 20 }}>
                    {t("my_space.title2_trail")}
                  </H6>

                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate("Favoris", { type: "Trail" })
                    }
                  >
                    <BoldTxt
                      style={{
                        paddingRight: isMobile ? 10 : 20,
                      }}
                    >
                      {t("my_space.see_all")}
                    </BoldTxt>
                  </TouchableOpacity>
                </View>

                <FavorisSwiper navigation={navigation} type="Trail" />
              </View>

              {/* Favoris Ateliers */}

              <View style={TrailsContainer}>
                <View style={styles.row}>
                  <H6 style={{ paddingLeft: isMobile ? 10 : 20 }}>
                    {t("my_space.title2_workshop")}
                  </H6>

                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate("Favoris", { type: "Atelier" })
                    }
                  >
                    <BoldTxt
                      style={{
                        paddingRight: isMobile ? 10 : 20,
                      }}
                    >
                      {t("my_space.see_all")}
                    </BoldTxt>
                  </TouchableOpacity>
                </View>

                <FavorisSwiper navigation={navigation} type="Atelier" />
              </View>
            </View>




            {/** finished contents */}
            <FinishedSwiper navigation={navigation} />

            {/** recommandations 

            <RecommandationsSwiper navigation={navigation} type="Trail" />

            <RecommandationsSwiper navigation={navigation} type="Atelier" />*/}
          </View>
          <View style={{ height: 30 }} />
          {isDesktop && <Footer />}
          {Platform.OS == "web" && <ChatModal isOpen={showChatModal} setIsOpen={setShowChatModal} />}
        </ScrollView>

      </ImageBackground>
      {selectedEpisode && showEpisode && (
        <View

          style={{
            position: "absolute",
            width,
            height,
            backgroundColor: '#00000095'
          }}
        >
          <EpisodeModal
            episode={selectedEpisode}
            setEpisode={setSelectedEpisode}
            isOpen={showEpisode}
            setIsOpen={setShowEpisode}
          /></View>
      )}
      {data && showVideoModal && (
        <View

          style={{
            position: "absolute",
            width,
            height,
            backgroundColor: '#00000095'
          }}
        >
          <VideoMovement
            withTitle={true}
            isOpen={showVideoModal}
            setIsOpen={setShowVideoModal}
            data={data}
          /></View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },

  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 10,
  },
});

export default Espace;
