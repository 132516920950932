import {
  StyleSheet,
  View,
  ScrollView,
  Platform,
  ImageBackground,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { colors } from "../styles/GlobalStyle";
import BackHeader from "../components/BackHeader";
import axios from "axios";
import Spinner from "../components/Spinner";
import UpperSection from "../components/trail/UpperSection";
import DimensionsHook from "../hooks/DimensionsHook";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setUserActivity,
  setUserData,
  setWatchedEpisodes,
} from "../redux/Actions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import PrealableVideo from "../components/videos/PrealableVideo";
import TrailEpisodeVideo from "../components/videos/TrailEpisodeVideo";
import VideoMovement from "../components/videos/VideoMovement";
import SuccessEpisode from "../components/modals/SuccessEpisode";

const Trail = ({ navigation, route }) => {
  const { t, i18n } = useTranslation();

  const item = route.params;
  // in

  const dispatch = useDispatch();
  const { current_trails } = useSelector((state) => state.userActivity);
  const watched_episodes = useSelector((state) => state.watchedEpisodes);
  const questionnairesData = useSelector((state) => state.questionnairesData);
  const [firstTimeFinished, setFirstTimeFinished] = useState(false)
  const { isDesktop, width, height } = DimensionsHook();
  const [loader, setLoader] = useState(true);
  const [selectedSeason, setSelectedSeason] = useState(0);
  const [backFromEpisode, setBackFromEpisode] = useState(false)
  const [episodesLoader, setEpisodesLoader] = useState(true);
  const [prealable, setPrealable] = useState(null);
  const [isOpenFirstVisit, setIsOpenFirstVisit] = useState(false);
  const [showPrealableVideoModal, setShowPrealableVideoModal] = useState(false);

  const [lastWatchedEpisode, setLastWatchedEpisode] = useState(0);
  const [seasons, setSeasons] = useState([]);
  const [sequenceEpisodes, setSequenceEpisodes] = useState([]);
  const [isOpenGreenModal, setIsOpenGreenModal] = useState(false);

  const postQuestionnaire =
    questionnairesData.find((el) => {
      return el.trail == item.trail_code && el.amont == false;
    }) || null;
  const [showTrailVideoModal, setShowTrailVideoModal] = useState(false);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [isOpenChooseModal, setIsOpenChooseModal] = useState(false);
  const [upperLoader, setUpperLoader] = useState(true);
  const [showPostQuestionnaire, setShowPostQuestionnaire] = useState(false);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);
  const [showMovementVideoModal, setShowMovementVideoModal] = useState(false);
  const [episodes, setEpisodes] = useState([])
  const [showSeason, setShowSeason] = useState(false);
  const [showSuccessEpisode, setShowSuccessEpisode] = useState(false)

  const [seasonOptions, setSeasonOptions] = useState([
    {
      label: `${t("trail.season")} 1`,
      value: 0,
    },
  ]);

  const [currentSeason, setCurrentSeason] = useState({
    label: `${t("trail.season")}`,
    value: " ",
  });

  // get seasons + check user progression and accesibility
  const getData = async () => {
    setLoader(true);

    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    // get prealable

    const url = process.env.TRAILS_ENDPOINT;

    axios
      .get(`${url}/prealable?language=${i18n.language}`, config)
      .then((prealableRes) => {
        setPrealable(prealableRes.data);
        // get Seasons
        axios
          .get(
            `${process.env.SEASONS_ENDPOINT}/${item.trail_code}?language=${i18n.language}`,
            config
          )
          .then(async (res) => {
            const seasonsData = res.data;

            setSeasons(seasonsData);
            const allEpisodes = [];
            await seasonsData.forEach((season) => {
              allEpisodes.push(...season.episodes);
            });
            setSequenceEpisodes(allEpisodes);

            // is First time ?
            const isFirstVisit = !current_trails.includes(item.trail_code);
            if (isFirstVisit) {
              const season = seasonsData.find(
                (season) => season.season_code == item.trail_code + "-S1"
              );

              setSelectedSeason(item.seasonData ? item.seasonData : season);
              setLoader(false);
            } else {
              const watchedEpisodes = watched_episodes.filter((ep) =>
                ep.includes(item.trail_code)
              );
              const lastWatchedEpisode =
                watchedEpisodes[watchedEpisodes.length - 1];

              const lastEpisodeCodeSplitted = lastWatchedEpisode.split("-");

              const lastWatchedSeason =
                item.trail_code + "-" + lastEpisodeCodeSplitted[2];
              setLoader(false);
              const season = seasonsData.find(
                (season) => season.season_code == lastWatchedSeason
              );

              setSelectedSeason(item.seasonData ? item.seasonData : season);

              setLastWatchedEpisode(lastWatchedEpisode);

              //in case come from episode

              setLoader(false);
            }
          });
      });
  };

  useEffect(() => {
    getData();
    dispatch(setWatchedEpisodes());
    dispatch(setUserActivity());
    dispatch(setUserData());
  }, []);


  const getEpisodes = async () => {
    if (selectedSeason.season_code) {

      setUpperLoader(true);

      const TOKEN = await AsyncStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      axios
        .get(
          `${process.env.EPISODES_ENDPOINT}/${selectedSeason.season_code}?language=${i18n.language}`,
          config
        )
        .then((res) => {
          setEpisodes(res.data);
          setUpperLoader(false);
        });
    }
  };

  useEffect(() => {
    getEpisodes();
  }, [selectedSeason.season_code]);

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <BackHeader
          isOpen={false}
          setIsOpen={() => { }}
          navigation={navigation}
        />

        {loader || episodes.length == 0 ? (
          <View style={styles.loadingContainer}>
            <Spinner size="large" />
          </View>
        ) : (
          <ScrollView
            style={{ flex: 1 }}
            showsVerticalScrollIndicator={Platform.OS === "web"}
          >
            <UpperSection
              loader={upperLoader}
              backFromEpisode={backFromEpisode}
              setBackFromEpisode={setBackFromEpisode}
              navigation={navigation}
              lastWatchedEpisode={lastWatchedEpisode}
              episodes={episodes}
              item={item}
              sequenceEpisodes={sequenceEpisodes}
              seasons={seasons}
              selectedSeason={selectedSeason}
              setSelectedSeason={setSelectedSeason}
              setIsOpenFirstVisit={setIsOpenFirstVisit}
              isOpenFirstVisit={isOpenFirstVisit}
              setShowPrealableVideoModal={setShowPrealableVideoModal}
              setShowTrailVideoModal={setShowTrailVideoModal}
              selectedEpisode={selectedEpisode}
              setIsOpenChooseModal={setIsOpenChooseModal}
              setIsOpenGreenModal={setIsOpenGreenModal}
              postQuestionnaire={postQuestionnaire}
              setShowPostQuestionnaire={setShowPostQuestionnaire}
              setSelectedEpisode={setSelectedEpisode}
              isOpenChooseModal={isOpenChooseModal}
              showPostQuestionnaire={showPostQuestionnaire}
              isOpenGreenModal={isOpenGreenModal}
              setSelectedWorkshop={setSelectedWorkshop}
              setShowMovementVideoModal={setShowMovementVideoModal}
              showSeason={showSeason}
              setShowSeason={setShowSeason}
              seasonOptions={seasonOptions}
              setSeasonOptions={setSeasonOptions}
              currentSeason={currentSeason}
              setCurrentSeason={setCurrentSeason}
            />

          </ScrollView>
        )}
      </ImageBackground>

      {/** prealable video */}
      {prealable && showPrealableVideoModal && (
        <View
          style={{
            position: "absolute",
            width,
            height,
            backgroundColor: "#00000095",
          }}
        >
          <PrealableVideo
            isOpen={showPrealableVideoModal}
            setIsOpen={setShowPrealableVideoModal}
            setIsOpenFirstVisit={setIsOpenFirstVisit}
            data={prealable}
          />
        </View>
      )}


      {/** success episode tokens win */}
      <SuccessEpisode setIsOpenChooseModal={setIsOpenChooseModal} isOpen={showSuccessEpisode} setIsOpen={setShowSuccessEpisode} title={t('trail.done')} text={t('trail.done1')} />
      {/** trail episode */}

      {showTrailVideoModal && selectedEpisode && (
        <View
          style={{
            position: "absolute",
            width,
            height,
            backgroundColor: "#00000095",
          }}
        >
          <TrailEpisodeVideo
            isOpen={showTrailVideoModal}
            setIsOpen={setShowTrailVideoModal}
            data={selectedEpisode}
            setIsOpenChooseModal={setIsOpenChooseModal}
            setIsOpenGreenModal={setIsOpenGreenModal}
            setShowSuccessEpisode={setShowSuccessEpisode}
            firstTimeFinished={firstTimeFinished}
            setFirstTimeFinished={setFirstTimeFinished}
            setBackFromEpisode={setBackFromEpisode}
          />
        </View>
      )}

      {/** show movement video */}
      {selectedWorkshop && showMovementVideoModal && (
        <View
          style={{
            position: "absolute",
            width,
            height,
            backgroundColor: "#00000095",
          }}
        >
          <VideoMovement
            isOpen={showMovementVideoModal}
            setIsOpen={setShowMovementVideoModal}
            data={selectedWorkshop}
          />
        </View>
      )}
    </View>
  );
};

export default Trail;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },
  loadingContainer: {
    width: "100%",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
