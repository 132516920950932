import { StyleSheet, View, TouchableWithoutFeedback } from "react-native";
import React, { useState } from "react";
import DimensionsHook from "../../hooks/DimensionsHook";
import { BoldTxt, H1, SmallBoldTxt, Txt } from "../TextsComponents";
import { DropDown } from "../Inputs";
import { colors } from "../../styles/GlobalStyle";
import { PrimaryButton } from "../Buttons";
import { useTranslation } from "react-i18next";
import GreenModal from "../modals/GreenModal";
import Constants from "expo-constants";
import { Switch } from "native-base";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDispatch, useSelector } from "react-redux";
import { toggleNotificationAction } from "../../redux/Actions";
import { RadioButton } from "react-native-paper";

const SettingsScreen = ({ navigation }) => {
  const version = Constants.manifest.version;

  const { notification, notification_frequency, notification_mode } =
    useSelector((state) => state.userData);
  const { isDesktop, isMobile } = DimensionsHook();
  const [isOpenGreenModal, setIsOpenGreenModal] = useState(false);
  const [acceptNotification, setAcceptNotification] = useState(notification);
  const [frequencyNotification, setFrequencyNotification] = useState(
    notification_frequency
  );
  const [modeLoader, setModeLoader] = useState(false);
  const [frequencyLoader, setFrequencyLoader] = useState(false);
  const [modeNotification, setModeNotification] = useState(notification_mode);
  const [toggleLoader, setToggleLoader] = useState(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const languages = [
    { label: "Français", value: "fr" },
    { label: "English", value: "en" },
  ];
  const frequences = [
    { label: t("setting_screen.options.q"), value: "q" },
    { label: t("setting_screen.options.h"), value: "h" },
    { label: t("setting_screen.options.m"), value: "m" },
  ];
  const modes = [
    { label: t("setting_screen.options.all"), value: "all" },
    { label: t("setting_screen.options.sms"), value: "sms" },
    { label: t("setting_screen.options.mail"), value: "mail" },
  ];

  const [language, setLanguage] = useState(
    languages.find((lng) => lng.value === i18n.language)
  );
  const [showLanguage, setShowLanguage] = useState(false);

  const logoutHandler = () => {
    setIsOpenGreenModal(true);
  };

  const toggleNotification = async () => {
    if (!toggleLoader) {
      setToggleLoader(true);
      setAcceptNotification(!acceptNotification);

      const TOKEN = await AsyncStorage.getItem("access_token");
      axios
        .put(process.env.USER_UPDATE, {
          access_token: TOKEN,
          notification: !acceptNotification,
        })
        .then((res) => {
          dispatch(toggleNotificationAction(!acceptNotification));
          setToggleLoader(false);
        })
        .catch((err) => {
          setToggleLoader(false);
          console.log(err);
        });
    }
  };
  const changeFrequenceHandler = async (el) => {
    const TOKEN = await AsyncStorage.getItem("access_token");
    if (!frequencyLoader) {
      setFrequencyLoader(true);
      setFrequencyNotification(el.value);
      axios
        .put(process.env.USER_UPDATE, {
          access_token: TOKEN,
          notification_frequency: el.value,
        })
        .then(() => {
          setFrequencyLoader(false);
        })
        .catch(() => {
          setFrequencyLoader(false);
          setFrequencyNotification(notification_frequency);
        });
    }
  };

  const changeModeHandler = async (el) => {
    const TOKEN = await AsyncStorage.getItem("access_token");
    if (!modeLoader) {
      setModeLoader(true);
      setModeNotification(el.value);
      axios
        .put(process.env.USER_UPDATE, {
          access_token: TOKEN,
          notification_mode: el.value,
        })
        .then(() => {
          setModeLoader(false);
        })
        .catch(() => {
          setModeLoader(false);
          setModeNotification(notification_mode);
        });
    }
  };

  return (
    <TouchableWithoutFeedback onPress={() => setShowLanguage(false)}>
      <View style={{ marginTop: isDesktop ? 0 : 20, flex: 1 }}>
        <H1>{t("setting_screen.settings")}</H1>
        {/** language */}
        <View
          style={{
            marginVertical: 20,
            marginBottom: 30,
            flexDirection: !isMobile ? "row" : "column",
            alignItems: !isMobile ? "center" : "flex-start",
            justifyContent: !isMobile ? "space-between" : "flex-start",
          }}
        >
          <BoldTxt style={{ marginBottom: isMobile ? 20 : 0, fontSize: 15 }}>
            {t("setting_screen.your_language")}
          </BoldTxt>
          <View
            style={{
              width: isMobile ? "90%" : 300,
              alignSelf: "center",
            }}
          >
            <DropDown
              show={showLanguage}
              language={true}
              setting={true}
              navigation={navigation}
              setShow={() => setShowLanguage(!showLanguage)}
              value={language}
              setValue={setLanguage}
              options={languages}
              placeholder={language}
              height={64}
              smallLabel={t("setting_screen.language")}
            />
          </View>
        </View>
        <View style={{ zIndex: -1 }}>
          <View style={styles.divider} />
          {/** logout */}
          <View
            style={{
              marginVertical: 20,
              flexDirection: !isMobile ? "row" : "column",
              alignItems: !isMobile ? "center" : "flex-start",
              justifyContent: !isMobile ? "space-between" : "flex-start",
            }}
          >
            <BoldTxt
              style={{
                marginBottom: isMobile ? 20 : 0,
                marginRight: 10,
                fontSize: 15,
              }}
            >
              {t("setting_screen.logout")}
            </BoldTxt>
            <PrimaryButton
              whiteText
              style={{ backgroundColor: colors.red1 }}
              onPress={logoutHandler}
            >
              {t("setting_screen.logout_button")}
            </PrimaryButton>
          </View>
          <View style={styles.divider} />

          <View>
            <Txt style={{ fontSize: 15 }}>
              {t("setting_screen.notification_mail1")}
            </Txt>
            <Txt style={{ fontSize: 15, marginTop: 10 }}>
              <BoldTxt style={{ fontSize: 15 }}>
                {t("setting_screen.notification_required")}
              </BoldTxt>

              {t("setting_screen.notification_mail2")}
            </Txt>

            <View>
              <View style={[{ marginTop: 15, marginBottom: 0 }]} />
              {/** notification  */}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 10,
                  marginBottom: 20,
                }}
              >
                <BoldTxt
                  style={{
                    flex: 1,
                    fontSize: 15,
                  }}
                >
                  {t("setting_screen.notification")}
                </BoldTxt>
                <Switch
                  style={{ marginLeft: 15 }}
                  isChecked={acceptNotification}
                  onToggle={toggleNotification}
                  w="10%"
                  size="lg"
                  offTrackColor={colors.grayLabel}
                  onTrackColor={colors.green2}
                  onThumbColor={colors.green3}
                  offThumbColor={colors.green0}
                />
              </View>
              <Txt style={{ fontSize: 15 }}>
                <BoldTxt style={{ fontSize: 15 }}>
                  {t("setting_screen.notification_optional")}
                </BoldTxt>
                {t("setting_screen.notification_mail3")}
              </Txt>
              <Txt style={{ fontSize: 15, marginTop: 10 }}>
                {t("setting_screen.notification_frequency")}
              </Txt>
              {/** frequency */}
              {notification && (
                <View>
                  <BoldTxt
                    style={{
                      marginTop: 25,
                      fontSize: 15,
                      marginBottom: isDesktop ? 15 : 0,
                    }}
                  >
                    {t("setting_screen.frequency")}
                  </BoldTxt>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: isDesktop ? "row" : "column",
                      alignItems: isDesktop ? "center" : "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    {frequences.map((element) => {
                      return (
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          key={element.value}
                        >
                          <View
                            style={{
                              width: 50,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Switch
                              isChecked={element.value == frequencyNotification}
                              onToggle={() => changeFrequenceHandler(element)}
                              size="md"
                              offTrackColor={colors.grayLabel}
                              onTrackColor={colors.green2}
                              onThumbColor={colors.green3}
                              offThumbColor={colors.green0}
                            />
                          </View>
                          <SmallBoldTxt
                            style={{
                              marginRight: isDesktop ? 30 : 0,
                              marginLeft: 10,
                            }}
                          >
                            {element.label}
                          </SmallBoldTxt>
                        </View>
                      );
                    })}
                  </View>
                </View>
              )}
              {/** mode */}
              {/*<View>
                  <BoldTxt
                    style={{
                      marginTop: 25,
                      fontSize: 15,
                      marginBottom: 10,
                    }}
                  >
                    {t("setting_screen.mode")}
                  </BoldTxt>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: isDesktop ? "flex-start" : "space-evenly",
                    }}
                  >
                    {modes.map((mode) => {
                      return (
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          key={mode.value}
                        >
                          <RadioButton
                            color={colors.green2}
                            value={mode.value}
                            status={
                              modeNotification === mode.value
                                ? "checked"
                                : "unchecked"
                            }
                            onPress={() => changeModeHandler(mode)}
                          />
                          <SmallBoldTxt
                            style={{
                              marginRight: isDesktop ? 30 : 0,
                              color:
                                modeNotification === mode.value
                                  ? colors.green2
                                  : colors.blue3,
                            }}
                          >
                            {mode.label}
                          </SmallBoldTxt>
                        </View>
                      );
                    })}
                  </View>
                  </View>*/}
            </View>
          </View>
        </View>

        <GreenModal
          logout={true}
          navigation={navigation}
          isOpen={isOpenGreenModal}
          setIsOpen={setIsOpenGreenModal}
          title={t("modals.title2")}
          text={t("modals.text4")}
        />
        <View
          style={{
            alignSelf: "center",
            marginBottom: isMobile ? 10 : 20,
            marginTop: 40,
          }}
        >
          <Txt style={{ fontSize: 14, color: colors.grayLabel }}>
            LISL UP - Version {version}
          </Txt>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default SettingsScreen;

const styles = StyleSheet.create({
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: colors.grayBorder,
    marginBottom: 20,
  },
});
