import { StyleSheet, View } from "react-native";
import React, { useEffect, useState } from "react";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { colors } from "../../../styles/GlobalStyle";
import DimensionsHook from "../../../hooks/DimensionsHook";
import { DropDown } from "../../Inputs";
import LoaderEffect from "../../LoaderEffect";
import { HStack } from "native-base";
import { useTranslation } from "react-i18next";
import { H6, SmallBoldTxt, SmallLightTxt, Txt } from "../../TextsComponents";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import moment from "moment";
import EpisodesScore from "./EpisodesScore";

const Scores = () => {
  const { t } = useTranslation();
  const { isDesktop } = DimensionsHook();

  const sourceLabels = [
    { label: t("tokens_history.labels.episode"), value: "episode" },
    { label: t("tokens_history.labels.workshop"), value: "workshop" },
    { label: t("tokens_history.labels.trail"), value: "trail" },
    {
      label: t("tokens_history.labels.subscription_cycle"),
      value: "subscription_cycle",
    },
    { label: t("tokens_history.labels.buy_trails"), value: "buy_trails" },
    {
      label: t("tokens_history.labels.buy_trails_with_points"),
      value: "buy_trails_with_points",
    },
    { label: t("tokens_history.labels.buy_book"), value: "buy_book" },
    {
      label: t("tokens_history.labels.buy_book_with_points"),
      value: "buy_book_with_points",
    },
    {
      label: t("tokens_history.labels.buy_flash_coaching"),
      value: "buy_flash_coaching",
    },
    {
      label: t("tokens_history.labels.buy_flash_coaching_with_points"),
      value: "buy_flash_coaching_with_points",
    },
    {
      label: t("tokens_history.labels.gift_subscription"),
      value: "gift_subscription",
    },
    { label: t("tokens_history.labels.parrainage"), value: "parrainage" },
    { label: t("tokens_history.labels.don"), value: "don" },
  ];

  const sourcesOptions = [
    { label: t("tokens_history.options.all"), value: "all" },
    {
      label: t("tokens_history.options.subscription"),
      value: "subscription",
    },
    { label: t("tokens_history.options.don"), value: "don" },
    /* { label: t("tokens_history.options.workshop"), value: "workshop" }, */
    { label: t("tokens_history.options.episode"), value: "episode" },
    { label: t("tokens_history.options.trail"), value: "trail" },
    {
      label: t("tokens_history.options.flash_coaching"),
      value: "flash_coaching",
    },

    { label: t("tokens_history.options.book"), value: "book" },
    { label: t("tokens_history.options.parrainage"), value: "parrainage" },
  ];
  const [showSource, setShowSource] = useState(false);
  const [loader, setLoader] = useState(true);
  const [source, setSource] = useState(sourcesOptions[0]);
  const [data, setData] = useState([]);
  const [earned, setEarned] = useState(0);
  const [spent, setSpent] = useState(0);
  function earnedAndSpentTokens(arr) {
    let earned = 0;
    let spent = 0;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] >= 0) {
        // If element is positive or zero, add to earned
        earned += arr[i];
      } else {
        // If element is negative, substact from spent
        spent -= arr[i];
      }
    }
    return {
      earned: earned,
      spent: spent,
    };
  }

  const getData = async () => {
    const TOKEN = await AsyncStorage.getItem("access_token");
    setLoader(true);
    const response = await axios.get(
      `${process.env.USER_TOKENS_HISTORY}?source=${source.value}`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    );

    setData(response.data);
    if (response.data.length > 0) {
      const values = response.data.map((el) => el.value);
      console.log(values);
      const result = earnedAndSpentTokens(values);
      setEarned(result.earned);
      setSpent(result.spent);
    }
    setTimeout(() => {
      setLoader(false);
    }, 1200);
  };
  useEffect(() => {
    if (source.value != "episode") {
      getData();
    }
  }, [source]);
  if (loader) {
    return (
      <View>
        <HStack alignItems="center" justifyContent="space-between">
          <View style={{ flex: 1, marginRight: 10 }}>
            <H6
              style={{
                fontSize: isDesktop ? 30 : 16,
                lineHeight: isDesktop ? 36 : 22,
              }}
            >
              {t("score_screen.tokens_history")}{" "}
            </H6>
          </View>
          <View style={{ width: isDesktop ? 250 : 180 }}>
            <DropDown
              height={40}
              placeholder=""
              show={showSource}
              setShow={() => setShowSource(!showSource)}
              value={source}
              setValue={setSource}
              options={sourcesOptions}
            />
          </View>
        </HStack>

        <View style={{ zIndex: -1, marginTop: 10 }}>
          {["0", "1", "2", "3", "4", "5"].map((i) => {
            return (
              <View key={i} style={styles.container}>
                <LoaderEffect width="100%" height={45} />
              </View>
            );
          })}
        </View>
      </View>
    );
  }

  if (data.length == 0) {
    return (
      <View>
        <HStack alignItems="center" justifyContent="space-between">
          <View style={{ flex: 1, marginRight: 10 }}>
            <H6
              style={{
                fontSize: isDesktop ? 30 : 16,
                lineHeight: isDesktop ? 36 : 22,
              }}
            >
              {t("score_screen.tokens_history")}{" "}
            </H6>
          </View>
          <View style={{ width: isDesktop ? 250 : 180 }}>
            <DropDown
              height={40}
              placeholder=""
              show={showSource}
              setShow={() => setShowSource(!showSource)}
              value={source}
              setValue={setSource}
              options={sourcesOptions}
            />
          </View>
        </HStack>
        <View style={{ minHeight: 300, zIndex: -1 }}>
          <Txt style={{ textAlign: "center", margin: 15 }} color="#8a8686">
            {t("tokens_history.no_data")}
          </Txt>
        </View>
      </View>
    );
  }

  return (
    <View>
      <HStack alignItems="center" justifyContent="space-between">
        <View style={{ flex: 1, marginRight: 10 }}>
          <H6
            style={{
              fontSize: isDesktop ? 30 : 16,
              lineHeight: isDesktop ? 36 : 22,
            }}
          >
            {t("score_screen.tokens_history")}{" "}
          </H6>
        </View>
        <View style={{ width: isDesktop ? 250 : 180 }}>
          <DropDown
            height={40}
            placeholder=""
            show={showSource}
            setShow={() => setShowSource(!showSource)}
            value={source}
            setValue={setSource}
            options={sourcesOptions}
          />
        </View>
      </HStack>
      {source.value != "episode" && (
        <View style={{ alignSelf: "flex-end", marginTop: 15, zIndex: -2 }}>
          <Txt style={{ textAlign: "right", fontSize: 14 }}>
            {earned} {t("tokens_history.collected")}.
          </Txt>
          <Txt
            style={{
              textAlign: "right",
              fontSize: 14,
              marginTop: isDesktop ? 5 : 0,
            }}
          >
            {spent} {t("tokens_history.spent")}.
          </Txt>
        </View>
      )}
      <View style={{ zIndex: -1, marginTop: 10, minHeight: 300 }}>
        {source.value != "episode" &&
          data.map((doc) => {
            const label = sourceLabels.find(
              (element) => element.value == doc.source
            ).label;
            if (doc.source == "workshop" || doc.source == "episode") {
              return null;
            }
            if (label) {
              return (
                <View
                  key={doc._id}
                  style={[
                    styles.scoreContainer,
                    {
                      backgroundColor: "#FBF6F325",
                      borderBottomWidth: 1,
                      borderBottomColor: colors.grayBorder,
                    },
                  ]}
                >
                  <View>
                    {/** left section */}
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <MaterialCommunityIcons
                          name={
                            doc.value < 0 ? "arrow-down-bold" : "arrow-up-bold"
                          }
                          size={24}
                          color={doc.value < 0 ? colors.red1 : colors.green2}
                        />
                        <SmallBoldTxt
                          color={doc.value < 0 ? colors.red1 : colors.green2}
                        >
                          {doc.value} {t("score_screen.points")}
                        </SmallBoldTxt>
                      </View>
                      <SmallLightTxt style={{ fontSize: 13 }} color="#8a8686">
                        {moment(doc.createdAt).format("DD/MM/YYYY HH:mm")}
                      </SmallLightTxt>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginHorizontal: 10,
                      marginBottom: 10,
                      marginTop: -2,
                    }}
                  >
                    <Txt style={{ textAlign: "center", fontSize: 14 }}>
                      {label}
                    </Txt>
                  </View>
                </View>
              );
            }
            return null;
          })}
        {source.value == "episode" && <EpisodesScore />}
      </View>
    </View>
  );
};

export default Scores;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    marginTop: 15,
    borderRadius: 10,
    overflow: "hidden",
    height: 45,
  },
  scoreContainer: {
    width: "100%",
    marginTop: 5,
    overflow: "hidden",
    minHeight: 45,
    justifyContent: "center",
    padding: 5,
  },
});
