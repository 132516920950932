import {
  FlatList,
  Image,
  Platform,
  Pressable,
  ScrollView,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  H7,
  H6,
  BoldTxt,
  SmallLightTxt,
} from "../../../components/TextsComponents";
import { PrimaryButton } from "../../../components/Buttons";
import DimensionsHook from "../../../hooks/DimensionsHook";
import { colors } from "../../../styles/GlobalStyle";
import { setSavedEpisodes } from "../../../redux/Actions";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
} from "@expo/vector-icons";
import { CustomTooltip } from "../../../components/Tooltips";
import { HStack } from "native-base";
import { useHover } from "react-native-web-hooks";
import { FavoriteIcon } from "../../../assets/svg/Icons";
import { LislUp } from "../../../assets/svg/Logo";
import EpisodeModal from "../../../components/videos/EpisodeModal";

const SavedSwiper = ({ setSelectedEpisode, setShowEpisode }) => {
  const navigation = useNavigation();
  const { t, i18n } = useTranslation();
  const savedEpisodes = useSelector((state) => state.savedEpisodes);
  const dispatch = useDispatch();
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const { isMobile, width, isDesktop } = DimensionsHook();



  const swiperContainerStyle = {
    backgroundColor: colors.white,
    width: "100%",
    alignSelf: "center",
    paddingTop: 15,
    paddingBottom: 5,
    borderRadius: 20,
    marginTop: 20,
    paddingLeft: isMobile ? 10 : 20,
    paddingRight: isMobile ? 0 : 20,
  };

  const removeFromSavedHandler = async (ep) => {
    const TOKEN = await AsyncStorage.getItem("access_token");
    axios
      .put(process.env.USER_SAVED_EPISODES, {
        access_token: TOKEN,
        episode_code: ep,
      })
      .then(() => dispatch(setSavedEpisodes()));
  };

  const getData = async () => {
    if (Data.length === 0) {
      setLoader(true);
    }
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const url = `${process.env.USER_SAVED_EPISODES_DATA}?language=${i18n.language}`;

    const Response = await axios.get(url, config);

    setData(Response.data);

    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [savedEpisodes]);

  if (loader) {
    return <View />;
  }
  if (Data.length === 0) {
    return (
      <View style={swiperContainerStyle}>
        <H6>{t("my_space.title6")}</H6>
        <View
          style={{
            height: width <= 790 ? 200 : 260,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <H7
            style={{
              color: colors.grayLabel,
              textAlign: "center",
              padding: width <= 790 ? 5 : 0,
            }}
          >
            {t("my_space.text3")}
            {"\n"}
            {t("my_space.no_finished2")}
          </H7>
          <PrimaryButton
            onPress={() => navigation.navigate("Home")}
            style={{ marginTop: 20 }}
          >
            {t("my_space.button3")}
          </PrimaryButton>
        </View>
      </View>
    );
  }

  const EpisodeItem = ({ item }) => {
    const playRef = useRef(null);
    const playHover = useHover(playRef);
    const removeRef = useRef(null);
    const removeHover = useHover(removeRef);

    const trailTitle = item.season[0].tail[0].title || null;
    const seasonTitle = item.season[0].title || null;
    const seasonCode = item.season_code || null;
    const SeasonNumber = seasonCode
      ? seasonCode.split("-")[1].replace("S", "")
      : null;
    const EpisodeNumber = seasonCode
      ? item.episode_code.split("-")[3].replace("E", "")
      : null;

    const playHandler = (ep) => {
      setSelectedEpisode(ep);
      setShowEpisode(true);
    };

    return (
      <Pressable
        onPress={() => playHandler(item)}
        style={[styles.container, { width: isDesktop ? 370 : 270 }]}
      >
        <Image
          source={{ uri: item.season[0].poster_link }}
          style={styles.image}
        />

        <LinearGradient
          colors={["#00000060", "#00000090"]}
          style={styles.linear}
        >
          <View
            style={{
              width: 35,
              height: 35,
              position: "absolute",
              top: 10,
              left: 10,
            }}
          >
            <LislUp color={colors.white} />
          </View>
          {trailTitle && (
            <BoldTxt
              numberOfLines={2}
              color={colors.white}
              style={{
                textAlign: "center",
                marginBottom: isDesktop ? 20 : 5,
                fontSize: isDesktop ? 20 : 16,
                color: colors.green1,
              }}
            >
              {trailTitle}
            </BoldTxt>
          )}
          <BoldTxt
            numberOfLines={2}
            color={colors.white}
            style={{ textAlign: "center", marginBottom: 5, fontSize: 16 }}
          >
            {t("trail.season")} {SeasonNumber} - {t("trail.button1")}{" "}
            {EpisodeNumber}
          </BoldTxt>
          <H7
            numberOfLines={2}
            color="white"
            style={{ textAlign: "center", fontSize: 14, marginHorizontal: 8 }}
          >
            {item.title}
          </H7>
          <View style={styles.statusBarContainer} />
          <HStack
            alignItems="center"
            justifyContent="space-between"
            my="2"
            style={{ width: "100%" }}
          >
            {item.duration ? (
              <HStack alignItems="center" justifyContent="space-between">
                <MaterialCommunityIcons
                  style={{ marginRight: 2, marginLeft: 10 }}
                  name="clock-fast"
                  size={18}
                  color={colors.white}
                />
                <SmallLightTxt color={colors.white}>
                  {item.duration} min
                </SmallLightTxt>
              </HStack>
            ) : (
              <View />
            )}
            <HStack alignItems="center" justifyContent="flex-end">
              {/** play */}
              <CustomTooltip
                style={{ marginTop: 5 }}
                bg={colors.blue3}
                color={colors.white}
                label={t("saved.replay")}
              >
                <Pressable
                  ref={playRef}
                  onPress={() => playHandler(item)}
                  style={[
                    styles.playButton,
                    {
                      backgroundColor: playHover ? colors.blue3 : colors.green2,
                    },
                  ]}
                >
                  <MaterialIcons name="replay" size={24} color={colors.white} />
                </Pressable>
              </CustomTooltip>
              {/** remove */}

              <CustomTooltip
                bg={colors.red1}
                style={{ marginTop: 5 }}
                color={colors.white}
                label={t("saved.remove")}
              >
                <TouchableOpacity
                  ref={removeRef}
                  style={[
                    styles.removeButton,
                    {
                      backgroundColor: removeHover ? colors.red0 : colors.red1,
                    },
                  ]}
                  onPress={() => removeFromSavedHandler(item.episode_code)}
                >
                  <FavoriteIcon color={colors.white} />
                </TouchableOpacity>
              </CustomTooltip>
            </HStack>
          </HStack>
        </LinearGradient>
      </Pressable>
    );
  };

  return (
    <View>
      <View style={swiperContainerStyle}>
        <H6>{t("my_space.title6")}</H6>
        <View style={{ marginTop: 15 }}>
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={Platform.OS === "web"}
          >
            {Data.map((item) => {
              return (
                <View key={item._id}>
                  <EpisodeItem item={item} />
                </View>
              );
            })}
          </ScrollView>
        </View>
      </View>

    </View>
  );
};

export default SavedSwiper;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.grayBackground,
    height: 240,
    borderRadius: 20,
    marginBottom: 15,
    marginRight: 15,

    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
    position: "absolute",
  },
  linear: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
    position: "absolute",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  statusBarContainer: {
    width: 240,
    alignSelf: "center",
    height: 3,
    borderRadius: 20,
    marginBottom: 5,
    overflow: "hidden",
    justifyContent: "center",
    backgroundColor: colors.green2,
    marginTop: 10,
  },
  removeButton: {
    width: 40,
    height: 40,
    alignItems: "center",
    justifyContent: "center",

    borderRadius: 20,
    marginRight: 10,
    padding: 10,
  },
  playButton: {
    width: 40,
    height: 40,
    backgroundColor: colors.green2,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 6,
  },
});
