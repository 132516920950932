import { StyleSheet, Platform, Linking, View } from "react-native";
import React, { useEffect, useState } from "react";
import moment from "moment";
import DimensionsHook from "../../../hooks/DimensionsHook";
import {
  BoldTxt,
  LightTxt,
  SmallBoldTxt,
  SmallLightTxt,
  Txt,
} from "../../TextsComponents";
import { SecondaryButton } from "../../Buttons";
import { useTranslation } from "react-i18next";
import { colors } from "../../../styles/GlobalStyle";
import { Check } from "../../../assets/svg/Coaching";

import CancelAppointmentModal from "../../modals/CancelAppointmentModal";

const InvoiceItem = ({ invoice, setData }) => {
  const { isDesktop } = DimensionsHook();
  const { t } = useTranslation();

  const types = [
    { label: "", value: "" },
    { label: t("plans.invoice_lifetime"), value: "Lifetime Plan" },
    { label: t("plans.invoice_trails"), value: "Buy Trails" },
    { label: t("plans.invoice_subscribtion"), value: "Subscription" },
    { label: t("plans.invoice_ebook"), value: "Buy Ebook" },
    { label: t("plans.invoice_coaching"), value: "Buy Flash Coaching" },
    { label: t("plans.invoice_gift"), value: "Gift Plan" },
  ];
  // check if to cancel appointment that time between now and appointment time is less than 48h (if time is set)
  const [moreThanTwoDays, setMoreThanTwoDays] = useState(false)

  const appointmentData =
    invoice.appointment_data.length > 0 ? invoice.appointment_data[0] : null;

  const [type, setType] = useState(types[0]);
  const [isOpen, setIsOpen] = useState(false);
  const downloadInvoiceHandler = (link) => {
    if (Platform.OS == "web") {
      window.open(link, "_blank");
    } else {
      Linking.openURL(link);
    }
  };

  useEffect(() => {
    const t = types.find((type) => type.value == invoice.source);
    setType(t);
  }, []);

  return (
    <View
      style={[
        styles.invoiceContainer,
        {
          marginHorizontal: isDesktop ? 40 : 10,
          backgroundColor: isDesktop ? colors.beige : colors.white,
          flexDirection: isDesktop ? "row" : "column",
          alignItems: isDesktop ? "flex-start" : "flex-end",
          justifyContent: isDesktop ? "space-between" : "space-between",
        },
      ]}
      key={invoice.id}
    >
      <View style={{ marginTop: 10, alignSelf: "flex-start" }}>
        <BoldTxt style={{ fontSize: 14 }}>{type.label}</BoldTxt>
        {invoice.period_start && invoice.total > 0 && (
          <SmallLightTxt style={{ fontSize: 13 }}>
            {t("plans.period")} |{" "}
            {moment.unix(invoice.period_start).format("DD/MM/YYYY")}-{" "}
            {moment.unix(invoice.period_end).format("DD/MM/YYYY")}
          </SmallLightTxt>
        )}
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}
        >
          <View style={{ width: 16, height: 16, marginRight: 3 }}>
            <Check />
          </View>
          <SmallBoldTxt color={colors.green2}>
            {t("plans.pay_at")}{" "}
            {moment(invoice.createdAt).format("DD/MM/YYYY - HH:mm")}{" "}
          </SmallBoldTxt>
        </View>
        <SmallBoldTxt
          style={{ marginTop: 10, fontSize: 13 }}
          color={colors.grayLabel}
        >
          {t("plans.sub_total")} | {(invoice.subtotal / 100).toFixed(2)}
          {t("plans.eur")}
        </SmallBoldTxt>
        <SmallBoldTxt color={colors.grayLabel} style={{ fontSize: 13 }}>
          {t("plans.tax")} | {(invoice.tax / 100).toFixed(2)}
          {t("plans.eur")}
        </SmallBoldTxt>
        {invoice.discount > 0 && (
          <SmallBoldTxt color={colors.grayLabel} style={{ fontSize: 13 }}>
            {t("plans.discount")} | {(invoice.discount / 100).toFixed(2)}
            {t("plans.eur")}
          </SmallBoldTxt>
        )}
        <SmallBoldTxt
          style={{ marginVertical: 10, fontSize: 14 }}
          color={colors.blue3}
        >
          {t("plans.total")} | {(invoice.total / 100).toFixed(2)}
          {t("plans.eur")}
        </SmallBoldTxt>
      </View>
      <View>
        {appointmentData && appointmentData.companyPayment && (
          <SecondaryButton
            disabled={true}
            style={{ marginTop: 10, alignSelf: "flex-end" }}
            width={220}
            onPress={() => downloadInvoiceHandler(invoice.invoice_link)}
          >
            {t("books.free")}
          </SecondaryButton>
        )}
        {invoice.invoice_link && (
          <SecondaryButton
            style={{ marginTop: 10, alignSelf: "flex-end" }}
            width={220}
            onPress={() => downloadInvoiceHandler(invoice.invoice_link)}
          >
            {t("plans.download_invoice")}
          </SecondaryButton>
        )}
        {appointmentData && (
          <View style={{ marginTop: 10 }}>
            {/** case canceled by user => show if remboursed or not */}
            {appointmentData.status == 5 ? (
              <Txt
                style={{
                  alignSelf: "flex-end",
                  fontSize: 12,
                  color: "white",
                  backgroundColor: colors.red1,
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderRadius: 1000,
                }}
              >
                {t("appointment.status_canceled")}{" "}
                {!appointmentData.remboursable ? "" : appointmentData.remboursed
                  ? "(Remboursé)"
                  : "(en attente de rembousement)"}
              </Txt>
            ) : appointmentData.status == 3 ? (
              <Txt
                style={{
                  alignSelf: "flex-end",
                  fontSize: 13,
                  color: "white",
                  backgroundColor: colors.green2,
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderRadius: 1000,
                }}
              >
                {t("appointment.status_finished")}
              </Txt>
            ) : appointmentData.status == 6 ? (
              <Txt
                style={{
                  alignSelf: "flex-end",
                  fontSize: 13,
                  color: "white",
                  backgroundColor: colors.red1,
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderRadius: 1000,
                }}
              >
                {t("appointment.status_canceled")}
              </Txt>
            ) : (
              /** button to cancel appointment */
              <SecondaryButton
                onPress={() => {

                  const time = appointmentData.time ? appointmentData.time : null
                  if (time) {
                    const now = new Date().toUTCString()
                    const nowDate = moment.utc(now)

                    var duration = moment(time).diff(nowDate, 'hours')

                    if (duration < 48) {
                      setMoreThanTwoDays(false)
                      setIsOpen(true)
                    } else {
                      setMoreThanTwoDays(true)
                      setIsOpen(true)
                    }
                  } else {
                    setMoreThanTwoDays(true)
                    setIsOpen(true)
                  }
                }}
                disabled={appointmentData.isGift == "true"}
                dangerButton={true}
                width={220}
              >
                {t("appointment.cancel")}
              </SecondaryButton>
            )}
          </View>
        )}
      </View>
      {appointmentData && (
        <CancelAppointmentModal
          moreThanTwoDays={moreThanTwoDays}
          setData={setData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          appointment={appointmentData}
        />
      )}
    </View>
  );
};

export default InvoiceItem;

const styles = StyleSheet.create({
  invoiceContainer: { marginTop: 15, padding: 15, borderRadius: 15 },
});
