import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-svg";
import { BarChart, Grid, ProgressCircle } from "react-native-svg-charts";
import { colors } from "../../styles/GlobalStyle";
import { useTranslation } from "react-i18next";
import { BoldTxt, H6, Txt } from "../../components/TextsComponents";
import { VStack, HStack } from "native-base";
import DimensionsHook from "../../hooks/DimensionsHook";
import Cards from "./Cards";
import {
    VictoryArea,
    VictoryChart,
    VictoryPolarAxis,
    VictoryTheme,
} from "victory-native";

const Charts = ({ bilanData, analysisData, companyQuestions, setRedFlag }) => {
    const { t, i18n } = useTranslation();
    const { width } = DimensionsHook();
    const [maxScoreCompany, setMaxScoreCompany] = useState(0);

    const section5Percent =
        bilanData && bilanData.section5 && bilanData.section5.percent
            ? bilanData.section5.percent
            : 0;
    const section6Percent =
        bilanData && bilanData.section6 && bilanData.section6.percent
            ? bilanData.section6.percent
            : 0;

    const socialData = [section5Percent, section6Percent];

    const emotionalData = [
        {
            x: ` ${t("bilan.title2")}\n\n\n`,
            y:
                bilanData && bilanData.section1 && bilanData.section1.percent
                    ? Number(bilanData.section1.percent)
                    : 1,
        },
        {
            x: t("bilan.title3"),
            y:
                bilanData && bilanData.section1 && bilanData.section1.percent
                    ? Number(bilanData.section2.percent)
                    : 1,
        },
        {
            x: `${t("bilan.title4")}\n\n\n`,
            y:
                bilanData && bilanData.section1 && bilanData.section1.percent
                    ? Number(bilanData.section3.percent)
                    : 1,
        },
        {
            x: t("bilan.title5"),
            y:
                bilanData && bilanData.section1 && bilanData.section1.percent
                    ? Number(bilanData.section4.percent)
                    : 1,
        },
    ];

    const [workData, setWorkData] = useState([
        {
            x: `${t("bilan.title14")}\n`,
            y:
                bilanData && bilanData.section10 && bilanData.section10.percent
                    ? Number(bilanData.section11.percent)
                    : 0,
        },
        {
            x: `${t("bilan.title15")}`,
            y:
                bilanData && bilanData.section10 && bilanData.section10.percent
                    ? Number(bilanData.section12.percent)
                    : 0,
        },
        {
            x: `${t("bilan.title16")}  \n`,
            y:
                bilanData && bilanData.section10 && bilanData.section10.percent
                    ? Number(bilanData.section13.percent)
                    : 0,
        },
        {
            x: i18n.language == "fr" ? `Gestion\ndu stress` : "Stress management",
            y:
                bilanData && bilanData.section10 && bilanData.section10.percent
                    ? Number(bilanData.section14.percent)
                    : 0,
        },
        {
            x: i18n.language == "fr" ? `Autonomie\ndans le travail` : `Autonomy\nin work`,
            y:
                bilanData && bilanData.section10 && bilanData.section10.percent
                    ? Number(bilanData.section10.percent)
                    : 0,
        },


    ]
    )
    // Calculate the sum of max values in each "answers" array
    const sumOfMaxValues = (a) =>
        a.reduce((sum, question) => {
            // Extract the "answers" array from the question object
            const answers = question.answers;

            // Find the maximum value in the "answers" array
            const maxAnswerValue = Math.max(
                ...answers
                    .filter((answer) => answer.value !== undefined)
                    .map((answer) => answer.value)
            );

            // Add the maximum value to the sum (if there's at least one valid "value" key)
            return maxAnswerValue !== -Infinity ? sum + maxAnswerValue : sum;
        }, 0);

    const CUT_OFF = 20;
    const Labels = ({ x, y, bandwidth, data }) =>
        data.map((value, index) => (
            <Text
                key={index}
                x={x(index) + bandwidth / 2}
                y={value < CUT_OFF ? y(value) - 10 : y(value) + 25}
                fontSize={12}
                fill={value >= CUT_OFF ? "white" : colors.blue3}
                alignmentBaseline={"middle"}
                fontFamily="Gotu"
                textAnchor={"middle"}
            >
                {value}
            </Text>
        ));

    function sumOfNumbers(arr) {
        return arr.reduce((sum, element) => {
            // Convert the element to a number (ignoring non-numeric values)
            const numericValue = parseFloat(element);

            // Check if the conversion is successful and the value is not NaN
            if (!isNaN(numericValue)) {
                // Add the numeric value to the sum
                return sum + numericValue;
            }

            // If the element is not numeric, just return the current sum
            return sum;
        }, 0);
    }

    useEffect(() => {
        if (companyQuestions.length > 0) {
            const maxScoreOfCompanyQuestions = Number(
                sumOfMaxValues(companyQuestions.filter((el) => el.hidden == false))
            );
            setMaxScoreCompany(maxScoreOfCompanyQuestions);
            const arr = workData
            arr.unshift({
                x: `${t("bilan.title18")}\n`,
                y:
                    bilanData && bilanData.sectionCompany
                        ?
                        Number(
                            (
                                bilanData.sectionCompany.percent
                            ).toFixed(0)

                        )
                        : 0,
            });
            setWorkData(arr)
        }
    }, [companyQuestions]);

    const renderAnalysisText = (val, percent) => {
        const obj = analysisData.find((el) => el.value === val);
        const levelObj =
            percent < 34 ? obj.level1 : percent < 67 ? obj.level2 : obj.level3;
        if (i18n.language == "fr") {
            return `${levelObj.text_fr}`;
        } else {
            return `${levelObj.text_en}`;
        }
    };

    const renderConstantText = (c) => {
        const obj = analysisData.find((el) => el.value === c.value);
        if (obj.text_fr) {
            if (i18n.language == "fr") {
                return (
                    <Txt
                        style={{
                            fontSize: 15,
                            color: "#484848",
                            textAlign: "justify",
                            marginBottom: 5,
                        }}
                    >
                        {obj.text_fr}
                    </Txt>
                );
            } else {
                return (
                    <Txt
                        style={{
                            fontSize: 15,
                            color: "#484848",
                            textAlign: "justify",
                            marginBottom: 5,
                        }}
                    >
                        {obj.text_en}
                    </Txt>
                );
            }
        } else {
            return "";
        }
    };

    const generalData = analysisData.find((el) => el.value == -1);

    return (
        <VStack w="full" alignSelf="center" mb="8">
            <HStack
                bg="#ffffff80"
                style={{
                    width: width <= 900 ? "100%" : "100%",
                    borderRadius: 15,
                    paddingTop: 15,
                    shadowColor: "#00000010",
                    shadowOffset: { width: 2, height: 2 },
                }}
                alignSelf="center"
                alignItems="flex-start"
                justifyContent={width >= 1090 ? "space-evenly" : "flex-start"}
                flexWrap="wrap"
                mt="2"
                mb="3"
            >
                {/** chart 1 */}
                <View
                    style={{
                        width: width < 600 ? "100%" : width < 1090 ? "48%" : 300,
                        marginRight: width < 600 ? 0 : width < 1090 ? "1%" : 10,
                        paddingVertical: 6,
                    }}
                >
                    <View
                        style={{
                            width: "100%",

                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <H6 style={{ fontSize: 16, textAlign: "center" }}>
                            {t("bilan.title1")}
                        </H6>
                        <VStack style={{ marginTop: -10 }}>
                            <VictoryChart theme={VictoryTheme.material} polar>
                                {emotionalData.map((d, i) => {
                                    return (
                                        <VictoryPolarAxis
                                            dependentAxis
                                            key={i}
                                            label={`${d.x}`}
                                            labelDy={50}
                                            labelOrientation="vertical"
                                            theme={VictoryTheme.material}
                                            labelPlacement="vertical"
                                            style={{
                                                axisLabel: {
                                                    fill: "#484848",
                                                    fontFamily: "OxygenBold",
                                                    fontSize: 11,
                                                },
                                                tickLabels: {
                                                    fill: "#484848",
                                                    fontFamily: "OxygenBold",
                                                    fontSize: 10,
                                                    opacity: 0,
                                                },
                                                axis: { stroke: "#48484830", strokeWidth: 0.7 },
                                                grid: {
                                                    stroke:
                                                        i == emotionalData.length - 1
                                                            ? "#48484810"
                                                            : "#48484830", //CHANGE COLOR OF X-AXIS GRID LINES
                                                    strokeDasharray: 4,
                                                    strokeWidth: 0.5,
                                                },
                                            }}
                                            axisValue={d.x}
                                            domain={[0, 100]} // Set the domain to be 0-100
                                            tickValues={[33, 66, 100]} // Specify custom tick values
                                            tickFormat={(t) => (i % 2 != 0 ? t : "")} // Show label only for 100
                                        />
                                    );
                                })}

                                <VictoryArea
                                    style={{
                                        data: {
                                            fill: "#1b496550",
                                            stroke: "#1b4965",
                                            strokeWidth: 2,
                                        },
                                    }}
                                    animate={{
                                        onExit: {
                                            duration: 1000,
                                            before: () => ({ _y: 0 }),
                                        },
                                    }}
                                    data={emotionalData}
                                />
                            </VictoryChart>
                        </VStack>
                    </View>
                </View>

                {/** chart 2 */}
                <View
                    style={{
                        borderRadius: 16,
                        width: width < 600 ? "100%" : width < 1090 ? "48%" : 300,
                        marginRight: width < 600 ? 0 : width < 1090 ? "1%" : 10,
                        paddingVertical: 6,
                        marginTop: width < 1090 ? 10 : 0,


                    }}
                >
                    <View
                        style={{
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <H6 style={{ fontSize: 16, textAlign: "center" }}>
                            {t("bilan.title12")}
                        </H6>
                        <VStack style={{ marginTop: -10 }}>
                            <VictoryChart style={{ overflow: 'visible' }} theme={VictoryTheme.material} polar>
                                {workData.map((d, i) => {
                                    return (
                                        <VictoryPolarAxis
                                            dependentAxis
                                            key={i}
                                            label={`${d.x}`}

                                            labelOrientation="vertical"
                                            theme={VictoryTheme.material}
                                            labelPlacement="vertical"
                                            style={{
                                                axisLabel: {
                                                    fill: "#484848",
                                                    fontFamily: "OxygenBold",
                                                    fontSize: 11,
                                                },
                                                tickLabels: {
                                                    fill: "#484848",
                                                    fontFamily: "OxygenBold",
                                                    fontSize: 10,
                                                    opacity: 0,
                                                },
                                                axis: { stroke: "#48484830", strokeWidth: 0.7 },
                                                grid: {
                                                    stroke:
                                                        i == workData.length - 1
                                                            ? "#48484810"
                                                            : "#48484830", //CHANGE COLOR OF X-AXIS GRID LINES
                                                    strokeDasharray: 4,
                                                    strokeWidth: 0.5,
                                                },
                                            }}
                                            axisValue={d.x}
                                            domain={[0, 100]} // Set the domain to be 0-100
                                            tickValues={[33, 66, 100]} // Specify custom tick values
                                            tickFormat={(t) => (i % 2 != 0 ? t : "")} // Show label only for 100
                                        />
                                    );
                                })}

                                <VictoryArea
                                    style={{
                                        data: {
                                            fill: "#1b496550",
                                            stroke: "#1b4965",
                                            strokeWidth: 2,
                                        },
                                    }}
                                    animate={{
                                        onExit: {
                                            duration: 1000,
                                            before: () => ({ _y: 0 }),
                                        },
                                    }}
                                    data={workData}
                                />
                            </VictoryChart>
                        </VStack>
                    </View>
                </View>
                {/** chart 3 */}
                <View
                    style={{
                        borderRadius: 16,
                        width: width < 600 ? "100%" : width < 1090 ? "48%" : 300,
                        marginRight: width < 600 ? 0 : width < 1090 ? "1%" : 10,
                        paddingVertical: 6,
                        marginTop: width < 600 ? 10 : 0,
                    }}
                >
                    <View
                        style={{
                            width: "95%",
                            alignSelf: "center",
                            aspectRatio: 1 / 1,
                        }}
                    >
                        <VStack style={{ marginBottom: 20 }}>
                            <H6 style={{ fontSize: 16, textAlign: "center" }}>
                                {t("bilan.title6")}
                            </H6>
                        </VStack>
                        <BarChart
                            style={{ flex: 1 }}
                            data={socialData}
                            svg={{ fill: colors.blue3 }}
                            contentInset={{ top: 10, bottom: 10 }}
                            yMin={0}
                            spacingInner={0.7}
                            spacingOuter={0.3}
                            yMax={100}
                            gridMin={0}
                            gridMax={100}
                        >
                            <Grid direction={Grid.Direction.HORIZONTAL} />
                            <Labels />
                        </BarChart>
                    </View>
                    <HStack
                        style={{ width: "95%", alignSelf: "center" }}
                        justifyContent="space-around"
                    >
                        <VStack w="1/2">
                            <BoldTxt
                                style={{
                                    fontSize: 11,
                                    color: "#484848",
                                    textAlign: "center",
                                    marginLeft: -15,
                                }}
                            >
                                {t("bilan.title7")}
                            </BoldTxt>
                        </VStack>
                        <VStack w="1/2">
                            <BoldTxt
                                style={{
                                    fontSize: 11,
                                    color: "#484848",
                                    textAlign: "center",
                                    marginLeft: 10,
                                }}
                            >
                                {t("bilan.title8")}
                            </BoldTxt>
                        </VStack>
                    </HStack>
                </View>
            </HStack>
            {/** general score */}
            {bilanData && bilanData.mean != undefined && (
                <VStack w="full">
                    <VStack
                        bg="#ffffff80"
                        overflow="hidden"
                        mb="3"
                        w="full"
                        style={{
                            borderRadius: 15,
                            shadowColor: "#00000010",
                            shadowOffset: { width: 2, height: 2 },
                        }}
                        py="3"
                        px="5"
                    >
                        <VStack
                            style={{
                                height: "100%",
                                aspectRatio: 1 / 1,
                                borderRadius: 1000,
                                position: "absolute",
                                backgroundColor:
                                    bilanData.mean < 34
                                        ? colors.red1
                                        : bilanData.mean < 67
                                            ? colors.yellow
                                            : colors.green2,
                                top: "50%",
                                left: "75%",
                                opacity: 0.2,
                            }}
                        ></VStack>
                        <VStack style={{ borderRadius: 10 }}>
                            <HStack
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                space="2"
                                mt="3"
                            >
                                <VStack style={{ width: 70, height: 70 }}>
                                    <VStack>
                                        <ProgressCircle
                                            style={{ height: 70 }}
                                            progress={bilanData.mean / 100}
                                            progressColor={
                                                bilanData.mean < 34
                                                    ? colors.red1
                                                    : bilanData.mean < 67
                                                        ? colors.yellow
                                                        : colors.green2
                                            }
                                            startAngle={-Math.PI * 0.8}
                                            endAngle={Math.PI * 0.8}
                                            strokeWidth={8}
                                        />
                                    </VStack>
                                    <VStack
                                        style={{
                                            width: 70,
                                            height: 70,
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                        position="absolute"
                                    >
                                        <BoldTxt
                                            style={{
                                                fontSize: 16,
                                                color:
                                                    bilanData.mean < 34
                                                        ? colors.red1
                                                        : bilanData.mean < 67
                                                            ? colors.yellow
                                                            : colors.green2,
                                            }}
                                        >
                                            {bilanData.mean}
                                        </BoldTxt>
                                    </VStack>
                                </VStack>

                                <VStack flex={1} ml="1">
                                    <VStack>
                                        <VStack>
                                            <H6 style={{ fontSize: 18 }}>
                                                {i18n.language == "fr"
                                                    ? generalData.name_fr
                                                    : generalData.name_en}
                                            </H6>
                                        </VStack>
                                        <VStack mt="2">
                                            {renderConstantText(generalData)}
                                            <Txt
                                                style={{
                                                    fontSize: 15,
                                                    color: "#484848",
                                                    textAlign: "justify",
                                                }}
                                            >
                                                {renderAnalysisText(-1, bilanData.mean)}
                                            </Txt>
                                        </VStack>
                                    </VStack>
                                </VStack>
                            </HStack>
                        </VStack>
                    </VStack>
                </VStack>
            )}
            <VStack>
                <Cards setRedFlag={setRedFlag} bilanData={bilanData} analysisData={analysisData} />
            </VStack>
        </VStack>
    );
};

export default Charts;
