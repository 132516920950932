import { StyleSheet, View, Image, TouchableOpacity } from "react-native";
import React, { useEffect, useState } from "react";
import DimensionsHook from "../../hooks/DimensionsHook";
import { SmallLightTxt, Txt, H1, SmallBoldTxt } from "../TextsComponents";
import { colors } from "../../styles/GlobalStyle";
import { GeneralBadge } from "../../assets/svg/Icons";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { DropDown, Input, NumericInput } from "../Inputs";
import { Ionicons } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import Spinner from "../Spinner";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useToast } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import { Avatar } from "../../assets/svg/Icons";
import * as ImagePicker from "expo-image-picker";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setUserData } from "../../redux/Actions";
import { Divider } from "react-native-elements";
import { useTranslation } from "react-i18next";

const GeneralScreen = ({ navigation, scrollToTop }) => {
  const { t, i18n } = useTranslation();
  const genderOptions = [
    { value: "", label: t("gender.placeholder") },
    { value: "H", label: t("gender.male") },
    { value: "F", label: t("gender.female") },
    { value: "NP", label: t("gender.np") },
  ];
  const ageOptions = [
    { value: "", label: t("age.placeholder") },
    { value: "NP", label: t("gender.np") },
    { value: "18-25", label: t("age.option1") },
    { value: "26-35", label: t("age.option2") },
    { value: "36-45", label: t("age.option3") },
    { value: "46-55", label: t("age.option4") },
    { value: ">56", label: t("age.option5") },
  ];

  const Languages = [
    { label: t("placeholders.language"), value: "" },
    { label: t("language.fr"), value: "fr" },
    { label: t("language.en"), value: "en" },
  ];

  const userData = useSelector((state) => state.userData);
  const userAge = ageOptions.find((age) => age.value == userData.age);
  const userLanguage = Languages.find((lng) => lng.value == userData.language);
  const userGender = genderOptions.find(
    (gender) => gender.value == userData.genre
  );
  const dispatch = useDispatch();
  const toast = useToast();

  const { isDesktop, width } = DimensionsHook();
  const [uploadLoader, setUploadLoader] = useState(false);
  const [firstname, setFirstname] = useState(userData.firstname);
  const [lastname, setLastname] = useState(userData.lastname);
  const [pseudo, setPseudo] = useState(userData.pseudo);
  const [address, setAddress] = useState(userData.address);
  const [city, setCity] = useState(userData.city);
  const [gender, setGender] = useState(userGender);
  const [age, setAge] = useState(userAge);
  const [language, setLanguage] = useState(userLanguage);
  const [country, setCountry] = useState(userData.country);
  const [showGender, setShowGender] = useState(false);
  const [showAge, setShowAge] = useState(false);
  const [showLanguage, setShowLanguage] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [zipCode, setZipCode] = useState(userData.zip_code);
  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      dispatch(setUserData());
    });

    return unsubscribe;
  }, [navigation]);

  const resetHandler = async () => {
    setErrorMessage("");
    const TOKEN = await AsyncStorage.getItem("access_token");
    if (!uploadLoader) {
      axios
        .post(process.env.USER_RESET_AVATAR, {
          access_token: TOKEN,
        })
        .then(async (res) => {
          dispatch(setUserData());
          setUploadLoader(false);
        })
        .catch((err) => {
          setUploadLoader(false);
        });
    }
  };

  const pickImageHandler = async () => {
    if (!uploadLoader) {
      setErrorMessage("");
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        base64: true,
        aspect: [1, 1],
        quality: 1,
      });
      // IF CHANGE PHOTO UPLOAD IT AND SAVE IT TO THE USER INFO
      if (!result.cancelled) {
        const size = result.base64.length * (3 / 4) - 2;
        if (size > 810000) {
          setErrorMessage(t("general_screen.error_image_size"));
        } else {
          setUploadLoader(true);
          const TOKEN = await AsyncStorage.getItem("access_token");

          axios
            .post(process.env.USER_UPLOAD_AVATAR, {
              access_token: TOKEN,
              image: result.base64,
            })
            .then(async (res) => {
              await dispatch(setUserData());
              setUploadLoader(false);
            })
            .catch((err) => {
              setUploadLoader(false);
            });
        }
      }
    }
  };

  // UPDATE USER INFO HANDLER
  const saveHandler = async () => {
    if (!submitLoader) {
      setSubmitLoader(true);
      setErrorMessage("");
      if (
        lastname.trim() == "" ||
        firstname.trim() == "" ||
        pseudo.trim() == ""
      ) {
        setSubmitLoader(false);
        setErrorMessage(t("general_screen.error_data"));
        scrollToTop();
      } else {
        const TOKEN = await AsyncStorage.getItem("access_token");
        axios
          .put(process.env.USER_UPDATE, {
            addToUpdates: true,
            access_token: TOKEN,
            firstname,
            lastname,
            pseudo,
            language: language.value,
            genre: gender.value,
            age: age.value,
            address,
            zip_code: zipCode,
            country,
            city,
          })
          .then((res) => {
            dispatch(setUserData());
            i18n.changeLanguage(language.value);
            AsyncStorage.setItem("lng", language.value);
            setTimeout(() => {
              setSubmitLoader(false);
              scrollToTop();
              toast.show({
                placement: "top",
                render: () => {
                  return (
                    <View
                      style={[
                        styles.toastContainer,
                        { marginTop: isDesktop ? 30 : 20 },
                      ]}
                    >
                      <Ionicons
                        name="checkmark-circle"
                        size={24}
                        color={colors.white}
                        style={{ marginRight: 5 }}
                      />
                      <Txt color={colors.white}>
                        {t("general_screen.success_update")}
                      </Txt>
                      <TouchableOpacity
                        style={{ marginLeft: 20 }}
                        onPress={() => toast.closeAll()}
                      >
                        <AntDesign
                          name="close"
                          size={24}
                          color={colors.white}
                        />
                      </TouchableOpacity>
                    </View>
                  );
                },
              });
            }, 1000);
          })
          .catch((err) => {
            //console.log(err);
            console.log(err);
            setSubmitLoader(false);
          });
      }
    }
  };

  return (
    <View style={{ marginTop: isDesktop ? 0 : 35 }}>
      <H1>{t("account_navigation.general")}</H1>

      {/** profile image with badge + upload buttons */}
      <View style={styles.profileImageContainer}>
        <View
          style={[
            styles.avatar,
            { padding: userData.image ? 0 : uploadLoader ? 0 : 15 },
          ]}
        >
          {uploadLoader ? (
            <Spinner color={colors.blue3} size="small" />
          ) : userData.image ? (
            <Image
              source={{
                uri: process.env.BASE_URL + "/" + userData.image,
              }}
              style={styles.avatar}
            />
          ) : (
            <Avatar color={colors.blue3} />
          )}
          <View style={styles.imageBadge}>
            <GeneralBadge />
          </View>
        </View>
        <View>
          <View
            style={{
              flexDirection: width > 400 ? "row" : "column",
              alignItems: width > 400 ? "center" : "flex-start",
              marginLeft: 5,
            }}
          >
            {/** modif button */}
            <PrimaryButton
              disabled={uploadLoader}
              onPress={pickImageHandler}
              style={{ height: 28, paddingHorizontal: 15, marginLeft: 10 }}
            >
              {t("general_screen.img_button1")}
            </PrimaryButton>
            {/** reset button */}
            <SecondaryButton
              disabled={!userData.image ? true : false}
              onPress={resetHandler}
              style={{
                height: 28,
                paddingHorizontal: 15,
                marginLeft: 10,
                marginTop: width > 400 ? 0 : 10,
              }}
            >
              {t("general_screen.img_button2")}
            </SecondaryButton>
          </View>
          <View style={{ marginTop: 10 }}>
            <SmallLightTxt color={colors.grayLabel} style={{ marginLeft: 15 }}>
              {t("general_screen.img_text1")}
            </SmallLightTxt>
            <SmallLightTxt color={colors.grayLabel} style={{ marginLeft: 15 }}>
              {t("general_screen.img_text2")}
            </SmallLightTxt>
          </View>
        </View>
      </View>

      <SmallLightTxt
        color={colors.blue3}
        style={{ marginVertical: 10, textAlign: "justify", marginRight: 10 }}
      >
        {t("general_screen.img_text3")}
      </SmallLightTxt>
      <Divider />
      <SmallBoldTxt
        style={{
          color: colors.red1,
          textAlign: "center",

          marginTop: 20,
        }}
      >
        {errorMessage.length > 0 ? errorMessage : " "}
      </SmallBoldTxt>
      {/** formulaire */}
      <View style={styles.formContainer}>
        {/** lastname */}
        <View style={{ width: isDesktop ? "49%" : "100%", marginTop: 15 }}>
          <Input
            placeholder=""
            value={lastname}
            onChangeText={(val) => setLastname(val)}
            smallLabel={t("placeholders.name")}
          />
        </View>

        {/** firstname */}
        <View style={{ width: isDesktop ? "49%" : "100%", marginTop: 15 }}>
          <Input
            placeholder=""
            value={firstname}
            onChangeText={(val) => setFirstname(val)}
            smallLabel={t("placeholders.surname")}
          />
        </View>

        {/** gender */}
        <View
          style={{
            width: isDesktop ? "49%" : "100%",
            marginTop: 15,
            zIndex: 20,
          }}
        >
          <DropDown
            height={64}
            placeholder=""
            show={showGender}
            setShow={() => setShowGender(!showGender)}
            value={gender}
            disableFirstOption={true}
            setValue={setGender}
            options={genderOptions}
            smallLabel={t("placeholders.gender")}
          />
        </View>
        {/** age */}
        <View
          style={{
            width: isDesktop ? "49%" : "100%",
            marginTop: 15,
            zIndex: 18,
          }}
        >
          <DropDown
            height={64}
            placeholder=""
            show={showAge}
            setShow={() => setShowAge(!showAge)}
            value={age}
            disableFirstOption={true}
            setValue={setAge}
            options={ageOptions}
            smallLabel={t("placeholders.age")}
          />
        </View>
        {/** pseudo */}
        <View style={{ width: isDesktop ? "49%" : "100%", marginTop: 15 }}>
          <Input
            placeholder=""
            value={pseudo}
            onChangeText={(val) => setPseudo(val)}
            smallLabel="Pseudo"
          />
        </View>
        {/** language */}
        <View
          style={{
            width: isDesktop ? "49%" : "100%",
            marginTop: 22,
            zIndex: 16,
          }}
        >
          <DropDown
            height={!isDesktop ? 64 : 66}
            placeholder=""
            show={showLanguage}
            setShow={() => setShowLanguage(!showLanguage)}
            value={language}
            disableFirstOption={true}
            setValue={setLanguage}
            options={Languages}
            smallLabel={t("placeholders.language")}
          />
        </View>
        {/** address */}
        <View style={{ width: isDesktop ? "49%" : "100%", marginTop: 15 }}>
          <Input
            placeholder=""
            value={address}
            onChangeText={(val) => setAddress(val)}
            smallLabel={t("placeholders.address")}
          />
        </View>
        <View style={{ width: isDesktop ? "49%" : "100%", marginTop: 22 }}>
          <NumericInput
            maxLength={6}
            placeholder=""
            value={zipCode}
            setValue={setZipCode}
            style={{ height: 65 }}
            smallLabel={t("placeholders.zip_code")}
          />
        </View>
        {/** city */}
        <View style={{ width: isDesktop ? "49%" : "100%", marginTop: 15 }}>
          <Input
            placeholder=""
            value={city}
            onChangeText={(val) => setCity(val)}
            smallLabel={t("placeholders.city")}
          />
        </View>
        {/** country */}
        <View
          style={{
            width: isDesktop ? "49%" : "100%",
            marginTop: 15,
          }}
        >
          <Input
            placeholder=""
            value={country}
            onChangeText={(val) => setCountry(val)}
            smallLabel={t("placeholders.country")}
          />
        </View>
      </View>
      {/** ↑↑ end form */}
      <View style={{ zIndex: -1 }}>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            marginTop: 40,
            justifyContent: isDesktop ? "flex-end" : "center",
          }}
        >
          <PrimaryButton
            onPress={saveHandler}
            style={{ width: !isDesktop ? "100%" : 250, height: 50 }}
          >
            {submitLoader ? (
              <Spinner color={colors.white} />
            ) : (
              t("general_screen.submit")
            )}
          </PrimaryButton>
        </View>
        <View style={{ height: 70 }} />
      </View>
    </View>
  );
};

export default GeneralScreen;

const styles = StyleSheet.create({
  avatar: {
    width: 90,
    height: 90,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 45,
    backgroundColor: colors.grayBackground,
  },
  profileImageContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 10,
  },
  imageBadge: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: colors.beige,
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    right: -10,
    top: 0,
  },

  formContainer: {
    flexDirection: "row",

    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: colors.grayBorder,
    marginVertical: 20,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  toastContainer: {
    backgroundColor: colors.green2,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 20,
  },
});
