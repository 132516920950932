import { StyleSheet, View, Platform } from "react-native";
import React from "react";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { LinearGradient } from "expo-linear-gradient";

import { LislUp } from "../../assets/svg/Logo";
import WebView from "react-native-webview";
import OutsidePressHandler from "react-native-outside-press";
import BackHeader from "../BackHeader";
import { useNavigation } from "@react-navigation/native";
import { H6, Txt } from "../TextsComponents";
import { HStack, Pressable } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import { useTranslation } from "react-i18next";

const EpisodeModal = ({ isOpen, setIsOpen, episode, setEpisode }) => {
  const { isBigScreen, isDesktop, isTablet } = DimensionsHook();
  const { t } = useTranslation()
  const url = episode.link.replace("playlist.m3u8", "");

  const navigation = useNavigation();

  const closeHandler = () => {
    setIsOpen(false);
    setEpisode(null);
  };
  if (!isOpen) {
    return null;
  }

  return (
    <View
      style={{
        width: "100%",
        alignSelf: "center",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      {Platform.OS == "web" ? (
        <OutsidePressHandler
          style={{ alignItems: "center", justifyContent: "center", flex: 1 }}
          onOutsidePress={closeHandler}
        >

          <View
            style={{
              aspectRatio: 16 / 9,
              width: isBigScreen
                ? 1000
                : isDesktop
                  ? 700
                  : isTablet
                    ? "90%"
                    : "100%",
            }}
          >
            <video
              autoPlay={true}
              src={url + "play_480p.mp4"}
              poster={episode.season[0].poster_link}
              preload="true"
              onEnded={closeHandler}
              crossOrigin="anonymous"
              controls
              controlsList="nodownload noremoteplayback"
              style={{
                width: "100%",
                aspectRatio: 16 / 9,
                backgroundColor: "#000000",
              }}
            >
              {episode.srt.map((srt, i) => {
                return (
                  <track
                    key={srt.value}
                    default={i == 0 ? true : false}
                    kind="captions"
                    label={srt.label}
                    src={url + `captions/${srt.value.toUpperCase()}.vtt`}
                    srcLang={srt.value.toLowerCase()}
                  ></track>
                );
              })}
            </video>
            <View
              style={{
                width: isBigScreen
                  ? 1000
                  : isDesktop
                    ? 700
                    : isTablet
                      ? "90%"
                      : "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: '#00000095',
                padding: 10,
                position: 'absolute',
                top: 0

              }}
            >
              <View style={{ width: 50, height: 50 }}>
                <LislUp color='#f5f5f4' />
              </View>

              <H6 style={{ fontSize: 16 }} color='#fff' >{episode.title}</H6>

              <Pressable alignItems="center" justifyContent='center' flexDirection="row" borderBottomWidth='1' borderColor="#f5f5f4" onPress={closeHandler} >
                <AntDesign name="closesquareo" size={20} color="#f5f5f4" />
                <Txt style={{ fontSize: 13, marginLeft: 5 }} color='#f5f5f4' >{t('tuto.close')}</Txt>
              </Pressable>
            </View>
          </View>

        </OutsidePressHandler>
      ) : (
        /** ANDROID || IOS CASES */
        <View style={{ width: "100%", flex: 1 }}>
          <BackHeader
            isOpen={false}
            setIsOpen={() => { }}
            navigation={navigation}
            closeEpisodeHandler={closeHandler}
          />
          <WebView
            onMessage={(event) => {
              closeHandler();
            }}
            containerStyle={{
              margin: 0,
              padding: 0,
            }}
            allowsFullscreenVideo={true}
            scalesPageToFit={false}
            setBuiltInZoomControls={false}
            useWebView2={true}
            source={{
              html: `
            <body style="margin-top:-60px !important; padding:0 !important; background-color:#FBF6F3; ">
            <div style="margin:0px; padding:0px; width:100%; height:100%; display:flex; align-items:center; justify-content:center;  ">
              <div style="border-radius:10px; overflow:hidden;  margin:0 ; padding:0 ; margin-top:-20px; width:98%; ">
              <video
              preload="true"
              ontimeupdate="onTimeUpdate(this)"
              onended="onEnded()"
              poster="${episode.season[0].poster_link}"
              crossorigin="anonymous"
              src="${url + "playlist.m3u8"}"
              controls
              allowfullscreen
              controlsList="nodownload"
              width="100%"
              
            >
            ${episode.srt.map(
                (srt, i) => `"<track
            key="${srt.value}"
            kind="captions"
            default=${i == 0 ? "true" : "false"}
            label="${srt.label}"
            src="${url + `captions/${srt.value.toUpperCase()}.vtt`}"
            srcLang="${srt.value.toLowerCase()}"
          ></track>"`
              )}
           
            </video>
            </div>
           
            </div>
           
            
            <script>
            function onEnded() {
                  window.ReactNativeWebView.postMessage("ended!")
            }
           
            </script>
            </body>
            `,
            }}
          />
        </View>
      )}
    </View>
  );
};

export default EpisodeModal;

const styles = StyleSheet.create({
  backContainer: {
    height: 70,
    width: "100%",
    flexDirection: "row",
    backgroundColor: colors.beige,
    borderBottomWidth: 1,
    borderColor: colors.grayBorder,
  },
  backButton: {
    height: 70,
    width: 70,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1B496515",
  },

  modalContainer: {
    maxWidth: 900,
    maxHeight: 5000,
  },
});
