import {
  StyleSheet,
  ImageBackground,
  View,
  ScrollView,
  Platform,
} from "react-native";
import React, { useState, useEffect } from "react";
import { colors } from "../styles/GlobalStyle";
import HeaderComponent from "../components/HeaderComponent";
import { H2, Txt } from "../components/TextsComponents";
import DimensionsHook from "../hooks/DimensionsHook";
import axios from "axios";
import Footer from "../components/Footer";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import {
  BoiteIcon,
  ConscienceIcon,
  CorpsIcon,
  PlaisirIcon,
  RituelsIcon,
  ScienceIcon,
  TemoignageIcon,
} from "../assets/svg/WorkshopBadgeIcons";
import SwiperItem from "../components/swiper/SwiperItem";
import Spinner from "../components/Spinner";
import HomeSearch from "./Home/HomeSearch";

const Workshops = ({ navigation }) => {
  const { t, i18n } = useTranslation();
  const Categories = [
    {
      label: t("workshop_types.label"),
      value: "",
    },
    {
      label: t("workshop_types.conscience"),
      value: "conscience",
      textColor: colors.blue3,
      backgroundColor: colors.green2,
      icon: () => <ConscienceIcon />,
    },
    {
      label: t("workshop_types.corps"),
      value: "body",
      textColor: colors.blue3,
      backgroundColor: colors.yellow,
      icon: () => <CorpsIcon />,
    },
    {
      label: t("workshop_types.plaisir"),
      value: "fun",
      textColor: colors.blue3,
      backgroundColor: colors.green3,
      icon: () => <PlaisirIcon />,
    },
    {
      label: t("workshop_types.rituels"),
      value: "rituals",
      textColor: colors.white,
      backgroundColor: colors.red1,
      icon: () => <RituelsIcon color="white" />,
    },
    {
      label: t("workshop_types.science"),
      value: "science",
      textColor: colors.white,
      backgroundColor: colors.blue3,
      icon: () => <ScienceIcon color="white" />,
    },

    {
      label: t("workshop_types.outils"),
      value: "tools",
      textColor: colors.blue3,
      backgroundColor: colors.blue2,
      icon: () => <BoiteIcon />,
    },
    {
      label: t("workshop_types.temoignages"),
      value: "testimony",
      textColor: colors.blue3,
      backgroundColor: colors.blue0,
      icon: () => <TemoignageIcon />,
    },
  ];

  const [keyword, setKeyword] = useState("");
  const { isDesktop, height, isMobile } = DimensionsHook();

  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedCategorie, setSelectedCategorie] = useState(Categories[0]);
  const [isOpenSuggestion, setIsOpenSuggestion] = useState(false);

  const searchHandler = async (searchText) => { };

  const getData = async () => {
    setLoader(true);
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    try {
      const Response = await axios.get(
        `${process.env.WORKSHOPS_ENDPOINT}/search/search-by-group?language=${i18n.language}`,
        config
      );

      setData(Response.data);

      setLoader(false);
    } catch (error) {
      setData([]);
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [selectedCategorie]);

  const swiperContainerStyle = {
    backgroundColor: colors.white,
    width: "100%",
    alignSelf: "center",
    paddingTop: 15,
    paddingBottom: 5,
    borderRadius: 20,
    marginTop: 20,
    paddingLeft: isMobile ? 10 : 20,
    paddingRight: isMobile ? 0 : 20,
  };

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <ScrollView
          nestedScrollEnabled
          showsVerticalScrollIndicator={Platform.OS === "web"}
        >
          <HeaderComponent
            name={t("tab_navigation.workshops")}
            navigation={navigation}
          />
          <View style={{ marginHorizontal: "2.5%" }}>
            <View
              style={{
                flexDirection: !isDesktop ? "column" : "row-reverse",
                alignItems: !isDesktop ? "flex-start" : "center",
                justifyContent: !isDesktop ? "flex-start" : "space-between",
                marginTop: 10,
              }}
            >
              {/** search bar & dropwdown */}
              <View
                style={{
                  flexDirection: !isDesktop ? "column" : "row-reverse",
                  alignItems: "center",
                  width: !isDesktop ? "100%" : "auto",
                }}
              >


                <View
                  style={{
                    width: !isDesktop ? "100%" : 300,
                  }}
                >
                  <HomeSearch
                    type="Atelier"
                    keyword={keyword}
                    setKeyword={setKeyword}
                    searchHandler={searchHandler}
                    isOpenSuggestion={isOpenSuggestion}
                    setIsOpenSuggestion={setIsOpenSuggestion}
                  />
                </View>
                <View></View>
              </View>
              <H2 style={{ marginTop: !isDesktop ? 20 : 0 }}>
                {t("workshops.title")}
              </H2>
            </View>
            {/** list of workshops in rows  */}
            <View style={{ minHeight: height - 245, zIndex: -1 }}>
              {loader ? (
                <View
                  style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: -20,
                  }}
                >
                  <Spinner size="large" />
                </View>
              ) : (
                <View>
                  {Data.map((el) => {
                    const category = Categories.find((c) => c.value == el._id);
                    const ws = el.workshops.sort(
                      (a, b) => Number(b.free) - Number(a.free)
                    );
                    return (
                      <View style={swiperContainerStyle} key={el._id}>
                        <View
                          style={[
                            styles.categoryBadge,
                            { backgroundColor: category.backgroundColor },
                          ]}
                        >
                          <View
                            style={{ width: 18, height: 18, marginRight: 5 }}
                          >
                            {category.icon()}
                          </View>
                          <Txt color={category.textColor}>
                            {t("workshops.title")} {category.label}
                          </Txt>
                        </View>
                        <Txt
                          style={{
                            fontSize: 14,
                            marginVertical: 10,
                            paddingLeft: 5,
                          }}
                          color={colors.grayLabel}
                        >
                          {" (" +
                            el.count +
                            " " +
                            t("workshops.title") +
                            " " +
                            t("found") +
                            ")"}
                        </Txt>
                        <ScrollView
                          nestedScrollEnabled
                          horizontal
                          showsHorizontalScrollIndicator={Platform.OS === "web"}
                        >
                          {ws.map((item) => {
                            return (
                              <View
                                key={
                                  item.trail_code
                                    ? item.trail_code
                                    : item.workshop_code
                                }
                              >
                                <SwiperItem
                                  item={item}
                                  type={item.trail_code ? "Trail" : "Atelier"}
                                  navigation={navigation}
                                />
                              </View>
                            );
                          })}
                        </ScrollView>
                      </View>
                    );
                  })}
                </View>
              )}
            </View>
            <View style={{ height: 30 }} />
          </View>
          {isDesktop && <Footer />}
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

export default Workshops;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.beige,
    flex: 1,
  },
  searchBarContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  categoryBadge: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-start",
    paddingHorizontal: 15,
    borderRadius: 100,
    paddingVertical: 4,
  },
});
