import { StyleSheet, Image, View } from "react-native";
import React, { useEffect } from "react";
import { Modal, Pressable, VStack } from "native-base";
import DimensionsHook from "../../hooks/DimensionsHook";
import { colors } from "../../styles/GlobalStyle";
import {
    FestivalUpgrade,
    FestivalUpgradeSmall,
} from "../../assets/svg/MyAccountIcons";
import { H6, H2 } from "../TextsComponents";
import { CloseIcon } from "../../assets/svg/Logo";
import { Feather } from "@expo/vector-icons";

const RedModal = ({
    isOpen,
    setIsOpen,
    title,
    text,
    renderStars,

}) => {
    const { isMobile, isDesktop } = DimensionsHook();


    const closeHandler = async () => {
        setIsOpen(false);


    };


    return (
        <Modal isOpen={isOpen} onClose={closeHandler}>
            <Modal.Content
                style={[styles.container, { maxWidth: 600, backgroundColor: colors.beige, }]}
            >
                <Modal.Body
                    style={[
                        styles.body,
                        {
                            marginTop: 20,
                        },
                    ]}
                >
                    {isDesktop ? (
                        <View style={[styles.festivalContainer, { top: -30 }]}>
                            <FestivalUpgrade />
                        </View>
                    ) : (
                        <View
                            style={[
                                styles.festivalContainerSmall,
                                { width: "100%", height: "100%", bottom: 0 },
                            ]}
                        >
                            <FestivalUpgradeSmall />
                        </View>
                    )}
                    <Pressable onPress={closeHandler} style={{ width: 26, height: 26, padding: 5, alignSelf: 'flex-end' }}>
                        <CloseIcon color="#484848" />
                    </Pressable>
                    <VStack justifyContent="center" alignItems="center">

                        <View
                            style={[
                                styles.modalWrapper,
                                {

                                    flexDirection: isDesktop ? "row-reverse" : "column",
                                },
                            ]}
                        >

                            <View
                                style={{
                                    flex: 1,
                                    marginHorizontal: isDesktop ? 30 : 0,
                                }}
                            >
                                <VStack style={{ marginTop: 10, marginBottom: 15, alignSelf: 'center' }}>
                                    <Feather name="x-octagon" size={60} color={colors.red1} />
                                </VStack>
                                <H2 style={{ textAlign: "center", marginBottom: 10, color: colors.blue3 }}>
                                    {title}
                                </H2>
                                <VStack p="3" bg="#ffffff80">
                                    <H6 style={{ textAlign: "center", color: '#484848', fontSize: 16 }}>
                                        {text}
                                    </H6>

                                </VStack>
                                {renderStars && <VStack alignItems='center' justifyContent="center">
                                    {renderStars()}
                                </VStack>}

                            </View>
                            <View
                                style={{
                                    marginTop: isDesktop ? 0 : 20,
                                    marginLeft: isDesktop ? 20 : 0,
                                }}
                            >
                                <Image
                                    source={{
                                        uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
                                    }}
                                    style={{
                                        width: 100,
                                        height: 165,
                                    }}
                                />
                            </View>
                        </View>
                    </VStack>
                </Modal.Body>
            </Modal.Content>
        </Modal >
    );
};

export default RedModal;

const styles = StyleSheet.create({
    container: {
        width: "95%",
        maxHeight: 5000,
    },
    festivalContainer: {
        position: "absolute",
        width: "120%",
        height: "120%",
        alignSelf: "center",
    },
    festivalContainerSmall: {
        position: "absolute",

        alignSelf: "center",
    },
    body: {
        overflow: "hidden",
        marginHorizontal: 10,
    },
    modalWrapper: {
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
    },
});
