import { Platform, StyleSheet, View } from "react-native";
import React, { useRef, useState } from "react";
import DimensionsHook from "../../hooks/DimensionsHook";
import { useHover } from "react-native-web-hooks";
import { BoldTxt, H4, H7, Txt } from "../../components/TextsComponents";
import { PrimaryButton } from "../../components/Buttons";
import { Surface } from "react-native-paper";
import { colors } from "../../styles/GlobalStyle";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Spinner from "../../components/Spinner";
import { useSelector } from "react-redux";
import { t } from "i18next";
import ResetUserModal from "../../components/modals/ResetUserModal";
import { Linking } from "react-native";

const TrialCard = ({ navigation, userGift }) => {
  const { width } = DimensionsHook();
  const isDesktop = width > 1000;
  const isMobile = width < 600;
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const [loader, setLoader] = useState(false);
  const [showResetUserModal, setShowResetUserModal] = useState(false);

  const getCurrentUrl = async () => {
    const url = await Linking.getInitialURL();
    return url;
  };

  const userData = useSelector((state) => state.userData);
  const beginTrialHandler = async () => {
    if (userData.subscription_status == "canceled") {
      setShowResetUserModal(true);
    } else {
      if (!loader) {
        setLoader(true);
        const TOKEN = await AsyncStorage.getItem("access_token");
        setTimeout(() => {
          const url = process.env.BEGIN_TRIAL;

          axios
            .post(url, {
              access_token: TOKEN,
              email: userData.email,
            })
            .then(async () => {
              setLoader(false);

              const currentUrl = await getCurrentUrl();
              if (Platform.OS == "web" && currentUrl.includes("/gift")) {
                navigation.replace("GiftWeb");
              } else if (
                Platform.OS == "web" &&
                currentUrl.includes("/sponsor")
              ) {
                navigation.replace("ParrainageWeb");
              } else {
                navigation.replace("TabNavigation");
              }
            });
        }, 1200);
      }
    }
  };

  return (
    <Surface
      style={[
        styles.cardContainer,
        {
          width: isMobile ? "100%" : isDesktop ? "24%" : "48%",
          backgroundColor: isHovered ? colors.blue2 : colors.beige,
          cursor: "pointer",
        },
      ]}
      elevation={4}
    >
      <View style={{ width: "100%", alignItems: "center" }} ref={hoverRef}>
        {/** duration plan */}
        <View style={{ alignItems: "center", marginTop: 20 }}>
          <H7 color={isHovered ? colors.blue3 : colors.blue3}>LISL UP</H7>
          <H4 color={isHovered ? colors.blue3 : colors.blue3}>
            {userGift ? t("plans.gift") : t("plans.freemium.name")}
          </H4>
        </View>
        {/** price */}
        <View style={{ alignItems: "center", paddingTop: 5 }}>
          <View style={[styles.priceContainer, { marginBottom: 10 }]}>
            <Txt> </Txt>
          </View>
          <View
            style={[
              styles.priceContainer,
              {
                backgroundColor: userGift
                  ? "transparent"
                  : isHovered
                    ? "#ffffff15"
                    : "#88C1A315",
              },
            ]}
          >
            <BoldTxt
              color={
                userGift
                  ? "transparent"
                  : isHovered
                    ? colors.white
                    : colors.green2
              }
              style={{ fontSize: 20 }}
            >
              {userGift ? t(" ") : t("plans.freemium.price")}
            </BoldTxt>
          </View>
        </View>

        {/** subscribe button */}

        <PrimaryButton
          width={200}
          style={{ marginTop: 20 }}
          onPress={beginTrialHandler}
        >
          {loader ? (
            <Spinner color={colors.white} />
          ) : userGift ? (
            t("plans.gift_button")
          ) : (
            t("plans.freemium.price")
          )}
        </PrimaryButton>
        <View style={{ marginHorizontal: 15, marginBottom: 30, marginTop: 15 }}>
          <Txt
            style={{ textAlign: "center", fontSize: 15 }}
            color={isHovered ? colors.blue3 : colors.blue3}
          >
            {userGift?.plan == "sub_annual"
              ? t("plans.gift_text_annual")
              : t("plans.freemium.text")}
          </Txt>
        </View>
      </View>
      {/** reset modal */}
      <ResetUserModal
        isOpen={showResetUserModal}
        setIsOpen={setShowResetUserModal}
        navigation={navigation}
      />
    </Surface>
  );
};

export default TrialCard;

const styles = StyleSheet.create({
  cardContainer: {
    marginVertical: 10,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 10,
  },
  priceContainer: {
    paddingVertical: 6,
    paddingHorizontal: 20,
    borderRadius: 10,
  },
});
