import React, { useState, useRef, useEffect } from "react";
import { View, Pressable } from "native-base";
import { useTranslation } from "react-i18next";
import { TextInput } from "react-native-paper";
import { colors } from "../../styles/GlobalStyle";
import { Ionicons } from "@expo/vector-icons";
import { Platform, ScrollView } from "react-native";
import { Txt } from "../../components/TextsComponents";
import OutsidePressHandler from "react-native-outside-press";
import { useHover } from "react-native-web-hooks";
import Spinner from "../../components/Spinner";
import axios from "axios";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";

const HomeSearch = ({
  keyword,
  setKeyword,
  searchHandler,
  isOpenSuggestion,
  setIsOpenSuggestion,
  type
}) => {
  const { t, i18n } = useTranslation();
  const resetHandler = () => {
    setKeyword("");
    searchHandler("");
    setIsOpenSuggestion(false);
    inputRef.current?.blur();
  };

  const inputRef = useRef();
  const navigation = useNavigation();
  const [data, setData] = useState({ trails: [], workshops: [] });
  const [loader, setLoader] = useState(false);

  const getData = async () => {
    setLoader(true);
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    const url = `${process.env.WORKSHOPS_ENDPOINT}/search-home?language=${i18n.language}&keyword=${keyword}`;

    axios.get(url, config).then((res) => {
      setData(res.data);

      setTimeout(() => {
        setLoader(false);
      }, 500);
    });
  };

  useEffect(() => {
    getData();
  }, [keyword]);

  const RenderSuggestion = ({ el, type }) => {
    const buttonRef = useRef();
    const playHandler = () => {
      if (type == "workshop") {
        navigation.navigate("Workshop", el);
      } else {
        navigation.navigate("Trail", el);
      }
    };
    const isHovered = useHover(buttonRef);
    return (
      <Pressable
        ref={buttonRef}
        px="2"
        py="3"
        onPress={playHandler}
        borderBottomWidth={1}
        bg={isHovered ? colors.beige : "white"}
        borderBottomColor={colors.grayBackground}
      >
        <Txt style={{ fontSize: 13 }} numberOfLines={1}>
          {type == "workshop" ? t("appointment.workshop") : "Trail"}: {el.title}
        </Txt>
      </Pressable>
    );
  };

  return (
    <OutsidePressHandler
      onOutsidePress={() => {
        inputRef.current?.blur();
        setIsOpenSuggestion(false);
      }}
    >
      <TextInput
        ref={inputRef}
        placeholder={t("placeholders.search")}
        mode="outlined"
        onFocus={() => setIsOpenSuggestion(true)}
        onBlur={() => setIsOpenSuggestion(true)}
        autoComplete={Platform.OS === "web" ? "none" : "off"}
        outlineColor={colors.grayBorder}
        activeOutlineColor={colors.green2}
        placeholderTextColor={colors.grayBorder}
        onSubmitEditing={() => searchHandler(keyword)}
        value={keyword}
        style={{
          backgroundColor: "#ffffff",
          justifyContent: "center",
          color: colors.blue3,
        }}
        theme={{ colors: { text: colors.blue3 } }}
        right={
          <TextInput.Icon
            onPress={() => searchHandler(keyword)}
            name={() => <Ionicons name={"search"} size={24} />}
          />
        }
        left={
          keyword.length > 0 ? (
            <TextInput.Icon
              onPress={resetHandler}
              name={() => <Ionicons name={"close"} size={24} />}
            />
          ) : null
        }
        onChangeText={(val) => setKeyword(val)}
      />
      {keyword.length > 0 && isOpenSuggestion && (
        <View
          maxH={300}
          minH={60}
          bg={colors.white}
          borderWidth="2"
          borderColor={colors.green2}
          w="full"
          position="absolute"
          style={{ top: 60 }}
          zIndex={10}
        >
          {loader ? (
            <View
              flex={1}
              style={{ alignItems: "center", justifyContent: "center" }}
              w="full"
              height="full"
            >
              <Spinner />
            </View>
          ) : data.trails.length > 0 || data.workshops.length > 0 ? (
            <ScrollView nestedScrollEnabled>
              {type != "Atelier" && data.trails.map((el) => (
                <RenderSuggestion type="trail" key={el._id} el={el} />
              ))}
              {type != "Trail" && data.workshops.map((el) => (
                <RenderSuggestion type="workshop" key={el._id} el={el} />
              ))}
            </ScrollView>
          ) : (
            <View
              flex={1}
              style={{ alignItems: "center", justifyContent: "center" }}
              w="full"
              height="full"
            >
              <Txt style={{ fontSize: 13 }} color="#484848">
                {t("trails.no_content")}
              </Txt>
            </View>
          )}
        </View>
      )}
    </OutsidePressHandler>
  );
};

export default HomeSearch;
