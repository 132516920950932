import { StyleSheet, View } from "react-native";
import React, { useEffect, useState } from "react";
import DimensionsHook from "../../../hooks/DimensionsHook";
import { H1, H2, Txt } from "../../TextsComponents";
import { colors } from "../../../styles/GlobalStyle";
import { PrimaryButton } from "../../Buttons";
import { useTranslation } from "react-i18next";
import InvoiceItem from "./InvoiceItem";
import Spinner from "../../Spinner";
import axios from "axios";
import { useSelector } from "react-redux";

const CompanyFreemium = ({ setPlansModal }) => {
  const { customer } = useSelector((state) => state.userData);
  const [loader, setLoader] = useState(true);
  // data = invoices
  const [data, setData] = useState([]);
  const { width } = DimensionsHook();
  const isDesktop = width > 1000;
  const { t } = useTranslation();
  const invoiceUrl = process.env.USER_INVOICES;
  useEffect(() => {
    //get invoices
    axios
      .get(`${invoiceUrl}?customerId=${customer}`)
      .then((InvoicesRes) => {
        // setData(InvoicesRes.data);
        /*   console.log("invoices :", res.data);*/

        setLoader(false);
      })
      .catch((err) => {
        setData([]);
        setLoader(false);
      });
  }, []);

  if (loader) {
    return (
      <View style={[styles.loadingContainer, { minHeight: 250 }]}>
        <Spinner size="large" />
      </View>
    );
  }

  return (
    <View>
      <View style={{ marginTop: isDesktop ? 0 : 20 }}>
        <H1>
          {t("plans.your_subscription")} - {t("plans.freemium.name")}
        </H1>
      </View>

      <Txt style={{ marginTop: 20 }}>{t("plans.freemium.text")}</Txt>
      <View style={styles.divider} />
      {/** buttons */}
      <View
        style={{
          width: "100%",
          flexDirection: isDesktop ? "row-reverse" : "column",
          alignItems: isDesktop ? "center" : "center",
          justifyContent: isDesktop ? "flex-start" : "center",
        }}
      >
        <PrimaryButton
          onPress={() => setPlansModal(true)}
          style={{ marginRight: isDesktop ? 15 : 0, width: 180 }}
        >
          {t("plans.subscribe")}
        </PrimaryButton>
      </View>

      {/** historique factures */}
      {data.length > 0 && (
        <View style={{ marginTop: 20 }}>
          <H2>{t("plans.invoices")}</H2>
          {data.map((invoice) => {
            return <InvoiceItem setData={setData} key={invoice._id} invoice={invoice} />;
          })}
        </View>
      )}
    </View>
  );
};

export default CompanyFreemium;

const styles = StyleSheet.create({
  badge: {
    backgroundColor: colors.green2,
    paddingHorizontal: 15,
    borderRadius: 100,
    paddingVertical: 5,
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: colors.grayBorder,
    marginVertical: 20,
  },
  loadingContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  greenContainer: {
    backgroundColor: colors.green0,
    paddingVertical: 15,
    marginVertical: 20,
    flexWrap: "wrap",
    borderRadius: 20,
  },
  webSiteContainer: {},
});
