import { StyleSheet, Pressable } from "react-native";
import React, { useRef } from "react";
import { colors } from "../styles/GlobalStyle";
import { useHover } from "react-native-web-hooks";
import { Txt } from "./TextsComponents";
import { Fab, Icon, VStack } from "native-base";
import { AntDesign } from "@expo/vector-icons";

export const PrimaryButton = ({
  children,
  style,
  onPress,
  width,
  disabled,
  whiteText,
  isSmallText
}) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  return (
    <Pressable
      disabled={disabled || false}
      ref={hoverRef}
      onPress={onPress}
      style={[
        styles.buttonPrimary,
        {
          backgroundColor:
            isHovered && !disabled
              ? colors.blue3
              : disabled
                ? "rgba(168, 196, 146,0.5)"
                : colors.green2,
          width: width || "auto",
          cursor: disabled ? "not-allowed" : "pointer",
          paddingHorizontal: width ? 0 : 30,
        },

        { ...style },
      ]}
    >
      <Txt

        numberOfLines={1}
        color={
          whiteText
            ? colors.white
            : isHovered && !disabled
              ? colors.white
              : disabled
                ? colors.grayLabel
                : colors.blue3
        }
        style={{ fontSize: isSmallText ? 14 : 16 }}
      >
        {children}
      </Txt>
    </Pressable>
  );
};

export const SecondaryButton = ({
  children,
  style,
  onPress,
  width,
  disabled,
  isWhite,
  dangerButton,
}) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  return (
    <Pressable
      disabled={disabled || false}
      ref={hoverRef}
      onPress={onPress}
      style={[
        styles.buttonSecondary,
        {
          backgroundColor: disabled
            ? "transparent" : isHovered && dangerButton ? colors.red1
              : isWhite
                ? colors.white : isHovered
                  ? colors.blue3
                  : "transparent",
          width: width || "auto",
          paddingHorizontal: width ? 0 : 30,
          borderColor: disabled
            ? colors.grayBorder
            : isHovered
              ? "transparent" : dangerButton ? colors.red1
                : isWhite
                  ? colors.white
                  : colors.grayBorder,
          ...style,
        },
      ]}
    >
      <Txt
        fontSize={16}
        color={
          disabled
            ? colors.grayBorder
            : isHovered
              ? colors.white
              : dangerButton ? colors.red1 : isWhite
                ? colors.white
                : colors.blue3
        }
      >
        {children}
      </Txt>
    </Pressable>
  );
};


const styles = StyleSheet.create({
  buttonPrimary: {
    height: 42,
    borderRadius: 100,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonSecondary: {
    height: 42,
    borderRadius: 100,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
  },
});
