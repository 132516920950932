import { StyleSheet, View } from "react-native";
import React, { useState } from "react";
import { AlertDialog } from "native-base";
import { BoldTxt, Txt } from "../TextsComponents";
import { PrimaryButton } from "../Buttons";
import { colors } from "../../styles/GlobalStyle";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDispatch } from "react-redux";
import { signCommunityConditions } from "../../redux/Actions";
import Spinner from "../Spinner";
import { useTranslation } from "react-i18next";
import DimensionsHook from "../../hooks/DimensionsHook";
import * as WebBrowser from "expo-web-browser";

const CommunityConditionsModal = ({
  isOpen,
  setIsOpen,
  navigation,
  community_visit,
  getCurrentTrails,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = DimensionsHook();
  const dispatch = useDispatch();
  const [conditionsLoader, setConditionsLoader] = useState(false);
  const onClose = () => {
    setIsOpen(false);
    if (!community_visit) {
      navigation.navigate("Home");
    }
  };

  const openPrivacy = () => {
    WebBrowser.openBrowserAsync(
      "https://www.lislup.com/politique-de-confidentialite/"
    );
  };

  const onAcceptConditions = async () => {
    if (!conditionsLoader) {
      setConditionsLoader(true);
      const TOKEN = await AsyncStorage.getItem("access_token");
      axios
        .put(process.env.USER_UPDATE, {
          access_token: TOKEN,
          community_visit: true,
        })
        .then((res) => {
          dispatch(signCommunityConditions());
          setConditionsLoader(false);
          setIsOpen(false);
          getCurrentTrails();
        })
        .catch((err) => {
          console.log(err);
          setConditionsLoader(false);
        });
    }
  };
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialog.Content
        style={{ width: "95%", maxWidth: 1300, height: "95%", maxHeight: 720 }}
      >
        <AlertDialog.CloseButton />

        <AlertDialog.Header>
          <BoldTxt style={{ fontSize: 16, marginTop: isDesktop ? 0 : 30 }}>
            {t("community_charter.title")}
          </BoldTxt>
        </AlertDialog.Header>
        <AlertDialog.Body>
          <View style={{ paddingHorizontal: 15 }}>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 20 }}>
              {t("community_charter.text")}
            </Txt>
            <BoldTxt
              style={{ fontSize: 15, textAlign: "justify", marginTop: 20 }}
            >
              {t("community_charter.title1")}
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text1_1")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {t("community_charter.text1_2")}
            </Txt>
            <BoldTxt
              style={{ fontSize: 15, textAlign: "justify", marginTop: 20 }}
            >
              {t("community_charter.title2")}
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text2_1")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 10 }}>
              {t("community_charter.text2_2")}
            </Txt>
            <BoldTxt
              style={{ fontSize: 15, textAlign: "justify", marginTop: 20 }}
            >
              {t("community_charter.title3")}
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text3_1")}
            </Txt>
            <Txt
              style={{
                fontSize: 14,
                textAlign: "justify",
                marginTop: 15,
                marginLeft: 8,
              }}
            >
              {t("community_charter.text3_2")}
            </Txt>
            <Txt
              style={{
                fontSize: 14,
                textAlign: "justify",
                marginTop: 10,
                marginLeft: 8,
              }}
            >
              {t("community_charter.text3_3")}
            </Txt>
            <Txt
              style={{
                fontSize: 14,
                textAlign: "justify",
                marginTop: 10,
                marginLeft: 8,
              }}
            >
              {t("community_charter.text3_4")}
            </Txt>
            <Txt
              style={{
                fontSize: 14,
                textAlign: "justify",
                marginTop: 10,
                marginLeft: 8,
              }}
            >
              {t("community_charter.text3_5")}
            </Txt>
            <Txt
              style={{
                fontSize: 14,
                textAlign: "justify",
                marginTop: 10,
                marginLeft: 8,
              }}
            >
              {t("community_charter.text3_6")}
            </Txt>
            <Txt
              style={{
                fontSize: 14,
                textAlign: "justify",
                marginTop: 10,
                marginLeft: 8,
              }}
            >
              {t("community_charter.text3_7")}
            </Txt>
            <Txt
              style={{
                fontSize: 14,
                textAlign: "justify",
                marginTop: 10,
                marginLeft: 8,
              }}
            >
              {t("community_charter.text3_8")}
            </Txt>
            <Txt
              style={{
                fontSize: 14,
                textAlign: "justify",
                marginTop: 10,
                marginLeft: 8,
              }}
            >
              {t("community_charter.text3_9")}
            </Txt>
            <Txt
              style={{
                fontSize: 14,
                textAlign: "justify",
                marginTop: 10,
                marginLeft: 8,
              }}
            >
              {t("community_charter.text3_10")}
            </Txt>
            <Txt
              style={{
                fontSize: 14,
                textAlign: "justify",
                marginTop: 10,
                marginLeft: 8,
              }}
            >
              {t("community_charter.text3_11")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text3_12")}
            </Txt>
            <BoldTxt
              style={{ fontSize: 15, textAlign: "justify", marginTop: 20 }}
            >
              {t("community_charter.title4")}
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text4_1")}
            </Txt>
            <BoldTxt
              style={{ fontSize: 15, textAlign: "justify", marginTop: 20 }}
            >
              {t("community_charter.title5")}
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text5_1")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text5_2")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text5_3")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text5_4")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text5_5")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text5_6")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text5_7")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text5_8")}
            </Txt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text5_9")}
            </Txt>
            <BoldTxt
              style={{ fontSize: 15, textAlign: "justify", marginTop: 20 }}
            >
              {t("community_charter.title6")}
            </BoldTxt>
            <Txt style={{ fontSize: 14, textAlign: "justify", marginTop: 15 }}>
              {t("community_charter.text6_1")}{" "}
              <Txt
                style={{
                  fontSize: 14,
                  textAlign: "justify",
                  textDecoration: "underline",
                }}
                onPress={openPrivacy}
              >
                {t("community_charter.text6_2")}.
              </Txt>
            </Txt>
          </View>
          {community_visit ? (
            <View style={{ height: 50 }}></View>
          ) : (
            <PrimaryButton
              onPress={onAcceptConditions}
              style={{ marginVertical: 30, width: 250, alignSelf: "center" }}
            >
              {conditionsLoader ? <Spinner color={colors.white} /> : "Accept"}
            </PrimaryButton>
          )}
        </AlertDialog.Body>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default CommunityConditionsModal;

const styles = StyleSheet.create({});
