import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import {
  userDataReducer,
  userActivityReducer,
  watchedEpisodesReducer,
  monthlyMessageReducer,
  savedEpisodesReducer,
  questionnairesDataReducer,
  answeredQuestionnairesReducer,
  dominosReducer,
  cardsReducer
} from "./Reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {

};

const reducer = combineReducers({
  userData: userDataReducer,
  userActivity: userActivityReducer,
  watchedEpisodes: watchedEpisodesReducer,
  savedEpisodes: savedEpisodesReducer,
  monthlyMessage: monthlyMessageReducer,
  questionnairesData: questionnairesDataReducer,
  answeredQuestionnaires: answeredQuestionnairesReducer,
  dominos: dominosReducer,
  cards: cardsReducer
});

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
