import { Platform, StyleSheet, View } from "react-native";
import React, { useState } from "react";
import WebView from "react-native-webview";
import BackHeader from "../components/BackHeader";
import { colors } from "../styles/GlobalStyle";
import Spinner from "../components/Spinner";
import DimensionsHook from "../hooks/DimensionsHook";

const Chat = ({ navigation }) => {
  const { width } = DimensionsHook();
  if (Platform.OS == "web") {
    const [isLoading, setIsLoading] = useState(true);
    return (
      <View style={{ flex: 1, backgroundColor: colors.beige }}>
        <BackHeader navigation={navigation} />
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <View
            style={{
              flex: 1,

              alignItems: "center",
              justifyContent: "center",
              width,
              alignSelf: "center",
            }}
          >
            <iframe
              frameBorder="0"
              onLoad={() => setIsLoading(false)}
              width="100%"
              height="100%"
              src="https://secure.livechatinc.com/customer/action/open_chat?license_id=15144921"
              //src="https://tawk.to/chat/644a6e4331ebfa0fe7fabd0a/1gv1btedl"
            ></iframe>
            {isLoading && (
              <View style={{ position: "absolute" }}>
                <Spinner size="large" />
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ zIndex: 999 }}>
        <BackHeader
          isOpen={false}
          setIsOpen={() => {}}
          navigation={navigation}
        />
      </View>
      <WebView
        style={{ flex: 1 }}
        scalesPageToFit
        startInLoadingState
        onError={() => navigation.goBack()}
        source={{
          //uri: "https://tawk.to/chat/644a6e4331ebfa0fe7fabd0a/1gv1btedl",
          uri: "https://secure.livechatinc.com/customer/action/open_chat?license_id=15144921",
        }}
      />
    </View>
  );
};

export default Chat;

const styles = StyleSheet.create({});
