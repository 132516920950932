import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React, { useEffect } from "react";
import HomeMain from "./HomeMain";
import SeeAllTrails from "./SeeAllTrails";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Stack = createNativeStackNavigator();

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

async function registerForPushNotificationsAsync() {

  let token;

  if (Platform.OS === "android") {
    await Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#ffffff",
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      console.log("Failed to get push token for push notification!");
      return;
    }
    if (Platform.OS == "web") {
      console.log("is on web");
    } else {
      token = (
        await Notifications.getExpoPushTokenAsync({
          projectId: "38cf181d-7cac-456a-a09c-30a9a2d6bdda",
        })
      ).data;

      const TOKEN = await AsyncStorage.getItem("access_token");
      if (Platform.OS == "android") {
        axios.put(process.env.USER_UPDATE, {
          notification_android: token,
          access_token: TOKEN,
        });
      } else {
        axios.put(process.env.USER_UPDATE, {
          notification_ios: token,
          access_token: TOKEN,
        });
      }
    }
  } else {
    console.log("Must use physical device for Push Notifications");
  }

  return token;
}

const Home = () => {
  const { i18n } = useTranslation()
  const { language } = useSelector(state => state.userData)

  useEffect(() => {
    registerForPushNotificationsAsync();
  }, []);

  useEffect(() => {
    if (i18n.language != language) {
      i18n.changeLanguage(language)
    }
  }, [])

  useEffect(() => {
    const trackDevice = async () => {
      const TOKEN = await AsyncStorage.getItem("access_token");
      axios.post(process.env.USER_DEVICE, {
        device: Platform.OS,
        access_token: TOKEN,
      }).then(() => {

      }).catch(err => {
        console.log(err)
      })
    }
    trackDevice()
  }, [])

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="HomeMain"
        component={HomeMain}
        options={{ title: "Accueil" }}
      />
      <Stack.Screen
        name="SeeAllTrails"
        component={SeeAllTrails}
        options={{ title: "Trails" }}
      />
    </Stack.Navigator>
  );
};

export default Home;
