import {

  StyleSheet,
  View,
  Platform,

} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { Pressable } from "native-base";
import DimensionsHook from "../../hooks/DimensionsHook";

import { LislUp } from "../../assets/svg/Logo";
import WebView from "react-native-webview";
import { useDispatch, useSelector } from "react-redux";
import FinishEpisodeModal from "../modals/FinishEpisodeModal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import {
  setUserActivity,
  setUserData,
  setWatchedEpisodes,
} from "../../redux/Actions";
import OutsidePressHandler from "react-native-outside-press";
import BackHeader from "../BackHeader";
import { useNavigation } from "@react-navigation/native";
import { H6, Txt } from "../TextsComponents";
import { AntDesign } from "@expo/vector-icons";
import { useTranslation } from "react-i18next";

const TrailEpisodeVideo = ({
  setIsOpen,
  data,
  setIsOpenGreenModal,
  setIsOpenChooseModal,
  setShowSuccessEpisode,
  firstTimeFinished,
  setFirstTimeFinished,
  setBackFromEpisode


}) => {
  const dispatch = useDispatch();
  const { isDesktop, isBigScreen, isTablet } = DimensionsHook();
  const vidRef = useRef(null);
  const navigation = useNavigation()
  const watched_episodes = useSelector((state) => state.watchedEpisodes);

  const { t } = useTranslation()

  const { company } = useSelector((state) => state.userData);

  const isLastEpisodeInSeason = data.isLastEpisodeInSeason;

  const { current_trails, finished_trails, current_finished_trails } =
    useSelector((state) => state.userActivity);
  const [showFinishTrail, setShowFinishTrail] = useState(false);

  const [finished, setFinished] = useState(
    watched_episodes.includes(data.episode_code)
  );

  useEffect(() => {
    setBackFromEpisode(true)
  }, [])


  const [inCurrentTrails, setInCurrentTrails] = useState(
    current_trails.includes(data.trail_code)
  );
  const [finishModal, setFinishModal] = useState(false);

  const url = data.link.replace("playlist.m3u8", "");

  // close  video modal
  const closeHandler = () => {
    setIsOpen(false);
    if (firstTimeFinished) {
      setShowSuccessEpisode(true)
      setFirstTimeFinished(false)
    } else {
      setIsOpenChooseModal(true);
    }
    if (Platform.OS == "web") {
      vidRef.current.pause();
    }
  };

  // add to current trails
  const addToCurrentTrails = async () => {
    setInCurrentTrails(true);
    const TOKEN = await AsyncStorage.getItem("access_token");
    axios
      .post(process.env.CURRENT_TRAILS, {
        access_token: TOKEN,
        trail_code: data.trail_code,
      })
      .then(() => {
        dispatch(setUserActivity());
      });
  };

  //finish video  handler
  const finishedHandler = async () => {
    setFinished(true);
    setFirstTimeFinished(true)
    const TOKEN = await AsyncStorage.getItem("access_token");

    axios
      .post(process.env.USER_WATCHED_EPISODES, {
        access_token: TOKEN,
        episode_code: data.episode_code,
        last_episode: data.isTheLastEpisode,
        trail_code: data.trail_code,
        daily_limit: data.daily_limit,
        company: company,
      })
      .then(() => {
        if (data.isTheLastEpisode) {
          setShowFinishTrail(true);
          dispatch(setUserActivity());
        }

        dispatch(setUserData());
        dispatch(setWatchedEpisodes());
      });
  };

  const onTimeUpdateHandler = (e) => {
    const { duration, currentTime } = e.target;
    if (!inCurrentTrails && data.episode_code.includes("S1-E2")) {
      addToCurrentTrails();
    }
    // mark as finished if current time > 0.8 of duration and not marked before as finished
    if (currentTime / duration > 0.8 && !finished) {
      finishedHandler();
    }
  };
  const onEndedHandler = async () => {
    setFinishModal(true);
    closeHandler();

    if (data.isTheLastEpisode && showFinishTrail) {
      setIsOpenGreenModal(showFinishTrail);
    }

  };

  useEffect(() => {
    const isFinished = watched_episodes.includes(data.episode_code);
    setFinished(isFinished);
    setInCurrentTrails(current_trails.includes(data.trail_code));
    setShowFinishTrail(!finished_trails.includes(data.trail_code));
  }, [data]);

  return (


    <View
      style={{
        width: "100%",
        alignSelf: "center",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",

      }}
    >
      {Platform.OS == "web" ? (
        <OutsidePressHandler
          style={{ alignItems: "center", justifyContent: "center", flex: 1 }}
          onOutsidePress={closeHandler}
        >
          <View

            style={{
              width: isBigScreen ? 1000 : isDesktop ? 700 : isTablet ? '90%' : '100%',
              aspectRatio: 16 / 9,

            }}
          >
            <video
              ref={vidRef}
              autoPlay={true}
              src={url + "play_480p.mp4"}
              poster={url + "/thumbnail.jpg"}
              onEnded={onEndedHandler}
              onTimeUpdate={onTimeUpdateHandler}
              preload="true"
              crossOrigin="anonymous"
              controls
              controlsList="nodownload noremoteplayback"
              style={{ width: '100%', aspectRatio: 16 / 9, backgroundColor: '#000000' }}
            >
              {data.srt.map((srt, i) => {
                return (
                  <track
                    key={srt.value}
                    default={i == 0 ? true : false}
                    kind="captions"
                    label={srt.label}
                    src={url + `captions/${srt.value.toUpperCase()}.vtt`}
                    srcLang={srt.value.toLowerCase()}
                  ></track>
                );
              })}
            </video>
            <View
              style={{
                width: isBigScreen
                  ? 1000
                  : isDesktop
                    ? 700
                    : isTablet
                      ? "90%"
                      : "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: '#00000095',
                padding: 10,
                position: 'absolute',
                top: 0

              }}
            >
              <View style={{ width: 50, height: 50 }}>
                <LislUp color='#f5f5f4' />
              </View>

              <H6 style={{ fontSize: 16 }} color='#fff' >{data.title}</H6>

              <Pressable alignItems="center" justifyContent='center' flexDirection="row" borderBottomWidth='1' borderColor="#f5f5f4" onPress={closeHandler} >
                <AntDesign name="closesquareo" size={20} color="#f5f5f4" />
                <Txt style={{ fontSize: 13, marginLeft: 5 }} color='#f5f5f4' >{t('tuto.close')}</Txt>
              </Pressable>
            </View>
          </View>
        </OutsidePressHandler>
      ) : (
        /** ANDROID || IOS CASES */
        <View style={{ width: "100%", flex: 1 }}>
          <BackHeader isOpen={false} setIsOpen={() => { }} navigation={navigation} closeEpisodeHandler={closeHandler} />
          <WebView
            onMessage={(event) => {
              const d = event.nativeEvent.data;
              if (d == "ended") {
                closeHandler();
                setFinishModal(true);
                if (data.isTheLastEpisode && showFinishTrail) {
                  setIsOpenGreenModal(showFinishTrail);
                }
              } else {
                const splittedD = d.split("-");
                const duration = splittedD[0];
                const current = splittedD[1];

                if (
                  !inCurrentTrails &&
                  data.episode_code.includes("S1-E2")
                ) {
                  addToCurrentTrails();
                }

                // if not finished before and watched more than 80%
                if (current / duration > 0.8 && !finished) {
                  finishedHandler();
                }
              }
            }}
            containerStyle={{
              margin: 0,
              padding: 0,
            }}
            allowsFullscreenVideo={true}
            scalesPageToFit={false}
            setBuiltInZoomControls={false}
            useWebView2={true}
            source={{
              html: `
            <body style="margin:0 !important; padding:0 !important; background-color:#FBF6F3; ">
            <div style="margin:0px; padding:0px; width:100%; height:100%; display:flex; align-items:center; justify-content:center;  ">
              <div style="border-radius:10px; overflow:hidden;  margin:0 ; padding:0 ; margin-top:-20px; width:98%; ">
              <video
              preload="true"
              autoplay
              ontimeupdate="onTimeUpdate(this)"
              onended="onEnded()"
              poster="${url + "thumbnail.jpg"}"
              crossorigin="anonymous"
              src="${url + "playlist.m3u8"}"
              controls
              allowfullscreen
              controlsList="nodownload"
              width="100%" 
            > 
            ${data.srt.map(
                (srt, i) => `"<track
            key="${srt.value}"
            kind="captions"
            label="${srt.label}"
            default=${i == 0 ? "true" : "false"}
            src="${url + `captions/${srt.value.toUpperCase()}.vtt`}"
            srcLang="${srt.value.toLowerCase()}"
          ></track>"`
              )}
            </video>
            </div>
            </div>
            <script>
          function onEnded() {
                window.ReactNativeWebView.postMessage("ended")
          }
          function onTimeUpdate(e) {
            window.ReactNativeWebView.postMessage(e.duration + "-" + e.currentTime)
          }
          </script>
            </body>
            `,
            }}
          />
        </View>
      )}




      {/*finish episode modal*/}
      <FinishEpisodeModal
        isOpen={finishModal}
        setIsOpen={setFinishModal}
        item={data}
      />
    </View>
  );
};

export default TrailEpisodeVideo;

const styles = StyleSheet.create({

});
