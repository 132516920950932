import { StyleSheet, View } from "react-native";
import React, { useState } from "react";
import { Modal, VStack } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { H2, SmallBoldTxt, Txt } from "../TextsComponents";
import { Input } from "../Inputs";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import axios from "axios";
import { useDispatch, } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Spinner from "../Spinner";
import { useTranslation } from "react-i18next";
import { setUserData } from "../../redux/Actions";

const CompanyCodeModal = ({ isOpen, setIsOpen, fromPlans, navigation }) => {
    const { t } = useTranslation();
    const { isMobile } = DimensionsHook();
    const [submitLoader, setSubmitLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [code, setCode] = useState("");
    const dispatch = useDispatch()


    const closeHandler = () => {
        setIsOpen(false);
        setErrorMessage(null);

    };

    const submitHandler = async () => {
        if (!submitLoader) {
            setErrorMessage(null);
            setSubmitLoader(true)
            const TOKEN = await AsyncStorage.getItem("access_token");
            axios
                .post(process.env.USER_JOIN_COMPANY, {
                    access_token: TOKEN,
                    code
                })
                .then(async () => {
                    await dispatch(setUserData())
                    setSubmitLoader(false);
                    setIsOpen(false)
                    if (fromPlans) {
                        navigation.replace('TabNavigation')
                    }
                })
                .catch((err) => {
                    if (err.response.status === 406) {
                        setErrorMessage(t("signup.error_company_max_users"));
                        setSubmitLoader(false);
                    } else if (err.response.status === 410) {
                        setErrorMessage(t("signup.error_company_inactive"));
                        setSubmitLoader(false);
                    } else if (err.response.status === 412) {
                        setErrorMessage(t("signup.error_company_code"));
                        setSubmitLoader(false);
                    } else {
                        setErrorMessage(t("signup.error_connexion"));
                        setSubmitLoader(false);
                    }

                });
        }
    };

    return (
        <Modal focusable={false} isOpen={isOpen} onClose={closeHandler}>
            <Modal.Content style={styles.container}>
                <Modal.Body>
                    <Modal.CloseButton
                        focusable={false}
                        style={{ marginRight: isMobile ? 0 : 5 }}
                    />
                    <VStack
                        style={{
                            marginHorizontal: isMobile ? 5 : 30,
                        }}
                    >
                        <H2>{t("company.title")}</H2>
                        <SmallBoldTxt
                            style={{
                                color: colors.red1,
                                textAlign: "center",
                                marginTop: 6,
                                marginVertical: 12,
                            }}
                        >
                            {errorMessage ? errorMessage : " "}
                        </SmallBoldTxt>

                        <Txt style={{ fontSize: 14, textAlign: "justify" }}>
                            {t("company.text")}
                        </Txt>

                        {/** message input */}
                        <View style={{ marginVertical: 20 }}>
                            <Input
                                placeholder={t("placeholders.company_code")}
                                value={code}
                                onChangeText={(val) => setCode(val)}
                            />
                        </View>
                        {/** buttons */}
                        <View
                            style={{
                                flexDirection: isMobile ? "column" : "row-reverse",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: 20,
                            }}
                        >
                            <PrimaryButton
                                whiteText={true}
                                width={200}
                                onPress={submitHandler}
                                style={{ width: 200 }}
                            >
                                {submitLoader ? (
                                    <Spinner color={colors.white} />
                                ) : (
                                    t("challenge_modal.valid")
                                )}
                            </PrimaryButton>
                            <SecondaryButton
                                onPress={closeHandler}
                                style={{
                                    marginTop: isMobile ? 15 : 0,
                                    width: 200,
                                    marginLeft: isMobile ? 0 : -10,
                                    marginRight: isMobile ? 0 : 10,
                                }}
                            >
                                {t("unsubscribe.undo")}
                            </SecondaryButton>
                        </View>
                    </VStack>
                </Modal.Body>
            </Modal.Content>
        </Modal>
    );
};

export default CompanyCodeModal;

const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.white,
        width: "95%",
        maxWidth: 600,
        maxHeight: 5000,
        paddingTop: 14,
    },
});
