import { Svg, Circle, Polygon, Rect, Path } from "react-native-svg";
import { colors } from "../../styles/GlobalStyle";

export const CircleIcon = () => {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81.4303 81.4303">
            <Circle
                cx="50"
                cy="50"
                r="39.2151"
                transform="translate(-29.9955 40.7151) rotate(-45)"
                fill='#484848'
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
            />
        </Svg>
    );
};

export const TriangleIcon = () => {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.8599 72.1606">
            <Polygon
                points="41.43 1.5 1.5 70.661 81.36 70.661 41.43 1.5"
                fill="#d5aaf2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
            />
        </Svg>
    );
};
export const SquareIcon = () => {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.7643 61.7644">
            <Rect
                x="1.5"
                y="1.5"
                width="58.7643"
                height="58.7644"
                fill={colors.blue3}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
            />
        </Svg>
    );
};

export const PlusIcon = () => {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.6879 73.688">
            <Polygon
                points="72.188 28.132 45.556 28.132 45.556 1.5 28.132 1.5 28.132 28.132 1.5 28.132 1.5 45.556 28.132 45.556 28.132 72.188 45.556 72.188 45.556 45.556 72.188 45.556 72.188 28.132"
                fill={colors.green3}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
            />
        </Svg>
    );
};

export const PentagonIcon = () => {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.5213 72.9229">
            <Polygon
                points="38.261 1.5 1.5 28.208 15.541 71.423 60.98 71.423 75.021 28.208 38.261 1.5"
                fill={colors.blue2}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
            />
        </Svg>
    );
};

export const HeartIcon = () => {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.4395 67.7876">
            <Path
                d="M77.5515,19.7565c-5.3668-2.7848-12.7664-3.06-17.9494.3314C56.95,21.8234,50,29.3458,50,31.45c0-2.1037-6.95-9.6261-9.6021-11.3616-5.183-3.3911-12.5826-3.1162-17.9494-.3314-8.1924,4.2514-11.108,20.9994-7.88,28.8129,3.3955,8.2192,12.0568,12.7169,18.9125,17.5536C42.696,72.6243,50,82.3938,50,82.3938s7.3039-9.7695,16.5192-16.2708c6.8556-4.8367,15.5169-9.3344,18.9124-17.5536C88.66,40.7559,85.7439,24.0079,77.5515,19.7565Z"
                transform="translate(-11.7803 -16.1062)"
                fill={colors.red1}

                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
            />
        </Svg>
    );
};

export const StarIcon = () => {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.707 69.2953">
            <Polygon
                points="36.354 1.5 47.124 23.323 71.207 26.823 53.78 43.809 57.894 67.795 36.354 56.471 14.813 67.795 18.927 43.809 1.5 26.823 25.583 23.323 36.354 1.5"
                fill={colors.yellow}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
            />
        </Svg>
    );
};

export const DiamondIcon = () => {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.3631 63.5369">
            <Polygon
                points="27.182 1.5 1.5 31.768 27.182 62.037 52.863 31.768 27.182 1.5"
                fill="#e89679"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
            />
        </Svg>
    );
};
