import { StyleSheet, Image, View } from "react-native";
import React, { useEffect } from "react";
import { Modal, Pressable, VStack } from "native-base";
import DimensionsHook from "../../hooks/DimensionsHook";
import { colors } from "../../styles/GlobalStyle";
import {
  FestivalUpgrade,
  FestivalUpgradeSmall,
} from "../../assets/svg/MyAccountIcons";
import { H6, H2 } from "../TextsComponents";
import { useDispatch } from "react-redux";
import { resetUser } from "../../redux/Actions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { CloseIcon } from "../../assets/svg/Logo";

const GreenModal = ({
  isOpen,
  setIsOpen,
  title,
  text,
  navigation,
  logout,
  workshop,
  renderStars,

}) => {
  const { isMobile, isDesktop } = DimensionsHook();

  const dispatch = useDispatch();
  const closeHandler = async () => {
    setIsOpen(false);
    if (logout) {
      await AsyncStorage.removeItem("access_token");
      navigation.reset({
        index: 0,
        routes: [{ name: "Loader" }],
      });
      dispatch(resetUser());
    }
    if (workshop || renderStars) {
      navigation.goBack();
    }
  };
  useEffect(() => {
    if (logout && isOpen) {
      setTimeout(() => {
        closeHandler();
      }, 2000);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content
        style={[styles.container, { maxWidth: logout ? 500 : renderStars ? 600 : 800, backgroundColor: renderStars ? colors.beige : colors.green0, }]}
      >
        <Modal.Body
          style={[
            styles.body,
            {
              marginTop: 20,
            },
          ]}
        >
          {isDesktop ? (
            <View style={[styles.festivalContainer, { top: -30 }]}>
              <FestivalUpgrade />
            </View>
          ) : (
            <View
              style={[
                styles.festivalContainerSmall,
                { width: "100%", height: "100%", bottom: 0 },
              ]}
            >
              <FestivalUpgradeSmall />
            </View>
          )}
          <Pressable onPress={closeHandler} style={{ width: 26, height: 26, padding: 5, alignSelf: 'flex-end' }}>
            <CloseIcon color="#484848" />
          </Pressable>
          <VStack justifyContent="center" alignItems="center">
            <View
              style={[
                styles.modalWrapper,
                {
                  marginTop: isMobile ? 15 : 0,
                  flexDirection: isDesktop ? "row-reverse" : "column",
                },
              ]}
            >
              <View
                style={{
                  flex: 1,
                  marginHorizontal: isDesktop ? 30 : 0,
                }}
              >
                <H2 style={{ textAlign: "center", marginBottom: 10, color: colors.blue3 }}>
                  {title}
                </H2>
                <H6 style={{ textAlign: "center", marginBottom: 20, color: colors.blue3 }}>
                  {text}
                </H6>
                {renderStars && <VStack alignItems='center' justifyContent="center">
                  {renderStars()}
                </VStack>}

              </View>
              <View
                style={{
                  marginTop: isDesktop ? 0 : 20,
                  marginLeft: isDesktop ? 20 : 0,
                }}
              >
                <Image
                  source={{
                    uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
                  }}
                  style={{
                    width: 100,
                    height: 165,
                  }}
                />
              </View>
            </View>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default GreenModal;

const styles = StyleSheet.create({
  container: {
    width: "95%",
    maxHeight: 5000,
  },
  festivalContainer: {
    position: "absolute",
    width: "120%",
    height: "120%",
    alignSelf: "center",
  },
  festivalContainerSmall: {
    position: "absolute",

    alignSelf: "center",
  },
  body: {
    overflow: "hidden",
    marginHorizontal: 10,
  },
  modalWrapper: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
