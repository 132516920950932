import { ImageBackground, StyleSheet, View } from "react-native";
import React from "react";
import { HStack, Modal, VStack } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { H2, Txt } from "../TextsComponents";

import { useTranslation } from "react-i18next";
import { Entypo, Ionicons } from "@expo/vector-icons";

const SuccessParrainage = ({ isOpen, setIsOpen, email }) => {
  const { isMobile } = DimensionsHook();
  const closeHandler = async () => {
    setIsOpen(false);
    window.location.href = "/";
  };
  const { t } = useTranslation();
  return (
    <Modal focusable={false} isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={styles.container}>
        <Modal.Body>
          <ImageBackground
            source={require("../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ marginRight: isMobile ? 0 : 10 }}
            />
            <VStack
              style={{
                marginTop: 30,
                marginHorizontal: isMobile ? 0 : 20,
              }}
            >
              <View
                style={{
                  width: 60,
                  height: 60,
                  alignSelf: "center",
                  backgroundColor: colors.green2,
                  alignItems: "center",
                  justifyContent: "center",
                  marginVertical: 20,
                  borderRadius: 40,
                }}
              >
                <Entypo name="check" size={28} color={colors.white} />
              </View>
              <H2
                style={{
                  fontSize: 22,
                  lineHeight: 28,
                  textAlign: "center",

                  marginHorizontal: 20,
                }}
              >
                {t("parrainage.success")}
              </H2>
              <HStack
                style={{ marginVertical: 20, alignSelf: "center" }}
                alignItems="center"
              >
                <Ionicons
                  name="mail-unread-outline"
                  size={20}
                  color="#484848"
                />
                <Txt
                  style={{
                    color: "#484848",
                    fontSize: 14,
                    marginLeft: 5,
                  }}
                >
                  {email}
                </Txt>
              </HStack>
            </VStack>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default SuccessParrainage;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "95%",
    maxWidth: 400,
  },
});
