import {
  Image,
  Linking,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useState } from "react";
import DimensionsHook from "../../hooks/DimensionsHook";
import {
  BoldTxt,
  SmallBoldTxt,
  SmallTxt,
  Txt,
} from "../../components/TextsComponents";
import { useTranslation } from "react-i18next";
import { colors } from "../../styles/GlobalStyle";
import { Surface } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import Spinner from "../../components/Spinner";
import EmailModal from "./EmailModal";
import Purchases from "react-native-purchases";
import { setUserData } from "../../redux/Actions";

const Book = ({
  book,
  isGift,
  setErrorMessage,
  scrollToTop,
  setShowSuccessPayment,
  buyViaCompany,
  setShowSuccessBuyViaCompany,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { isDesktop, isBigScreen } = DimensionsHook();
  const [loader, setLoader] = useState(false);
  const [loaderPoints, setLoaderPoints] = useState(false);
  const [payWithPoints, setPayWithPoints] = useState(false);
  const { customer, email, firstname, lastname } = useSelector(
    (state) => state.userData
  );

  const [giftMail, setGiftMail] = useState("");
  const [giftFirstName, setGiftFirstName] = useState("");
  const [giftLastName, setGiftLastName] = useState("");
  const [showEmailModal, setShowEmailModal] = useState(false);
  const bookCardStyle = {
    width: isDesktop ? "48%" : "100%",
    marginHorizontal: isDesktop ? "1%" : 0,
    elevation: 2,
    backgroundColor: colors.white,
    padding: 15,
    marginTop: 20,
    borderRadius: 10,
    flexDirection: isDesktop ? "row" : "column",
  };

  const bookInAppPurchaseHandler = async () => {
    if (!loader) {
      setLoader(true);
      const price = book.ios_iap;

      try {
        await Purchases.purchaseProduct(price);
        await dispatch(setUserData());
        setShowSuccessPayment(true);
        setLoader(false);
      } catch (error) {
        console.log(error);
        setLoader(false);
      }
    }
  };

  const orderPressHandler = async (withPoints) => {
    setErrorMessage("");
    const toggleLoader = () => {
      if (withPoints) {
        setLoaderPoints(false);
      } else {
        setLoader(false);
      }
    };

    if (isGift) {
      setPayWithPoints(withPoints);
      setShowEmailModal(true);
    } else {
      if (!loader && !loaderPoints) {
        if (withPoints) {
          setLoaderPoints(true);
        } else {
          setLoader(true);
        }
        const url = process.env.BUY_BOOK;
        const TOKEN = await AsyncStorage.getItem("access_token");
        const body = {
          access_token: TOKEN,
          customer,
          price: withPoints ? book.stripe_points : book.stripe,
          email,
          firstname,
          lastname,
          withPoints,
          points: book.points,
        };

        axios
          .post(url, body)
          .then((res) => {
            toggleLoader();
            const sessionUrl = res.data.session.url;
            if (Platform.OS == "web") {
              window.open(sessionUrl, "_blank");
            } else {
              toggleLoader();
              Linking.openURL(sessionUrl);
            }
          })
          .catch((err) => {
            if (err.response.status === 403) {
              setErrorMessage(t("books.error_points"));
              toggleLoader();
              scrollToTop();
            } else {
              setErrorMessage(t("signup.error_connexion"));
              toggleLoader();
              scrollToTop();
            }
          });
      }
    }
  };

  const buyViaCompanyHandler = async () => {
    if (!loader) {
      setLoader(true);
      setErrorMessage("");
      const url = `${process.env.PAYMENT_APIS}/buy-book-via-company`;
      const TOKEN = await AsyncStorage.getItem("access_token");
      const body = {
        access_token: TOKEN,
        book: book._id,
      };

      axios
        .post(url, body)
        .then((res) => {
          setLoader(false);
          setShowSuccessBuyViaCompany(true);
        })
        .catch((err) => {
          setErrorMessage(t("signup.error_connexion"));
          setLoader(false);
          scrollToTop();
        });
    }
  };

  return (
    <Surface style={bookCardStyle}>
      <View style={{ width: isBigScreen ? 150 : isDesktop ? 120 : "100%" }}>
        <Image
          source={{ uri: book.image }}
          style={{
            height: 150,
            width: isBigScreen ? 150 : isDesktop ? 120 : "100%",
            backgroundColor: colors.white,
            marginTop: 10,
            marginRight: isDesktop ? 5 : 0,
          }}
          resizeMode="center"
        />
      </View>
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1 }}>
          <BoldTxt
            color={colors.green2}
            style={{ alignSelf: "flex-end", marginTop: 10, fontSize: 15 }}
          >
            {book.price}€ {t("tax")}
          </BoldTxt>
          <BoldTxt style={{ fontSize: 15 }}>
            {i18n.language == "fr" ? book.title_fr : book.title_en}
          </BoldTxt>

          <SmallTxt
            style={{ marginTop: 10, marginBottom: 40, textAlign: "justify" }}
          >
            {i18n.language == "fr" ? book.description_fr : book.description_en}
          </SmallTxt>
        </View>
        {buyViaCompany ? (
          <View>
            <View
              style={[styles.divider, { marginBottom: 10, marginTop: 0 }]}
            />
            <View style={styles.priceContainer}>
              <Txt style={{ fontSize: 15 }}>{t("books.free")}</Txt>

              <TouchableOpacity
                onPress={buyViaCompanyHandler}
                style={styles.button}
              >
                {loader ? (
                  <Spinner size="small" color={colors.white} />
                ) : (
                  <SmallBoldTxt color={colors.white}>
                    {t("books.get")}
                  </SmallBoldTxt>
                )}
              </TouchableOpacity>
            </View>
          </View>
        ) : (
          <View>
            {/** pay with eur */}
            <View style={styles.priceContainer}>
              <Txt style={{ fontSize: 15 }}>
                {book.price}€ {t("tax")}
              </Txt>

              <TouchableOpacity
                onPress={() => {
                  if (Platform.OS == "ios") {
                    bookInAppPurchaseHandler();
                  } else {
                    orderPressHandler(false);
                  }
                }}
                style={styles.button}
              >
                {loader ? (
                  <Spinner size="small" color={colors.white} />
                ) : (
                  <SmallBoldTxt color={colors.white}>
                    {t("books.pay")}
                  </SmallBoldTxt>
                )}
              </TouchableOpacity>
            </View>
            {Platform.OS != "ios" && <View style={styles.divider} />}
            {/** pay with eur + points */}
            {Platform.OS != "ios" && (
              <View style={styles.priceContainer}>
                <Txt style={{ fontSize: 15 }}>
                  {book.price_points}€ {t("tax")} + {book.points}{" "}
                  {t("score_screen.points")}
                </Txt>
                <TouchableOpacity
                  onPress={() => orderPressHandler(true)}
                  style={styles.button}
                >
                  {loaderPoints ? (
                    <Spinner size="small" color={colors.white} />
                  ) : (
                    <SmallBoldTxt color={colors.white}>
                      {t("books.pay")}
                    </SmallBoldTxt>
                  )}
                </TouchableOpacity>
              </View>
            )}
          </View>
        )}
      </View>

      {/** email to gift modal */}
      <EmailModal
        book={book}
        isOpen={showEmailModal}
        setIsOpen={setShowEmailModal}
        giftMail={giftMail}
        setGiftMail={setGiftMail}
        giftFirstName={giftFirstName}
        giftLastName={giftLastName}
        setGiftFirstName={setGiftFirstName}
        setGiftLastName={setGiftLastName}
        withPoints={payWithPoints}
      />
    </Surface>
  );
};

export default Book;

const styles = StyleSheet.create({
  orderButton: {
    width: "100%",
    height: 40,

    alignItems: "center",
    justifyContent: "center",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: "absolute",
    bottom: 0,
  },
  priceContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    height: 35,
    width: 100,
    borderRadius: 20,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.green2,
  },
  divider: {
    height: 1,
    backgroundColor: colors.grayLabel,
    width: "100%",
    marginVertical: 5,
  },
});
