import { View } from "react-native";
import React, { useState } from "react";

const CoachingsManagement = ({ }) => {
  return (
    <View>
      list coachings
    </View>
  );
};

export default CoachingsManagement;
