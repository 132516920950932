import {
  Pressable,
  StyleSheet,
  View,
  Platform,
  ImageBackground,
} from "react-native";
import React from "react";
import { Modal } from "native-base";
import { colors } from "../../styles/GlobalStyle";
import DimensionsHook from "../../hooks/DimensionsHook";
import { ArrowBack } from "../../assets/svg/Icons";
import Constants from "expo-constants";
import RenderHTML from "react-native-render-html";
import WebView from "react-native-webview";
import IframeRenderer, { iframeModel } from "@native-html/iframe-plugin";
import { useTranslation } from "react-i18next";

const renderers = {
  iframe: IframeRenderer,
};

const customHTMLElementModels = {
  iframe: iframeModel,
};

const TrailerVideo = ({ isOpen, setIsOpen }) => {
  const { isMobile, width, height } = DimensionsHook();
  const { i18n } = useTranslation();
  const WIDTH =
    Platform.OS == "macos"
      ? 850
      : isMobile
      ? width
      : width < 1000
      ? width * 0.9
      : width * 0.6;

  const closeHandler = () => {
    setIsOpen(false);
  };

  const link =
    i18n.language === "fr"
      ? "https://iframe.mediadelivery.net/embed/74303/eb81605e-3f1c-4087-90ff-d8b6b6480b60?autoplay=true"
      : "https://iframe.mediadelivery.net/embed/74303/8bf42f09-0921-454c-a4d9-618375410cb3?autoplay=true";

  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content
        style={[
          styles.modalContainer,
          {
            backgroundColor: isMobile ? colors.beige : colors.white,
            height: Platform.OS == "macos" ? 540 : isMobile ? "100%" : "auto",
            paddingTop: Platform.OS == "ios" ? Constants.statusBarHeight : 0,
            width: Platform.OS == "macos" ? 900 : isMobile ? WIDTH : WIDTH + 50,
          },
        ]}
      >
        <ImageBackground
          source={require("../../assets/background.png")}
          style={{ flex: 1 }}
        >
          {isMobile ? (
            <View style={styles.backContainer}>
              <Pressable onPress={closeHandler} style={styles.backButton}>
                <View onPress={closeHandler} style={{ width: 24, height: 24 }}>
                  <ArrowBack />
                </View>
              </Pressable>
            </View>
          ) : (
            <></>
          )}
          <Modal.Body
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",

              height: isMobile ? height - 70 : "100%",
              marginTop: Platform.OS === "ios" ? -Constants.statusBarHeight : 0,
            }}
          >
            <View
              style={{
                borderRadius: 0,
                flex: 1,
                alignItems: "center",
                marginTop: isMobile ? 0 : 20,
                marginBottom: isMobile ? 0 : 20,
                justifyContent: "center",
              }}
            >
              {/** iframe bunny */}
              {Platform.OS == "web" && isOpen ? (
                <View
                  style={{
                    alignSelf: "center",
                    width: WIDTH,
                    aspectRatio: "16/9",

                    cursor: "pointer",
                    backgroundColor: colors.grayBackground,
                    alignSelf: "center",
                    alignItems: "center",
                    justifyContent: "center",

                    overflow: "hidden",
                  }}
                >
                  <iframe
                    allowFullScreen
                    loading="eager"
                    id="a"
                    src={link}
                    style={{ width: "100%", height: "100%" }}
                  ></iframe>
                </View>
              ) : isOpen ? (
                <View style={{ flex: 1 }}>
                  <RenderHTML
                    renderers={renderers}
                    WebView={WebView}
                    contentWidth={width}
                    source={{
                      html: `
        <div style="width:100%; height:100%; background-color:transparent; align-items:center;justify-content:center;">
        <iframe src="${link}" 
      loading="eager" style="border: none;  width: 100%;  color:green;" 
      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true">
  </iframe>
  </div>
  `,
                    }}
                    customHTMLElementModels={customHTMLElementModels}
                    defaultWebViewProps={{}}
                    renderersProps={{
                      iframe: {
                        scalesPageToFit: true,
                        webViewProps: {},
                      },
                    }}
                  />
                </View>
              ) : (
                <></>
              )}
            </View>
          </Modal.Body>
        </ImageBackground>
      </Modal.Content>
    </Modal>
  );
};

export default TrailerVideo;

const styles = StyleSheet.create({
  backContainer: {
    height: 70,
    width: "100%",
    flexDirection: "row",
    backgroundColor: colors.beige,
    borderBottomWidth: 1,
    borderColor: colors.grayBorder,
  },
  backButton: {
    height: 70,
    width: 70,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1B496515",
  },

  modalContainer: {
    maxWidth: 5000,
    maxHeight: 5000,
  },
});
