import { StyleSheet, ImageBackground } from "react-native";
import React from "react";
import { Modal, VStack, View } from "native-base";
import { colors } from "../../../styles/GlobalStyle";
import { Txt } from "../../TextsComponents";
import DimensionsHook from "../../../hooks/DimensionsHook";

import { useTranslation } from "react-i18next";
import { PrimaryButton, SecondaryButton } from "../../Buttons";
import { AntDesign } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useSelector } from "react-redux";

const NavigateToQuestionnaireModal = ({
  isOpen,
  setIsOpen,
  navigation,
  amontQuestionnaire,
  setShowTrailVideoModal,
  trail_code,
  trail,
  episodeData,
  seasonData,

}) => {
  const { t } = useTranslation();
  const { isMobile } = DimensionsHook();
  const { _id } = useSelector(state => state.userData)

  const closeHandler = () => {
    setIsOpen(false);
    setShowTrailVideoModal(true);

  };

  const amont = `amont_${_id}_${trail_code}`;

  const toReturnData = { ...trail, episodeData, seasonData };
  return (
    <Modal isOpen={isOpen} onClose={closeHandler}>
      <Modal.Content style={styles.container}>
        <Modal.Body>
          <ImageBackground
            source={require("../../../assets/background.png")}
            style={{ flex: 1 }}
          >
            <Modal.CloseButton
              focusable={false}
              style={{ marginRight: isMobile ? 0 : 10 }}
            />
            <VStack
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 40 }}
            >
              <VStack>
                <AntDesign
                  name="questioncircle"
                  size={60}
                  color={colors.blue3}
                />
              </VStack>
              <Txt
                style={{
                  marginTop: 30,

                  textAlign: "center",
                  marginHorizontal: isMobile ? 20 : 30,
                }}
              >
                {t("questionnaires.text5")}
              </Txt>
              <Txt
                style={{
                  marginTop: 10,

                  textAlign: "center",
                  marginHorizontal: isMobile ? 20 : 30,
                }}
              >
                {t("questionnaires.text6")}
              </Txt>
              <View style={{ marginVertical: 30 }}>
                <PrimaryButton
                  onPress={() => {
                    setIsOpen(false);

                    navigation.navigate("Questionnaire", {
                      ...amontQuestionnaire,
                      toReturnData,
                    });
                  }}
                >
                  {t("questionnaires.button")}
                </PrimaryButton>
                <SecondaryButton
                  style={{ marginTop: 15 }}
                  onPress={async () => {
                    await AsyncStorage.setItem(amont, "true");
                    setIsOpen(false);
                    setShowTrailVideoModal(true);
                  }}
                >
                  {t("questionnaires.not_show")}
                </SecondaryButton>
              </View>
            </VStack>
          </ImageBackground>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default NavigateToQuestionnaireModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "95%",
    maxWidth: 700,
    maxHeight: 5000,
  },
});
