import { AlertDialog, Button } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../styles/GlobalStyle";
import Spinner from "../Spinner";
import { BoldTxt, SmallBoldTxt, Txt } from "../TextsComponents";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { View } from "react-native";
import DimensionsHook from "../../hooks/DimensionsHook";

const ResetUserModal = ({ isOpen, setIsOpen, navigation }) => {
  const { t } = useTranslation();

  const [loader, setLoader] = useState(false);
  const { isMobile } = DimensionsHook();
  const [errorMessage, setErrorMessage] = useState(null);

  const cancelRef = React.useRef(null);

  const onClose = () => {
    if (!loader) {
      setIsOpen(false);
    }
  };

  const resetAccountHander = async () => {
    if (!loader) {
      const TOKEN = await AsyncStorage.getItem("access_token");
      setLoader(true);
      setErrorMessage(null);

      const url = process.env.RESET_USER;
      axios
        .post(url, { access_token: TOKEN })
        .then(() => {
          setTimeout(async () => {
            setLoader(false);
            navigation.reset({
              index: 0,
              routes: [{ name: "Loader" }],
            });
          }, 1000);
        })
        .catch((err) => {
          setErrorMessage(t("unsubscribe.error_connexion"));
          setLoader(false);
        });
    }
  };

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialog.Content
        style={{
          width: "95%",
          maxWidth: 900,
        }}
      >
        <AlertDialog.CloseButton focusable={false} />

        <AlertDialog.Header>
          <BoldTxt style={{ fontSize: 16 }}> </BoldTxt>
        </AlertDialog.Header>
        <AlertDialog.Body style={{ padding: isMobile ? 5 : 30 }}>
          <BoldTxt
            style={{
              color: colors.red1,
              textAlign: "center",
              marginBottom: 10,
              fontSize: 17,
            }}
          >
            {t("reset_modal.text1")}
          </BoldTxt>
          <Txt style={{ textAlign: "justify", marginTop: 15, fontSize: 17 }}>
            {t("reset_modal.text2")}
          </Txt>
          <Txt style={{ textAlign: "justify", marginTop: 15, fontSize: 17 }}>
            {t("reset_modal.text3")}
          </Txt>
          <Txt style={{ textAlign: "justify", marginTop: 15, fontSize: 17 }}>
            {t("reset_modal.text4")}
          </Txt>
          <Txt style={{ textAlign: "justify", marginTop: 15, fontSize: 17 }}>
            {t("reset_modal.text5")}
          </Txt>
          <SmallBoldTxt
            style={{
              color: colors.red1,
              fontSize: 12,
              textAlign: "center",
              marginBottom: 10,
            }}
          >
            {errorMessage ? errorMessage : " "}
          </SmallBoldTxt>
          <Button.Group alignSelf="center" space={2}>
            <Button
              focusable={false}
              style={{
                backgroundColor: colors.green2,
                color: colors.white,
                height: 40,
                width: 100,
              }}
              onPress={onClose}
              ref={cancelRef}
            >
              <Txt style={{ textAlign: "justify", color: colors.white }}>
                {t("delete_account_modal.cancel")}
              </Txt>
            </Button>
            <Button
              focusable={false}
              style={{
                backgroundColor: "transparent",
                color: "#1b4965",
                borderWidth: 1,
                borderColor: "#1b4965",
                width: 200,
                height: 40,
              }}
              onPress={resetAccountHander}
            >
              {loader ? (
                <Spinner color={"#1b4965"} />
              ) : (
                <Txt
                  style={{
                    color: "#1b4965",
                  }}
                >
                  {t("reset_modal.button")}
                </Txt>
              )}
            </Button>
          </Button.Group>
        </AlertDialog.Body>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default ResetUserModal;
