import { StyleSheet, View, Platform, Linking } from "react-native";
import React, { useState, useEffect } from "react";
import DimensionsHook from "../../../hooks/DimensionsHook";
import {
  BoldTxt,
  H1,
  H2,
  SmallBoldTxt,
  SmallTxt,
  Txt,
} from "../../TextsComponents";
import { colors } from "../../../styles/GlobalStyle";
import { PrimaryButton } from "../../Buttons";
import axios from "axios";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner";
import { useTranslation } from "react-i18next";
import NumberOfTrails from "./NumberOfTrails";
import InvoiceItem from "./InvoiceItem";
import moment from "moment";

const CompanyUser = ({ navigation }) => {
  const { width } = DimensionsHook();
  const isDesktop = width > 1000;
  const userData = useSelector((state) => state.userData);
  const { t } = useTranslation();

  const [loader, setLoader] = useState(true);
  // data = invoices
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoader(true);
    const invoiceUrl = process.env.USER_INVOICES;
    axios
      .get(`${invoiceUrl}?customerId=${userData.customer}`)
      .then((InvoicesRes) => {
        setData(InvoicesRes.data);
        setTimeout(() => {
          setLoader(false);
        }, 1500);
      });
  }, []);

  return (
    <View>
      <View
        style={{
          marginTop: isDesktop ? 0 : 20,
          marginBottom: 10,
        }}
      >
        <H1>{t("plans.company_subscription")}</H1>
        <View style={styles.row}>
          <BoldTxt>{t("plans.company")}</BoldTxt>
          <SmallTxt>{userData.company_name}</SmallTxt>
        </View>
        <View style={styles.row}>
          <BoldTxt>{t("plans.period")}</BoldTxt>
          <SmallTxt>
            {moment(userData.company_start_date).format("DD/MM/YYYY")}-{" "}
            {moment(userData.company_end_date).format("DD/MM/YYYY")}
          </SmallTxt>
        </View>
      </View>

      <View style={styles.divider} />
      {/** num of allowed trails */}
      {userData.demo ? <></> : <NumberOfTrails navigation={navigation} />}
      {/** historique factures */}
      {data.length > 0 && (
        <View style={{ marginTop: 20 }}>
          <H2>{t("plans.invoices")}</H2>
          {loader ? (
            <View style={{ marginVertical: 20 }}>
              <Spinner size="large" />
            </View>
          ) : (
            data.map((invoice) => {
              return <InvoiceItem setData={setData} key={invoice._id} invoice={invoice} />;
            })
          )}
        </View>
      )}
    </View>
  );
};

export default CompanyUser;

const styles = StyleSheet.create({
  badge: {
    backgroundColor: colors.green2,
    paddingHorizontal: 15,
    borderRadius: 100,
    paddingVertical: 5,
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: colors.grayBorder,
    marginTop: 20,
  },
  row: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 0.75,
    borderColor: colors.grayBackground,
    marginTop: 15,
    paddingBottom: 10,
  },
});
