import { StyleSheet, View } from "react-native";
import React, { useEffect, useState } from "react";
import { BoldTxt, H6, SmallBoldTxt, Txt } from "../../TextsComponents";
import { useSelector } from "react-redux";
import LoaderEffect from "../../LoaderEffect";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { colors } from "../../../styles/GlobalStyle";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import DimensionsHook from "../../../hooks/DimensionsHook";

const EpisodesScore = () => {
  const [loader, setLoader] = useState(true);
  const [currentTrails, setCurrentTrails] = useState([]);
  const { current_trails } = useSelector((state) => state.userActivity);
  const { width } = DimensionsHook();
  const watchedEpisodes = useSelector((state) => state.watchedEpisodes);
  const { i18n } = useTranslation();

  const getTrails = async () => {
    const TOKEN = await AsyncStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    };
    axios
      .get(`${process.env.CURRENT_TRAILS}?language=${i18n.language}`, config)
      .then((res) => {
        setCurrentTrails(res.data);

        setLoader(false);
      });
  };

  useEffect(() => {
    getTrails();
  }, [current_trails]);

  if (loader) {
    return (
      <View style={{ marginTop: 20 }}>
        <LoaderEffect width="100%" height={200} />
      </View>
    );
  }
  return (
    <View style={{ marginTop: 20 }}>
      {currentTrails.map((trail) => {
        const episodes = watchedEpisodes.filter((ep) =>
          ep.includes(trail.trail_code)
        );
        return (
          <View key={trail.trail_code} style={styles.trailContainer}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  width: 60,
                }}
              >
                <MaterialCommunityIcons
                  name="arrow-up-bold"
                  size={30}
                  color={colors.green2}
                />
                <BoldTxt color={colors.green2}>{episodes.length * 2}</BoldTxt>
              </View>
              <View style={{ flex: 1, marginLeft: 15 }}>
                <H6
                  style={{
                    fontSize: 18,
                    lineHeight: 24,
                  }}
                >
                  {trail.title}{" "}
                  <H6
                    style={{ fontSize: 18, lineHeight: 24, color: "#8a8686" }}
                  >
                    ({episodes.length} episodes regardés)
                  </H6>
                </H6>
              </View>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default EpisodesScore;

const styles = StyleSheet.create({
  trailContainer: {
    backgroundColor: "#FBF6F325",
    borderBottomWidth: 1,
    borderBottomColor: colors.grayBorder,
    paddingVertical: 10,
  },
});
