import {
  ImageBackground,
  Image,
  StyleSheet,
  View,
  TouchableOpacity,
} from "react-native";
import React, { useState } from "react";
import { H2, SmallBoldTxt, SmallTxt, Txt } from "../components/TextsComponents";
import { colors } from "../styles/GlobalStyle";
import { Input, NumericInput } from "../components/Inputs";
import { PrimaryButton } from "../components/Buttons";
import BackHeader from "../components/BackHeader";
import Spinner from "../components/Spinner";
import DimensionsHook from "../hooks/DimensionsHook";
import axios from "axios";
import { useToast } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import { useTranslation } from "react-i18next";
import { TextInput } from "react-native-paper";

const ChangePassword = ({ navigation, email }) => {

  const { width } = DimensionsHook();
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [isSecure, setIsSecure] = useState(true);
  const [isConfirmSecure, setIsConfirmSecure] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const { isDesktop } = DimensionsHook();
  const [errorMessage, setErrorMessage] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const { t } = useTranslation();
  const toast = useToast();

  const toggleSecure = () => setIsSecure(!isSecure);
  const toggleConfirmSecure = () => setIsConfirmSecure(!isConfirmSecure);

  const setAsyncTimeout = (cb, timeout = 0) =>
    new Promise((resolve) => {
      setTimeout(() => {
        cb();
        resolve();
      }, timeout);
    });

  const submitHandler = async () => {
    try {
      setSubmitLoader(true);
      setErrorMessage("");
      const strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[#?!@$%^&*-])(?=.{8,})"
      );

      if (code.length != 6 || isNaN(code)) {
        setErrorMessage(t("validation.error_code"));
        setSubmitLoader(false);
        return;
      }
      const passwordTest = password.length >= 8;

      if (passwordTest == false) {
        setSubmitLoader(false);
        setErrorMessage(t("signup.error_password"));
        return;
      }
      if (confirmPassword != password) {
        setSubmitLoader(false);
        setErrorMessage(t("signup.error_confirm"));
        return;
      }
      const payload = { email, code, password };
      const Reponse = await axios.post(
        process.env.USER_CHANGE_FORGOT_PASSWORD,
        payload
      );
      await setAsyncTimeout(() => {
        // Do stuff
      }, 1000);

      await toast.show({
        placement: "bottom",
        render: () => {
          return (
            <View style={styles.toastContainer}>
              <Txt
                style={{ paddingLeft: isDesktop ? 5 : 0 }}
                color={colors.white}
              >
                {t("signin.success_change_password")}
              </Txt>
              <TouchableOpacity
                style={{ paddingHorizontal: 5 }}
                onPress={() => toast.closeAll()}
              >
                <AntDesign name="close" size={16} color={colors.white} />
              </TouchableOpacity>
            </View>
          );
        },
      });
      navigation.replace("Login");
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setErrorMessage(t("validation.error_code"));
      } else {
        setErrorMessage(t("reset_pass_screen.error_connexion"));
      }
    }

    setSubmitLoader(false);
  };

  return (
    <View style={styles.container}>
      <BackHeader isOpen={false} setIsOpen={() => { }} navigation={navigation} />
      <ImageBackground
        source={require("../assets/background.png")}
        style={{ flex: 1 }}
      >
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            marginTop: -20,
          }}
        >
          <H2 style={{ textAlign: "center" }}>
            {t("reset_pass_screen.title")}
          </H2>
          <SmallBoldTxt
            style={{
              color: colors.red1,
              textAlign: "center",
              marginTop: 10,
            }}
          >
            {errorMessage.length > 0 ? errorMessage : " "}
          </SmallBoldTxt>
          <View style={{ width: "95%", maxWidth: 500, marginVertical: 20 }}>
            {/** code */}
            <NumericInput
              placeholder={t("placeholders.code_reset")}
              value={code}
              setValue={setCode}
              maxLength={6}
            />
            {/** password */}
            <Input
              mode="outlined"
              style={{ marginTop: 10 }}
              placeholder={t("placeholders.new_password")}
              secureTextEntry={isSecure}
              value={password}
              onChangeText={(val) => setPassword(val)}
              right={
                isSecure ? (
                  <TextInput.Icon
                    forceTextInputFocus={false}
                    onPress={toggleSecure}
                    name={"eye"}
                    size={24}
                    color={colors.blue3}
                  />
                ) : (
                  <TextInput.Icon
                    forceTextInputFocus={false}
                    onPress={toggleSecure}
                    name="eye-off"
                    size={24}
                    color={colors.blue3}
                  />
                )
              }
            />
            {/** confirm password */}
            <Input
              mode="outlined"
              style={{ marginTop: 10 }}
              placeholder={t("placeholders.confirm_new_password")}
              secureTextEntry={isConfirmSecure}
              value={confirmPassword}
              onChangeText={(val) => setConfirmPassword(val)}
              right={
                isConfirmSecure ? (
                  <TextInput.Icon
                    forceTextInputFocus={false}
                    onPress={toggleConfirmSecure}
                    name={"eye"}
                    size={24}
                    color={colors.blue3}
                  />
                ) : (
                  <TextInput.Icon
                    forceTextInputFocus={false}
                    onPress={toggleConfirmSecure}
                    name="eye-off"
                    size={24}
                    color={colors.blue3}
                  />
                )
              }
            />
            <PrimaryButton
              onPress={submitHandler}
              style={{ marginVertical: 30 }}
            >
              {submitLoader ? (
                <Spinner
                  color={submitLoader ? colors.white : colors.blue3}
                  size="small"
                />
              ) : (
                t("reset_pass_screen.button1")
              )}
            </PrimaryButton>
          </View>
        </View>
      </ImageBackground>
      {width > 1000 && (
        <Image
          source={{
            uri: "https://firebasestorage.googleapis.com/v0/b/khalil-chikhaoui.appspot.com/o/mascotte.png?alt=media&token=b9dbf40d-3a50-496a-ad1a-47b2c9929728",
          }}
          style={styles.image}
        />
      )}
    </View>
  );
};

export default ChangePassword;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.beige,
  },
  image: {
    position: "absolute",
    bottom: 0,
    right: 20,
    height: 230,
    width: 140,
  },

  toastContainer: {
    backgroundColor: colors.green2,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 20,
  },
});
